import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Box from '@material-ui/core/Box';
import { Fullscreen as FScreen, FullscreenExit, FileTree } from 'mdi-material-ui';
import Fullscreen from "react-full-screen";
import Dock from 'react-dock';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CheckBox from 'devextreme-react/check-box';
import DashBoardSummary from './DashBoardSummary';
import DashBoardNetworkMonitoring from './DashBoardNetworkMonitoring';
import DashBoardGeoMap from './DashBoardGeoMap';
//import DashBoardGeoVecotorMap from './DashBoardGeoVecotorMap';
//import DashBoardGeoVecotorMapBox from './DashBoardGeoVecotorMapBox';
import Events from './Events';
import NetworkHierarchy from './network-hierarchy/NetworkHierarchy';
//import GeoMap from './geo-map/Map';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const styles = theme => ({
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: 48, //theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
	cardContent: {
		padding: theme.spacing(1),
        overflow: 'auto',
        height: `calc(100vh - 109px)`, //`calc(100vh - ${theme.mixins.gridOffset}px)`,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	cardContentFull: {
		padding: theme.spacing(1),
        overflow: 'auto',
        height: '100vh',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
    tabs: {
        float: 'left'
    },
    expander: {
        float: 'right'
    },
    dbBtn: {

    },
    dbIcon: {

    },
    dbBtnPicker: {
        position: 'fixed',
        zIndex: 2,
        right: 0,
        top: 230,
        background: fade(theme.palette.background.paper, 0.8),
        borderRadius: '30px 0 0 30px',
        overflow: 'hidden',
        border: `1px solid ${theme.palette.grey[300]}`,
        '& $dbBtn': {
            background: theme.palette.secondary.main,
            borderRadius: 30,
            padding: 8,
            //boxShadow: theme.shadows(4),
            display: 'flex',
            alignItems: 'center',
            width: 40,
            height: 40,
            textCenter: 'center',
            overflow: 'hidden',
            color: 'transparent',
            fontSize: '0.775rem',
            transition: 'all 0.3s ease',
            '& $dbIcon': {
                color: theme.palette.background.paper
            },
            '&:hover': {
                color: theme.palette.background.paper,
                width: 190
            }
        }
    }
});

class DashBoard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            isFullScreen: false,
            showEventsGrid: false,
            showHeirarchyGrid: false
        };
    }

    componentWillMount() {

    }

    componentDidMount() {

    };
    componentWillUnmount () {

    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
    handleChange = (event, newValue) => {
        this.setState({value: newValue})
    };
    handleDock = () => {
		this.setState({showEventsGrid: false})
	};
    handleCheckBoxValue = (args) => {
        this.setState({showEventsGrid: args.value})
    };
    handleSummaryTreeGrid = () => {
        this.setState({showHeirarchyGrid: !this.state.showHeirarchyGrid})
    };
render() {
        const { classes, raised } = this.props;
        const { isFullScreen, value, showEventsGrid, showHeirarchyGrid } = this.state;
        const title = 'Dashboard';
        return (
            <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                <div className="full-screenable-node">
                    <Card className={classes.root} raised={raised}>
                        <CardHeader className={classes.cardHeader} title={title}
                            action={
                                <Fragment>
                                    <div className={classes.tabs} id={'tabs'}>
                                        <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                                          <Tab value={0} label="Summary" {...a11yProps(0)} component={Link} to="/" />
                                          <Tab value={1} label="Monitoring" {...a11yProps(1)} component={Link} to="/monitoring" />
                                          <Tab value={2} label="Geo Map" {...a11yProps(2)} component={Link} to="/geo-map" />
                                            {/*<Tab value={3} label="Geo Vector Map" {...a11yProps(3)} component={Link} to="/geo-vector-map" />
                                          <Tab value={4} label="Geo Vector Map Box" {...a11yProps(4)} component={Link} to="/geo-vector-map-box" />*/}
                                        </Tabs>
                                    </div>
                                    <div className={classes.expander}>
                                        { value === 0 && <CheckBox hint='Show Events' defaultValue= {false} value={showEventsGrid} onValueChanged={this.handleCheckBoxValue} elementAttr={{ id: 'dashboardCheckBox' }} /> }
                                        <IconButton onClick={this.handleFullScreen}>
                                            { isFullScreen ? <FullscreenExit /> : <FScreen /> }
                                        </IconButton>
                                    </div>
                                </Fragment>
                            }
                        />
					    <CardContent className={isFullScreen ? classes.cardContentFull : classes.cardContent}>
                            <div id={'creadlepoint-tabs'}>
                              <TabPanel value={value} index={0}>
                                  <DashBoardSummary isFullScreen={isFullScreen}/>
                              </TabPanel>
                               <TabPanel value={value} index={1}>
                                   { value === 1 && <DashBoardNetworkMonitoring isFullScreen={isFullScreen}/> }
                              </TabPanel>
                              <TabPanel value={value} index={2}>
                                  { value === 2 && <DashBoardGeoMap isFullScreen={isFullScreen}/> }
                              </TabPanel>
                                {/* <TabPanel value={value} index={3}>
                                  <DashBoardGeoVecotorMap isFullScreen={isFullScreen}/>
                              </TabPanel>
                              <TabPanel value={value} index={4}>
                                  <DashBoardGeoVecotorMapBox isFullScreen={isFullScreen}/>
                              </TabPanel>*/}
                            </div>
					    </CardContent>
						<Dock position={'bottom'} fluid={true} dimMode='none' zIndex='1250' isVisible={ showEventsGrid }>
                            <Events handleDock={this.handleDock} showHeader={true}/>
						</Dock>
                        <Dock position={'right'} fluid={true} dimMode='none' zIndex='1250' isVisible={ showHeirarchyGrid }>
                            <NetworkHierarchy handleDock={this.handleSummaryTreeGrid} showHeader={true}/>
						</Dock>
                        <Button onClick={this.handleSummaryTreeGrid} className={classes.dbBtnPicker}>
                            <span className={classes.dbBtn}>
                                <FileTree className={classes.dbIcon}/>&nbsp;&nbsp;Network Hierarchy
                            </span>
                        </Button>
                    </Card>
                </div>
            </Fullscreen>
        )
    }
}

DashBoard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(DashBoard);
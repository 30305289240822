import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import compose from 'recompose/compose';
import { Fullscreen as FScreen, FullscreenExit } from 'mdi-material-ui';
import Fullscreen from "react-full-screen";
import InfoBloxGridMain from './InfoBloxGridMain';
//import InfoBloxGridStats from './InfoBloxGridStats';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: 48,//theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    tabs: {
        float: 'left'
    },
    expander: {
        float: 'right'
    }
});

class Infoblox extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            isFullScreen: false,

        };
    }
    componentWillMount() {

    }
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
    handleChange = (event, newValue) => {
    this.setState({value: newValue})
  };
render() {
        const { classes, raised } = this.props;
        const { isFullScreen, value } = this.state;
        const title = 'Infoblox';
        return (
            <Fullscreen enabled={isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                <div className="full-screenable-node">
                    <Card className={classes.root} raised={raised}>
                        <CardHeader className={classes.cardHeader} title={title}
                            action={
                                <Fragment>
                                    <div className={classes.tabs} id={'tabs'}>
                                        <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                                          <Tab value={0} label="Search" {...a11yProps(0)} />
                                            {/*<Tab value={1} label="Stats" {...a11yProps(1)} />*/}
                                        </Tabs>
                                    </div>
                                    <div className={classes.expander}>
                                        <IconButton onClick={this.handleFullScreen}>
                                            { isFullScreen ? <FullscreenExit /> : <FScreen /> }
                                        </IconButton>
                                    </div>
                                </Fragment>
                            }
                        />
                        <div id={'infoblox-tabs'}>
                          <TabPanel value={value} index={0}>
                            <InfoBloxGridMain isFullScreen={isFullScreen}/>
                          </TabPanel>
                            {/*<TabPanel value={value} index={1} disabled>
                            <InfoBloxGridStats isFullScreen={isFullScreen}/>
                          </TabPanel>*/}
                        </div>
                    </Card>
                </div>
            </Fullscreen>
        )
    }
}

Infoblox.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(Infoblox);
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Moment from 'moment';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import red from '@material-ui/core/colors/red';
import { withOktaAuth } from '@okta/okta-react';

const styles = theme => ({
	  root: {
	    flexGrow: 1
	  },
	  card: {
		  height: '100%'
	  },
	  leftIcon: {
	    marginRight: theme.spacing.unit,
	  },
	  avatar: {
	    backgroundColor: red[500],
        color: '#fff'
	  },
	  media: {
	    height: 500,
	  },
	  tableRow: {
	    backgroundColor: theme.palette.common.black,
	    color: theme.palette.common.white,
	    fontSize: 14
	  }
	});

class UserContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		    hasErrors: false,
            userInfo: null
		};
		this.getCurrentUser = this.getCurrentUser.bind(this);
	};
    async getCurrentUser () {
		const userInfo = await this.props.oktaAuth.getUser();
        this.setState({ userInfo });
    }
	/**
	 * componentDidMount: 
	 */
    componentDidMount() {
        this.getCurrentUser();
    };
    componentDidUpdate() {
        //this.checkAuthentication();
    };
    /**
     * componentDidCatch: 
     */
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Error: '+info);
    };
	render() {
		const { classes } = this.props;
		const { hasErrors, userInfo} = this.state;
		return (
				<div className={classes.root}>
					{hasErrors ?
						<Grid container spacing={8}>
							<div>Page has erros, please fix!!!</div>
						</Grid>
					:
						<Grid container spacing={8}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
                                { userInfo && <div>
							        <Card className={classes.card}>
							          <CardHeader
							            avatar={
							              <Avatar
								              alt={_.toUpper(userInfo['name'].substring(2))}
								              src={userInfo.picture}
								              className={classes.avatar}
                                          />
							            }
							            title={_.toUpper(userInfo['name'])}
							            subheader={Moment().format("MM/DD/YYYY HH:mm A")}
							          />
							          <Table className={classes.table} size='small'>

								          <TableBody>
							                <TableRow>
							                	<TableCell className={classes.tableRow} component="th" scope="row"> User Details </TableCell>
							                	<TableCell className={classes.tableRow}></TableCell>
							                </TableRow>
							                <TableRow>
							                  <TableCell component="th" scope="row"> Name </TableCell>
							                  <TableCell>{userInfo['name']}</TableCell>
							                </TableRow>
                                            <TableRow>
                                              <TableCell component="th" scope="row"> User Name </TableCell>
                                              <TableCell>{userInfo['nickname']}</TableCell>
                                            </TableRow>
							                <TableRow>
							                  <TableCell component="th" scope="row"> E-mail </TableCell>
							                  <TableCell>{userInfo['email']}</TableCell>
							                </TableRow>
							                <TableRow>
							                  <TableCell component="th" scope="row"> Roles </TableCell>
							                  <TableCell>{userInfo['role']}</TableCell>
							                </TableRow>
								          </TableBody>
								        </Table>

							        </Card>
							      </div>}
							</Grid>
					    </Grid>
					}
				</div>
		)
	}
}
UserContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
	  theme: PropTypes.object.isRequired
};
export default withOktaAuth(withRouter(withStyles(styles, {withTheme: true})(UserContainer)));

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { Template } from 'devextreme-react/core/template';

import { DataGrid, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, Scrolling, Export } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';

import fetchClient  from '../../../axios';
//import * as constants from '../../../constants';
import { textFormatter } from "../../common/utils";
//import NetworkEventsRowActionsMenu from './NetworkEventsRowActionsMenu';

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    cardHeader: {
        padding: `${theme.spacing.unit} !important`,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

const ImpactedCustomersGridColumns = [
    { dataField: "customer", caption: "Name", customizeText: textFormatter, sortOrder: 'asc'},
    { dataField: "device_count", caption: "Device Count"}
];
class ImpactedCustomers extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {

        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.renderGridTitle = this.renderGridTitle.bind(this);
        this.dataGrid = null;
    }
    componentWillMount() {
        //this.getNetworksData();
    }

    componentDidMount() {
        //this.NetworksTask = setInterval(()=> this.getNetworksData(), constants.TASK_TIME)
    };
    componentWillUnmount () {
        //clearTimeout(this.NetworksTask);
    };
    refreshDataGrid () {
        this.dataGrid.instance.refresh();
    };
    renderGridTitle = () => {
        return (
            <div>
                <Typography variant={"h5"} component={"h5"}>Impacted Devices</Typography>
            </div>)
    };
    onRowPrepared = (e) => {

    };
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onToolbarPreparing = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, exportButton, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                        exportButton = item;
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push({
                    location: 'before',
                    template: 'gridTitleTemplate'
                }, searchPanel , exportButton, columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                }
                );
    };
    render(){
        const { classes, raised=false, group_name } = this.props;
        //const { query } = this.state;
        const networkEventsDataStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                        let params = { 'device_group': group_name};
                        return fetchClient.get('/api/v1.0/es/impacted-customers', {params: params})
                            .then(res => {
                                let ImpactedCustomersData = [];
                                let ImpactedCustomersDataTotal = 0;
                                if (res.data && res.data.data) {
                                    ImpactedCustomersData = res.data.data;
                                    ImpactedCustomersDataTotal = res.data.total;
                                }
                                return {
                                    data: ImpactedCustomersData,
                                    totalCount: ImpactedCustomersDataTotal
                                };
                            })
                            .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
        return (
            <Card className={classes.root} raised={raised}>
                <DataGrid id={'ImpactedCustomersGrid'}
                    ref={(ref) => this.dataGrid = ref}
                    height={'100%'}
                    dataSource={networkEventsDataStore}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    repaintChangesOnly={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    //columnWidth={100}
                    //columnMinWidth={70}
                    cellHintEnabled={true}
                    onContentReady={this.onContentReady}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onRowPrepared={this.onRowPrepared}
                    >
                    <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    <Export enabled={true} fileName={'ActiveNetworkEvents'} />
                    {
                        ImpactedCustomersGridColumns.map(row => {
                          return (
                            // eslint-disable-next-line
                            <Column dataField={row.dataField} caption={row.caption} width={row.width} alignment={row.alignment} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)} fixed={row.fixed} sortOrder={row.sortOrder}/>
                          );
                        })
                    }
                    <Paging defaultPageSize={25}/>
                    <Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[15, 25, 50, 100]}/>
                    <Template name="gridTitleTemplate" render={this.renderGridTitle}/>
                </DataGrid>
            </Card>
        )
    }
}

ImpactedCustomers.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(ImpactedCustomers);
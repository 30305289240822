import React, { Fragment, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Fullscreen as FScreen, FullscreenExit } from 'mdi-material-ui';
import Fullscreen from "react-full-screen";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SFCases from './SFCases';
//import ActiveNotifications from './ActiveNotifications';
//import Dispatches from './Dispatches';
//import TechSupportTasks from './TechSupportTasks';
import SFActiveNotifications from './SFActiveNotifications';
import SFDispatches from './SFDispatches';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const styles = theme => ({
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: 48, //theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
	cardContent: {
		padding: theme.spacing(1),
        overflow: 'auto',
        height: `calc(100vh - 109px)`, //`calc(100vh - ${theme.mixins.gridOffset}px)`,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	cardContentFull: {
		padding: theme.spacing(1),
        overflow: 'auto',
        height: '100vh',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
    tabs: {
        float: 'left'
    },
    expander: {
        float: 'right'
    }
});

class NMCTools extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            isFullScreen: false
        };
    }

    componentWillMount() {

    }

    componentDidMount() {

    };
    componentWillUnmount () {

    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
    handleChange = (event, newValue) => {
        this.setState({value: newValue})
    };
render() {
        const { classes, raised } = this.props;
        const { isFullScreen, value } = this.state;
        const title = 'NMC Tools';
        return (
            <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                <div className="full-screenable-node">
                    <Card className={classes.root} raised={raised}>
                        <CardHeader className={classes.cardHeader} title={title}
                            action={
                                <Fragment>
                                    <div className={classes.tabs} id={'tabs'}>
                                        <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                                          <Tab value={0} label="SF Cases" {...a11yProps(0)} component={Link} to="/sf-cases" />
                                          <Tab value={1} label="SF Active Notifications" {...a11yProps(1)} component={Link} to="/sf-active-notifications" />
                                          <Tab value={2} label="SF Dispatches" {...a11yProps(2)} component={Link} to="/sf-dispatches" />
                                            {/*<Tab value={2} label="Active Notifications" {...a11yProps(2)} component={Link} to="/active-notifications" />
                                          <Tab value={3} label="Tech Support Tasks" {...a11yProps(3)} component={Link} to="/tech-support-tasks" />
                                          <Tab value={4} label="Replacements/Dispatches" {...a11yProps(4)} component={Link} to="/replacements-dispatches" />*/}
                                        </Tabs>
                                    </div>
                                    <div className={classes.expander}>
                                        <IconButton onClick={this.handleFullScreen}>
                                            { isFullScreen ? <FullscreenExit /> : <FScreen /> }
                                        </IconButton>
                                    </div>
                                </Fragment>
                            }
                        />
					    <CardContent className={isFullScreen ? classes.cardContentFull : classes.cardContent}>
                            <div id={'nmctools-tabs'}>
                              <TabPanel value={value} index={0}>
                                <SFCases isFullScreen={isFullScreen}/>
                              </TabPanel>
                              <TabPanel value={value} index={1}>
                                <SFActiveNotifications isFullScreen={isFullScreen}/>
                              </TabPanel>
                              <TabPanel value={value} index={2}>
                                <SFDispatches isFullScreen={isFullScreen}/>
                              </TabPanel>
                                {/*<TabPanel value={value} index={2}>
                                <ActiveNotifications isFullScreen={isFullScreen}/>
                              </TabPanel>
                               <TabPanel value={value} index={3}>
                                <TechSupportTasks isFullScreen={isFullScreen}/>
                              </TabPanel>
                              <TabPanel value={value} index={4}>
                                 <Dispatches isFullScreen={isFullScreen}/>
                              </TabPanel>*/}
                            </div>
					    </CardContent>
                    </Card>
                </div>
            </Fullscreen>
        )
    }
}

NMCTools.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(NMCTools);
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { DataGrid, Paging, Pager, Column, Scrolling, ColumnChooser, LoadPanel,
    Export, MasterDetail } from 'devextreme-react/data-grid';
//import CustomStore from 'devextreme/data/custom_store';

import fetchClient from "../../axios";
// import axios from "axios";
// import * as constants from "../../constants";
import { textFormatter} from "../common/utils";
import Dock from 'react-dock';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { Close } from 'mdi-material-ui';
import InfobloxHelp from '../common/InfobloxHelp';
import InfoBloxNetworkTeplateView from './InfoBloxNetworkTeplateView';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: 48,//theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
});
class InfoBloxGridMain extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            runTask: false,
            infoBloxFilterSelectData: [
                { "name": "hostname", "label": "Hostname" },
                { "name": "ipvaddr", "label": "IPv4/IPv6 Address" },
                { "name": "comment", "label": "Comments" },
                { "name": "owner", "label": "Owner" },
                { "name": "subnet", "label": "Subnet" },
                //{ "name": "iprange", "label": "IP Range" },
            ],
            selectedFilter: 'hostname',
            networkViewSearchText: '',
            networkViewSearchVisible: false,
            isDockVisible: false,
            title: '',
            value: 0,
            infoBloxGridSearchText: '',
            infoBloxData: [],
            infoBloxDataTotal: 0,
            isDataLoading: false
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.calculateCellValue = this.calculateCellValue.bind(this);
        this.handleInfobloxContentCopy = this.handleInfobloxContentCopy.bind(this);
        this.handleSortingIpAddress = this.handleSortingIpAddress.bind(this);
        this.getInfloboxData = this.getInfloboxData.bind(this);
        this.infoBloxGrid = null;

    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    onRowPrepared(e) {

    };
    onToolbarPreparing(e) {
        //const runTask = this.state.runTask;
        const { networkViewSearchVisible } = this.state;
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, columnChooserPanel, exportButton, item, i,
            items = e.toolbarOptions.items, networkSearchInput = '';
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'exportButton') {
                    items.splice(i, 1);
                    exportButton = item;
                    //exportButton.options.text = "Export Search Results to Excel";
                    //exportButton.options.hint = "Export Search Results to Excel"
                }
            }
            if (networkViewSearchVisible) {
                networkSearchInput = {
                    widget: 'dxTextBox',
                    location: 'before',
                    locateInMenu: 'auto',
                    options: {
                        type: 'default',
                        showClearButton: true,
                        placeholder: 'Type Network View',
                        width: 150,
                        elementAttr: {'id': 'infoBloxNetworkTextBox'},
                        valueChangeEvent: "keyup",
                        onValueChanged: this.searchNetworkValueChanged,
                        value: this.state.networkViewSearchText,
                    }
                }
            }
            toolbarItems.push({
                    widget: 'dxSelectBox',
                    location: 'before',
                    locateInMenu: 'auto',
                    options: {
                        type: 'default',
                        width: 150,
                        elementAttr: {'id': 'infoBloxFilterSelect'},
                        items: this.state.infoBloxFilterSelectData,
                        valueExpr: 'name',
                        displayExpr: 'label',
                        value: this.state.selectedFilter,
                        onValueChanged: (args) => {this.handleInfoBloxFilterSelectChange(args)}
                    }
                },{
                    widget: 'dxTextBox',
                    location: 'before',
                    locateInMenu: 'auto',
                    options: {
                        type: 'default',
                        showClearButton: true,
                        placeholder: 'Search for ...',
                        width: 250,
                        elementAttr: {'id': 'infoBloxTextBox'},
                        valueChangeEvent: "keyup",
                        onValueChanged: this.searchValueChanged,
                        onEnterKey: this.handleSearch.bind(this),
                        value: this.state.infoBloxGridSearchText,
                    },
                    buttons: [{
                        widget: 'dxButton',
                        name: 'search-button',
                        location: 'after',
                        options: {
                            type: 'default',
                            stylingMode: 'contained',
                            hint: 'Search',
                            elementAttr: {'id': 'infoBloxSearchBtn'},
                            icon: this.state.isDataLoading ? 'mdi mdi-cog-outline mdi-24px mdi-spin': 'mdi mdi-magnify mdi-light',
                            onClick: this.handleSearch.bind(this)
                        }
                    }]
                }, networkSearchInput, {
                    widget: 'dxButton',
                    location: 'before',
                    locateInMenu: 'auto',
                    options: {
                        type: 'default',
                        stylingMode: 'contained',
                        width: 100,
                        elementAttr: {'id': 'infoBloxSearchBtn'},
                        hint: 'Search',
                        icon: 'mdi mdi-magnify mdi-light',
                        text: 'Search',
                        onClick: this.handleSearch.bind(this)
                    }
                },
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-help-circle-outline',
                        hint: 'Infoblox Search Help',
                        text: 'Infoblox Search Help',
                        onClick: this.handleInfobloxHelpDock.bind(this, 1)
                    }
                }/*,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-content-copy',
                        hint: 'Copy Search Results to Clipboard',
                        text: 'Copy Search Results to Clipboard',
                        onClick: this.handleInfobloxContentCopy.bind(this, 1)
                    }
                }*/,
                exportButton,
                columnChooserPanel, {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        elementAttr: {'id': 'infoBloxRefreshBtn'},
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    }
    onContentReady =  (e) => {
        //To expand first row of the master grid
        /*if (!e.component.getSelectedRowKeys().length) {
            e.component.selectRowsByIndexes(0);
        }*/
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onCellPrepared = (e) => {
     if(e.rowType === 'data') {
          /*if(e.column.dataField === 'ipv4addrs' && e.data['ipv4addrs']) {
                e.cellElement.textContent = e.data['ipv4addrs'][0]['ipv4addr'];
          }
          if(e.column.dataField === 'ipv6addrs' && e.data['ipv6addrs']) {
                e.cellElement.textContent = e.data['ipv6addrs'][0]['ipv6addr'];
          }*/
          if(e.column.dataField === 'owner' && e.data['extattrs'] && e.data['extattrs']['IPPlan UserID']) {
                e.cellElement.textContent = e.data['extattrs']['IPPlan UserID']['value'];
          }
          if(e.column.dataField === 'ownerGroup' && e.data['extattrs'] && e.data['extattrs']['Owner Group']) {
                e.cellElement.textContent = e.data['extattrs']['Owner Group']['value'];
          }
          if(e.column.dataField === 'lastModified' && e.data['extattrs'] && e.data['extattrs']['IPPlan Last Modified']) {
                e.cellElement.textContent = e.data['extattrs']['IPPlan Last Modified']['value'];
          }
      }
    };
    handleInfoBloxFilterSelectChange = (args) => {
        if (args.value === 'iprange') {
            this.setState({ selectedFilter: args.value, networkViewSearchVisible: true  });
        } else {
            this.setState({ selectedFilter: args.value, networkViewSearchVisible: false });
        }
    };
    getInfloboxData = () => {
        const { selectedFilter, infoBloxGridSearchText, networkViewSearchText } = this.state;
        this.setState({
            isDataLoading: true
        });
        let params = {
            search_query: infoBloxGridSearchText
        };
        let url = '/api/v1.0/infoblox/ibsearch/hostname';
        switch (selectedFilter) {
            case 'hostname':
                url = '/api/v1.0/ibsearch/hostname';
                break;
            case 'ipvaddr':
                url = '/api/v1.0/ibsearch/ipaddr';
                break;
            case 'comment':
                url = '/api/v1.0/ibsearch/comment';
                break;
            case 'owner':
                url = '/api/v1.0/ibsearch/owner';
                break;
            case 'subnet':
                url = '/api/v1.0/ibsearch/subnet';
                break;
            case 'iprange':
                url = '/api/v1.0/ibsearch/iprange';
                params['network_view'] = networkViewSearchText;
                break;
            default:
                //None
        }
        fetchClient.get(url, { params: params })
            .then(res => {
                let infoBloxData = [];
                let infoBloxDataTotal = 0;
                if (res.data && res.data.data) {
                    infoBloxData = res.data.data;
                    infoBloxDataTotal = res.data.total;
                }
                this.setState({
                    infoBloxData: infoBloxData,
                    infoBloxDataTotal: infoBloxDataTotal,
                    isDataLoading: false
                });
            })
            .catch ( e => {
                if (e.response && e.response.data && e.response.data.error) {
                    console.log(`Data Loading Error: ${e.response.data.error}`);
                } else {
                    console.log(Error, e.message);
                }
                this.setState({
                    infoBloxData: [],
                    infoBloxDataTotal: 0,
                    isDataLoading: false
                });
            });
    };
    handleSearch = () => {
        //this.infoBloxGrid.instance.refresh();
        this.getInfloboxData();
    };
    searchValueChanged = (data) => {
        this.setState({ infoBloxGridSearchText: data.value });
    };
    searchNetworkValueChanged = (data) => {
        this.setState({ networkViewSearchText: data.value });
    };
    calculateCellValue = (rowData) => {

    };
    refreshDataGrid = () => {
        //this.infoBloxGrid.instance.refresh();
        this.getInfloboxData();
    };
    handleInfobloxHelpDock = (value) => {
        this.setState({value: value, isDockVisible: true });
    };
    handleCloseInfobloxHelpDock = () => {
        this.setState({ isDockVisible: false });
    };
    handleInfobloxContentCopy = () => {

    };
   ip4addrCellTemplate = (container, cellInfo) => {
       let noBreakSpace = '\u00A0';
       let text = (cellInfo.value || []).map(elem => {
            return elem['ipv4addr']
       }).join(', ');
       container.textContent = text || noBreakSpace;
       container.title = text;
   };
   ip6addrCellTemplate = (container, cellInfo) => {
       let noBreakSpace = '\u00A0';
       let text = (cellInfo.value || []).map(elem => {
            return elem['ipv6addr']
       }).join(', ');
       container.textContent = text || noBreakSpace;
       container.title = text;
   };
  handleSortingIpAddress = (a, b) => {
        const num1 = Number(a.split(".").map((num) => (`000${num}`).slice(-3) ).join(""));
        const num2 = Number(b.split(".").map((num) => (`000${num}`).slice(-3) ).join(""));
        return num1-num2;
      /*const numA = Number(
        a.split('.')
          .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
          .reduce((a, v) => ((a += v), a), 0)
      );
      const numB = Number(
        b.split('.')
          .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
          .reduce((a, v) => ((a += v), a), 0)
      );
      return numA - numB;*/
   };
  customizeExcelCell = (options) => {
      let gridCell = options.gridCell;
          if (!gridCell) {
              return;
          }

          if (gridCell.rowType === 'data') {
              if(gridCell.column.dataField === 'ipv4addrs' && gridCell.data['ipv4addrs']) {
                 options.value = (gridCell.data['ipv4addrs'] || []).map(elem => {
                        return elem['ipv4addr']
                   }).join(', ');
              }
              if(gridCell.column.dataField === 'ipv6addrs' && gridCell.data['ipv6addrs']) {
                  options.value = (gridCell.data['ipv6addrs'] || []).map(elem => {
                        return elem['ipv6addrs']
                   }).join(', ');
              }
              if(gridCell.column.dataField === 'owner' && gridCell.data['extattrs'] && gridCell.data['extattrs']['IPPlan UserID']) {
                    options.value = gridCell.data['extattrs']['IPPlan UserID']['value'];
              }
              if(gridCell.column.dataField === 'ownerGroup' && gridCell.data['extattrs'] && gridCell.data['extattrs']['Owner Group']) {
                    options.value = gridCell.data['extattrs']['Owner Group']['value'];
              }
              if(gridCell.column.dataField === 'lastModified' && gridCell.data['extattrs'] && gridCell.data['extattrs']['IPPlan Last Modified']) {
                    options.value = gridCell.data['extattrs']['IPPlan Last Modified']['value'];
              }
          }
  };
render() {
        const { classes, theme,  isFullScreen } = this.props;
        const { selectedFilter, value, infoBloxData } = this.state;
        //const { selectedFilter, value, infoBloxGridSearchText, networkViewSearchText, infoBloxData, infoBloxDataTotal } = this.state;
        /*let params = {
            search_query: infoBloxGridSearchText
        };
        let url = '/api/v1.0/infoblox/ibsearch/hostname';
        switch (selectedFilter) {
            case 'hostname':
                url = '/api/v1.0/ibsearch/hostname';
                break;
            case 'ipvaddr':
                url = '/api/v1.0/ibsearch/ipaddr';
                break;
            case 'comment':
                url = '/api/v1.0/ibsearch/comment';
                break;
            case 'owner':
                url = '/api/v1.0/ibsearch/owner';
                break;
            case 'subnet':
                url = '/api/v1.0/ibsearch/subnet';
                break;
            case 'iprange':
                url = '/api/v1.0/ibsearch/iprange';
                params['network_view'] = networkViewSearchText;
                break;
        }

        const dataStore = {
            store: new CustomStore({
                load: (loadOptions) => {
                        return fetchClient.get(url, { params: params })
                            .then(res => {
                                let records = [];
                                let recordsTotal = 0;
                                if (res.data && res.data.data) {
                                    records = res.data.data;
                                    recordsTotal = res.data.total;
                                }
                                return {
                                    data: records,
                                    totalCount: recordsTotal
                                };
                            })
                            .catch ( e => {
                                if (e.response && e.response.data && e.response.data.error) {
                                    throw `Data Loading Error: ${e.response.data.error}`;
                                } else {
                                    console.log(Error, e.message);
                                    throw `Data Loading Error: ${e.message}`;
                                }
                            });
                }
            })
        };*/
        return (
            <Fragment>
                {/*<Alert className={classes.alert} icon={false} severity="info">
                    <strong>Service Name:</strong> {serviceName}, <strong>Query:</strong> {query}
                </Alert>*/}
                <DataGrid id={'infoBloxGrid'}
                    ref={(ref) => this.infoBloxGrid = ref}
                    height={isFullScreen ? '100vh' : `calc(100vh - ${theme.mixins.gridOffset}px)`}
                    dataSource={infoBloxData}
                    //columns={InfoBloxHostColumns}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    columnMinWidth={70}
                    //columnMinWidth={70}
                    cellHintEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onContentReady={this.onContentReady}
                    onCellPrepared={this.onCellPrepared}
                    onRowPrepared={this.onRowPrepared}
                    >
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    <Export enabled={true} fileName={'infoBlox'} customizeExcelCell={this.customizeExcelCell} />
                    {(() => {
                        switch (selectedFilter) {
                            case 'hostname':
                                return ([
                                    <Column dataField="name" caption="Host Name" customizeText={textFormatter} sortOrder='asc' />,
                                    <Column dataField="ipv4addrs" caption="IPv4 Address" cellTemplate={this.ip4addrCellTemplate} />,
                                    <Column dataField="ipv6addrs" caption="IPv6 Address" cellTemplate={this.ip6addrCellTemplate} />,
                                    <Column dataField="view" caption="Network View" customizeText={textFormatter} />,
                                    <Column dataField="owner" caption="Owner" customizeText={textFormatter} />,
                                    <Column dataField="ownerGroup" caption="Owner Group" customizeText={textFormatter} />,
                                    <Column dataField="lastModified" caption="Last Modified" customizeText={textFormatter} />,
                                    <Column dataField="comment" caption="Comment" customizeText={textFormatter} />
                                ]);
                            case 'ipvaddr':
                                return ([
                                    <Column dataField="name" caption="Host Name" customizeText={textFormatter} />,
                                    <Column dataField="ipv4addrs" caption="IPv4 Address" dataType="number" cellTemplate={this.ip4addrCellTemplate} sortOrder='asc'/>,
                                    <Column dataField="ipv6addrs" caption="IPv6 Address" dataType="number" cellTemplate={this.ip6addrCellTemplate} />,
                                    <Column dataField="view" caption="Network View" customizeText={textFormatter} />,
                                    <Column dataField="owner" caption="Owner" customizeText={textFormatter} />,
                                    <Column dataField="ownerGroup" caption="Owner Group" customizeText={textFormatter} />,
                                    <Column dataField="lastModified" caption="Last Modified" customizeText={textFormatter} />,
                                    <Column dataField="comment" caption="Comment" customizeText={textFormatter} />
                                ]);
                            case 'comment':
                                return ([
                                    <Column dataField="name" caption="Host Name" customizeText={textFormatter} sortOrder='asc' />,
                                    <Column dataField="ipv4addrs" caption="IPv4 Address" dataType="number" cellTemplate={this.ip4addrCellTemplate} />,
                                    <Column dataField="ipv6addrs" caption="IPv6 Address" dataType="number" cellTemplate={this.ip6addrCellTemplate} />,
                                    <Column dataField="view" caption="Network View" customizeText={textFormatter} />,
                                    <Column dataField="owner" caption="Owner" customizeText={textFormatter} />,
                                    <Column dataField="ownerGroup" caption="Owner Group" customizeText={textFormatter} />,
                                    <Column dataField="lastModified" caption="Last Modified" customizeText={textFormatter} />,
                                    <Column dataField="comment" caption="Comment" customizeText={textFormatter} />
                                ]);
                            case 'owner':
                                return ([
                                    <Column dataField="name" caption="Host Name" customizeText={textFormatter} sortOrder='asc' />,
                                    <Column dataField="ipv4addrs" caption="IPv4 Address" dataType="number" cellTemplate={this.ip4addrCellTemplate} sortingMethod={this.handleSortingIpAddress}/>,
                                    <Column dataField="ipv6addrs" caption="IPv6 Address" dataType="number" cellTemplate={this.ip6addrCellTemplate} />,
                                    <Column dataField="view" caption="Network View" customizeText={textFormatter} />,
                                    <Column dataField="owner" caption="Owner" customizeText={textFormatter} />,
                                    <Column dataField="ownerGroup" caption="Owner Group" customizeText={textFormatter} />,
                                    <Column dataField="lastModified" caption="Last Modified" customizeText={textFormatter} />,
                                    <Column dataField="comment" caption="Comment" customizeText={textFormatter} />
                                ]);
                            case 'subnet':
                                return ([
                                    <MasterDetail enabled={true} component={InfoBloxNetworkTeplateView} />,
                                    <Column dataField="network" caption="Network" customizeText={textFormatter} sortOrder='asc' />,
                                    <Column dataField="network_view" caption="Network View" />,
                                    <Column dataField="owner" caption="Owner" customizeText={textFormatter} />,
                                    <Column dataField="lastModified" caption="Last Modified" customizeText={textFormatter} />,
                                    <Column dataField="comment" caption="Comment" customizeText={textFormatter} />
                                ]);
                            case 'iprange':
                                return ([
                                    <Column dataField="names" caption="Host Name" customizeText={textFormatter}  />,
                                    <Column dataField="ip_address" caption="IP Address" sortOrder='asc' sortingMethod={this.handleSortingIpAddress} />,
                                    <Column dataField="network" caption="Network" customizeText={textFormatter} />,
                                    <Column dataField="network_view" caption="Network View" customizeText={textFormatter} />,
                                    <Column dataField="owner" caption="Owner" customizeText={textFormatter} />,
                                    <Column dataField="ownerGroup" caption="Owner Group" customizeText={textFormatter} />,
                                    <Column dataField="lastModified" caption="Last Modified" customizeText={textFormatter} />
                                ]);
                            default:
                                //None
                        }
                     })()}
                    <Paging defaultPageSize={25}/>
                    <Pager visible={true} showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 25, 50, 100]}/>
                </DataGrid>
                <Dock position="right" fluid={true} dimMode='none' zIndex='1250' isVisible={this.state.isDockVisible}>
                    <Card  raise={true}>
                        <CardHeader className={ classes.cardHeader } title='Infoblox Search Help'
                                action={
                                        <IconButton onClick={this.handleCloseInfobloxHelpDock}>
                                          <Close/>
                                        </IconButton>
                                    }
                            />
                            {value === 1 && <InfobloxHelp /> }
                    </Card>
                </Dock>
            </Fragment>
        )
    }
}

InfoBloxGridMain.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(InfoBloxGridMain);
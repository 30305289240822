import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { DataGrid, Paging, Pager, Column, Scrolling, ColumnChooser, LoadPanel,
    Export } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import fetchClient  from '../../axios';
//import * as constants from '../../constants';
import {textFormatter} from "../common/utils";

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    masterDetailCaption: {
        padding: '0 0 5px 10px',
        fontSize: '14px',
        fontWeight: 'bold'
    }
});
class InfoBloxNetworkTeplateView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            infoBloxFilterSelectData: [
                { "name": "ALL", "label": "All IPs" },
                { "name": "USED", "label": "Used IPs" },
                { "name": "UNUSED", "label": "Unused IPs" },
                { "name": "CONFLICT", "label": "Conflict IPs" },
                { "name": "PENDING", "label": "Pending IPs" }
            ],
            selectedStatusFilter: 'ALL'
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.refreshDataGrid = this.refreshDataGrid.bind(this);
        this.handleSortingIpAddress = this.handleSortingIpAddress.bind(this);
        this.infoBloxIPRangeGrid = null;
    }
    componentWillMount() {

    }
    onToolbarPreparing(e) {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, columnChooserPanel, exportButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'exportButton') {
                    items.splice(i, 1);
                    exportButton = item;
                    //exportButton.options.text = "Export Search Results to Excel";
                    //exportButton.options.hint = "Export Search Results to Excel"
                }
            }
            toolbarItems.push({
                    widget: 'dxSelectBox',
                    location: 'after',
                    locateInMenu: 'auto',
                    options: {
                        type: 'default',
                        width: 150,
                        elementAttr: {'id': 'infoBloxStatusFilterSelect'},
                        items: this.state.infoBloxFilterSelectData,
                        valueExpr: 'name',
                        displayExpr: 'label',
                        value: this.state.selectedStatusFilter,
                        onValueChanged: (args) => {this.handleInfoBloxStatusFilterSelectChange(args)}
                    }
                }/*,{
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-content-copy',
                        hint: 'Copy Search Results to Clipboard',
                        text: 'Copy Search Results to Clipboard',
                        onClick: this.handleInfobloxContentCopy.bind(this)
                    }
                }*/,
                exportButton,
                columnChooserPanel, {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        elementAttr: {'id': 'infoBloxNetworkRefreshBtn'},
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    }
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onCellPrepared = (e) => {
     if(e.rowType === 'data') {
          if(e.column.dataField === 'owner' && e.data['extattrs'] && e.data['extattrs']['IPPlan UserID']) {
                e.cellElement.textContent = e.data['extattrs']['IPPlan UserID']['value'];
          }
          if(e.column.dataField === 'ownerGroup' && e.data['extattrs'] && e.data['extattrs']['Owner Group']) {
                e.cellElement.textContent = e.data['extattrs']['Owner Group']['value'];
          }
          if(e.column.dataField === 'lastModified' && e.data['extattrs'] && e.data['extattrs']['IPPlan Last Modified']) {
                e.cellElement.textContent = e.data['extattrs']['IPPlan Last Modified']['value'];
          }
      }
    };
    refreshDataGrid = () => {
        this.infoBloxIPRangeGrid.instance.refresh();
    };
    handleInfobloxContentCopy = (e) => {

    };
   hostNameCellTemplate = (container, cellInfo) => {
       let noBreakSpace = '\u00A0';
       let text = (cellInfo.value || []).join(', ');
       container.textContent = text || noBreakSpace;
       container.title = text;
   };
   handleInfoBloxStatusFilterSelectChange = (args) => {
        this.setState({ selectedStatusFilter: args.value });
   };
   handleSortingIpAddress = (a, b) => {
      const numA = Number(
        a.split('.')
          .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
            // eslint-disable-next-line
          .reduce((a, v) => ((a += v), a), 0)
      );
      const numB = Number(
        b.split('.')
          .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
            // eslint-disable-next-line
          .reduce((a, v) => ((a += v), a), 0)
      );
      return numA - numB;
   };
  customizeExcelCell = (options) => {
      let gridCell = options.gridCell;
          if (!gridCell) {
              return;
          }

          if (gridCell.rowType === 'data') {
              if(gridCell.column.dataField === 'ipv4addrs' && gridCell.data['ipv4addrs']) {
                 options.value = (gridCell.data['ipv4addrs'] || []).map(elem => {
                        return elem['ipv4addr']
                   }).join(', ');
              }
              if(gridCell.column.dataField === 'ipv6addrs' && gridCell.data['ipv6addrs']) {
                  options.value = (gridCell.data['ipv6addrs'] || []).map(elem => {
                        return elem['ipv6addrs']
                   }).join(', ');
              }
              if(gridCell.column.dataField === 'owner' && gridCell.data['extattrs'] && gridCell.data['extattrs']['IPPlan UserID']) {
                    options.value = gridCell.data['extattrs']['IPPlan UserID']['value'];
              }
              if(gridCell.column.dataField === 'ownerGroup' && gridCell.data['extattrs'] && gridCell.data['extattrs']['Owner Group']) {
                    options.value = gridCell.data['extattrs']['Owner Group']['value'];
              }
              if(gridCell.column.dataField === 'lastModified' && gridCell.data['extattrs'] && gridCell.data['extattrs']['IPPlan Last Modified']) {
                    options.value = gridCell.data['extattrs']['IPPlan Last Modified']['value'];
              }
          }
  };
render() {
        const { classes, data } = this.props;
        const { selectedStatusFilter } = this.state;
        const network = data['data']['network'];
        const network_view = data['data']['network_view'];
        let params = {
            search_query: network,
            network_view: network_view,
            status_filter: selectedStatusFilter
        };
        let url = '/api/v1.0/ibsearch/iprange';
        const networkDataStore = {
            store: new CustomStore({
                load: (loadOptions) => {
                        return fetchClient.get(url, { params: params })
                            .then(res => {
                                let records = [];
                                let recordsTotal = 0;
                                if (res.data && res.data.data) {
                                    records = res.data.data;
                                    recordsTotal = res.data.total;
                                }
                                return {
                                    data: records,
                                    totalCount: recordsTotal
                                };
                            })
                            .catch ( e => {
                                if (e.response && e.response.data && e.response.data.error) {
                                    throw new Error(`Data Loading Error: ${e.response.data.error}`);
                                } else {
                                    console.log(Error, e.message);
                                    throw new Error(`Data Loading Error: ${e.message}`);
                                }
                            });
                }
            })
        };
        return (
                  <Fragment>
                    <div className={classes.masterDetailCaption}>
                        {`IP Ranges for Network: ${network} Network View: ${network_view}`}
                    </div>
                    <DataGrid
                        ref={(ref) => this.infoBloxIPRangeGrid = ref}
                        height={'250px'}
                        dataSource={networkDataStore}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        rowAlternationEnabled={false}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={'widget'}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        wordWrapEnabled={false}
                        columnMinWidth={70}
                        //columnMinWidth={70}
                        cellHintEnabled={true}
                        onToolbarPreparing={this.onToolbarPreparing}
                        onContentReady={this.onContentReady}
                        onCellPrepared={this.onCellPrepared}
                        onRowPrepared={this.onRowPrepared}
                        >
                        <ColumnChooser enabled={true} mode={'select'}/>
                        <Scrolling showScrollbar={'always'} useNative={false} />
                        <LoadPanel enabled={true}/>
                        <Export enabled={true} fileName={'infoBlox'} customizeExcelCell={this.customizeExcelCell} />
                        <Column dataField="names" caption="Host Name" cellTemplate={this.hostNameCellTemplate} />
                        <Column dataField="ip_address" caption="IP Address" sortOrder='asc' sortingMethod={this.handleSortingIpAddress}/>
                        <Column dataField="status" caption="Status" />
                        <Column dataField="network" caption="Network" customizeText={textFormatter} />
                        <Column dataField="network_view" caption="Network View" customizeText={textFormatter} />
                        <Column dataField="owner" caption="Owner" customizeText={textFormatter} />
                        <Column dataField="ownerGroup" caption="Owner Group" customizeText={textFormatter} />
                        <Column dataField="lastModified" caption="Last Modified" customizeText={textFormatter} />
                        <Paging defaultPageSize={10}/>
                        <Pager visible={true} showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 25, 50, 100]}/>
                    </DataGrid>
                  </Fragment>
        )
    }
}

InfoBloxNetworkTeplateView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(InfoBloxNetworkTeplateView);
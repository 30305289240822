import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
//import { CheckBox, SelectBox, NumberBox } from 'devextreme-react';
import Form, { ButtonItem, GroupItem, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';
//import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
//import Card from '@material-ui/core/Card';
import 'devextreme-react/autocomplete';
import { Alert } from '@material-ui/lab';
//import Validator, { ValidationRule } from 'devextreme-react/validator';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';
import fetchClient from "../../axios";
//import axios from "axios/index";
import moment from 'moment';
//import { LoadPanel } from 'devextreme-react/load-panel';
import { DataGrid, RemoteOperations, Paging, Pager, Column, Editing, Scrolling, ColumnChooser, LoadPanel,
    Export, SearchPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
const styles = theme => ({
	root: {
	  width: '100%'
	},
	pamSuspendForm: {
	  padding: '0 14px',
	  borderBottom: '1px solid #e0e0e0'
	}
});

class pamSuspendGrid extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isDataLoading: false,
			showAdd: true,
			showRemove: false,
            pamSuspendData: {},
			showSuccessAlert: false,
			showErrorAlert: false,
			errorMsg: '',
			successMsg: ''
        };
	 	this.handleSubmit = this.handleSubmit.bind(this);
	 	this.addPamSuspend = this.addPamSuspend.bind(this);
	 	this.dateFormat = 'MM/DD/YYYY HH:mm:ss';
		this.startDateBoxOptions = {
		  width: '100%',
		  type: 'datetime',
		  displayFormat: 'MM/dd/yyyy HH:mm:ss',
		  dateSerializationFormat: 	'MM/dd/yyyy HH:mm:ss',
		  invalidDateMessage: 'The date must have the following format: MM/dd/yyyy HH:mm:ss (23:00:00)'
		};
		this.endDateBoxOptions = {
		  width: '100%',
		  type: 'datetime',
		  displayFormat: 'MM/dd/yyyy HH:mm:ss',
		  dateSerializationFormat: 	'MM/dd/yyyy HH:mm:ss',
		  invalidDateMessage: 'The date must have the following format: MM/dd/yyyy HH:mm:ss (23:00:00)'
		};
		this.minStartDateTime = moment().format(this.dateFormat);
		this.maxStartDateTime = moment().add(8, 'm').format(this.dateFormat);
		this.minEndDateTime = moment().add(2, 'h').format(this.dateFormat);
		this.maxEndDateTime = moment().add(10, 'y').format(this.dateFormat);
		this.validateForm = (e) => {
			  e.component.validate();
		};
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.pamSuspendGrid = null;
    };
	componentWillMount() {
        //this.getPamSuspendData();
    }
	refreshDataGrid() {
        this.pamSuspendGrid.instance.refresh();
    };
    onRowPrepared = (e) => {
        if(e.rowType === 'data') {
        	const status = e.data['status'];
            if (status === 'sched') {
                e.rowElement.style.color = yellow[500];
            }
            if (status === 'pensus') {
                e.rowElement.style.color = lightGreen[500];
            }
            if (status === 'sus') {
                e.rowElement.style.color = red[500];
            }
        }
    };
    onToolbarPreparing = (e) => {
        //const runTask = this.state.runTask;
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, exportButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                if(item.name === 'searchPanel') {
                    items.splice(i, 1);
                    searchPanel = item;
                }
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'exportButton') {
                    items.splice(i, 1);
                    exportButton = item;
                }
            }
            toolbarItems.push(searchPanel, exportButton,
                columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    };
    onContentReady = (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onCellPrepared = (e) => {
      /*if(e.rowType === 'header' && e.column.command === 'edit') {
          e.cellElement.textContent='Actions';
      }*/
    };
    /**
     * getPamSuspendData:
     */
    getPamSuspendData = () => {
    	const { deviceNames } = this.props;
    	let { showAdd, showRemove } = this.state;
    	if (deviceNames) {
			this.setState({isDataLoading: true});
			let url = '/api/v1.0/action/devices/pam-suspend';
			let params = {
			    device_names: deviceNames,
                active: true
            };
			fetchClient.get(url, { params: params })
			  .then((res) => {
			  		let pamSuspendData={};
			  		if(res.data && res.data.data && res.data.data.length > 0) {
			  			const data = res.data.data[0];
			  			const status = data.status;
			  			if (status === 'sched' || status === 'pendsus' || status === 'sus') {
			  				showAdd = true;
			  				showRemove = true;
						}
						pamSuspendData = {
							status: status === 'sus' ? 'PAM Suspended': status === 'pendsus' ? 'PAM Suspend is pending' : 'PAM Suspend is scheduled',
							start_time: moment.unix(data.start_time).format(this.dateFormat),
							end_time: moment.unix(data.end_time).format(this.dateFormat),
							info: data.info
						}
					} else {
						pamSuspendData = {
							status: 'PAM not suspended',
							start_time: moment().format(this.dateFormat),
							end_time: moment().add(2, 'h').format(this.dateFormat),
							info: 'Reason to suspend'
						}
					}
					this.setState({
						pamSuspendData: pamSuspendData,
						isDataLoading: false,
						showAdd: showAdd,
						showRemove: showRemove
					});
			  })
			 .catch( (error) => {
				console.log('Error: ' + error);
					this.setState({
						pamSuspendData: {
							status: 'PAM Not Suspended',
							start_time: moment().format(this.dateFormat),
							end_time: moment().add(2, 'h').format(this.dateFormat),
							info: 'Reason for PAM suspend'
						},
						errorMsg: error.message,
						showSuccessAlert: false,
						showErrorAlert: true,
						isDataLoading: false
					});
			 });
		}
    };
	handleSubmit =(e) => {
		const {pamSuspendData} = this.state;
		console.log(pamSuspendData);
		notify({
		  message: 'You have submitted the form',
		  position: {
			my: 'center top',
			at: 'center top'
		  }
		}, 'success', 3000);
		e.preventDefault();
  	};
	addPamSuspend = () => {
		const {pamSuspendData} = this.state;
		const { deviceNames, userInfo } = this.props;
		let url = '/api/v1.0/action/devices/pam-suspend?';
		this.setState({isDataLoading: true});
		let params = [{
		    schedule_type: 'device',
			device_name: deviceNames,
			info: pamSuspendData.info,
			start_time: moment(pamSuspendData.start_time, this.dateFormat).valueOf()/1000,
			end_time: moment(pamSuspendData.end_time, this.dateFormat).valueOf()/1000,
			user: userInfo['nickname']
		}];
		fetchClient.post(url, params)
		  .then((res) => {
				this.setState({
					//pamSuspendData: {},
					successMsg: 'PAM Suspend created successfully',
					showErrorAlert: false,
					showSuccessAlert: true,
					isDataLoading: false
				});
		  })
		 .catch( (error) => {
			console.log('Error: ' + error);
				this.setState({
					//pamSuspendData: {},
					errorMsg: `PAM Suspend not created, Please try again later.`,
					showErrorAlert: true,
					showSuccessAlert: false,
					isDataLoading: false
				});
		 });
	};
	removePamSuspend = () => {
		const { deviceNames } = this.props;
		let url = '/api/v1.0/action/devices/pam-suspend';
		this.setState({isDataLoading: true});
		let params = {
			device_names: deviceNames,
			active: true
		};
		fetchClient.delete(url, { params: params })
		  .then((res) => {
				this.setState({
					successMsg: 'PAM Suspend removed successfully',
					showErrorAlert: false,
					showSuccessAlert: true,
					isDataLoading: false
				});
		  })
		 .catch( (error) => {
			console.log('Error: ' + error);
				this.setState({
					errorMsg: `PAM Suspend not removed, Please try again later.`,
					showErrorAlert: true,
					showSuccessAlert: false,
					isDataLoading: false
				});
		 });
	};
	epochDateFormatter = (data) => {
    	return (data.value === 0 || data.value === '0') ? 'Unavailable' : moment.unix(data.value).format('MM/DD/YYYY HH:mm:ss');
	};
	statusRender = (data) => {
		let status;
			switch (data.value) {
				case 'pensus':
					status = 'Pending';
					break;
				case 'sched':
					status = 'Scheduled';
					break;
				case 'sus':
					status = 'Suspended';
					break;
				default:
					status = data.value;
					break;
			}
			return status
	};
	handleApply = () => {

	};
	render() {
        const { pamSuspendData, showSuccessAlert, showErrorAlert,  successMsg, errorMsg, isDataLoading} = this.state;
		const { classes, deviceNames} = this.props;
		const pamSuspendDataStore = {
            store: new CustomStore({
                key: 'device_name',
                load: (loadOptions) => {
                    let params = { device_names: deviceNames, active: true };
					return fetchClient.get('/api/v1.0/action/devices/pam-suspend', { params: params })
						.then(res => {
							let pamSchedules = [];
							let pamSchedulesTotal = 0;
							if (res.data && res.data.data) {
								pamSchedules = res.data.data;
								pamSchedulesTotal = res.data.total;
							}
							return {
								data: pamSchedules,
								totalCount: pamSchedulesTotal
							};
						})
						.catch ((e) => { throw new Error(`Data Loading Error, ${e}`);});
                },
                insert: (values) => {
                    return fetchClient.post('/api/v1.0/action/devices/pam-suspend', values)
                        .then(res => {

                        })
                        .catch ((e) => { throw new Error(`Not able to create, ${e}`);});
                },
                update: (key, values) => {
                    return fetchClient.put('/api/v1.0/action/devices/pam-suspend/'+key, values)
                        .then(res => {

                        })
                        .catch ((e) => { throw new Error(`Not able to update, ${e}`);});
                },
                remove: (key) => {
                    return fetchClient.delete('/api/v1.0/action/devices/pam-suspend/'+key)
                        .then(res => {

                        })
                        .catch ((e) => { throw new Error(`Not able to delete, ${e}`);});
                }
            })
        };
		return (
			    <div id="pam-suspend-grid-container-id" className={classes.root} >
					<div id="pam-suspend-form-id" className={classes.pamSuspendForm}>
						<form>
							<Form
								height={'100%'}
								formData={pamSuspendData}
								readOnly={false}
								showColonAfterLabel={true}
								onContentReady={this.validateForm}
							>
								<GroupItem colCount={2}>
								  <SimpleItem dataField="start_time"
									editorType="dxDateBox"
									editorOptions={this.startDateBoxOptions}>
									<Label text="Start Date" />
									  <RequiredRule max={this.maxStartDateTime} min={this.minStartDateTime} message="Start date is required" />
								  </SimpleItem>
								  <SimpleItem dataField="end_time"
									editorType="dxDateBox"
									editorOptions={this.endDateBoxOptions}>
									<Label text="End Date" />
									<RequiredRule message="End date is required" />
									  <RequiredRule max={this.maxEndDateTime} min={this.minEndDateTime} message="End date must be less than 10 years" />
								  </SimpleItem>
								</GroupItem>
								<GroupItem colCount={2}>
								  <SimpleItem dataField="info" editorType="dxTextArea" editorOptions={{ height: 30 }}>
									  <Label text="Info" />
									  <RequiredRule message="Info is required" />
								  </SimpleItem>
								  <ButtonItem horizontalAlignment='center' verticalAlignment='center' buttonOptions={{hint: 'Apply start/end date and info to all records', type: 'default', text: 'Apply', onClick:this.handleApply.bind(this) }} />
								</GroupItem>
								{/*<GroupItem colCount={2}>
								  <ButtonItem horizontalAlignment='right' verticalAlignment='center' visible={showAdd} buttonOptions={{hint: 'Create PAM Suspend', type: 'default', text: 'Create', onClick:this.addPamSuspend.bind(this) }} />
								  <ButtonItem horizontalAlignment='left' verticalAlignment='center' visible={showRemove} buttonOptions={{hint: 'Remove PAM Suspend', type: 'danger', text: 'Remove', onClick:this.removePamSuspend.bind(this) }}/>
								</GroupItem>*/}
							</Form>
						</form>
					</div>
					<DataGrid id={'pamSuspendGrid'}
						ref={(ref) => this.pamSuspendGrid = ref}
						height={'75vh'}
						dataSource={pamSuspendDataStore}
						showColumnLines={true}
						showRowLines={true}
						showBorders={true}
						rowAlternationEnabled={false}
						repaintChangesOnly={true}
						allowColumnReordering={true}
						allowColumnResizing={true}
						columnResizingMode={'widget'}
						columnAutoWidth={true}
						columnHidingEnabled={true}
						wordWrapEnabled={false}
						columnMinWidth={70}
						cellHintEnabled={true}
						onToolbarPreparing={this.onToolbarPreparing}
						onContentReady={this.onContentReady}
						onCellPrepared={this.onCellPrepared}
						onRowPrepared={this.onRowPrepared}
						>
						<SearchPanel visible={true} width={220} placeholder={'Search'}/>
						<ColumnChooser enabled={true} mode={'select'}/>
						<Scrolling showScrollbar={'always'} useNative={false} />
						<LoadPanel enabled={true}/>
						<Editing mode={'batch'} allowUpdating={true} allowDeleting={true} />
						<Export enabled={true} fileName={'pam_suspend_schedules'} />
						<Column caption={'Actions'} type={'buttons'} buttons={['edit', 'delete']} alignment='center' allowEditing={false} width={70}/>
						<Column dataField='device_name' caption='Device Name' dataType='string' allowEditing={false}  width={120} />
						<Column dataField='status' caption='Status' dataType='string' allowEditing={false}  width={100} customizeText={this.statusRender} />
						<Column dataField='start_time' caption='Start Date' alignment='center' dataType='datetime' validationRules={[{ type: 'required'}]} width={170} customizeText={this.epochDateFormatter} />
						<Column dataField='end_time' caption='End Date' alignment='center' dataType='datetime' validationRules={[{ type: 'required'}]} width={170} customizeText={this.epochDateFormatter} />
						<Column dataField='schedule_type' caption='Schedule Type' dataType='string' allowEditing={false} visible={false}/>
						<Column dataField='info' caption='Info' dataType='string' validationRules={[{ type: 'required'}]} width='10%' />
						<RemoteOperations sorting={false} paging={false} />
						<Paging defaultPageSize={15}/>
						<Pager visible={true} showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 25, 50, 100]}/>
					</DataGrid>
					{ showSuccessAlert && <Alert elevation={1} variant="outlined" icon={false} severity="success">{successMsg}</Alert> }
					{ showErrorAlert && <Alert elevation={1} variant="outlined" icon={false} severity="error">{errorMsg}</Alert> }
					<LoadPanel
						shadingColor="rgba(0,0,0,0.4)"
						position={{ of: '#pam-suspend-grid-container-id'}}
						visible={isDataLoading}
					/>
                </div>
			)
	}
}

pamSuspendGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(pamSuspendGrid);
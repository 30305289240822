import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as d3 from "d3";
import numeral from "numeral";
import "./SunBurst.css";
import StateLegend from '../../components/common/StateLegend';
import { getNetworkQuery } from '../common/utils';
import { Tooltip } from 'devextreme-react/tooltip';
import Popup from 'devextreme-react/popup';
import ContextMenu from 'devextreme-react/context-menu';
import ScrollView from 'devextreme-react/scroll-view';
import ApgImpactedGraph from '../service-view-navigator/ApgImpactedGraph';
import ImpactedDevices from '../dash-board/network-monitoring/ImpactedDevices';

const useStyles = makeStyles(
  (theme) => ({
    root: {
     //padding: theme.spacing.unit * 3,
     textAlign: 'center'
    },
    label1: {
        float: 'left'
    },
    label2: {
        float: 'right',
        marginLeft: theme.spacing(1)
    }
  }),
  { withTheme: true }
);

const legendData = [{label: 'Critical', color: '#f44336'}, {label: 'Major', color: '#ff9800'}, {label: 'Minor', color: '#ffeb3b'},
      {label: 'Minimal', color: '#03a9f4'}, {label: 'Normal', color: '#4caf50'}];
const contextMenuItems = [
    { text: 'Impacted Devices - Total/Percent', value: 'impactedDevicesChart', disable: false },
    { text: 'Impacted Devices', value: 'impactedDevices', disable: false  },
    { text: 'Show Device Map', value: 'showDeviceMap', disable: true }
];

const SunBurst = ({ width, height, data, onZoomChanged, includeServiceDetails }) => {
  const [title, setTitle] = React.useState('');
  const [sliceId, setSliceId] = React.useState('All_Devices_id');
  const [selectedItem, setSelectedItem] = React.useState('');
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [groupName, setGroupName] = React.useState(0);
  const [deviceCount, setDeviceCount] = React.useState(0);
  const [impactedDeviceCount, setImpactedDeviceCount] = React.useState(0);
  const [percent, setPercent] = React.useState('0.00%');
  const sunBurstRef = useRef();
  const sunBurstPopupRef = useRef();
  const actionsScrollViewRef = useRef();
  const classes = useStyles();
  const handlePopupHidden = () => {
      setPopupVisible(false);
  };
  const handleMenuClick = (e) => {
      setPopupVisible(true);
      if (e.itemData.value) {
          if (e.itemData.value === 'impactedDevicesChart') {
              setTitle(`Impacted History for ${selectedItem}`);
              setContent(<ApgImpactedGraph groupName={selectedItem} />);
          }
          if (e.itemData.value === 'impactedDevices') {
              setTitle(`Impacted Devices by ${selectedItem}`);
              setContent(<ImpactedDevices  group_name={selectedItem} raised={false} />);
          }
      }

  };
  // eslint-disable-next-line
  useEffect(() => {
    // eslint-disable-next-line
    const svg = d3
      .select(sunBurstRef.current)
      .style("width", width)
      .style("height", height)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .style("font", "Roboto,'Helvetica Neue',Helvetica,Arial,sans-serief")
      .attr("viewBox", `${-width / 2} ${-height / 2} ${width} ${height}`)
      .style("font-size", "11px");
     // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  useEffect(() => {
    createSunBurst();
    // eslint-disable-next-line
  }, [data]);

  const colors = {
    1: "#f44336",
    2: "#ff9800",
    3: "#ffeb3b",
    4: "#03a9f4",
    5: "#4caf50"
  };

  const getColor = (d) => {
    let severity = d.severity;
    let color = colors[severity];
    if (!color) {
      color = "#607d8b";
    }
    return color;
  };
  const maxRadius = Math.min(width, height) / 2 - 10;
  //const formatNumber = d3.format(",d");
  //const svg = d3.select(sunBurstRef.current);
  const x = d3
    .scaleLinear()
    .range([0, 2 * Math.PI])
    .clamp(true);
  //const y = d3.scaleSqrt().range([maxRadius * 0.03, maxRadius]);
  const y = d3.scaleSqrt().range([maxRadius * 0.02, maxRadius]);
  //const y = d3.scaleSqrt().range([maxRadius * 0.5, maxRadius]);
  //const y = d3.scaleSqrt().range([0, maxRadius]);

  const partition = d3.partition();

  const arc = d3
    .arc()
    .startAngle((d) => {
      return Math.max(0, Math.min(2 * Math.PI, x(d.x0)));
    })
    .endAngle((d) => {
      return Math.max(0, Math.min(2 * Math.PI, x(d.x1)));
    })
    .innerRadius((d) => {
      return Math.max(0, y(d.y0));
    })
    .outerRadius((d) => {
      return Math.max(0, y(d.y1));
    });
  const middleArcLine = (d) => {
    const halfPi = Math.PI / 2;
    const angles = [x(d.x0) - halfPi, x(d.x1) - halfPi];
    const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);

    const middleAngle = (angles[1] + angles[0]) / 2;
    const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
    if (invertDirection) {
      angles.reverse();
    }

    const path = d3.path();
    path.arc(0, 0, r, angles[0], angles[1], invertDirection);
    return path.toString();
  };

  const textFits = (d) => {
    const CHAR_SPACE = 6;

    const deltaAngle = x(d.x1) - x(d.x0);
    const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);
    const perimeter = r * deltaAngle;

    return d.data.name.length * CHAR_SPACE < perimeter;
  };
  const createSunBurst = () => {
    data = d3.hierarchy(data);
    let total = 0;
    let impactedDeviceCount = 0;
    let impactedPercent = 0;
    // eslint-disable-next-line
    let name = "";

    data.sum(function (d) {
      total = d.device_count;
      impactedDeviceCount = d.impacted_device_count;
      impactedPercent = impactedDeviceCount / total;
      name = d.name;
      return !d.children || d.children.length === 0 ? d.device_count : 0;
    });
    data.sort(function(a, b) { return b.value - a.value; });
    if(includeServiceDetails){
      const query1 = getNetworkQuery(data.data);
      onZoomChanged(query1, data.data.name);
    }
    const svg = d3
      .select(sunBurstRef.current)
      //.attr("viewBox", `${-width / 2} ${-height / 2} ${width} ${height}`)
      .on("click", function () {
        onCanvasClick();
        if(includeServiceDetails) {
            const query2 = getNetworkQuery(data.data);
            onZoomChanged(query2, data.data.name);
        }
      });// Reset zoom on canvas click
    const slice = svg.selectAll("g.slice").data(partition(data).descendants());

    slice.exit().remove();

    const newSlice = slice
      .enter()
      .append("g")
      .attr("class", "slice")
      .attr("id", (d) => (d.data.name ? d.data.name+"_id" : ""))
      .attr("stroke-width", (d) => (d.depth ? "1px" : "5px"))
      .on("click", (e, d) => {
        e.stopPropagation();
        onClick(d);
      })
      .on("mouseover", function (e, d) {
        setSliceId(`${d.data.name}_id`);
        //console.log(d)
        // eslint-disable-next-line
        const total = d.parent ? d.parent.data.device_count : d.data.device_count;
        const percent = numeral(d.data.impacted_device_count / d.data.device_count).format("0.00%"); // calculate percent
        setGroupName(d.data.name);
        setDeviceCount(numeral(d.data.device_count).format("0,0"));
        setImpactedDeviceCount(numeral(d.data.impacted_device_count).format("0,0"));
        setPercent(percent);
        setTooltipVisible(true);
      })
      .on("mouseout", function (e, d) {
          setTooltipVisible(false);
      })
      .on("mousemove", function (e, d) {
        // when mouse moves
        //tooltip.style("top", e.layerY + 10 + "px"); // always 10px below the cursor
        //tooltip.style("left", e.layerX + 10 + "px"); // always 10px to the right of the mouse
      })
      .on('contextmenu', function (e, d) {
            setTooltipVisible(false);
            //e.preventDefault();
            //e.stopPropagation();
            setContent('');
            setSelectedItem(d.data.name);
            setSliceId(`${d.data.name}_id`);
        });

    newSlice
      .append("path")
      .attr("class", "main-arc")
      .attr("stroke-width", (d) => (d.depth ? "1px" : "5px"))
      //.style("fill", (d) => (d.depth === 0)? '#607d8b' : getColor(d.data))
      .style("fill", (d) => getColor(d.data))
      //.style("fill", (d) => getColor((d.children ? d : d.parent).data))
      .attr("d", arc);

    newSlice
      .append("path")
      .attr("class", "hidden-arc")
      .attr("id", (_, i) => `hiddenArc${i}`)
      .attr("d", middleArcLine);

    const text = newSlice
      .append("text")
      .attr("display", (d) => (textFits(d) ? null : "none"));

    text
      .append("textPath")
      .attr("startOffset", "50%")
      .attr("xlink:href", (_, i) => `#hiddenArc${i}`)
      .style("fill", "#ffffff")
      .text((d) => d.data.name);

    /* Center Info */
    svg
      .append("text")
      .attr("class", "total-percent")
      .attr("text-anchor", "middle")
      .attr("font-size", "2em")
      .attr("fill", "#ffffff")
      .attr("y", -45)
      .text(numeral(impactedPercent).format("0.00%"));
    svg
      .append("text")
      .attr("class", "total-impact")
      .attr("text-anchor", "middle")
      .attr("font-size", "1.5em")
      .attr("fill", "#ffffff")
      .attr("y", -15)
      .text(
        `${numeral(impactedDeviceCount).format("0,0")} of ${numeral(
          total
        ).format("0,0")}`
      );
    svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("font-size", "1.5em")
      .attr("fill", "#ffffff")
      .attr("y", 20)
      .text("impacted devices in");
    /*newSlice
      .append("text")
      .attr("class", "total-name")
      .attr("text-anchor", "middle")
      .attr("font-size", "1em")
      .attr("fill", "#ffffff")
      .attr("y", 30)
      .text(name);*/
    /* End Center Info */

    const onClick = (d = { x0: 0, x1: 1, y0: 0, y1: 1 }) => {
      //console.log('SunBurst,OnClick'+d);
      // Reset to top-level if no data point specified
      const transition = svg
        .transition()
        .duration(750)
        .tween("scale", () => {
          const xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
            yd = d3.interpolate(y.domain(), [d.y0, 1]);
          return (t) => {
            x.domain(xd(t));
            y.domain(yd(t));
          };
        });

      transition.selectAll("path.main-arc").attrTween("d", (d) => () => arc(d));

      transition
        .selectAll("path.hidden-arc")
        .attrTween("d", (d) => () => middleArcLine(d));

      transition
        .selectAll("text")
        .attrTween("display", (d) => () => (textFits(d) ? null : "none"));
      d3.select(".total-percent").text(
        d.data
          ? numeral(d.data.impacted_device_count / d.data.device_count).format("0.00%")
          : ""
      );
      d3.select(".total-impact").text(
        d.data
          ? `${numeral(d.data.impacted_device_count).format("0,0")} of ${numeral(
              d.data.device_count
            ).format("0,0")}`
          : ""
      );
      d3.select(".total-name").text(d.data ? d.data.name : "");
      if(includeServiceDetails) {
          const query = getNetworkQuery(d.data);
          onZoomChanged(query, d.data.name);
      }
    };
    const onCanvasClick = (d = { x0: 0, x1: 1, y0: 0, y1: 1 }) => {
      // Reset to top-level if no data point specified
      const transition = svg
        .transition()
        .duration(750)
        .tween("scale", () => {
          const xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
            yd = d3.interpolate(y.domain(), [d.y0, 1]);
          return (t) => {
            x.domain(xd(t));
            y.domain(yd(t));
          };
        });

      transition.selectAll("path.main-arc").attrTween("d", (d) => () => arc(d));

      transition
        .selectAll("path.hidden-arc")
        .attrTween("d", (d) => () => middleArcLine(d));

      transition
        .selectAll("text")
        .attrTween("display", (d) => () => (textFits(d) ? null : "none"));
      d3.select(".total-percent").text(
        d.data
          ? numeral(d.data.impacted_device_count / d.data.device_count).format("0.00%")
          : numeral(impactedPercent).format("0.00%")
      );
      d3.select(".total-impact").text(
        d.data
          ? `${numeral(d.data.impacted_device_count).format("0,0")} of ${numeral(
              d.data.device_count
            ).format("0,0")}`
          : `${numeral(impactedDeviceCount).format("0,0")} of ${numeral(
              total
            ).format("0,0")}`
      );
      d3.select(".total-name").text(d.data ? d.data.name : "");
      const moveStackToFront = (elD) => {
        svg.selectAll(".slice")
          .filter((d) => d === elD)
          .each(function (d) {
            this.parentNode.appendChild(this);
            if (d.parent) {
              moveStackToFront(d.parent);
            }
          });
      };
      moveStackToFront(d);
    };
    return svg.node();
  };
  return (
        <div className={classes.root}>
          <svg ref={sunBurstRef} id="sunburst-chart" />
          <StateLegend data={legendData} orientation={'horizontal'} background={false}/>
          <ContextMenu
            dataSource={contextMenuItems}
            width={200}
            target={`#${sliceId}`}
            onItemClick={ handleMenuClick }
            disabledExpr='disable'
          />
          <Tooltip
            target={`#${sliceId}`}
            visible={tooltipVisible}
            closeOnOutsideClick={false}
            position={"right"}
            zIndex={2000}
          >
                  <div>
                      <div className={classes.label1}>Name:</div>
                      <div className={classes.label2}>{groupName}</div>
                  </div>
                  <div>
                      <div className={classes.label1}>Device Count: </div>
                      <div className={classes.label2}>{deviceCount}</div>
                  </div>
                  <div>
                      <div className={classes.label1}>Impacted Device Count: </div>
                      <div className={classes.label2}>{impactedDeviceCount}</div>
                  </div>
                  <div>
                      <div className={classes.label1}>Percent: </div>
                      <div className={classes.label2}>{percent}</div>
                  </div>
          </Tooltip>
          <Popup ref={ sunBurstPopupRef }
              maxwidth= { () => window.innerWidth/2 }
              maxHeight= '95%'
              width='50%'
              showTitle={true}
              title={title}
              dragEnabled={true}
              closeOnOutsideClick={false}
              visible={popupVisible}
              onHiding={ () => { handlePopupHidden() }}
              resizeEnabled={true}
            >
              <ScrollView height='100%' width='100%' direction='both' id='actions-scrollview' ref={ actionsScrollViewRef }>
                  <div>
                     {content}
                  </div>
              </ScrollView>
          </Popup>
        </div>
  )
};

export default React.memo(SunBurst);
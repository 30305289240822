import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { compose } from  'recompose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { Magnify, TimerOffOutline, TimerOutline,  Refresh, Fullscreen as FScreen, FullscreenExit } from 'mdi-material-ui';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { fade, withStyles } from "@material-ui/core/styles";
import { Chart, Series, CommonAxisSettings, ValueAxis, Legend, Size, Tooltip } from 'devextreme-react/chart';
import { LoadPanel } from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';
import Fullscreen from "react-full-screen";
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import GoogleMapReact from 'google-map-react';
import shouldPureComponentUpdate from 'react-pure-render/function';
import fetchClient  from '../../axios';
import * as constants from '../../constants';
import CustomMarker from './widgets/CustomMarker';
import MapNetworkEvents from './widgets/MapNetworkEvents';
import MapToggleLayerPanel from './widgets/MapToggleLayerPanel';
import NetworkEvents from './network-monitoring/NetworkEvents.js';
import StateLegend from '../../components/common/StateLegend';
import numeral from "numeral";

//const GMAPS_KEY = process.env.GMAPS_KEY;
const GMAPS_KEY = constants.GMAPS_KEY;
const chartTooltipTemplate = (info) => {
  return (
    <div className="state-tooltip">
      <div>
        <span className="">State</span>: {(info.argumentText).toUpperCase()}
      </div>
      <div>
        <span className="">Count</span>: {numeral(info.value).format('0,0')}
      </div>
    </div>
  );
};
const legendData = [{label: 'Critical', color: '#f44336'}, {label: 'Major', color: '#ff9800'}, {label: 'Minor', color: '#ffeb3b'},
      {label: 'Unknown', color: '#03a9f4'}, {label: 'Normal', color: '#4caf50'}];
const customizePoint = (point) => {
    switch (point.argument) {
        case 'Critical':
            return { color: red[500]};
        case 'Major':
            return { color: orange[500]};
        case 'Minor':
            return { color: yellow[500]};
        case 'Unknown':
            return { color: lightBlue[500]};
        case 'Normal':
            return { color: green[500]};
        default:
            //None
    }
};
const DeviceSummary = ({data, total}) => {
    return (
          <Card elevation={4} style={{margin: '4px'}}>
              <CardHeader style={{border: 'None', color: '#fff', textAlign: 'center', backgroundColor: teal[500], padding: '4px', height:'30px'}} title={numeral(total).format('0,0')}/>
              <CardContent style={{padding: 0}}>
                  <Chart id="device_summary_chart"
                     dataSource={data}
                     customizePoint={customizePoint}
                  >
                    <Series
                      visible={true}
                      valueField="value"
                      argumentField="label"
                      type="bar"
                      ignoreEmptyPoints={true}
                    />
                    <ValueAxis autoBreaksEnabled={true} maxAutoBreakCount={2} breakStyle={{line: 'waved'}}/>
                    <CommonAxisSettings visible={false} tick={{visible: false}} grid={{visible:false}} label={{visible:false}}/>
                    <Tooltip enabled={true} contentRender={chartTooltipTemplate} />
                    <Legend visible={false}/>
                    <Size width={200} height={100} />
                  </Chart>
              </CardContent>
          </Card>
    )
};
const styles = theme => ({
    root: {
        width: '100%'
    },
	cardContent: {
		padding: 0,
		overflow: 'auto',
		height: `calc(100vh - 125px)`,
        width: '100%',
        '&:last-child': {
			paddingBottom: 0
		}
	},
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    mapForm: {
		marginTop: 8
	},
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    paddingRight: theme.spacing(1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '70vw',
      },
    },
  },
  precisionSelect: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1)
  }
});

class DashBoardGeoMap extends React.Component {
  static defaultProps = {
      center: {
          lat: 36,
          lng: -81
      },
      zoom: 3.46
      //zoom: 4
  };
  shouldComponentUpdate = shouldPureComponentUpdate;
  constructor(props) {
    super(props);
    this.state = {
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      locations: [],
      query: "discovered:true",
      devices_summary: [],
      devices_summary_total: 0,
      network_events: [],
      network_events_total: 0,
      runTask: false,
      isFullScreen: false,
      markers: [],
      activeMarkers: [],
      statePowerOutageTiles: [],
      countyPowerOutageTiles: [],
      precision: 6,
      showMarkers: true,
      showWeather: true,
      showPowerOutage: false,
      loadPanelVisible: true,
      loadedCountyData: false
    };
    this.onCheckBoxChanged = this.onCheckBoxChanged.bind(this);
  }
  componentWillMount() {
    clearTimeout(this.statusTask);
    this.getStateWeatherTiltes();
    this.getCountyWeatherTiltes();
  }

  componentDidMount() {
		const { runTask } = this.state;
        if (runTask) {
            clearInterval(this.statusTask);
            this.statusTask = setInterval(()=> {this.getMapData();this.getNetworkEvents();this.getDeviceSummaryData();}, constants.TASK_TIME)
        }
  }
    /**
     * handleConfiguration:
     */
    handleConfiguration = () => {
        const { runTask } = this.state;
        this.setState({runTask: !runTask});
        if (runTask) {
            clearInterval(this.statusTask);
        } else {
            this.statusTask = setInterval(()=> {this.getMapData();this.getNetworkEvents();this.getDeviceSummaryData();}, constants.TASK_TIME)
        }
    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
    onInfoBoxLoad = (infoBox) => {
      console.log('infoBox:', infoBox)
    };
    getMapData = () => {
      const { query, mapInstance, precision } = this.state;
      this.setState({loadPanelVisible: true});
        let bounds = mapInstance.getBounds();
        let ne = bounds.getNorthEast();
        let sw = bounds.getSouthWest();
        //console.log(ne.lat(), sw.lng(), sw.lat(), ne.lng());
        let params = {"top_left_lat": ne.lat(), "top_left_lng": sw.lng(), "bottom_right_lat": sw.lat(), "bottom_right_lng": ne.lng(), query: query, precision: precision};
      fetchClient.get('/api/v1.0/es/geo-map/markers', { params: params })
          .then(res => {
              let locations = [];
              if (res.data && res.data.data) {
                  locations = res.data.data;
              }
              // let title = React.createElement('h1', {}, "Test Title");
              // mapInstance.controls[mapApi.ControlPosition.TOP_CENTER].push(title);
              this.setState({
                  locations: locations,
                  loadPanelVisible: false
              }, () => {this.createMarkers()});
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  locations: [],
                  loadPanelVisible: false
              }, () => {this.createMarkers()});
          });
    };
    getDeviceSummaryData = () => {
        const {query, mapInstance, mapApi} = this.state;
        let bounds = mapInstance.getBounds();
        let ne = bounds.getNorthEast();
        let sw = bounds.getSouthWest();
        let deviceSummaryControlDiv = "";
            mapInstance.controls[mapApi.ControlPosition.TOP_LEFT].clear();
            if (!document.getElementById('deviceSummaryControlDiv')) {
                deviceSummaryControlDiv = document.createElement('div');
                deviceSummaryControlDiv.setAttribute('id', 'deviceSummaryControlDiv');
            }
        let params = {"top_left_lat": ne.lat(), "top_left_lng": sw.lng(), "bottom_right_lat": sw.lat(), "bottom_right_lng": ne.lng(), query: query };
        //let params = {"top_left_lat": 101.25, "top_left_lng": 68.65, "bottom_right_lat": 67.5, "bottom_right_lng": -45.08984, query: query};
        let devices_summary = [];
        let devices_summary_total = 0;
      fetchClient.get('/api/v1.0/es/geo-map/device-summary', { params: params })
          .then(res => {
              if (res.data && res.data.data) {
                  devices_summary = res.data.data;
                  devices_summary_total = res.data.total
              }
              this.setState({
                  devices_summary: devices_summary,
                  devices_summary_total: devices_summary_total
              });
              ReactDOM.render(<DeviceSummary data={devices_summary} total={devices_summary_total}/>, deviceSummaryControlDiv);
              mapInstance.controls[mapApi.ControlPosition.TOP_LEFT].setAt(0, deviceSummaryControlDiv);
          })
          .catch(error => {
              //mapInstance.controls[mapApi.ControlPosition.TOP_LEFT].clear();
              console.error('Error:', error);
              this.setState({
                  devices_summary: [],
                  devices_summary_total: 0
              });
              ReactDOM.render(<DeviceSummary data={devices_summary} />, deviceSummaryControlDiv);
              mapInstance.controls[mapApi.ControlPosition.TOP_LEFT].setAt(0, deviceSummaryControlDiv);
          });
    };
    getNetworkEvents = () => {
      const { mapInstance, mapApi } = this.state;
      const networkEventsControlDiv = document.createElement('div');
      let network_events = [];
      let network_events_total = 0;
        //mapInstance.controls[mapApi.ControlPosition.LEFT_TOP].removeAt(1,networkEventsControlDiv);
      fetchClient.get('/api/v1.0/es/network-events')
          .then(res => {
              if (res.data && res.data.data) {
                  network_events = res.data.data;
                  network_events_total = res.data.total
              }
              this.setState({
                  network_events: network_events,
                  network_events_total: network_events_total
              });
              ReactDOM.render(<MapNetworkEvents onClcik={ () => this.handleNetworkEventsClick.bind(this)} network_events_total={network_events_total} network_events={network_events} />, networkEventsControlDiv);
              mapInstance.controls[mapApi.ControlPosition.LEFT_TOP].setAt(1, networkEventsControlDiv);
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  network_events: [],
                  network_events_total: 0
              });
              ReactDOM.render(<MapNetworkEvents data={network_events_total} network_events={network_events} />, networkEventsControlDiv);
              mapInstance.controls[mapApi.ControlPosition.LEFT_TOP].setAt(1, networkEventsControlDiv);
          });
    };
    getStateWeatherTiltes = () => {
      //const { query, mapInstance, mapApi } = this.state;
      let params = { group_by: 'stateName' };
      let weather_tiles = [];
      fetchClient.get('/api/v1.0/es/geo-map/power-outage/realtime', { params: params })
          .then(res => {
              if (res.data && res.data.data) {
                  weather_tiles = res.data.data;
              }
              this.setState({
                  statePowerOutageTiles: weather_tiles
              });
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  statePowerOutageTiles: []
              });
          });
    };
    getCountyWeatherTiltes = () => {
      //const { query, mapInstance, mapApi } = this.state;
      this.setState({loadedCountyData: false});
      let params = { group_by: 'countyName' };
      let weather_tiles = [];
      fetchClient.get('/api/v1.0/es/geo-map/power-outage/realtime', { params: params })
          .then(res => {
              if (res.data && res.data.data) {
                  weather_tiles = res.data.data;
                  console.log(res.data.data)
              }
              this.setState({
                  countyPowerOutageTiles: weather_tiles,
                  loadedCountyData: true
              });
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  countyPowerOutageTiles: [],
                  loadedCountyData: true
              });
          });
    };
    createMarkers = () => {
        const { locations } = this.state;
        let markers = locations && locations.map((location) => {
            //return new mapApi.Marker({position: location["location"], map: mapInstance, title: "Test"})
            return <CustomMarker onClick={ this.onMarkerClick } lat={location["location"]["lat"]} lng={location["location"]["lng"]} data={location.states} />
        });
        this.setState({markers: markers});
        //console.log('Markers'+markers);
    };
    createLegend = () => {
        const {mapInstance, mapApi} = this.state;
        const legendControlDiv = document.createElement('div');
              legendControlDiv.setAttribute('id', 'legendControlDiv');
              ReactDOM.render(<StateLegend data={legendData} orientation={'horizontal'} background={true} />, legendControlDiv);
              mapInstance.controls[mapApi.ControlPosition.LEFT_TOP].setAt(0, legendControlDiv);
    };
    handleApiLoaded = (map, maps) => {
        this.setState({mapInstance: map, mapApi: maps, mapApiLoaded: true}, () => {
            this.handleRefresh();
/*            this.getDeviceSummaryData();
            this.getMapData();
            this.getNetworkEvents();*/
            this.renderWeatherLayer();
            this.renderToggleControl();
            this.createLegend();
        });
        map.addListener("zoom_changed", () => {
            const { showPowerOutage } = this.state;
            this.getDeviceSummaryData();
            this.getMapData();
            showPowerOutage ? this.renderPowerOutageTiles() : this.clearPowerOutageTiles()
        });
        //this.loadMapShapes();
    };

    createMapOptions = (maps) => {
          // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
          // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
          // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
          // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
          // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
        ///mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain'],
        /*const mapStyle = [{
              'featureType': 'all',
              'elementType': 'all',
              'stylers': [{'visibility': 'on'}]
            }, {
              'featureType': 'landscape',
              'elementType': 'geometry',
              'stylers': [{'visibility': 'on'}]
            }, {
              'featureType': 'water',
              'elementType': 'labels',
              'stylers': [{'visibility': 'off'}]
            }, {
              'featureType': 'water',
              'elementType': 'geometry',
              'stylers': [{'visibility': 'on'}, {'hue': '#5f94ff'}, {'lightness': 60}]
            }];*/
        return {
            zoomControlOptions: {
              position: maps.ControlPosition.RIGHT_CENTER,
              style: maps.ZoomControlStyle.DEFAULT
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: maps.ControlPosition.TOP_RIGHT,
              mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain'],
              style: maps.MapTypeControlStyle.DEFAULT
            },
            streetViewControl: false,
            resetBoundsOnResize: true,
            layerTypes: ['TrafficLayer', 'TransitLayer'],
            fullscreenControl: false,
            gestureHandling: 'cooperative'//,
            //styles: mapStyle
        }
    };
    handleRefresh = () => {
        this.getMapData();
        this.getDeviceSummaryData();
        this.getNetworkEvents();
    };
    onChildMouseEnter = () => {

    };
    onChildMouseLeave = () => {

    };
    renderToggleControl = () => {
        const { mapInstance, mapApi } = this.state;
        const toggleLayerControlDiv = document.createElement('div');
        ReactDOM.render(<MapToggleLayerPanel  onCheckBoxChanged={this.onCheckBoxChanged} />, toggleLayerControlDiv);
        mapInstance.controls[mapApi.ControlPosition.LEFT_CENTER].setAt(0, toggleLayerControlDiv);
    };
    clearWeatherLayer = () => {
        const { mapInstance } = this.state;
        mapInstance.overlayMapTypes.setAt(0, null);
    };
    renderWeatherLayer = () => {
        const { mapInstance, mapApi } = this.state;
        /* Config for weather tiles from NEXRAD */
        let tileNEXRAD = new mapApi.ImageMapType({
            getTileUrl: function(tile, zoom) {
                return "https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/nexrad-n0q-900913/" + zoom + "/" + tile.x + "/" + tile.y +".png?"+ (new Date()).getTime();
            },
            tileSize: new mapApi.Size(256, 256),
            opacity:0.60,
            name : 'NEXRAD',
            isPng: true
        });
        mapInstance.overlayMapTypes.setAt(0, tileNEXRAD);
    };
    clearPowerOutageTiles = () => {
        const { mapInstance } = this.state;
            mapInstance.data.setStyle({ visible: false })
    };
    renderPowerOutageTiles = () => {
        const { mapInstance, mapApi, statePowerOutageTiles, countyPowerOutageTiles } = this.state;
        let zoomL = mapInstance.getZoom();
        let infoWindow = new mapApi.InfoWindow();
            //Remove data from map
            mapInstance.data.forEach((feature)=>{
                mapInstance.data.remove(feature);
            });
            if (zoomL < 5) {
                mapInstance.data.addGeoJson(statePowerOutageTiles, { idPropertyName: 'STATE' });
            } else {
                mapInstance.data.addGeoJson(countyPowerOutageTiles, { idPropertyName: 'GEOID' });
            }
            mapInstance.data.setStyle( (feature) => {
                let color = feature.getProperty('COLOR');
                    return {
                        visible: true,
                        fillColor: color,
                        fillOpacity: 0.4,
                        strokeColor: color,
                        strokeOpacity: 1,
                        strokeWeight: 1
                    }
            });
            mapInstance.data.addListener('mouseover', (e) => {
                let feature = e.feature;
                let html = '';
                    if (zoomL < 5) {
                        html = `<b>State Name:  ${feature.getProperty('NAME')}</b></br>
                                <b>Outage Count:  ${numeral(feature.getProperty('OUTAGE_COUNT')).format('0,0')}</b></br>
                                <b>Tracked Count:  ${numeral(feature.getProperty('TRACKED_COUNT')).format('0,0')}</b></br>
                                <b>Power Availability:  ${feature.getProperty('AVAILABILITY') === -1 ? 'Not Tracked' : feature.getProperty('AVAILABILITY')}%</b>`;
                    } else {
                        html = `<b>County Name:  ${feature.getProperty('NAME')}</b></br>
                                <b>Outage Count:  ${numeral(feature.getProperty('OUTAGE_COUNT')).format('0,0')}</b></br>
                                <b>Tracked Count:  ${numeral(feature.getProperty('TRACKED_COUNT')).format('0,0')}</b></br>
                                <b>Power Availability:  ${feature.getProperty('AVAILABILITY') === -1 ? 'Not Tracked' : feature.getProperty('AVAILABILITY')}%</b>`;
                    }
                    //<b>State Name:${"  "}${feature.getProperty('stateName')}</b></br>
                    infoWindow.setContent(html);
                    infoWindow.setPosition(e.latLng);
                    infoWindow.open(mapInstance);
            });
            mapInstance.data.addListener('mouseout', (e) => {
                    infoWindow.close();
            })
    };
    onCheckBoxChanged = (check, checked) => {
        //const { mapInstance, } = this.state;
        //let zoomL = mapInstance.getZoom();
        //console.log('Zoom:' + zoomL);
        if (check === 'showMarkers') {
            if (checked) {
                this.setState({'showMarkers': checked});
            } else {
                this.setState({'showMarkers': false});
            }
        }
        if (check === 'showWeather') {
            if (checked) {
                this.setState({'showWeather': checked}, () => { this.renderWeatherLayer(); });

            } else {
                this.setState({'showWeather': false}, () => { this.clearWeatherLayer();});
            }
        }
        if (check === 'showPowerOutage') {
            if (checked) {
                this.setState({'showPowerOutage': checked}, () => { this.renderPowerOutageTiles(); });
            } else {
                this.setState({'showPowerOutage': false}, () => { this.clearPowerOutageTiles(); });
            }
        }
    };
    handlePrecisionChange = (e) => {
        this.setState({precision: e.value}, () => { this.getMapData(); });
    };
    handlePopupHidden = () => {
        this.setState({ mapPopupVisible: false });
    };
    handleNetworkEventsClick = () => {
        this.setState({ mapPopupVisible: !this.state.mapPopupVisible });
    };
    onMapTilesLoaded = () => {
        this.setState({loadPanelVisible: false})
    };
    hideLoadPanel = () => {
        this.setState({loadPanelVisible: false})
    };
  render() {
    const { classes } = this.props;
    const { query, isFullScreen, runTask, markers, precision, showMarkers, mapPopupVisible, loadPanelVisible } = this.state;
    return (
        <Fullscreen enabled={isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
            <div className="full-screenable-node">
                <Card className={classes.root} raised={true}>
                    {/* <MuiAlert severity="warning" elevation={0} variant="filled">This page is in progress...</MuiAlert> */}
                    <CardHeader className={classes.cardHeader} title={" "}
                        action={
                                <div>
                                    <FormControl>
                                      <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                          <Magnify />
                                        </div>
                                        <InputBase
                                          placeholder="Filter with Lucene query"
                                          defaultValue={query}
                                          classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                          }}
                                          inputProps={{ 'aria-label': 'search' }}
                                          onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                              e.preventDefault();
                                              this.setState({
                                                  query: 'discovered:true AND '+e.target.value
                                              }, () => {this.getMapData(); this.getDeviceSummaryData(); this.getNetworkEvents()});
                                            }
                                          }}
                                        />
                                      </div>
                                    </FormControl>
                                    <FormControl>
                                        <SelectBox
                                           className={classes.precisionSelect}
                                           value={precision}
                                           items={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]}
                                           width={60}
                                           onValueChanged={this.handlePrecisionChange}
                                        />
                                    </FormControl>
                                    <IconButton onClick={this.handleConfiguration} title={ runTask ? 'Stop Timer' : 'Start Timer'} >
                                        { runTask ? <TimerOutline/> : <TimerOffOutline /> }
                                    </IconButton>
                                    <IconButton onClick={this.handleRefresh}>
                                     <Refresh/>
                                    </IconButton>
                                    <IconButton onClick={this.handleFullScreen}>
                                        { isFullScreen ? <FullscreenExit /> : <FScreen /> }
                                    </IconButton>
                                </div>
                            }
                                />
                        <CardContent className={classes.cardContent}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{key: GMAPS_KEY, language: 'en', libraries:'places,drawing,geometry,weather'}}
                                    draggable={true}
                                    defaultCenter={this.props.center}
                                    defaultZoom={this.props.zoom}
                                    yesIWantToUseGoogleMapApiInternals={true}
                                    options={this.createMapOptions}
                                    //onTilesLoaded={this.onMapTilesLoaded}
                                    onGoogleApiLoaded={ ({map, maps}) => this.handleApiLoaded(map, maps) }
                                    //onChildMouseEnter={this.onChildMouseEnter}
                                    //onChildMouseLeave={this.onChildMouseLeave}
                                >
                                { showMarkers && markers }
                                <Popup ref={(ref) => this.manageDevicePopup = ref}
                                    maxwidth= { () => window.innerWidth/1.5}
                                    maxHeight= '95%'
                                    showTitle={true}
                                    title={'Network Events'}
                                    dragEnabled={true}
                                    closeOnOutsideClick={false}
                                    visible={mapPopupVisible}
                                    onHiding={this.handlePopupHidden}
                                    resizeEnabled={true}
                                >
                                    <ScrollView height='100%' width='100%' direction='both' id='actions-scrollview' ref={(ref) => this.actionsScrollView = ref}>
                                        <NetworkEvents />
                                    </ScrollView>
                                    {/*<LoadPanel visible={!isCommandExecuted}/>*/}
                                </Popup>
                                </GoogleMapReact>
                                <LoadPanel
                                  shadingColor="rgba(0,0,0,0.4)"
                                  position={'center'}
                                  onHiding={this.hideLoadPanel}
                                  visible={ loadPanelVisible }
                                />
                        </CardContent>
                </Card>
            </div>
        </Fullscreen>
      )
  }
}

DashBoardGeoMap.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);

export default enhance(DashBoardGeoMap);
import React from 'react';

const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;

function calculateTotal(pieChart) {
  return formatNumber(pieChart.getAllSeries()[0].getVisiblePoints().reduce((s, p) => s + p.originalValue, 0));
}

export default function CustomMarkerChartCenterTemplate(pieChart) {
  return (
    <svg width="100" height="100">
      <circle cx="50" cy="50" r={pieChart.getInnerRadius() - 0} fill="#607d8b" />
      <text textAnchor="middle" x="50%" y="50%" dy=".3em" style={{ fontSize: 5, fill:"#ffffff" }}>{ calculateTotal(pieChart) }</text>
    </svg>
  );
}
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import compose from "recompose/compose";

const styles = theme => ({
	  root: {
	  	backgroundColor: "#95c2de",
  		margin: "auto",
  		height: "100%",
  		width: "100%",
  		position: "relative"
	  },
	  err: {
    	color: "#ffffff",
    	fontSize: "10rem",
    	position: "absolute",
    	left: "40%",
    	top: "25%"
  	  },
	  msg: {
		textAlign: "center",
		fontSize: "1.6rem",
		position: "absolute",
		left: "40%",
		top: "55%",
	  }
	});


class FourZeroFourContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		    hasErrors: false,
		};
	};
    componentDidMount() {
    };
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    };
	render() {
		const { classes } = this.props;
		//const { hasErrors, userInfo, groups } = this.state;
		return (
				<div className={classes.root}>
					<div className={classes.err}> 404 </div>
					<div className={classes.msg}>Page Not Found<p>Please go to <Link to="/dash-board">Dashboard</Link></p></div>
				</div>
		)
	}
}
FourZeroFourContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default withOktaAuth(withRouter(enhance(FourZeroFourContainer)));
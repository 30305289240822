import React, { Fragment, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import withWidth from '@material-ui/core/withWidth'
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import compose from 'recompose/compose';
import Hidden from '@material-ui/core/Hidden';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Fade from '@material-ui/core/Fade';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';
import { withOktaAuth } from '@okta/okta-react';
import { NavLink as RouterLink, } from 'react-router-dom';
import { DatabaseCogOutline, ViewDashboardOutline, Details, ChartDonutVariant, ToolboxOutline, Logout, CogOutline, HelpCircleOutline,
    AccountDetails, AccountCogOutline, AccessPointNetwork, Brightness4, Brightness7, ChevronDoubleLeft, ChevronDoubleRight, MenuDown, CloudSearchOutline,
    CogTransferOutline, LightningBolt, Devices } from 'mdi-material-ui';
import InfloBlox from '../svg-icons/InfoBlox';
//import ApiDocs from '../svg-icons/ApiDocs';
//import ServiceViewNavigatorIcon from '../svg-icons/ServiceViewNavigator';
import ReactRouter from './Routes';
import NMSToolsLogo from '../images/brand/NMS_Tools_2_200x45.png';
const drawerWidth = 270;

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100vh',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 0,
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    //marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6),
    /*[theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
    },*/
  },
  toolbar: {
    paddingRight: 24
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(6),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
        content: {
            height: `calc(100vh - ${theme.mixins.gridOffset}px)`,
        },
    },
    [theme.breakpoints.up('xs')]: {
        marginTop: '3.2em',
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        marginLeft: 0
    },
  },
  content1: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
  },
  contentShift: {
    [theme.breakpoints.up('md')]: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
  },
  title: {
      flex: 1,
  },
  active: {
    backgroundColor: theme.palette.action.selected,
    color: '#ffffff',
    '& $listIcon': {
        color: '#ffffff'
    }
  },
  listItem: {
      padding: theme.spacing(1),
  },
  listIcon: {
      minWidth: 46,
      color: "#03A9F4"
  },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
  button: {
      color: '#fff'
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 10
  },
    userName: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
  bottomCloseButton: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
      borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
    sideMenu: {
      position: 'absolute',
      marginTop: '48px',
      width: '100%'
    },
   menuIcon: {
      color: '#03A9F4'
   },
   // logo: {
   //    maxWidth: 160
   // }
});
const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }} >
    <RouterLink {...props} />
  </div>
));

async function checkAuthentication() {
    if (this.props.authState.isAuthenticated && !this.state.userInfo) {
        const authenticated = this.props.authState.isAuthenticated;
        const userInfo = await this.props.oktaAuth.getUser();
        const groups = userInfo['groups'];
        if (this._isMounted) {
            this.setState({authenticated, userInfo, groups});
        }
    }
}

class AppLayout extends React.Component {
    _isMounted = false;
  	constructor(props) {
		super(props);
		this.state = {
            open: false,
            anchorEl: null,
            userInfo: null,
            groups: [],
            authenticated: false,
            selectedTheme: this.props.selectedTheme
		};
        this.checkAuthentication = checkAuthentication.bind(this);
        this.logout = this.logout.bind(this);
        //this.checkAuthentication();
	};
    toggleUserMenu = event => {
      this.setState({
          anchorEl: event.currentTarget === this.state.anchorEl ? null : event.currentTarget
      });
    };
    handleClose = () => {
      this.setState({ anchorEl: null });
    };
    componentWillMount() {
        //this.checkAuthentication();
    }
    async componentDidMount() {
        this._isMounted = true;
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    }
    async componentDidUpdate() {
        this._isMounted = true;
        this.checkAuthentication();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
  resize() {
    //this.setState({ open: window.innerWidth >= 760 });
  }

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
    handleNavLink = (route) => {
      //this.props.history.push(route);
    };
    handleCloseMenu = () => {
      this.setState( {anchorEl: null});
    };
    handleThemeChange = () => {
        const ttype = this.state.selectedTheme === 'Light' ? 'Dark' : 'Light';
        this.props.changeTheme(ttype);
        this.setState({selectedTheme: ttype});
    };
    handleSidebarOpen = () => {
        if (this.state.open === false) {
            this.setState({ open: true });
        }
    };
    async logout() {
        // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
        await this.props.oktaAuth.signOut();
    }
  render() {
    const { classes } = this.props;
    const { open, anchorEl, userInfo, authenticated, selectedTheme, groups } = this.state;
    const openUserMenu = Boolean(anchorEl);
    const popperId = openUserMenu ? 'user-popper' : undefined;
    const isAdmin = groups && groups.length > 0 && groups.includes('nmstools_admin');
    //const isUser = groups && groups.length > 0 && groups.includes('nmstools_user');
    //const isPowerUser = groups && groups.length > 0 && groups.includes('nmstools_poweruser');
    const drawer = (
      <Fragment>
        <div className={classes.toolbar} />
        <Divider />
           <List className={classes.sideMenu}  component="div" disablePadding >
              <Tooltip title="Dashboard">
                  <ListItem key="Dashboard" button exact component={CustomRouterLink} to={'/'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <ViewDashboardOutline  />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
              </Tooltip>
               { isAdmin &&
                   <Tooltip title="Admin">
                       <ListItem button exact component={CustomRouterLink} to={'/admin'} activeClassName={classes.active}
                                 className={classes.listItem}>
                           <ListItemIcon className={classes.listIcon}>
                               <AccountCogOutline/>
                           </ListItemIcon>
                           <ListItemText primary="Admin"/>
                       </ListItem>
                   </Tooltip>
               }
               <Tooltip title="Manage Devices">
                 <ListItem button exact component={CustomRouterLink} to={'/manage-devices'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <Devices  />
                    </ListItemIcon>
                    <ListItemText primary="Manage Devices" />
                  </ListItem>
               </Tooltip>
                <Tooltip title="Service View Navigator">
                 <ListItem key="ServiceViewNavigator" button exact component={CustomRouterLink} to={'/svn'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <ChartDonutVariant  />
                    </ListItemIcon>
                    <ListItemText primary="Service View Navigator" />
                  </ListItem>
                </Tooltip>
               <Tooltip title="Device Detail">
                  <ListItem button exact component={CustomRouterLink} to={'/device-details'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <Details  />
                    </ListItemIcon>
                    <ListItemText primary="Device Details" />
                  </ListItem>
               </Tooltip>
              <Tooltip title="Device Search">
                  <ListItem button exact component={CustomRouterLink} to={'/device-search'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <CloudSearchOutline  />
                    </ListItemIcon>
                    <ListItemText primary="Device Search" />
                  </ListItem>
               </Tooltip>
                <Tooltip title="CMDB Admin">
                 <ListItem key="CmdbAdmin" button exact component={CustomRouterLink} to={'/cmdb-admin'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <DatabaseCogOutline  />
                    </ListItemIcon>
                    <ListItemText primary="CMDB Admin" />
                  </ListItem>
                </Tooltip>
               {/*<Tooltip title="SVN Views">
                  <ListItem button exact component={CustomRouterLink} to={'/svn-views'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <CalendarCheckOutline  />
                    </ListItemIcon>
                    <ListItemText primary="SVN Views" />
                  </ListItem>
                </Tooltip>*/}
               <Tooltip title="NMC Tools">
                  <ListItem button exact component={CustomRouterLink} to={'/sf-cases'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <ToolboxOutline  />
                    </ListItemIcon>
                    <ListItemText primary="NMC Tools" />
                  </ListItem>
               </Tooltip>
              <Tooltip title="Cradlepoint">
                  <ListItem key="cradlepoint" button exact component={CustomRouterLink} to={'/cradlepoint'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <AccessPointNetwork  />
                    </ListItemIcon>
                    <ListItemText primary="Cradlepoint" />
                  </ListItem>
              </Tooltip>
              <Tooltip title="Infoblox">
                  <ListItem key="info-blox" button exact component={CustomRouterLink} to={'/infoblox'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      <InfloBlox />
                    </ListItemIcon>
                    <ListItemText primary="Infoblox" />
                  </ListItem>
              </Tooltip>
               <Tooltip title="Lumos">
                  <ListItem key="lumos" button exact component={CustomRouterLink} to={'/lumos'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                      {/* <LightbulbOnOutline /> */}
                      <LightningBolt />
                    </ListItemIcon>
                    <ListItemText primary="Lumos" />
                  </ListItem>
              </Tooltip>
              <Tooltip title="NMS Tools API">
                  <ListItem key="docs" button exact component={CustomRouterLink} to={'/ab/getting-started'} activeClassName={classes.active} className={classes.listItem}>
                    <ListItemIcon className={classes.listIcon} >
                        <CogTransferOutline  />
                    </ListItemIcon>
                    <ListItemText primary="NMS Tools API" />
                  </ListItem>
              </Tooltip>
              <Divider />
          </List>
          {
              open ?
                <Fragment>
                    <Divider />
                    <div className={classes.bottomCloseButton}>
                          <ListItem key="close-open-sidebar" button  className={classes.listItem} onClick={this.handleDrawerToggle}>
                            <ListItemIcon className={classes.listIcon} >
                              { open ? <ChevronDoubleLeft /> :  <ChevronDoubleRight />}
                            </ListItemIcon>
                            <ListItemText primary="Collapse" />
                          </ListItem>
                    </div>
                </Fragment>
                :
              <Fragment>
                  <Divider />
                <div className={classes.bottomCloseButton}>
                      <Tooltip title="Expand">
                          <ListItem key="close-open-sidebar" button  className={classes.listItem} onClick={this.handleDrawerToggle}>
                            <ListItemIcon className={classes.listIcon} >
                              { open ? <ChevronDoubleLeft /> :  <ChevronDoubleRight />}
                            </ListItemIcon>
                            <ListItemText primary="Collapse" />
                          </ListItem>
                      </Tooltip>
                </div>
              </Fragment>
          }

      </Fragment>
    );
    if (authenticated) {
        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, open && classes.appBarShift)}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classNames(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <img src={NMSToolsLogo} alt="NMS Tools" className={classes.logo} />
                        {/*<AppLogo/>*/}
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title} />
                        {authenticated && userInfo &&
                        <Fragment>
                            <Tooltip title="Toggle light/dark theme" aria-label="Toggle light/dark theme"
                                     enterDelay={300}>
                                <IconButton color='inherit' onClick={this.handleThemeChange}>
                                    {selectedTheme === 'Light' ? <Brightness4/> : <Brightness7/>}
                                </IconButton>
                            </Tooltip>
                            {/*<Button className={classes.button} aria-describedby={popperId} size='small' onClick={this.toggleUserMenu}>
                      <Account className={classes.leftIcon} />
                      {userInfo['name']}
                  </Button>*/}
                            <div className={classes.userName} aria-describedby={popperId} onClick={this.toggleUserMenu}>
                                <Avatar
                                    alt={_.toUpper(userInfo['name'])}
                                    src={userInfo.picture}
                                    onClick={this.toggleUserMenu}
                                    className={classes.avatar}
                                />
                                <Typography className={classes.name}
                                            variant="h7">{_.toUpper(userInfo['name'])}</Typography>
                                <IconButton color='inherit' onClick={this.toggleUserMenu}>
                                    <MenuDown/>
                                </IconButton>
                            </div>
                            <ClickAwayListener mouseEvent="onMouseUp" onClickAway={this.handleCloseMenu}>
                                <Popper id={popperId} open={openUserMenu} anchorEl={anchorEl} transition
                                        disablePortal={true}>
                                    {({TransitionProps, placement}) => (
                                        <Fade {...TransitionProps} timeout={300}
                                              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                                            <Paper>
                                                <MenuList>
                                                    <ListItem button exact component={CustomRouterLink}
                                                              to={'/user-details'} onClick={this.handleCloseMenu}>
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <AccountDetails/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="User Details"/>
                                                    </ListItem>
                                                    <ListItem button exact component={CustomRouterLink} to={'/settings'}
                                                              onClick={this.handleCloseMenu}>
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <CogOutline/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="Settings"/>
                                                    </ListItem>
                                                    <ListItem button exact component={CustomRouterLink} to={'/help'}
                                                              onClick={this.handleCloseMenu}>
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <HelpCircleOutline/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="Help"/>
                                                    </ListItem>
                                                    <ListItem button exact component={CustomRouterLink} to={'/login'} onClick={ this.logout }>
                                                        <ListItemIcon className={classes.listIcon}>
                                                            <Logout/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="Logout"/>
                                                    </ListItem>
                                                </MenuList>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </ClickAwayListener>
                        </Fragment>
                        }
                    </Toolbar>
                </AppBar>
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={open}
                        onClose={this.handleDrawerToggle}
                        onMouseOver={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={this.handleDrawerToggle}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                        {authenticated ? drawer : null}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                        }}
                        open={open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={this.handleDrawerToggle}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                        {authenticated ? drawer : null}
                    </Drawer>
                </Hidden>
                <main className={classNames(classes.content, open && classes.contentShift)}>
                    <ReactRouter groups={groups} changeTheme={this.props.changeTheme} selectedTheme={this.props.selectedTheme}/>
                </main>
            </div>
        );
    } else {
        return (
            <div className={classNames(classes.content1, false && false)}>
                <ReactRouter groups={groups} changeTheme={this.props.changeTheme} selectedTheme={this.props.selectedTheme}/>
            </div>
        )
    }
  }
}

AppLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
    withStyles(styles, {withTheme: true}),
    withWidth()
);
export default withOktaAuth(enhance(AppLayout));

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import _ from 'lodash';
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { DataGrid } from 'devextreme-react/data-grid';



const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
                overflow: 'auto',
                padding: 0,
                '&:last-child': {
                        paddingBottom: theme.spacing(1)
                }
    },
    headerTitle: {
      fontSize: '1.2rem',
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    orange: {
        color: '#fff',
        backgroundColor: orange[500],
    },
    grey: {
        color: '#fff',
        backgroundColor: grey[500],
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableCellBold: {
        padding: '4px 16px',
        lineHeight: '0.80rem',
        fontWeight: 'bold',
        width: '300px'
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        fontWeight: 'bold',
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5'
    }
});
const dateFormat = 'MM/DD/YYYY hh:mm:ss A';
class PingOrCurrentStatusApeView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
    
        };
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    render() {
        const { classes, raised, title, actionData }  = this.props;
        let deviceProps = [
            {fieldName: 'san', label: 'Device Name'},
            {fieldName: 'id', label: 'Device Id'},
            {fieldName: 'customerId', label: 'Customer Id'},
            {fieldName: 'ipAddress', label: 'IP Address'},
            {fieldName: 'macAddress', label: 'macAddress'},
            {fieldName: 'serialNumber', label: 'serialNumber'},
            {fieldName: 'versionId', label: 'Firmware Version'},
            //{fieldName: 'assigned', label: 'Assigned At'},
            {fieldName: 'registered', label: 'Registered At'},
            {fieldName: 'profile_name', label: 'Device Profile'},
            {fieldName: 'nr_outlets', label: 'Number of Outlets'}];
        let statusProps = [
            {fieldName: 'online', label: 'Status'},
            {fieldName: 'total_power', label: 'Total Power Draw'},
            {fieldName: 'status_led_a', label: 'Status LED A'},
            {fieldName: 'status_led_b', label: 'Status LED B'},
            {fieldName: 'surge_protection_faults', label: 'Surge Protection Faults'},
            {fieldName: 'mem_total_kB', label: 'Total Memory'},
            {fieldName: 'mem_avail_kB', label: 'Free Memory'},
            {fieldName: 'mem_swap_free_kB', label: 'Free Swap'},
            {fieldName: 'core_temp_C', label: 'Core Temperature'},
            //{fieldName: 'timestamp_ms', label: 'System Time'},
            {fieldName: 'lastReported', label: 'Last Reported'}];
        let outlet_columns = [
            {dataField: 'outlet_number', caption: ' '},
            {dataField: 'name', caption: 'Name'},
            {dataField: 'outlet_energized', caption: 'Status'},
            {dataField: 'power_watts', caption: 'Power Draw'},
            {dataField: 'ping_enabled', caption: 'Ping Enabled'}];
        let ping_columns = [
            {dataField:'position', caption: 'Outlet'},
            {dataField:'timestamp_ms', caption: 'Last Ping'},
            {dataField:'pingTarget', caption: 'Target'},
            {dataField:'status', caption: 'Status'},
            {dataField:'packetsTransmitted', caption: 'Num Sent'},
            {dataField:'packetLossPercentage', caption: 'Loss %'},
            {dataField:'msRoundTripAvg', caption: 'Avg Resp (ms)'}];

        const outlets = actionData.hasOwnProperty('outlets') ? actionData['outlets'] : [];
        const pings   = actionData.hasOwnProperty('outlet_pings') ? actionData['outlet_pings'] : [];
        const error   = actionData && typeof actionData !== 'string' && actionData.hasOwnProperty('san') ? "" : 
                        actionData && typeof actionData === 'string' ? 
                        actionData : "Unknown error";
        //console.log("the action data is "+JSON.stringify(actionData))
        //console.log("error: " + error);

        // calc total power usage and change bools to strings for cleaner output
        let total_power = 0;
        outlets.forEach(function(elem, i, arr) {
            total_power += elem.power_watts;
            arr[i].outlet_energized = arr[i].outlet_energized ? "ON" : "OFF";
            arr[i].ping_enabled     = arr[i].ping_enabled ? "True" : "False";
            arr[i].power_watts      = arr[i].power_watts + " W";
        });
        // format ping date stamp
        pings.forEach(function (elem, i, arr) {
            arr[i].timestamp_ms = moment.unix(arr[i].timestamp_ms/1000).format(dateFormat);
        });

        return (
            <Card variant="outlined" className={classes.root} raised={raised}>
              <CardHeader className={classes.cardHeader} classes={{title: classes.headerTitle}} title={title} />
              <CardContent className={classes.cardContent} >
 
            { error ?
                <Table size="small">
                  <TableBody stripedRows>
                    <TableRow key="Error" className={ classes.tableRow }>
                      <TableCell className={ classes.tableCellBold } component="head" scope="row">Error:</TableCell>
                      <TableCell className={ classes.tableCell }>{error}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
 
                :

                <Table size="small">
                  <TableBody stripedRows>
                    <TableRow key='detailsHeader' className={classes.tableRow1}>
                      <TableCell colSpan={2} align="center" className={classes.tableCellBold}>Device Details</TableCell>
                    </TableRow>

                   { _.map(deviceProps, function (value, key) {
                        let fieldName = value['fieldName'];
                        let val = actionData[fieldName];
                        let label = value['label'];

                        // convert Zulu time to EST

                        return (
                            <TableRow key={key} className={ classes.tableRow }>
                            <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                            <TableCell className={ classes.tableCell }>{val}</TableCell>
                            </TableRow>
                            );
                        })
                    }

                    </TableBody>
                  </Table>
            }

                <Table size="small">
                  <TableBody stripedRows>
                    <TableRow key='statusHeader' className={classes.tableRow1}>
                      <TableCell colSpan={2} align="center" className={classes.tableCellBold}>Device Status</TableCell>
                    </TableRow>

            { !error ?
                  _.map(statusProps, function (value, key) {
                        let fieldName = value['fieldName'];
                        let val = actionData[fieldName];
                        let label = value['label'];

                        // convert online boolean to up/down
                        if (fieldName === 'online') {
                            val = val? "UP" : "DOWN";
                        } 
                        // sum outlets power draw for total power draw 
                        if (fieldName === 'total_power') {
                            //let total_power = 0;
                            //outlets.forEach(function (elem) {
                            //    total_power += elem.power_watts;
                            //});
                            val = total_power.toFixed(1) + " W";
                        }
                        // convert total memory to MB
                        if (fieldName === 'mem_total_kB') {
                                  val = (val / 1000).toFixed(0) + " MB"
                        }
                        // calc free memory 
                        if (fieldName === 'mem_avail_kB') {
                                  val = ((val + actionData['mem_free_kB']) / 1000).toFixed(0) + " MB"
                        }
                        // calc free swap 
                        if (fieldName === 'mem_swap_free_kB') {
                                  val = (val / actionData['mem_swap_total_kB'] * 100).toFixed(0) + "%"
                        }
                        // add unit to core temp
                        if (fieldName === 'core_temp_C') {
                                  val = val.toFixed(0) + " C"
                        }
                        // convert epoch time stamps to human readable
                        if (fieldName === 'timestamp_ms') {
                                  val = moment.unix(val/1000).format(dateFormat);
                        }

                        return (
                               <TableRow key={key} className={ classes.tableRow }>
                                 <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                 <TableCell className={ classes.tableCell }>{val}</TableCell>
                               </TableRow>
                               );
                    })
                : "" 
             } 

                  </TableBody>
                </Table>

                      { outlets ?
                          <Card className={classes.card1}>
                            <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Outlets'}/>
                            <DataGrid
                              dataSource={outlets}
                              columns={outlet_columns}
                              width="100%"
                              showBorders={true}
                            />
                          </Card>
                          : ""
                      }
                      { pings ?
                          <Card className={classes.card1}>
                            <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Pings'}/>
                            <DataGrid
                              dataSource={pings}
                              columns={ping_columns}
                              width="100%"
                              showBorders={true}
                            />
                          </Card>
                          : ""
                      }
              </CardContent>
            </Card>
        )
    }
}

PingOrCurrentStatusApeView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(PingOrCurrentStatusApeView);

import cyan from '@material-ui/core/colors/cyan';
//import grey from '@material-ui/core/colors/grey';
//import indigo from '@material-ui/core/colors/indigo';
//import lightBlue from '@material-ui/core/colors/lightBlue';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
//import { fade } from '@material-ui/core/styles/colorManipulator';

const DarkBaseTheme = {
		type: 'dark',
		typography: {
			useNextVariants: true,
			//fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
		},
		palette: {
			type: 'dark',
			primary: {
				light: "#78a7eb",
				main: "#4378b8",
				dark: "#004d88"
			},
		    secondary: pink,
		    error: red,
			contrastThresold: 3,
			tonalOffset: 0.2,
			action: {
				selected: cyan[500],
				//active: lightBlue[500]
			},
		  },
		mixins: {
		  toolbar: {
			  minHeight: 45,
			  '@media (min-width:600px)': {
				  minHeight: 45
			  }
		  },
		  cardHeader: {
			height: 45,
			secondHeight: 36
		  },
		  gridOffset: 106
		},
		overrides: {
			MuiButton: { // Name of the component ⚛️ / style sheet
				text: { // Name of the rule

				},
			},
			MuiTableRow: {
				root: {
					height: 36,
				},
				head: {
					height: 40,
				},
				footer: {
					height: 40,
				}
			},
			MuiTableCell: {
				body: {

				}
			}
		},
};

export default DarkBaseTheme;
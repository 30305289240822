import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { DotsHorizontal } from 'mdi-material-ui';
import { Popup } from 'devextreme-react/popup';
//import _ from 'lodash';
//import fetchClient  from '../../../axios';
import PropTypes from "prop-types";
import compose from "recompose/compose";
import ScrollView from 'devextreme-react/scroll-view';
import MuiAlert from '@material-ui/lab/Alert';
import ImpactedDevices from './ImpactedDevices';
import ImpactedCustomers from './ImpactedCustomers';
import ApgImpactedGraph from '../../service-view-navigator/ApgImpactedGraph';

const styles = theme => ({
    button: {
        padding: 0,
        color: '#ffffff'
    },
    networkEventsPopup: {
        padding: 0
    }
});


class NetworkEventsRowActionsMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            anchorEl: null,
            isPopupVisible: false,
            content: '',
            title: ''
        };
        this.onPopupHiding = this.onPopupHiding.bind(this);
        this.networkEventsPopup = null;
        this.networkEventsScrollView = null;

    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleAuditLog = () => {

    };
    onPopupHiding = () => {
        this.setState({
            isPopupVisible: false
        })
    };
    getImpactedDevices = () => {
        const rowData = this.props.rowData;
        this.setState({
            isPopupVisible: true,
            anchorEl: null,
            content: <ImpactedDevices group_name={rowData.name} raised={false} />
        });
    };
    getImpactedCustomers = () => {
        const rowData = this.props.rowData;
        this.setState({
            isPopupVisible: true,
            anchorEl: null,
            content: <ImpactedCustomers group_name={rowData.name} raised={false} />
        });
    };
    getImpactedHistory = () => {
        const rowData = this.props.rowData;
        this.setState({
            isPopupVisible: true,
            anchorEl: null,
            content: <ApgImpactedGraph groupName={rowData.name} raised={false} />
        });
    };
    showImpactedDevicesMap = () => {
        //const rowData = this.props.rowData;
        this.setState({
            isPopupVisible: true,
            anchorEl: null,
            content: <MuiAlert severity="info" elevation={1} variant="outlined">Sorry, We are still working on this component.</MuiAlert>
        });
    };
   render() {
    const { anchorEl, isPopupVisible, content, title } = this.state;
    const { classes } = this.props;
    return (
          <Fragment>
              <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClick}
                className={classes.button} >
                <DotsHorizontal />
              </IconButton>
               <Menu
                 id="simple-menu"
                 anchorEl={anchorEl}
                 open={Boolean(anchorEl)}
                 onClose={() => this.setState({ anchorEl: null })}
               >
                   <MenuItem key="impactedDevices" onClick={this.getImpactedDevices}>Impacted Devices</MenuItem>
                   <MenuItem key="impactedCustomers" onClick={this.getImpactedCustomers}>Impacted Customers</MenuItem>
                   <MenuItem key="impactedHistoryPercent" onClick={this.getImpactedHistory} >Impacted History</MenuItem>
                   <MenuItem key="showImpactedDevicesMap" onClick={this.showImpactedDevicesMap} disabled>Show Impacted Devices Map</MenuItem>
                </Menu>
              <Popup
                  elementAttr={{
                      id: 'networkEventsPopup',
                      class: 'network-event-popup'
                  }}
                  ref={(ref) => this.networkEventsPopup = ref}
                    maxwidth= { () => window.innerWidth/1.5}
                    maxHeight= '95%'
                    showTitle={true}
                    title={title}
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    visible={isPopupVisible}
                    onHiding={this.onPopupHiding}
                    resizeEnabled={true}
              >
                  <ScrollView height='100%' width='100%' direction='both' id='network-events-scrollview' ref={(ref) => this.networkEventsScrollView = ref}>
                            <div>
                                {content}
                            </div>
                    </ScrollView>
              </Popup>
          </Fragment>
    );
  }
}

NetworkEventsRowActionsMenu.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(NetworkEventsRowActionsMenu);
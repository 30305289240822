import React, {  PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import DataGrid from 'devextreme-react/data-grid';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
//import moment from 'moment';
//import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
        overflow: 'auto',
        padding: 0,
        '&:last-child': {
            paddingBottom: theme.spacing(1)
        }
    },
    headerTitle: {
      fontSize: '1.2rem',
      //color: '#ffffff'
    },
    summaryViewIconButton: {
        padding: 6
    },
    summaryViewActions: {
        marginBottom: -6,
        marginTop: 3
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5'
    }
});
class PingOrCurrentStatusMerakiView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {

        };
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    textFormatter = (cellInfo) => {
        return cellInfo.value.toUpperCase();
    };
    render() {
        const { classes,  raised, title, actionData, deviceClassName }   = this.props;
        const deviceDetailsProps = [
            {fieldName: 'name', label: 'Device Name'},
            {fieldName: 'serial', label: 'Serial No'},
            {fieldName: 'productType', label: 'Device Type'},
            {fieldName: 'model', label: 'Device Model'},
            {fieldName: 'mac', label: 'MAC Address'}
            ];
        const deviceStatusProps = [
            {fieldName: 'gateway', label: 'Gateway'},
            {fieldName: 'publicIp', label: 'Public IP'},
            {fieldName: 'lastReportedAt', label: 'Last Reported'},
            {fieldName: 'status', label: 'Status'}
            ];
        const deviceUplinkProps = [
            {dataField: 'interface', caption: 'Interface', customizeText: this.textFormatter},
            {dataField: 'gateway', caption: 'Gateway'},
            {dataField: 'ip', caption: 'IP'},
            {dataField: 'ipAssignedBy', caption: 'IP Assigned By', customizeText: this.textFormatter},
            {dataField: 'publicIp', caption: 'Public IP'},
            {dataField: 'primaryDns', caption: 'Primary DNS'},
            {dataField: 'secondaryDns', caption: 'Secondary DNS'},
            {dataField: 'status', caption: 'Status', customizeText: this.textFormatter}];
        const deviceUplinkPropsHnsModemCell = [
            {dataField: 'apn', caption: 'APN', customizeText: this.textFormatter},
            {dataField: 'connectionType', caption: 'Connection Type', customizeText: this.textFormatter},
            {dataField: 'dns1', caption: 'DNS1'},
            {dataField: 'dns2', caption: 'DNS2'},
            {dataField: 'gateway', caption: 'Gateway'},
            {dataField: 'iccid', caption: 'ICC ID'},
            {dataField: 'interface', caption: 'Interface', customizeText: this.textFormatter},
            {dataField: 'ip', caption: 'IP'},
            {dataField: 'model', caption: 'Model', customizeText: this.textFormatter},
            {dataField: 'provider', caption: 'Provider', customizeText: this.textFormatter},
            {dataField: 'publicIp', caption: 'Public IP'},
            {dataField: 'signalType', caption: 'Signal Type'},
            {dataField: 'status', caption: 'Status', customizeText: this.textFormatter},
            {dataField: 'signalStat.rsrp', caption: 'RSRP'},
            {dataField: 'signalStat.rsrq', caption: 'RSRQ'}
            ];
        const device_details = actionData && actionData['device_details'] ? actionData['device_details'][0] : [];
        const device_status = actionData && actionData['device_status'] ? actionData['device_status'][0] : [];
        const device_uplinks = actionData && actionData['device_uplinks'] && actionData['device_uplinks'].length > 0 ? actionData['device_uplinks'][0]['uplinks'] : [];
        return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
                    <CardContent className={ classes.cardContent } >
                     <Table size="small">
                         <TableBody stripedRows>
                            <TableRow key='detailsHeader' className={ classes.tableRow1 }>
                              <TableCell colSpan={2} align="center" className={ classes.tableCell }>Device Details</TableCell>
                            </TableRow>
                             { device_details ?
                                  _.map(deviceDetailsProps, function (value, key) {
                                      let fieldName = value['fieldName'];
                                      let val = (fieldName === 'productType') ? _.capitalize(device_details[fieldName]) : device_details[fieldName];
                                      let label = value['label'];
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCell } component="head" scope="row">{label}</TableCell>
                                          <TableCell className={ classes.tableCell }>{val}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                             { device_status ?
                                  _.map(deviceStatusProps, function (value, key) {
                                      let fieldName = value['fieldName'];
                                      let val = (fieldName === 'status') ? _.capitalize(device_status[fieldName]) : device_status[fieldName];
                                      let label = value['label'];
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCell } component="head" scope="row">{label}</TableCell>
                                          <TableCell className={ classes.tableCell }>{val}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                         </TableBody>
                     </Table>
                         { device_uplinks &&
                             <Card className={classes.card1}>
                              <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Device Uplinks'}/>
                              <DataGrid
                                dataSource={device_uplinks}
                                columns={deviceClassName === 'HNS_ModemCell' ? deviceUplinkPropsHnsModemCell : deviceUplinkProps}
                                showBorders={true}
                             />
                            </Card>
                         }
                    </CardContent>
                </Card>
            )
    }
}

PingOrCurrentStatusMerakiView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(PingOrCurrentStatusMerakiView);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Grid as Grid1 } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import compose from 'recompose/compose';
import moment from 'moment';
import { LoadingIndicator, Chart, Series, ArgumentAxis, Label, CommonAxisSettings, CommonSeriesSettings, Margin, Export, Grid, ValueAxis, Legend, Title, Tick, Tooltip, Point } from 'devextreme-react/chart';
import fetchClient from "../../axios";
// import * as constants from "../../constants";
// import _ from "lodash";
import numeral from "numeral";
import axios from "axios/index";
import CustomStore from "devextreme/data/custom_store";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup);

const styles = theme => ({
	root: {
	  width: '100%',
      height: '100%'
	},
	paper: {
		padding: theme.spacing(2),
		border: '1px solid rgba(224, 224, 224, 1)',
		boxShadow: "none",
        height: "60vh"
	},
	dateText: {
		fontSize: "1rem"
	},
    apgChart: {
	    height: '100%',
        width: '100%'
    }
});
const dateFormat = 'MM/DD/YYYY HH:mm:ss';

class ApgImpactedGraph extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			timeRange: 24,
			startDateTime: moment().subtract(24, 'hours').format(dateFormat),
			endDateTime: moment().format(dateFormat),
			timePeriod: 3600,
            data_length: 0,
			impactedData: [],
			totalData: [],
			dateTickInterval: 24,
			height: '100%',
			width: '100%'
		};
		this.apgChartRef = React.createRef();
		this.refreshChart = this.refreshChart.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handlePreviousDate = this.handlePreviousDate.bind(this);
		this.handleNextDate = this.handleNextDate.bind(this);
	}
	componentWillMount() {
        //this.getApgChartData();
    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
	handleToggle = (e, val) => {
        this.setState({
			timeRange: val,
			startDateTime: moment().subtract(val, 'hours').format(dateFormat),
			endDateTime: moment().format(dateFormat),
		}, () => {this.refreshChart()});
    };
	handlePreviousDate = () => {
		const { startDateTime, endDateTime, timeRange } = this.state;
        this.setState({
			startDateTime: moment(startDateTime).subtract(timeRange, 'hours').format(dateFormat),
			endDateTime: moment(endDateTime).subtract(timeRange, 'hours').format(dateFormat)
		}, () => {this.refreshChart()});
	};
	handleNextDate = () => {
		const { startDateTime, endDateTime, timeRange } = this.state;
        this.setState({
			startDateTime: moment(startDateTime).add(timeRange, 'hours').format(dateFormat),
			endDateTime: moment(endDateTime).add(timeRange, 'hours').format(dateFormat)
		}, () => {this.refreshChart()});
	};
    chartTooltipTemplate = (info) => {
      let value = 0;
      if (info.seriesName === 'Total Count' || info.seriesName === 'Impacted Count') {
          value = numeral(info.value).format('0,0')
      }
      else if (info.seriesName === 'Impacted Percent' || info.seriesName === 'Network Event Threshold') {
          value = `${numeral(info.value).format('0.00')}%`
      } else {
          value = info.value
      }
      return (
        <div className="state-tooltip">
          <div>
            <span className="">Date/Time</span>: {info.argumentText }
          </div>
          <div>
            <span className="">{info.seriesName}</span>: {value}
          </div>
        </div>
      );
    };
    customizeText = (info) => {
		const  { timeRange } = this.state;
		let ddate = '';
		//let numTickes = '';
		switch(timeRange) {
			case 1:
			case 6:
			case 24:
				ddate = moment(info.value, dateFormat).format("HH:mm:ss");
				//numTickes = 24;
			break;
			case 120:
				ddate = moment(info.value, dateFormat).format("MM/DD/YYYY");
			break;
			case 720:
				ddate = moment(info.value, dateFormat).format("DD-MMM");
				break;
			case 2160:
				ddate = moment(info.value, dateFormat).format("DD-MMM");
				break;
			case 8760:
				ddate = moment(info.value, dateFormat).format("MMM-YYYY");
			break;
			default:
				ddate = info.value
		}
		return ddate;
	};
    refreshChart = () => {
        this.apgChartRef.current.instance.refresh();
    };
    onChartInitialized = () => {

    };
	render() {
		const { classes, groupName } = this.props;
		const { timeRange, startDateTime, endDateTime, } = this.state;
        const apgImpactedGraphStore = {
            store: new CustomStore({
				key: 'name',
                load: function(loadOptions) {
                    const params = {
                        start_timestamp: moment(startDateTime, dateFormat).valueOf()/1000,
                        end_timestamp: moment(endDateTime, dateFormat).valueOf()/1000,
                        period: timeRange <= 24 ? 0 : timeRange <= 120 ? 3600 : timeRange <= 720 ? 86400 : 604800,
                        filter_expression: `device=='${groupName}' & (name=='Total')`,
                    };
                    const params1 = {
                        start_timestamp: moment(startDateTime, dateFormat).valueOf()/1000,
                        end_timestamp: moment(endDateTime, dateFormat).valueOf()/1000,
                        period: timeRange <= 24 ? 0 : timeRange <= 120 ? 3600 : timeRange <= 720 ? 86400 : 604800,
                        filter_expression: `device=='${groupName}' & (name=='Impacted')`
                    };
                    const params2 = {
                        start_timestamp: moment(startDateTime, dateFormat).valueOf()/1000,
                        end_timestamp: moment(endDateTime, dateFormat).valueOf()/1000,
                        period: timeRange <= 24 ? 0 : timeRange <= 120 ? 3600 : timeRange <= 720 ? 86400 : 604800,
                        filter_expression: `device=='${groupName}' & (name=='maxperc')`
                    };
                    const url = '/api/v1.0/apg/db/object-data/with-filter';
                    return axios.all([
                            fetchClient.get(url, {params: params}),
                            fetchClient.get(url, {params: params1}),
                            fetchClient.get(url, {params: params2})
                          ])
                          .then(axios.spread((res, res1, res2) => {
                                let impactedData = [];
                                let totalData = [];
                                let thresholdData = [];
                                if ((res.data && res.data.data) && (res1.data && res1.data.data) && (res2.data && res2.data.data)) {
                                    totalData = res.data.data[0]['avg_data'];
                                    impactedData = res1.data.data[0]['avg_data'];
                                    thresholdData = res2.data.data[0]['avg_data'];
                                    impactedData.forEach(function(item, idx){
                                        let percent = (item['value']/totalData[idx]['value'])*100;
                                        let threshold = thresholdData[idx]['value'];
                                        item['total'] = totalData[idx]['value'];
                                        item['percent'] = percent;
                                        item['threshold'] = threshold;
                                    });
                                    //console.log('impcted Data:', impactedData)
                                }
                                return impactedData;
                          }))
                         .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
		return (
			<div className={classes.root} >
						<Grid1 container spacing={1} direction="row" justify="center" alignContent="center" alignItems="center">
							<Grid1 item>
								<div>Zoom:</div>
							</Grid1>
							<Grid1 item>
								<StyledToggleButtonGroup exclusive value={timeRange} size="small" variant="text" color="primary" onChange={this.handleToggle}>
									<ToggleButton value={1}>1h</ToggleButton>
									<ToggleButton value={6}>6h</ToggleButton>
									<ToggleButton value={24}>1d</ToggleButton>
									<ToggleButton value={120}>5d</ToggleButton>
									<ToggleButton value={720}>1m</ToggleButton>
									<ToggleButton value={2160}>3m</ToggleButton>
									<ToggleButton value={8760}>1y</ToggleButton>
								</StyledToggleButtonGroup>
							</Grid1>
						</Grid1>
						<Grid1 container spacing={1} direction="row" justify="center" alignContent="center"  alignItems="center">
							<Grid1 item>
								<div>Date Range:</div>
							</Grid1>
							<Grid1 item>
								<IconButton color='primary' onClick={this.handlePreviousDate} >
                                    <span className="mdi mdi-chevron-left mdi-24px" />
								</IconButton>
							</Grid1>
							<Grid1 item>
								<Typography className={classes.dateText} variant="h6" component="h6">{startDateTime} - {endDateTime}</Typography>
							</Grid1>
							<Grid1 item>
								<IconButton color='primary' onClick={this.handleNextDate} >
									<span className="mdi mdi-chevron-right mdi-24px" />
								</IconButton>
							</Grid1>
						</Grid1>
                        <Paper className={classes.paper} id="apgChart">
                         <Chart ref={this.apgChartRef} className={classes.apgChart} id="chart" dataSource={apgImpactedGraphStore} theme="material.blue.light">
                            <CommonSeriesSettings argumentField="date_string" type="line" />
                            <Series axis="totalAxis" valueField="total" name="Total Count" type="spline" pallete="material" color={green[300]} dashStyle="longDash">
                                <Point size={4}/>
                            </Series>
                             <Series axis="totalAxis" valueField="value" name="Impacted Count" type="spline" pallete="material" color={red[500]} dashStyle="longDash">
                                 <Point size={4}/>
                             </Series>
                            <Series axis="percentAxis" valueField="percent" name="Impacted Percent" type="spline" pallete="material" color={orange[500]}>
                                <Point size={8}/>
                            </Series>
                             <Series axis="percentAxis" valueField="threshold" name="Network Event Threshold" type="spline" pallete="material" color={blue[500]}>
                                 <Point size={8}/>
                             </Series>
                            <ValueAxis name="totalAxis" position="left" title="Total/Impacted Devices - Count">
                              <Grid visible={true} />
                            </ValueAxis>
                            <ValueAxis tickInterval={5} showZero={true} valueMarginsEnabled={true} name="percentAxis" position="right" title="Impacted Devices - Percent">
                                <Grid visible={true}/>
                                {/*<VisualRange startValue={0} endtValue={100}/>*/}
                            </ValueAxis>
                            <CommonAxisSettings discreteAxisDivisionMode="crossLabels" />
                            <ArgumentAxis tickInterval={this.state.dateTickInterval}>
                                <Title text={"Date/Time"} />
                                <Tick width={3}/>
                                <Grid visible={true}/>
                                <Label wordwrap="none" customizeText={ this.customizeText} />
                            </ArgumentAxis>
                             <Tooltip zIndex={2000} enabled={true} contentRender={this.chartTooltipTemplate} />
                             {/*<Tooltip zIndex={2000} enabled={true} />*/}
                            <Export enabled={true} />
                            <Legend itemTextPosition='right' verticalAlignment="bottom" horizontalAlignment="center" />
                            <Margin top={8} right={8} bottom={8} left={8} />
                            <LoadingIndicator enabled={true}/>
                             {/*<Size width={width} height={height} />*/}
                          </Chart>
                        </Paper>
				</div>
			)
	}
}

ApgImpactedGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(ApgImpactedGraph);
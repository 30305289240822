import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import { DataGrid, Column, Scrolling } from 'devextreme-react/data-grid';
import { textFormatter, stateRender } from "../common/utils";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MuiAlert from '@material-ui/lab/Alert';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardHeader1: {
        border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
        border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
		color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});
class DeviceLocationInformation extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			isFullScreen: false
		};
	}
	render() {
		const { classes, raised, deviceData, devicesSameLocationData, handleDeviceNameClicked, pageType} = this.props;
		const title = 'Location Information';
		const nameRender = (data) => {
			if(pageType ==='devicedetails') {
				return (<Link className='dx-link' to={`/device-details/${data.value}`} onClick={(e)=> handleDeviceNameClicked(e, data.value) }>{data.value}</Link>);
			}
			else {
				return (<Link className='dx-link' to={`/lumos`} onClick={(e)=> handleDeviceNameClicked(e, data.value) }>{data.value}</Link>);
			}
		};
		let tableColumns = [
		    { caption: 'Customer Location ID', dataField: 'customerLocationId' },
		    { caption: 'Address', dataField: 'address' },
            { caption: 'City', dataField: 'city' },
            { caption: 'State', dataField: 'stateUSA' },
            { caption: 'Zip', dataField: 'zip' },
            { caption: 'Latitude', dataField: 'latitude' },
            { caption: 'Longitude', dataField: 'longitude' },
            { caption: 'Hughes Location ID', dataField: 'plsiteid' }
        ];
		let tableColumns1= [
			{ dataField: 'name', caption: 'Device ID', customizeText: textFormatter, cellRender: nameRender},
			{ dataField: 'productCode', caption: 'Product Name', customizeText: textFormatter, },
			{ dataField: 'productCategory', caption: 'Device Category', customizeText: textFormatter, },
			{ dataField: 'state', caption: 'Operational Status', cellRender: stateRender, alignment: 'center',}
		];
		return (
				<Card className={classes.root} raised={raised}>
					<CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
					<CardContent className={ classes.cardContent }>
					 <Table size="small">
						 <TableBody>
							{(deviceData) ?
								tableColumns.map(row => {
									  return (
										<TableRow key={row.dataField} className={ classes.tableRow }>
											<TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
											<TableCell className={ classes.tableCell }>{deviceData[row.dataField]}</TableCell>
										</TableRow>
									  );
								}) :
								tableColumns.map(row => {
								  return (
										<TableRow key={row.dataField} className={ classes.tableRow }>
											<TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
											<TableCell className={ classes.tableCell }>Unknown</TableCell>
										</TableRow>
								  );
								})
							}
						</TableBody>
					  </Table>
					  <CardHeader className={ classes.cardHeader1 } classes={{title: classes.headerTitle}} title="Other Devices at this Location" />
                        { (devicesSameLocationData && devicesSameLocationData.length === 0) &&
                            <MuiAlert severity="info">
                                No other devices are available at this location.
                            </MuiAlert>
                        }
                        {(devicesSameLocationData && devicesSameLocationData.length > 0) &&
                            <DataGrid id={'otherDevicesGrid'}
                                      dataSource={devicesSameLocationData}
                                      height={devicesSameLocationData.length < 5 ? "" : "150" }
                                      showColumnLines={true}
                                      showRowLines={true}
                                      showBorders={true}
                                      rowAlternationEnabled={true}
                                      repaintChangesOnly={true}
                                      allowColumnReordering={true}
                                      allowColumnResizing={true}
                                      columnResizingMode={'widget'}
                                      columnAutoWidth={true}
                                      wordWrapEnabled={false}
                                      columnMinWidth={70}
                                      cellHintEnabled={true}
                                      noDataText={'No other devices are available at this location'}
                            >
                                <Scrolling showScrollbar={'always'} useNative={false}/>
                                {
                                    tableColumns1.map(row => {
                                        return (
											// eslint-disable-next-line
                                            <Column dataField={row.dataField} caption={row.caption} alignment={row.alignment} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)}/>
                                        );
                                    })
                                }
                            </DataGrid>
                        }
					</CardContent>
				</Card>
			)
	}
}

DeviceLocationInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DeviceLocationInformation);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
import { DataGrid, RemoteOperations, Paging, Pager, Column, Scrolling, ColumnChooser, LoadPanel,
    Export, SearchPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import fetchClient  from '../../axios';
import * as constants from '../../constants';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});
//const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
class SFActiveNotifications extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            pageSizes: [5, 10, 15, 25, 50, 100],
            runTask: false,
            gridColumns: [
                //{dataField: 'id', caption: 'ID', dataType: 'number', visible: false },
                {dataField: 'case_number', caption: 'Case Number', dataType: 'string', allowSorting: true, width: '100', sortOrder: 'desc'},
                {dataField: 'case_owner', caption: 'Case Owner', dataType: 'string', allowSorting: true, width: '100'},
                {dataField: 'case_type', caption: 'Case Type', dataType: 'string', allowSorting: true, width: '100' },
                {dataField: 'case_subtype', caption: 'Case Subtype', dataType: 'string', allowSorting: true, width: '100' },
                {dataField: 'status', caption: 'Status', dataType: 'string',allowSorting: true, width: '100' },
                {dataField: 'hsvc_service_type', caption: 'Service Type', dataType: 'string', allowSorting: true, width: '100' },
                {dataField: 'subject', caption: 'Subject', dataType: 'string', allowSorting: true, width: '200' },
                {dataField: 'hsvc_customers_affected', caption: 'Customers Affected', dataType: 'string', allowSorting: true, width: '150' },
                {dataField: 'last_modified_date', caption: 'Last Modified Date', dataType: 'string', allowSorting: true, width: '150' },
                {dataField: 'last_modified_by_name', caption: 'Last Modified By', dataType: 'string', allowSorting: true, width: '150' },
                {dataField: 'event_details', caption: 'Event Details', dataType: 'string', allowSorting: true, width: '*' },
            ],
            // start_date: moment().subtract(24, 'hours').format(dateFormat),
            // end_date: moment().format(dateFormat),
            // start_date: moment().subtract(24, 'hours'),
            // end_date: moment.now()
        };
        //this.clearRequests = this.clearRequests.bind(this);
        //this.handleRefreshModeChange = this.handleRefreshModeChange.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.dataGrid = null;
        //Editing

    }

    componentWillMount() {

    }

    componentDidMount() {
        const { runTask } = this.state;
        if (runTask) {
            clearInterval(this.sfActiveNotificationsTask);
            this.sfActiveNotificationsTask = setInterval(()=> this.dataGrid.instance.refresh(), constants.NMC_TASK_TIME)
        }
    };
    componentWillUnmount () {
        clearTimeout(this.sfActiveNotificationsTask);
    };
    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }
    handleConfiguration = (e) => {
        const { runTask } = this.state;
        this.setState({runTask: !runTask});
        if (!runTask) {
            clearInterval(this.sfActiveNotificationsTask);
            this.sfActiveNotificationsTask = setInterval(()=> this.dataGrid.instance.refresh(), constants.NMC_TASK_TIME)
        } else {
            clearInterval(this.sfActiveNotificationsTask);
        }
        e.component.option('text', runTask ? 'Start Timer' : 'Stop Timer');
        e.component.option('icon', runTask ? 'mdi mdi-timer-off-outline' : 'mdi mdi-timer-outline');
    };
    onRowPrepared = (e) => {

    };
    onToolbarPreparing = (e) => {
        const runTask = this.state.runTask;
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, columnChooserPanel, exportButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                /*if(item.name === 'searchPanel') {
                    items.splice(i, 1);
                    searchPanel = item;
                }*/
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'exportButton') {
                    items.splice(i, 1);
                    exportButton = item;
                }
            }
            toolbarItems.push(exportButton,
                {
                    widget: 'dxButton',
                    location: 'after',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        elementAttr: {'id': 'SFActiveNotificationsConfigBtn'},
                        hint: runTask ? 'Stop Timer' : 'Start Timer',
                        icon: runTask ? 'mdi mdi-timer-outline' : 'mdi mdi-timer-off-outline',
                        text: runTask ? 'Stop Timer' : 'Start Timer',
                        onClick: this.handleConfiguration.bind(this)
                    }
                },
                columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    };
    onContentReady = (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onCellPrepared = (e) => {
      /*if(e.rowType === 'header' && e.column.command === 'edit') {
          e.cellElement.textContent='Actions';
      }*/
    };
render() {
        const { classes, theme, raised } = this.props;
        const { gridColumns, pageSize, pageSizes } = this.state;
        //const title = "Active Notifications";
        const dataStore = {
            store: new CustomStore({
                key: 'case_id',
                load: (loadOptions) => {
                        return fetchClient.get('/api/v1.0/nmc/sf_active_notifications')
                            .then(res => {
                                let sfActiveNotifications = [];
                                let sfActiveNotificationsTotal = 0;
                                if (res.data && res.data.data) {
                                    sfActiveNotifications = res.data.data;
                                    sfActiveNotificationsTotal = res.data.total;
                                }
                                return {
                                    data: sfActiveNotifications,
                                    totalCount: sfActiveNotificationsTotal
                                };
                            })
                            //.catch ((res) => { throw 'Data Loading Error';});
                            .catch (res => {
                                let sfActiveNotifications = [];
                                let sfActiveNotificationsTotal = 0;
                                if (res.data && res.data.data) {
                                    sfActiveNotifications = res.data.data;
                                    sfActiveNotificationsTotal = res.data.total;
                                }
                                return {
                                    data: sfActiveNotifications,
                                    totalCount: sfActiveNotificationsTotal
                                };
                            });
                }
            })
        };
        return (
            <Card className={classes.root} raised={raised}>
                <DataGrid id={'sfActiveNotificationsGrid'}
                        ref={(ref) => this.dataGrid = ref}
                        height={`calc(100vh - ${theme.mixins.gridOffset}px)`}
                        dataSource={dataStore}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        repaintChangesOnly={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={'widget'}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        wordWrapEnabled={false}
                        columnMinWidth={150}
                        cellHintEnabled={true}
                        onToolbarPreparing={this.onToolbarPreparing}
                        onContentReady={this.onContentReady}
                        onCellPrepared={this.onCellPrepared}
                        onRowPrepared={this.onRowPrepared}
                    >
                    <SearchPanel visible={true} width={220} placeholder={'Search for active notifications'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    <Export enabled={true} fileName={'active_notifications'} />
                    {
                        gridColumns.map(row => {
                          return (
                            // eslint-disable-next-line
                            <Column dataField={row.dataField} caption={row.caption} visible={row.visible} dataType={row.dataType} allowHeaderFiltering={row.allowHeaderFiltering} headerFilter={row.headerFilter} validationRules={row.validationRules} width={row.width} allowSorting={row.allowSorting} sortOrder={row.sortOrder} customizeText={eval(row.customizeText)} />
                          );
                        })
                    }
                    <RemoteOperations sorting={false} paging={false} />
                    <Paging defaultPageSize={pageSize}/>
                    <Pager visible={true} showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={pageSizes}/>
                </DataGrid>
            </Card>
        )
    }
}

SFActiveNotifications.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(SFActiveNotifications);
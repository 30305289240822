import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardContent : {
        padding: theme.spacing.unit,
        overflow: 'auto',
        height: `calc(100vh - 125px)`,
        '&:last-child': {
            paddingBottom: theme.spacing.unit
        }
      },
  divider: {
    display: 'block',
    margin: `${theme.spacing(3)}px 0`,
  },
  orderedList: {
      listStyle: 'decimal',
      marginLeft: 20,
      '& ul': {
          listStyle: 'lower-alpha',
          marginLeft: 20
      }
  },
  tokeTextArea: {
    height: 140,
    marginTop: 8,
    marginBottom: 8,
    width: '100%',
    padding: 8
  },
  copyButton: {
      float: 'right'
  },
});
class GettingStarted extends Component {
  constructor(props) {
      super(props);
      this.state = {
        token: ''
      };
  };
  componentWillMount() {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'));
    this.setState({token: token.accessToken ? token.accessToken.accessToken : 'No token available, Please login again'});
  };
  componentDidMount() {

  };
  onGetToken = () => {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'));
    this.setState({token: token.accessToken ? token.accessToken.accessToken : 'No token available, Please login again'});
  };
  render() {
    const { classes, raised=false } = this.props;
    return (
        <Card className={classes.root} raised={raised}>
            <CardContent className={ classes.cardContent }>
                <Typography variant="h6" gutterBottom>To use apis from the documentation tab:</Typography>
                <div>
                  <ul className={classes.orderedList}>
                      <li>No need to authorize, Navigate to "API Documentation". You should be able to try all the API, if you have proper access.</li>
                  </ul>
                </div>
                <Typography variant="h6" gutterBottom>To use APIs from external applications:</Typography>
                <div>
                  <ul className={classes.orderedList}>
                      <li>Please contact our OKTA team to setup a service account</li>
                  </ul>
                </div>
            </CardContent>
        </Card>
    )
  }
}

GettingStarted.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withOktaAuth(withStyles(styles, {withTheme: true})(GettingStarted));
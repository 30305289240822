import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
// import IconButton from '@material-ui/core/IconButton';
// import { Fullscreen as FScreen, FullscreenExit, TimerOffOutline, TimerOutline,  Refresh, Close } from 'mdi-material-ui';
// import { LoadPanel } from 'devextreme-react/load-panel';
// import DataGrid from 'devextreme-react/data-grid';
// import moment from 'moment';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import ReactJson from 'react-json-view';
// import ReactHtmlParse from 'react-html-parser';
// import fetchClient  from '../../../axios';
// import * as constants from '../../../constants';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	card1: {
	  //width: '100%',
      margin: theme.spacing(1),
	},
	cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
	    //maxHeight: '400px',
		//height: '400px',
		overflow: 'auto',
		padding: 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1.2rem',
	  //color: '#ffffff'
	},
    summaryViewIconButton: {
	    padding: 6
    },
    summaryViewActions: {
	    marginBottom: -6,
        marginTop: 3
    },
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.80rem'
	},
	tableRow: {
		height: 25,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default
		}
	}
});
class PingAwmsView extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {};
	}
	componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
	render() {
		const { classes, raised, title, actionData }  = this.props;
		//const radios = actionData['radios'] ? actionData['radios'] : [];
		delete actionData['radios'];
		return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
                    <CardContent className={ classes.cardContent } >
                     <Table size="small">
                         <TableBody stripedRows>
                             { actionData ?
                                  _.map(actionData, function (value, key) {
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCell } component="head" scope="row">{key}</TableCell>
                                          <TableCell className={ classes.tableCell }>{value}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                         </TableBody>
                     </Table>
                    </CardContent>
                </Card>
			)
	}
}

PingAwmsView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(PingAwmsView);
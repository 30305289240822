import React from 'react';
import _ from 'lodash';
import moment from 'moment';

export const updateBulkActionsList = (selectedItems) => {
    let selectedRows = selectedItems.selectedRowsData,
        isDiversity = _.indexOf(_.map(selectedRows, function (obj) { return obj.userdef2 && obj.userdef2 === 'Diversity' }), false) > -1 ? false : true,
        isPamAllowed = _.indexOf(_.map(selectedRows, function (obj) { return (obj.discovered && (obj.poller !== 'notset' || obj.poller !== '') && obj.nocid !== 'CRM-ACTV' && obj.nmd !== 'notset' && (obj.provisionStatus === 'ACTV' || obj.provisionStatus === 'maint') && (obj.eitService_Mode !== 'Unmonitored' || obj.eitService_Mode !== 'notset')) }), false) > -1 ? false : true,
        is_j2entdiv_device = _.indexOf(_.map(selectedRows, function (obj) { return obj.nocid && obj.nocid === 'J2ENTDIV' }), false) > -1 ? false : true,
        is_j2ent_device = _.indexOf(_.map(selectedRows, function (obj) { return obj.nocid && obj.nocid === 'J2ENT' }), false) > -1 ? false : true,
        //is_velocloud_device = _.indexOf(_.map(selectedRows, function (obj) { return obj.vendor && obj.vendor === 'VeloCloud' }), false) > -1 ? false : true,
        deviceNames = _.map(selectedRows, function (obj) {
            return obj.name
        }).join(),
        items = [];
    if(!is_j2entdiv_device) {
        items.push({
            value: 'multiPing',
            name: 'Multi Ping',
            deviceNames: deviceNames,
        })
    }
    if (isPamAllowed) {
        items.push({
            value: 'pamSuspend',
            name: 'PAM Suspend',
            deviceNames: deviceNames
        });
    }
    if (isDiversity && (!is_j2entdiv_device || !is_j2entdiv_device)) {
        items.push({
            value: 'switchToHome',
            name: 'Multi Switch to Home NOC',
            deviceNames: deviceNames
        }, {
            value: 'switchToBackup',
            name: 'Multi Switch to Backup NOC',
            deviceNames: deviceNames
        }, {
            value: 'switchToGtn',
            name: 'Multi Switch to Germantown NOC',
            deviceNames: deviceNames
        }, {
            value: 'switchToNlv',
            name: 'Multi Go to NLV NOC',
            deviceNames: deviceNames
        }, {
            value: 'status',
            name: 'Multi Get Status',
            deviceNames: deviceNames
        })
    }
    if (is_j2ent_device || is_j2entdiv_device) {
        items.push({
            value: 'multiPingJ2ENT',
            name: 'Multi Ping',
            deviceNames: deviceNames,
        })
    }
    if (isDiversity && is_j2entdiv_device) {
        items.push({
            value: 'switchToHomeJ2ENTDIV',
            name: 'Multi Switch to Home NOC',
            deviceNames: deviceNames
        }, {
            value: 'switchToBackupJ2ENTDIV',
            name: 'Multi Switch to Backup NOC',
            deviceNames: deviceNames
        }, {
            value: 'switchToGtnJ2ENTDIV',
            name: 'Multi Switch to Germantown NOC',
            deviceNames: deviceNames
        }, {
            value: 'switchToNlvJ2ENTDIV',
            name: 'Multi Switch to NLV NOC',
            deviceNames: deviceNames
        })
    }
    return items;
};

export const exportFormSubmit = (path, params, method = 'POST') => {
/*    //$('<iframe id="export-iframe" style="display:none" name="export-iframe">').appendTo('body');
    let form = document.createElement("form"),
        node = document.createElement("input");
        form.action = createURL(path);
        form.target = "export-iframe";
        form.method = method;
        form.style.display = "none";
        _.each(_.keys(params), function(key, idx){
            node.name  = key;
            node.value = params[key];
            form.appendChild(node.cloneNode());
        });
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);*/
};

export const textFormatter = (cellInfo) => {
    switch (cellInfo.value) {
        case 'true':
        case true:
            return 'Yes';
        case 'false':
        case false:
            return 'No';
        case 'UNKNOWN':
        case 'unknown':
            return 'Unknown';
        case 'UP':
        case 'up':
            return 'Up';
        case 'DOWN':
        case 'down':
            return 'Down';
        case 'NotApplicable':
            return 'Not Applicable';
        case '0.0.0.0':
            return 'DHCP';
        case 'notset':
        case '':
        case null:
        case '_e':
            return 'Unavailable';
        default:
            return cellInfo.value;
    }
};

export const dateFormatter = (options) => {
    return (options.value === 0 || options.value === '0' || options.value === '_e') ? 'Never' : moment(options.value).format('MM/DD/YYYY HH:mm:ss');
};

export const epochDateFormatter = (options) => {
    return (options.value === 0 || options.value === '0' || options.value === '_e') ? 'Unavailable' : moment.unix(options.value/1000).format('MM/DD/YYYY HH:mm:ss');
};

export const secondsToStringFormatter = (options) => {
    if (options.value === '_e' || options.value === 'notset' || options.value === '') {
        return 'Unavailable';
    } else {
        const seconds = Number(options.value/1000);
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);

        const dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
        const hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
        const mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins ") : "";
        const sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    }
};

export const prepareFilterExpression = (filterValue, selectedFilterOperation, target) => {
    let filterExpression,
        value,
        that = this;
        switch(selectedFilterOperation) {
            case '=':
                value = filterValue;
                break;
            case "<>":
                value = 'NOT '+filterValue;
                break;
            case "startswith":
                value = filterValue+'*';
                break;
            case "endswith":
                value = '*'+filterValue;
                break;
            case "contains":
                value = '*'+filterValue+'*';
                break;
            case "notcontains":
                value = 'NOT '+filterValue;
                break;
            default:
                //console.log('default');
        }
        if(target === 'search') {
            value = '*'+filterValue+'*';
        }
        filterExpression = [that['dataField']+':'+value];
        return filterExpression;
};

export const stateRender = (data) => {
    let state,
        label;
        switch (data.value) {
            case 1:
                state = 'critical';
                label = 'Critical';
                break;
            case 2:
                state = 'major';
                label = 'Major';
                break;
            case 3:
                state = 'minor';
                label = 'Minor';
                break;
            case 4:
                state = 'unknown';
                label = 'Unknown';
                break;
            case 5:
                state = 'normal';
                label = 'Normal';
                break;
            default:
                state = data.value;
                label = data.value;
                break;
        }
        return (<span className={"label alert-" + state}>{label}</span>)
};

export const updateData = (data) => {
    _.each(data, function (itm, key) {
            switch (itm) {
                case 'true':
                case true:
                    data[key] = 'Yes';
                    break;
                case false:
                case 'false':
                    data[key] = 'No';
                    break;
                case 'UNKNOWN':
                case 'unknown':
                    data[key] = 'Unknown';
                    break;
                case 'UP':
                case 'up':
                    data[key] = 'Up';
                    break;
                case 'DOWN':
                case 'down':
                    data[key] = 'Down';
                    break;
                case 'NotApplicable':
                    data[key] = 'Not Applicable';
                    break;
                case '0.0.0.0':
                    data[key] = 'DHCP';
                    break;
                case '_e':
                case 'notset':
                case '':
                case null:
                    data[key] = 'Currently Unavailable';
                    break;
                default:
                    //console.log('default');
            }
        });
    return data;
};

export const replaceKeysDeep = function(obj, keysMap) {
    return _.transform(obj, function(result, value, key) {
        let currentKey = keysMap[key] || key;
        result[currentKey] = _.isObject(value) ? replaceKeysDeep(value, keysMap) : value;
    });
};

export const updateDeviceData = (objectProperties) => {
    if (_.isArray(objectProperties)) {
        return objectProperties
    } else {
        return updateData(objectProperties);
    }
};

export const actionsFormatter = function(container, options) {
    /*let row = options.data,
        items = [],
        url,
        title;
     if(row.elementName!=''){
        if(externalAppName === 'eit') {
            url = createURL(getObjectDetails, {name:row.name, internal:true, hidePanelHeader: true, externalAppName:'eit'});
        } else {
            url = createURL(getObjectDetails, {name:row.name, internal:true, hidePanelHeader: true});
        }
        title = 'Device Details of ' + row.name;
        var deviceDetailsWindowHeight = document.body.clientHeight*.85,
            deviceDetailsWindowWidth = document.body.clientWidth*.90;
        items.push({'url': url, title: title, text: "Device Details"});
    }
    if(window.currentUserHasGroup('pamsuspendcrud')  && row.discovered == true && (row.poller !='notset' || row.sam == 'PAM-ST-SAM')) {
        url = (externalAppName === 'eit') ? createURL(suspendDevice, {deviceIds:row.id, name: row.name, externalAppName:'eit', includeScripts: false, hidePanelHeader: true}) : createURL(suspendDevice, {deviceIds:row.id, includeScripts: false, hidePanelHeader: true});
        title = 'PAM Suspend for' + ' ' + row.name;
        items.push({'url': url, title: title, text: "PAM Suspend"});
    }
    url = (externalAppName === 'eit') ? createURL(getAuditLog, {name: row.name, externalAppName:'eit', includeScripts: false, hidePanelHeader: true}) : createURL(getAuditLog, {name: row.name, includeScripts: false, hidePanelHeader: true});
    title = 'Audit Log for '+ row.name;
    items.push({'url': url, title: title, text: "Audit Logs"});
    $('<div>').appendTo(container).dxMenu({
        items: [{
            icon: 'mdi mdi-dots-horizontal',
            items: items
        }],
        showFirstSubmenuMode: 'onClick',
        hideSubmenuOnMouseLeave: true,
        onItemClick: function (e) {
            let link;
            if (e.itemData.url === undefined || e.itemData.url === null) return;
            if (e.itemData.text === 'Device Details') {
                link = $('<a>', { href: openPopup('#pamStatusDevicesPopup', e.itemData.url, e.itemData.title, deviceDetailsWindowHeight, deviceDetailsWindowWidth)});
            } else if(e.itemData.text === 'PAM Suspend') {
                link = $('<a>', { href: openPopup('#pamStatusDevicesPopup', e.itemData.url, e.itemData.title, 'auto')});
            } else {
                link = $('<a>', { href: openPopup('#pamStatusDevicesPopup', e.itemData.url, e.itemData.title)});
            }
            e.itemElement.wrap(link)
        }
    });*/
};

export const getNetworkQuery = (data) => {
    let query = '*:*';
    if (data) {
        const networkType = data.network_type;
        const networkName = data.name;
        const llg = data.low_level_group;
        let name = networkName.split('-');
        if (llg === 1) {
            return `discovered:true AND memberOf:"${networkName}"`
            //return `discovered:true AND memberOf.keyword:${networkName}`
            //return `discovered:true AND nmd:name[0] AND dataCenter:name[1] AND (napSource:name[2] OR provider:name[2])`
            //return `discovered:true AND memberOf:${networkName}`
        }
        switch(networkType) {
            case 'ALL_DEVICES':
                query = 'className:HNS_*';
                break;
            case 'ALL_DATACENTERS':
                query = '(className:HNS_VSAT OR className:HNS_Terrestrial) AND (dataCenter:GTN OR dataCenter:NLV)';
                break;
            case 'ALL_CUSTOMERS':
                query = '(className:HNS_VSAT OR className:HNS_Terrestrial)';
                break;
            case 'ALL_DSLGEOSTATES':
                query = 'className:HNS_Terrestrial';
                break;
            case 'ALL_HRGWS':
                query = 'gwid:*HRGW*';
                break;
            case 'ALL_APPLIANCES':
                query = 'className:HNS_* AND NOT className:HNS_VSAT AND NOT className:HNS_Terrestrial';
                break;
            case 'ALL_SATELLITES':
                query = 'className:HNS_VSAT';
                break;
            case 'ALL_PROVIDERS':
                query = 'className:HNS_Terrestrial';
                break;
            case 'NMD_DC_DSL':
                query = `nmd:${name[0]} AND dataCenter:${name[1]} AND (napSource:${name[2]} OR provider:${name[2]})`;
                break;
            case 'APPLIANCE':
                query = `memberOf:"${name[0]}"`;
                break;
            case 'DSLGEOSTATE':
                query = `className:HNS_Terrestrial AND stateUSA:${name[0]}`;
                break;
            case 'OUTROUTE':
                query = `satName:${networkName}`;
                break;
            case 'CUSTOMER':
                query = `nmd:${networkName}`;
                break;
            case 'DSL_PROVIDER':
                //query = `napSource:${name[1]} OR provider:${name[1]}`;
                query = `napSource:${networkName} OR provider:${networkName}`;
                break;
            case 'DATACENTER':
                query = `dataCenter:${networkName}`;
                break;
            case 'SATELLITE':
                query = `satName:${name[0]}`;
                break;
            case 'IPGW':
                query = `memberOf:"${networkName}"`;
                break;
            case 'DC_DSL':
                query = `dataCenter:${name[0]} AND (napSource:${name[1]} OR provider:${name[1]})`;
                break;
            case 'AGW':
            case 'RE_ROUTER':
            case 'APPLIANCE_NMD':
            case 'HRGW':
            case 'DSL_GEO':
                query = `memberOf:"${networkName}"`;
                break;
            default:
                //None;
        }
    }
    return `discovered:true AND ${query}`;
};

export const calculateEventDuration = (options) => {
    let data = options['data'];
    let lastNotifiedAt = data['lastNotifiedAt'];
    let now   = moment().unix().valueOf() * 1000;
    let duration = 0;
    if (data['active']) {
        duration = now -  lastNotifiedAt;
    } else {
        duration = data['clearedAt'] - lastNotifiedAt
    }
    const seconds = Number(duration/1000);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
    const hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const eventStatusFormatter = (options) => {
    return options.value ? 'Active' : 'Inactive';
};

export const handleErrors = (response) => {
    if(response['status'] !== 200) {
        throw Error(response.statusText)
    }
    return response;
};

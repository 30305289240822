import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { DataGrid, Column, Scrolling } from 'devextreme-react/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import fetchClient from '../../axios'

class ARPDetails extends PureComponent {
    constructor(props) {
		super(props);
        this.state = {
            deviceName: this.props.deviceName,
            system_ARP_details: [],
            message: '',
            loading: false
        }
	}


    async componentDidMount() {
        await this.getSystemARP(this.props.deviceName);
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.deviceName !== this.props.deviceName && prevProps.hnsLocationId !== this.props.hnsLocationId){
            await this.getSystemARP(this.props.deviceName);
        }
    }

    getSystemARP = (deviceName) => {
        let params = {device_name:deviceName}
        this.setState({loading:true}, async () => {
            const response = await fetchClient.get('api/v1.0/action/device/getSysArp', {params:params});
            if(response.data.results) {
                this.setState({
                    deviceName: deviceName,
                    system_ARP_details:response.data.results.data,
                    message:'',
                    loading: false
                });
            }
            else if(response.data.note) {
                this.setState({
                    system_ARP_details: [],
                    message: response.data.note,
                    loading: false
                })
            }
        });    
    }

    render() { 
        const{system_ARP_details, loading,deviceName} = this.state
		const title = 'ARP';
        let arpColumns = [
            {dataField:'interface', caption:'Interface'},
            {dataField:'ip', caption:'IP Address'},
            {dataField:'mac', caption:'MAC'},
            {dataField:'vendor', caption:'Vendor'}

        ]
        if(this.state.message==='') {
            return (
                <div>
					<h2>Real Time Data for {deviceName}</h2>
                    <Card>
                        <CardHeader  title={title} />
                        <CardContent >
                            <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                    }}>
                                {loading? <CircularProgress /> : <div>
                                <DataGrid 
                                            dataSource={system_ARP_details}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            repaintChangesOnly={true}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            columnResizingMode={'widget'}
                                            columnAutoWidth={true}
                                            wordWrapEnabled={false}
                                            columnMinWidth={50}
                                            cellHintEnabled={true}
                                            noDataText={'No ARP information available to display'}
                                    >
                                        <Scrolling showScrollbar={'always'} seNative={false}/>
                                        {
                                            arpColumns.map(row => {
                                                return (
                                                    // eslint-disable-next-line
                                                    <Column dataField={row.dataField} caption={row.caption} alignment={row.alignment} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)}/>
                                                );
                                            })
                                        }
                                    </DataGrid>
                                                                </div>}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            
            )
        }
        else {
            return (
                // <Card>
                //     <CardHeader  title={title} />
                //     <CardContent >
                //         <div style={{
                //                 display: "flex",
                //                 justifyContent: "center",
                //                 alignItems: "center"
                //                 }}>
                //             {loading? <CircularProgress /> : <div>
                //                 <p>{message}</p>
                //                                             </div>}
                //         </div>
                //     </CardContent>
                // </Card>
                null
            )

        }
    }
}
 
export default ARPDetails;
import React, {  PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
//import { DataGrid, Column, Button } from 'devextreme-react/data-grid';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
//import moment from 'moment';
//import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
        overflow: 'auto',
        padding: 0,
        '&:last-child': {
            paddingBottom: theme.spacing(1)
        }
    },
    headerTitle: {
      fontSize: '1.2rem',
      //color: '#ffffff'
    },
    summaryViewIconButton: {
        padding: 6
    },
    summaryViewActions: {
        marginBottom: -6,
        marginTop: 3
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5'
    }
});
class PingOrCurrentStatusFortiManagerView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {

        };
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    textFormatter = (cellInfo) => {
        return (cellInfo.value === true) ? 'Yes': 'No';
    };
    render() {
        const { classes,  raised, title, actionData }   = this.props;
        //const { disableResetButton } = this.state;
        const primaryDeviceProps = [
            {fieldName: 'device_name', label: 'Device Name'},
            {fieldName: 'ha_role', label: 'HA Role'},
            //{fieldName: 'ha_mode',  label: 'HA Mode'},
            {fieldName: 'serial_no', label: 'Serial No'},
            {fieldName: 'platform', label: 'Platform'},
            {fieldName: 'firmware', label: 'Firmware'},
            {fieldName: 'ip', label: 'Active WAN IP'},
            {fieldName: 'last_checked', label: 'Last Checked'},
            {fieldName: 'cpu_usage', label: 'CPU Usage'},
            {fieldName: 'mem_usage', label: 'Memory Usage'},
            {fieldName: 'sessions', label: 'Sessions'},
            {fieldName: 'uptime', label: 'Uptime'},
            {fieldName: 'status', label: 'Status'}];
        const backupDeviceProps = [
            {fieldName: 'device_name', label: 'Device Name'},
            {fieldName: 'ha_role', label: 'HA Role'},
            {fieldName: 'serial_no',  label: 'Serial No'},
            {fieldName: 'cpu_usage', label: 'CPU Usage'},
            {fieldName: 'mem_usage', label: 'Memory Usage'},
            {fieldName: 'sessions', label: 'Sessions'},
            {fieldName: 'uptime', label: 'Uptime'},
            {fieldName: 'status', label: 'Status'}];
        let deviceStateProps = [
            {fieldName: 'primary_transport_wan1_status', label: 'Primary Transport (wan1)'},
            {fieldName: 'backup_transport_wan2_status', label: 'Backup Transport (wan2)'}];
        //const xtraDeviceStateProps = [
        //    {fieldName: 'primary_tunnel_status', label: 'Primary NOC (ipsec-tunnel/ipsec-backup)'},
        //    {fieldName: 'backup_tunnel_status', label: 'Secondary NOC (ipsec-secondary/ipsec-sec-back)'}];
        const primary_device_info = actionData && actionData['primary_device_info'] ? actionData['primary_device_info'] : {};
        const backup_device_info = actionData && actionData['backup_device_info'] ? actionData['backup_device_info'] : {};
        
        // some customers (currently rit) have noc transports as well
        const transport_status = actionData && actionData['transport_status'] ? actionData['transport_status'] : {};
        if (!_.isEmpty(transport_status) && 'primary_noc_status' in transport_status) {
            deviceStateProps.push(
                {fieldName: 'primary_noc_status', label: 'Primary NOC (ipsec-tunnel/ipsec-backup)'},
                {fieldName: 'secondary_noc_status', label: 'Secondary NOC (ipsec-secondary/ipsec-sec-back)'});
        }

        // some customers (currently usps) have auxiliary transports as well
        if (!_.isEmpty(transport_status) && 'auxiliary_transport_wan1_status' in transport_status) {
            deviceStateProps.push(
                {fieldName: 'auxiliary_transport_wan1_status', label: 'Auxiliary Transport (wan1)'},
                {fieldName: 'auxiliary_transport_wan2_status', label: 'Auxiliary Transport (wan2)'});
        }

        //console.log("backup_device_info 7 is empty: " + JSON.stringify(_.isEmpty(backup_device_info)));
        return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
                    <CardContent className={ classes.cardContent } >
                     <Table size="small">
                         <TableBody stripedRows>
                            <TableRow key='primaryDetailsHeader' className={ classes.tableRow1 }>
                              <TableCell colSpan={2} align="center" className={ classes.tableCell }>Active Device Details</TableCell>
                            </TableRow>
                             { primary_device_info ?
                                  _.map(primaryDeviceProps, function (value, key) {
                                        let fieldName = value['fieldName'];
                                        let val = primary_device_info[fieldName];
                                        let label = value['label'];
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCell } component="head" scope="row">{label}</TableCell>
                                          <TableCell className={ classes.tableCell }>{val}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                             { !_.isEmpty(backup_device_info) ? 
                                  (<TableRow key='backupDetailsHeader' className={ classes.tableRow1 }>
                                    <TableCell colSpan={2} align="center" className={ classes.tableCell }>Standby Device Details</TableCell>
                                   </TableRow> )
                                  : ""
                             }
                             { !_.isEmpty(backup_device_info) ? 
                                  _.map(backupDeviceProps, function (value, key) {
                                      let fieldName = value['fieldName'];
                                      let val = backup_device_info[fieldName];
                                      let label = value['label'];
                                      return (
                                          <TableRow key={key} className={ classes.tableRow }>
                                            <TableCell className={ classes.tableCell } component="head" scope="row">{label}</TableCell>
                                            <TableCell className={ classes.tableCell }>{val}</TableCell>
                                          </TableRow>
                                        );
                                  })
                                  : ""
                             }
                            <TableRow key='transportHeader' className={ classes.tableRow1 }>
                              <TableCell colSpan={2} align="center" className={ classes.tableCell }>Transport Status</TableCell>
                            </TableRow>
                             { !_.isEmpty(transport_status) ?
                                  _.map(deviceStateProps, function (value, key) {
                                        let fieldName = value['fieldName'];
                                        let val = transport_status[fieldName];
                                        let label = value['label'];
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCell } component="head" scope="row">{label}</TableCell>
                                          <TableCell className={ classes.tableCell }>{val}</TableCell>
                                        </TableRow>
                                      );
                                 }) : ""
                             }
                         </TableBody>
                     </Table>
                    </CardContent>
                </Card>
            )
    }
}

PingOrCurrentStatusFortiManagerView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(PingOrCurrentStatusFortiManagerView);

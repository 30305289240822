import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
//import {  Refresh, Close } from 'mdi-material-ui';

import { DataGrid, RemoteOperations, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, Scrolling } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

import fetchClient  from '../../axios';
import { textFormatter, epochDateFormatter } from "../common/utils";
import NetworkEventsRowActionsMenu from '../dash-board/network-monitoring/NetworkEventsRowActionsMenu';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

const NetworkEventsGridColumns = [
    { dataField: "name", caption: "Impacted Group", customizeText: textFormatter},
    { dataField: "impact", caption: "Impact", customizeText: textFormatter},
    { dataField: "caseId", caption: "Case ID", customizeText: textFormatter},
    { dataField: "startTime", caption: "Start Time", cellRender: epochDateFormatter, sortOrder: 'desc'}
];
class NetworkEvents extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.dataGrid = null;
    }
    componentWillMount() {
        //this.getNetworksData();
    }

    componentDidMount() {
        //this.NetworksTask = setInterval(()=> this.getNetworksData(), constants.TASK_TIME)
    };
    componentWillUnmount () {
        //clearTimeout(this.NetworksTask);
    };
    refreshDataGrid () {
        this.dataGrid.instance.refresh();
    };
    onRowPrepared = (e) => {
        if(e.rowType === 'data') {
            e.rowElement.style.backgroundColor = '#FF0000';
            e.rowElement.style.color = '#FFFFFF';
        }
    };
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onToolbarPreparing(e) {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push(searchPanel , columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    }
    renderNetworkEventsActionsMenu = (cellInfo) => {
        return <NetworkEventsRowActionsMenu rowData={cellInfo.data} />
    };
    render(){
        const { classes, theme, raised, deviceName } = this.props;
        const dataStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                    let params = {};
                        params['device_name'] = deviceName;
                        params['active'] = true;
                        return fetchClient.get('/api/v1.0/es/device/network-events', {params: params})
                            .then(res => {
                                let NetworkEventsData = [];
                                let NetworkEventsDataTotal = 0;
                                if (res.data && res.data.data) {
                                    NetworkEventsData = res.data.data;
                                    NetworkEventsDataTotal = res.data.total;
                                }
                                return {
                                    data: NetworkEventsData,
                                    totalCount: NetworkEventsDataTotal
                                };
                            })
                            .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
        return (
            <Card className={classes.root} raised={raised}>
                <DataGrid id={'NetworkEventsGrid'}
                    ref={(ref) => this.dataGrid = ref}
                    height={`calc(100vh - ${theme.mixins.gridOffset}px)`}
                    dataSource={dataStore}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    repaintChangesOnly={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    columnMinWidth={70}
                    cellHintEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onRowPrepared={this.onRowPrepared}
                    onContentReady={this.onContentReady}
                    >
                    <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    <Column type="button" caption="Actions" alignment="center" width={70} cellRender={this.renderNetworkEventsActionsMenu} />
                    {
                        NetworkEventsGridColumns.map(row => {
                          return (
                          // eslint-disable-next-line
                            <Column dataField={row.dataField} caption={row.caption} cellRender={eval(row.cellRender)}/>
                          );
                        })
                    }
                    <RemoteOperations sorting={false} paging={false} />
                    <Paging defaultPageSize={15} />
                    <Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[15, 25, 50, 100]}/>
                </DataGrid>
            </Card>
        )
    }
}

NetworkEvents.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(NetworkEvents);
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Grid as Grid1 } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { LoadPanel } from 'devextreme-react/load-panel';
import moment from 'moment';
import { Chart, Series, ArgumentAxis, Label, VisualRange, Export, Size, Grid, ValueAxis, Legend, Title, Tick, ConstantLine, Tooltip } from 'devextreme-react/chart';
import fetchClient from "../../axios";
//import * as constants from "../../constants";
import _ from "lodash";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup);

const styles = theme => ({
	root: {
	  width: '100%',
      height: '100%'
	},
	paper: {
		padding: theme.spacing(1),
		border: '1px solid rgba(224, 224, 224, 1)',
		boxShadow: "none"
	},
	legendPaper: {
		display: "flex",
    	//border: `1px solid ${theme.palette.divider}`,
    	flexWrap: "wrap",
		padding: theme.spacing(1),
		height: 0,
		//marginTop: 48
	},
	dateText: {
		fontSize: "1rem"
	},
    apgChart: {
	    height: '100%',
        width: '100%'
    }
});
const dateFormat = 'MM/DD/YYYY HH:mm:ss';

class ApgTimeSeriesGraph extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
            isDataLoading: true,
			timeRange: 24,
			startDateTime: moment().subtract(24, 'hours').format(dateFormat),
			endDateTime: moment().format(dateFormat),
			timePeriod: 3600,
			maxValue: 0,
			minValue: 0,
			avgValue: 0,
            data_length: 0,
			apgGraphData: [],
			dateTickInterval: 24,
			height: '100%',
			width: '100%'
		};
		this.apgChartRef = React.createRef();
	}
	componentWillMount() {
        this.getApgChartData();
    }
    componentDidMount() {
        //this.setChartSize();
    };
    componentWillUnmount () {

    };
    /**
     * getApgChartData:
     */
    getApgChartData = () => {
    	const { apgObject } = this.props;
    	const { startDateTime, endDateTime, timeRange } = this.state;
    	this.setState({isDataLoading: true});
    	const params = {
    		start_timestamp: moment(startDateTime, dateFormat).valueOf()/1000,
			end_timestamp: moment(endDateTime, dateFormat).valueOf()/1000,
			filter_expression: apgObject['filter_expression'],
			period: timeRange <= 24 ? 0 : timeRange <= 120 ? 3600 : timeRange <= 720 ? 86400 : 604800
    	};
		fetchClient({
			method: 'get',
			url: '/api/v1.0/apg/db/object-data/with-filter',
			params: params,
		}).then(res => {
			let apgGraphData = [];
			let length = 0;
			let avgValue = '';
			let minValue = '';
			let maxValue = '';
				if (res.data && res.data.data) {
					apgGraphData = res.data.data[0]['avg_data'];
					length = res.data.data['length'];
					let obj = _.map(apgGraphData, 'value');
					maxValue = _.round(_.max(obj), 2);
					minValue = _.round(_.min(obj), 2);
					avgValue = _.round(_.mean(obj), 2);
				}
				this.setState({
					apgGraphData: apgGraphData,
                    data_length: length,
					maxValue: maxValue,
					minValue: minValue,
					avgValue: avgValue,
					isDataLoading: false
				});
		}).catch (() => {
				this.setState({
					apgGraphData: [],
                    data_length: 0,
					maxValue: 0,
					minValue: 0,
					avgValue: 0,
					isDataLoading: false
				});
			throw new Error('Data Loading Error');
		});
    };
    setChartSize = () => {
        const height = this.apgChartRef.current.offsetHeight;
		const width = this.apgChartRef.current.offsetWidth;
        this.setState({
            height: height+'px',
            width: width+'px'
        }, () => { this.getApgChartData(); });
    };
	handleToggle = (e, val) => {
        this.setState({
			timeRange: val,
			startDateTime: moment().subtract(val, 'hours').format(dateFormat),
			endDateTime: moment().format(dateFormat),
		}, () => { this.getApgChartData(); });
    };
	handlePreviousDate = () => {
		const { startDateTime, endDateTime, timeRange } = this.state;
        this.setState({
			startDateTime: moment(startDateTime).subtract(timeRange, 'hours').format(dateFormat),
			endDateTime: moment(endDateTime).subtract(timeRange, 'hours').format(dateFormat)
		}, () => { this.getApgChartData(); });
	};
	handleNextDate = () => {
		const { startDateTime, endDateTime, timeRange } = this.state;
        this.setState({
			startDateTime: moment(startDateTime).add(timeRange, 'hours').format(dateFormat),
			endDateTime: moment(endDateTime).add(timeRange, 'hours').format(dateFormat)
		}, () => { this.getApgChartData(); });
	};
    chartTooltipTemplate = (info) => {
      return (
        <div className="state-tooltip">
          <div>
            <span className="">Date/Time</span>: {info.argumentText }
          </div>
          <div>
            <span className="">Value</span>: {info.value}
          </div>
        </div>
      );
    };
    customizeXText = (info) => {
		const  { timeRange } = this.state;
		let ddate = '';
		//let numTickes = '';
		switch(timeRange) {
			case 1:
			case 6:
			case 24:
				ddate = moment(info.value, dateFormat).format("HH");
				//numTickes = 24;
			break;
			case 120:
				ddate = moment(info.value, dateFormat).format("MM/DD/YYYY");
			break;
			case 720:
				ddate = moment(info.value, dateFormat).format("DD-MMM");
				break;
			case 2160:
				ddate = moment(info.value, dateFormat).format("DD-MMM");
				break;
			case 8760:
				ddate = moment(info.value, dateFormat).format("MMM-YYYY");
			break;
			default:
				ddate = info.value
		}
		return ddate;
	};
	render() {
		const { classes, apgObject } = this.props;
		const {  isDataLoading,  timeRange, startDateTime, endDateTime, apgGraphData, height, width } = this.state;
		let label = '';
		    // eslint-disable-next-line
		let label1 = '';
		const metrics = apgObject.metrics ? apgObject.metrics : null;
		const series_labels = apgObject.series_labels ? apgObject.series_labels : null;
		if (metrics && series_labels) {
            metrics.forEach(function(item, idx){
                if(idx === 0) {
                    label = (series_labels[idx]) ? series_labels[idx][item] : '';
                }
                if(idx === 1) {
                    label1 = (series_labels[idx]) ? series_labels[idx][item] : '';
                }
            })
        } else {
		    label = apgObject.label ? apgObject.label : apgObject.name;
        }
		return (
			<div className={classes.root} ref={this.apgChartRef}>
						<Grid1 container spacing={1} direction="row" justify="center" alignContent="center" alignItems="center">
							<Grid1 item>
								<div>Zoom:</div>
							</Grid1>
							<Grid1 item>
								<StyledToggleButtonGroup exclusive value={timeRange} size="small" variant="text" color="primary" onChange={this.handleToggle}>
									<ToggleButton value={1}>1h</ToggleButton>
									<ToggleButton value={6}>6h</ToggleButton>
									<ToggleButton value={24}>1d</ToggleButton>
									<ToggleButton value={120}>5d</ToggleButton>
									<ToggleButton value={720}>1m</ToggleButton>
									<ToggleButton value={2160}>3m</ToggleButton>
									<ToggleButton value={8760}>1y</ToggleButton>
								</StyledToggleButtonGroup>
							</Grid1>
						</Grid1>
						<Grid1 container spacing={1} direction="row" justify="center" alignContent="center"  alignItems="center">
							<Grid1 item>
								<div>Date Range:</div>
							</Grid1>
							<Grid1 item>
								<IconButton color='primary' onClick={this.handlePreviousDate} >
                                    <span className="mdi mdi-chevron-left mdi-24px" />
								</IconButton>
							</Grid1>
							<Grid1 item>
								<Typography className={classes.dateText} variant="h6" component="h6">{startDateTime} - {endDateTime}</Typography>
							</Grid1>
							<Grid1 item>
								<IconButton color='primary' onClick={this.handleNextDate} >
									<span className="mdi mdi-chevron-right mdi-24px" />
								</IconButton>
							</Grid1>
						</Grid1>
							<Fragment>
								<Paper className={classes.paper} id="apgChart">
                                    {!isDataLoading &&
										<Chart className={classes.apgChart} id="chart" dataSource={apgGraphData}
											   theme="material.blue.light">
											<Series valueField="value" argumentField="date_string" type="line"
													pallete="material" color="#4caf50"/>
											<ArgumentAxis tickInterval={this.state.dateTickInterval}>
												<Title text={"Date/Time"}/>
												<Tick width={3}/>
												<Grid visible={true}/>
												<Label wordwrap="none" customizeText={this.customizeXText}/>
											</ArgumentAxis>
											<ValueAxis tickInterval={10}>
												<Title text={label}/>
												<Tick width={3}/>
												<Grid visible={true}/>
												<VisualRange startValue={0} endtValue={110}/>
												<ConstantLine width={2} value={this.state.maxValue} color="#009688"
															  dashStyle="dash" displayBehindSeries={true}>
													<Label text={"Max: " + this.state.maxValue}
														   font={{weight: 700, color: "#009688"}}/>
												</ConstantLine>
												<ConstantLine width={2} value={this.state.avgValue} color="#2196f3"
															  dashStyle="dash" displayBehindSeries={true}>
													<Label text={"Avg: " + this.state.avgValue}
														   font={{weight: 700, color: "#2196f3"}}
														   horizontalAlignment="center"/>
												</ConstantLine>
												<ConstantLine width={2} value={this.state.minValue} color="#ff9800"
															  dashStyle="dash" displayBehindSeries={true}>
													<Label text={"Min: " + this.state.minValue}
														   font={{weight: 700, color: "#ff9800"}}
														   horizontalAlignment="right"/>
												</ConstantLine>
											</ValueAxis>
											<Tooltip zIndex={2000} enabled={true}
													 contentRender={this.chartTooltipTemplate}/>
											{/*<Tooltip zIndex={2000} enabled={true} />*/}
											<Export enabled={false}/>
											<Legend visible={false}/>
											<Size width={width} height={height}/>
										</Chart>
                                    }
									<LoadPanel
									  shadingColor="rgba(0,0,0,0.4)"
									  position={{ of: '#apgChart'}}
									  visible={isDataLoading}
									/>
								</Paper>
							</Fragment>
				</div>
			)
	}
}

ApgTimeSeriesGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(ApgTimeSeriesGraph);
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Grid as Grid1 } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import moment from 'moment';
import { Chart, Series, ArgumentAxis, Label, Export, Size, Grid, ValueAxis, Legend, Title, Tick, CommonSeriesSettings,
	Tooltip, Point, Margin, LoadingIndicator, CommonAxisSettings } from 'devextreme-react/chart';
import fetchClient from "../../axios";
import CustomStore from "devextreme/data/custom_store";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import { handleErrors } from "../common/utils";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup);

const styles = theme => ({
	root: {
	  width: '100%',
      height: '100%'
	},
	paper: {
		padding: theme.spacing(2),
		border: '1px solid rgba(224, 224, 224, 1)',
		boxShadow: "none",
        height: "60vh"
	},
	legendPaper: {
		display: "flex",
    	flexWrap: "wrap",
		padding: theme.spacing(1),
		height: 0,
	},
	dateText: {
		fontSize: "1rem"
	},
    graph: {
	    height: '100%',
        width: '100%'
    },
});
const dateFormat = 'MM/DD/YYYY HH:mm:ss';

class HtsEnterpriseRfMetricsGraph extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
            isDataLoading: true,
			timeRange:6,
			startDateTime: moment().subtract(24, 'hours').format(dateFormat),
			endDateTime: moment().format(dateFormat),
			timePeriod: 3600,
			dateTickInterval: 12,
			height: '100%',
			width: '100%',
            title1: "Delay & Downlink ES",
            title2: "uplinkES & uplinkPowerBackoff"
		};
		this.htsChartRef = React.createRef();
		this.graphRef = React.createRef();
        this.refreshChart = this.refreshChart.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.handlePreviousDate = this.handlePreviousDate.bind(this);
		this.handleNextDate = this.handleNextDate.bind(this);
        this.htsGraphStore = {
            store: new CustomStore({
				key: 'deviceid',
                load: (loadOptions) => {
                    const params = {
                        device_name: this.props.deviceName,
                        start_time: moment(this.state.startDateTime, dateFormat).valueOf()/1000,
                        end_time: moment(this.state.endDateTime, dateFormat).valueOf()/1000
                    };
                    return fetchClient({
                            method: 'get',
                            url: '/api/v1.0/es/device/ts/jupiter-ent',
                            params: params,
                        })
                        .then(handleErrors)
                        .then(res => {
                            let graphData = [];
                            if (res.data && res.data.data) {
                                graphData = res.data.data;
                                graphData.forEach(function(item, idx){
                                    item['created_dt'] = moment.unix(item['created_at']).format("MM/DD/YYYY HH:mm");
                                });
                            }
                            return graphData;
                        })
                        .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
	}
	componentWillMount() {

    }
    componentDidMount() {
        this.setChartSize();
    };
    componentWillUnmount () {

    };
    componentWillReceiveProps() {
        this.setChartSize();
    }
    setChartSize = () => {
        const height = this.graphRef.current.offsetHeight;
		const width = this.graphRef.current.offsetWidth;
        this.setState({
            height: height+'px',
            width: width+'px'
        }, () => { this.refreshChart(); });
    };
	handleToggle = (e, val) => {
        this.setState({
			timeRange: val,
			startDateTime: moment().subtract(val, 'hours').format(dateFormat),
			endDateTime: moment().format(dateFormat),
		}, () => { this.refreshChart(); });
    };
	handlePreviousDate = () => {
		const { startDateTime, endDateTime, timeRange } = this.state;
        this.setState({
			startDateTime: moment(startDateTime).subtract(timeRange, 'hours').format(dateFormat),
			endDateTime: moment(endDateTime).subtract(timeRange, 'hours').format(dateFormat)
		}, () => { this.refreshChart(); });
	};
	handleNextDate = () => {
		const { startDateTime, endDateTime, timeRange } = this.state;
        this.setState({
			startDateTime: moment(startDateTime).add(timeRange, 'hours').format(dateFormat),
			endDateTime: moment(endDateTime).add(timeRange, 'hours').format(dateFormat)
		}, () => { this.refreshChart(); });
	};
    chartTooltipTemplate = (info) => {
      return (
        <div className="state-tooltip">
          <div>
            <span className="">Date</span>: { info.argumentText }
          </div>
          <div>
            <span className="">{ info.seriesName }</span>: { info.value }
          </div>
        </div>
      );
    };
    refreshChart = () => {
        this.htsChartRef.current.instance.refresh();
    };
    chartLegendClickHandler = (e) => {
      const series = e.target;
      if(series.isVisible()) {
          series.hide();
      } else {
          series.show();
      }
    };
	render() {
		const { classes } = this.props;
		const { timeRange, startDateTime, endDateTime, height, width, title1, title2 } = this.state;
		return (
			<div className={classes.root} ref={this.graphRef} >
						<Grid1 container spacing={1} direction="row" justify="center" alignContent="center" alignItems="center">
							<Grid1 item>
								<div>Zoom:</div>
							</Grid1>
							<Grid1 item>
								<StyledToggleButtonGroup exclusive value={timeRange} size="small" variant="text" color="primary" onChange={this.handleToggle}>
									<ToggleButton value={1}>1h</ToggleButton>
									<ToggleButton value={6}>6h</ToggleButton>
									<ToggleButton value={24}>1d</ToggleButton>
									<ToggleButton value={120}>5d</ToggleButton>
									<ToggleButton value={720}>1m</ToggleButton>
									<ToggleButton value={2160}>3m</ToggleButton>
									<ToggleButton value={8760}>1y</ToggleButton>
								</StyledToggleButtonGroup>
							</Grid1>
						</Grid1>
						<Grid1 container spacing={1} direction="row" justify="center" alignContent="center"  alignItems="center">
							<Grid1 item>
								<div>Date Range:</div>
							</Grid1>
							<Grid1 item>
								<IconButton color='primary' onClick={this.handlePreviousDate} >
                                    <span className="mdi mdi-chevron-left mdi-24px" />
								</IconButton>
							</Grid1>
							<Grid1 item>
								<Typography className={classes.dateText} variant="h6" component="h6">{startDateTime} - {endDateTime}</Typography>
							</Grid1>
							<Grid1 item>
								<IconButton color='primary' onClick={this.handleNextDate} >
									<span className="mdi mdi-chevron-right mdi-24px" />
								</IconButton>
							</Grid1>
						</Grid1>
							<Fragment>
								<Paper className={classes.paper} id="graph">
                                    <Chart ref={this.htsChartRef} className={classes.graph} id="chart" dataSource={this.htsGraphStore} theme="material.blue.light" onLegendClick={this.chartLegendClickHandler}>
                                        <CommonSeriesSettings argumentField="created_dt" type="line" pallete="material" />
                                        <Series axis="uplinkAxis" name="delay" valueField="delay" type="spline" color={green[300]}>
                                            <Point size={4}/>
                                        </Series>
                                        <Series axis="uplinkAxis" name="downlinkEsNo" valueField="downlinkEsNo" type="spline" color={red[500]}>
                                            <Point size={4}/>
                                        </Series>
                                        <Series axis="downlinkAxis" name="uplinkEsNo" valueField="uplinkEsNo" type="spline" color={orange[500]}>
                                            <Point size={8}/>
                                        </Series>
                                        <Series axis="downlinkAxis" name="uplinkPowerBackoff" valueField="uplinkPowerBackoff" type="spline" color={blue[500]}>
                                            <Point size={8}/>
                                        </Series>
                                        <ValueAxis name="uplinkAxis" position="left" title={title1}>
                                            <Grid visible={true} />
                                        </ValueAxis>
                                        <ValueAxis name="downlinkAxis" tickInterval={5} showZero={true} valueMarginsEnabled={true}  position="right" title={title2}>
                                            <Grid visible={true}/>
                                            {/*<VisualRange startValue={0} endtValue={100}/>*/}
                                        </ValueAxis>
                                        <CommonAxisSettings discreteAxisDivisionMode="crossLabels"/>
                                        <ArgumentAxis tickInterval={this.state.dateTickInterval}>
                                            <Title text={"Date"} />
                                            <Tick width={2}/>
                                            <Grid visible={true}/>
                                            <Label wordwrap="none" overlappingBehavior="hide" rotationAngle={45} />
                                            {/*<TickInterval hours={24}/>
                                            <MinorTickInterval hours={12} days={12}/>*/}
                                        </ArgumentAxis>
                                        <Tooltip zIndex={2000} enabled={true} contentRender={this.chartTooltipTemplate}/>
                                        {/*<Tooltip zIndex={2000} enabled={true} />*/}
                                        <Export enabled={true}/>
                                        <Legend itemTextPosition='right' verticalAlignment="bottom" horizontalAlignment="center" />
                                        <Margin top={8} right={8} bottom={8} left={8} />
                                        <Size width={width} height={height}/>
                                        <LoadingIndicator enabled={true}/>
                                    </Chart>
                                    {/*<LoadPanel
									  shadingColor="rgba(0,0,0,0.4)"
									  position={{ of: '#graph'}}
									  visible={isDataLoading}
									/>*/}
								</Paper>
							</Fragment>
				</div>
			)
	}
}

HtsEnterpriseRfMetricsGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(HtsEnterpriseRfMetricsGraph);
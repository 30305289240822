import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
// import IconButton from '@material-ui/core/IconButton';
// import { Fullscreen as FScreen, FullscreenExit, TimerOffOutline, TimerOutline,  Refresh, Close } from 'mdi-material-ui';
// import { LoadPanel } from 'devextreme-react/load-panel';
import DataGrid from 'devextreme-react/data-grid';
//import moment from 'moment';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import ReactJson from 'react-json-view';
// import ReactHtmlParse from 'react-html-parser';
// import fetchClient  from '../../../axios';
// import * as constants from '../../../constants';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	card1: {
	  //width: '100%',
      margin: theme.spacing(1),
	},
	cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		overflow: 'auto',
		padding: 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	cardContent1: {
	    maxHeight: '400px',
		height: '400px',
		overflow: 'auto',
		padding: 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1.2rem',
	  //color: '#ffffff'
	},
    summaryViewIconButton: {
	    padding: 6
    },
    summaryViewActions: {
	    marginBottom: -6,
        marginTop: 3
    },
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.80rem'
	},
	tableRow: {
		height: 25,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default
		}
	}
});
class PingInterfacesView extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {};
	}
	componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
	onCellPrepared = (e) => {
      /*if(e.rowType === 'header' && e.column.command === 'edit') {
          e.cellElement.textContent='Actions';
      }*/
    };
	render() {
		const { classes, raised, title, actionData, deviceName, setHeight }  = this.props;
        const info = actionData && actionData['Info'] ? actionData['Info'] : [];
        const deviceStatus = actionData && actionData['Device Status'] ? actionData['Device Status'] : [];
        const interfaceStatus = actionData && actionData['Interface'] && actionData['Interface'][deviceName+' Interface Table: '] ? actionData['Interface'][deviceName+' Interface Table: '] : [];
        const interfaceColumns = ['Description', 'Admin Status', 'Oper Status'];
        let ping = [];
        let pings = [];
        let pingsData = [];
        if (actionData && actionData['Ping']) {
            ping = actionData['Ping'];
            pings = ping['Pings'] ? ping['Pings'] : [];
            delete ping['Pings']
        };
        pings ?
            _.each(pings, function (value, idx) {
                for(let item in value) {
                    pingsData.push({"# Ping": item.split('Ping:')[1], "RTT": value[item].split(':')[1]});
                }
            }) : pingsData = [];
		return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
                    <CardContent className={ setHeight ? classes.cardContent1 : classes.cardContent} >
                     <Table size="small">
                         <TableBody stripedRows>
                             { info ?
                                  _.map(info, function (value, key) {
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCell } component="head" scope="row">{key}</TableCell>
                                          <TableCell className={ classes.tableCell }>{value}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                             { deviceStatus ?
                                  _.map(deviceStatus, function (value, key) {
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCell } component="head" scope="row">{key}</TableCell>
                                          <TableCell className={ classes.tableCell }>{value}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                             { ping ?
                                 _.map(ping, function (value, key) {
                                     return (
                                         <TableRow key={key} className={classes.tableRow}>
                                             <TableCell className={classes.tableCell} component="head" scope="row">{key}</TableCell>
                                             <TableCell className={classes.tableCell}>{value}</TableCell>
                                         </TableRow>
                                     );
                                 })
                                 : ""
                             }
                         </TableBody>
                     </Table>
						 { pings && pingsData ?
							 <Card className={classes.card1}>
							  <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Pings'}/>
							  <DataGrid
								dataSource={pingsData}
								width="100%"
								showBorders={true}
							 />
							</Card>
								: ""
						 }
						 { interfaceStatus &&
							 <Card className={classes.card1}>
							  <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Interface Status'}/>
							  <DataGrid
								dataSource={interfaceStatus}
								columns={interfaceColumns}
								showBorders={true}
								onCellPrepared={this.onCellPrepared}
							 />
							</Card>
						 }
                    </CardContent>
                </Card>
			)
	}
}

PingInterfacesView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(PingInterfacesView);
import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import fetchClient from '../../axios'

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardHeader1: {
        border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
        border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
		color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});

class ReachabilityandConfiguration extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            deviceData: [],
            loading: false
        }
    }
    stateRender = (stat) => {
		let state, label;
		switch (stat) {
			case 1:
				state = 'critical';
				label = 'Critical';
				break;
			case 2:
				state = 'major';
				label = 'Major';
				break;
			case 3:
				state = 'minor';
				label = 'Minor';
				break;
			case 4:
				state = 'unknown';
				label = 'Unknown';
				break;
			case 5:
				state = 'normal';
				label = 'Normal';
				break;
			default:
				state = 'warning';
				label = stat;
				break;
		}
		return (<span className={"label label-status alert-"+state}>{label}</span>)
	};

    async componentDidMount() {
        await this.getDeviceDetails(this.props.deviceName);
    }

    async componentDidUpdate(prevProps,prevState) {
        if(prevProps.deviceName!==this.props.deviceName) {
            await this.getDeviceDetails(this.props.deviceName);
        }
    }

    getDeviceDetails = (deviceName) => {
        let params = {device_id:deviceName}
        this.setState({loading: true}, async () => {
            const response = await fetchClient.get('api/v1.0/device/device-details', {params:params})
            this.setState({
                deviceData:response.data.data,
                loading: false
            })
        })
    }

    render() { 
        const classes = this.props
        const{deviceData,loading} = this.state
        let tableColumns = [
            {caption: 'SAN', dataField: 'name'},
            {caption: 'Device Category', dataField:'productGroup'},
            {caption: 'Model', dataField: 'eitModel'},
            {caption: 'Serial Number', dataField: 'diuSerialNum'},
            {caption: 'Management IP', dataField: 'managementIp'},
            {caption: 'LAN1 Ip', dataField: 'seedip'},
            {caption: 'NOC ID', dataField: 'nocid'},
            {caption: 'Status', dataField:'state'}
        ]
        return ( 
            <div>
                <Card>
                    <CardContent>
                    {loading? <CircularProgress />: <div>
                        <Table size="small">
                            <TableBody>
                                {(deviceData) ?
                                    tableColumns.map(row => {
                                        if (row.dataField === 'state') {
                                            return (
                                              <TableRow key={row.dataField} className={ classes.tableRow }>
                                                <TableCell variant="head" scope="row" className={ classes.tableCell }>{row.caption}</TableCell>
                                                <TableCell className={ classes.tableCell }>{this.stateRender(deviceData[row.dataField])}</TableCell>
                                              </TableRow>
                                            );
                                        }
                                        else {
                                            return (
                                                <TableRow key={row.dataField} className={classes.tableRow}>
                                                    <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
                                                    <TableCell className={ classes.tableCell }>{deviceData[row.dataField]}</TableCell>
                                                </TableRow>
                                            );
                                        }
                                    }) :
                                    tableColumns.map(row => {
                                    return (
                                            <TableRow key={row.dataField} className={ classes.tableRow }>
                                                <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
                                                <TableCell className={ classes.tableCell }>Unknown</TableCell>
                                            </TableRow>
                                    );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>}
                    
                    </CardContent>
                </Card>
            </div>

         );
    }
}

ReachabilityandConfiguration.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };
  
  const enhance = compose(
          withStyles(styles, {withTheme: true})
      );
export default enhance(ReachabilityandConfiguration);
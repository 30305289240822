import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import fetchClient from '../../axios'
import PingOrCurrentStatusView from '../common/views/PingOrCurrentStatusView';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardHeader1: {
        border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
        border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
		color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});


class TransportStatus extends PureComponent {
    constructor(props){
        super(props)
        this.state = {
            deviceName:this.props.deviceName,
            devicesSameLocationData: this.props.devicesSameLocationData,
            isCraddlePoint: false,
            isSatelliteModem: false,
            deviceInfo:[],
            pingCurrentStatusResponse:[]
        }
    }

    componentDidMount() {
        const deviceInfoArray = [{
            name: this.props.deviceData.name,
            productCategory: this.props.deviceData.productCategory,
            parentSite: this.props.deviceData.psiteid,
            nocId: this.props.deviceData.nocid
          }]
        this.state.devicesSameLocationData.forEach((item) => {
            let i={
                name: item.name,
                productCategory:item.productCategory,
                parentSite:item.psiteid,
                nocId:item.nocid
            }
            deviceInfoArray.push(i)
        })
        this.setState({deviceInfo:deviceInfoArray})
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.hnsLocationId !== this.props.hnsLocationId) { 
            const deviceInfoArray = [{
                name: this.props.deviceData.name,
                productCategory: this.props.deviceData.productCategory,
                parentSite: this.props.deviceData.psiteid,
                nocId: this.props.deviceData.nocid
              }]
            this.props.devicesSameLocationData.forEach((item) => {
                let i={
                    name: item.name,
                    productCategory:item.productCategory,
                    parentSite:item.psiteid,
                    nocId:item.nocid
                }
                deviceInfoArray.push(i)
            })
            this.setState({deviceInfo:deviceInfoArray,
                            isCraddlePoint:false,
                            isSatelliteModem:false,
                            pingCurrentStatusResponse:[]
                            })
            const deviceInfo = this.state.deviceInfo
            deviceInfo.forEach((item) => {
                if(item.nocId === 'ECM'){
                    this.getpingCurrentStatusECM(item.name)
                }       
                else{
                    this.getpingCurrentStatus(item)
                }
              })
        }
        else if(prevState.deviceInfo!==this.state.deviceInfo) {
            const deviceInfo = this.state.deviceInfo
            deviceInfo.forEach((item) => {
                if(item.nocId === 'ECM'){
                    this.getpingCurrentStatusECM(item.name)
                }
                else if(item.nocId === 'NNOC'){
                    this.getpingCurrentStatus(item.name)
                }
                else {
                    this.setState({
                        deviceName:this.props.deviceName,
                        deviceInfo:[],
                        pingCurrentStatusResponse:[],
                        isCraddlePoint:false,
                        isSatelliteModem:false
                    })
                }
              })
        }
    }

    getpingCurrentStatusECM = (deviceName) => {
        let params = {device_name:deviceName}
        this.setState({isCraddlePoint:true, pingCurrentStatusResponse:[]}, async () => {
            const response = await fetchClient.get('api/v1.0/action/device/pingEcm', {params:params})
            this.setState({
                deviceName:deviceName,
                pingCurrentStatusResponse:response.data.results,
                isCraddlePoint:true,
                isSatelliteModem:false
            })
        })

    }

    getpingCurrentStatus = (device) => {
            let params = {device_name:device}
            this.setState({isSatelliteModem:true, pingCurrentStatusResponse:[]}, async () => {
                const response = await fetchClient.get('api/v1.0/action/device/pingOrCurrentStatus', {params:params})
                this.setState({
                    deviceName:device.name,
                    pingCurrentStatusResponse:response.data.results,
                    isCraddlePoint:false,
                    isSatelliteModem:true
                })
            })


     }

    render() { 
        const classes = this.props;
        const {deviceName, pingCurrentStatusResponse, isCraddlePoint, isSatelliteModem} = this.state
        let craddlePointColumns = [
            {caption: 'IP Address', dataField: 'ip_address'},
            {caption: 'Current State', dataField: 'current_state'},
            {caption: 'Serial Number', dataField: 'serial_number'},
            {caption: 'Configuration Status', dataField: 'configuration_status'},
            {caption: 'DBM', dataField: 'dbm'},
            {caption: 'ECM_ID', dataField: 'ecm_id'},
            {caption: 'MAC Address' , dataField: 'mac_address'},
            {caption: 'Signal Strength' , dataField:'signal_strength'},
            {caption: 'Signal Strength DBM' , dataField:'signal_strength_dbm'},
            {caption: 'SINR' , dataField:'sinr'},
            {caption: 'Up Time' , dataField:'uptime'}
        ]
        if(isCraddlePoint) {
            return (
                <div>
                    <h2>
                        Transport Status CradlePoint device - {deviceName}
                    </h2>
                    <Card>
                        <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        {(pingCurrentStatusResponse) ?
                                            craddlePointColumns.map(row => {
                                                const keyValue = row.dataField
                                                return (
                                                    <TableRow key={row.dataField} className={classes.tableRow}>
                                                        <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
                                                        <TableCell className={ classes.tableCell }>{pingCurrentStatusResponse[keyValue]}</TableCell>
                                                    </TableRow>
                                                );
                                            }) :
                                            craddlePointColumns.map(row => {
                                                return (
                                                        <TableRow key={row.dataField} className={ classes.tableRow }>
                                                            <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
                                                            <TableCell className={ classes.tableCell }>Unknown</TableCell>
                                                        </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>                    
                            </CardContent>
                    </Card>
                </div>
            )
        }

        else {
            return (
                <div>
                    {isSatelliteModem? 
                    <div>
                        <h2>
                        Transport Status Satellite Modem - {deviceName}
                        </h2>
                        <PingOrCurrentStatusView raised={false} actionData={ pingCurrentStatusResponse } title="Ping / Current Status" />
                    </div>:
                     null
                }
                </div>
            )
        }
    }
}

TransportStatus.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
}; 
const enhance = compose(
    withStyles(styles, {withTheme: true})
);
 
export default enhance(TransportStatus);
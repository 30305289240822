import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { DotsHorizontal } from 'mdi-material-ui';
import { Popup } from 'devextreme-react/popup';
//import _ from 'lodash';
import fetchClient  from '../../../axios';
import PropTypes from "prop-types";
import compose from "recompose/compose";
import ScrollView from 'devextreme-react/scroll-view';
import MuiAlert from '@material-ui/lab/Alert';
import DeviceDetails from '../../device-details/DeviceDetails';
//import ReactHtmlParse from "react-html-parser";
import ReactJson from 'react-json-view';

const styles = theme => ({
    button: {
        padding: 0,
        color: '#03A9F4'
    },
    networkEventsPopup: {
        padding: 0
    }
});

class Events1RowActionsMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            anchorEl: null,
            isPopupVisible: false,
            content: '',
            title: ''
        };
        this.handleDeviceDetails = this.handleDeviceDetails.bind(this);
        this.onPopupHiding = this.onPopupHiding.bind(this);
        this.events1Popup = null;
        this.events1ScrollView = null;

    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    onPopupHiding = () => {
        this.setState({
            isPopupVisible: false
        })
    };
    handleDeviceDetails = () => {
        const rowData = this.props.rowData;
        this.setState({
            anchorEl: null,
            title: `Device Details for ${rowData.elementName}`,
            isPopupVisible: true,
            content: <DeviceDetails dName={rowData.elementName} raised={false} />
        });
    };
    getEventDetails = () => {
        //const rowData = this.props.rowData;
        this.setState({
            isPopupVisible: true,
            anchorEl: null,
            content: <MuiAlert severity="info" elevation={1} variant="outlined">Sorry, We are still working on this component.</MuiAlert>
        });
        // this.deviceDetailsPopup.instance.option('width', '750px');
        // this.deviceDetailsPopup.instance.repaint();
    };
    showMap = () => {
        //const rowData = this.props.rowData;
        this.setState({
            isPopupVisible: true,
            anchorEl: null,
            content: <MuiAlert severity="info" elevation={1} variant="outlined">Sorry, We are still working on this component.</MuiAlert>
        });
    };
    executePing = () =>{
        this.setState({
            isPopupVisible: true,
            anchorEl: null,
        });
        const rowData = this.props.rowData;
        const deviceName = rowData['elementName'];
        const title = `Ping Results of ${deviceName}`;
        if (deviceName) {
            let url = '/api/v1.0/action/device/ping';
            let params = {
                device_name: deviceName,
                ping_type: 'lan',
                num_pings: 5
            };
			fetchClient.get(url, {params: params})
				.then(res => {
					let actionData;
					if (res.data) {
						actionData = res.data.results;
					}
                    this.setState({
                        title: title,
                        content: <ReactJson src={actionData} />
                    });
				})
				.catch(error => {
					console.log('Error:'+error);
                    this.setState({
                        title: title,
                        content: <MuiAlert severity="danger" elevation={1} variant="outlined">Something error occurred while processing your request, Please try again.</MuiAlert>
                    });
				});
		}
    };
   render() {
    const { anchorEl, isPopupVisible, content, title } = this.state;
    const { classes, rowData } = this.props;
    const isIconVisible = rowData.elementName !== '' && rowData.elementName !== '_e' && rowData.elementName !== 'notset';
    return (
          <Fragment>
              <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClick}
                className={classes.button}
                disabled={!isIconVisible} >
                <DotsHorizontal />
              </IconButton>
               <Menu
                 id="simple-menu"
                 anchorEl={anchorEl}
                 open={Boolean(anchorEl)}
                 onClose={() => this.setState({ anchorEl: null })}
               >
                  { isIconVisible &&
                      <Fragment>
                       <MenuItem key="devicedetails" onClick={this.handleDeviceDetails}>Device Details</MenuItem>
                       <MenuItem key="eventDetails" onClick={this.getEventDetails}>Event Details</MenuItem>
                       <MenuItem key="ping" onClick={this.executePing}>Ping</MenuItem>
                       <MenuItem key="showMap" onClick={this.showMap}>Show Map</MenuItem>
                      </Fragment>
                   }
                </Menu>
              <Popup
                  elementAttr={{
                      id: 'events1Popup',
                      class: 'network-event-popup'
                  }}
                  ref={(ref) => this.events1Popup = ref}
                    maxwidth= { () => window.innerWidth/1.5}
                    maxHeight= '95%'
                    showTitle={true}
                    title={title}
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    visible={isPopupVisible}
                    onHiding={this.onPopupHiding}
                    resizeEnabled={true}
              >
                  <ScrollView height='100%' width='100%' direction='both' id='events1-scrollview' ref={(ref) => this.events1ScrollView = ref}>
                            <div>
                                {content}
                            </div>
                    </ScrollView>
              </Popup>
          </Fragment>
    );
  }
}

Events1RowActionsMenu.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(Events1RowActionsMenu);
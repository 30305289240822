import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { Close } from 'mdi-material-ui';

import { TreeList, Column, HeaderFilter,  SearchPanel, FilterRow, ColumnChooser, Scrolling, LoadPanel,
    Pager, Paging, FilterPanel, Selection } from 'devextreme-react/tree-list';
import CustomStore from 'devextreme/data/custom_store';
import ScrollView from 'devextreme-react/scroll-view';
import Popup from 'devextreme-react/popup';
import PieChart, { Series, Label, Connector, Size, Margin, Font, Legend, Tooltip } from 'devextreme-react/pie-chart';
import _ from "lodash";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import lightBlue from "@material-ui/core/colors/lightBlue";

import fetchClient  from '../../../axios';
//import * as constants from '../../../constants';
import { textFormatter } from "../../common/utils";
import NetworkHierarchyRowActions from './NetworkHierarchyRowActionsMenu';
import Events1 from '../network-monitoring/Events1';
import ArchivedEvents from '../network-monitoring/ArchivedEvents';
import ManageDevices from '../../manage-devices/ManageDevices';

const stateFilterData = [{value: 5, text: "Normal"}, {value: 1, text: "Critical"},{value: 2, text: "Major"}, {value: 3, text: "Minor"}, {value: 4, text: "Unknown"}];
const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: `${theme.spacing.unit} !important`,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    closeIcon: {
        marginTop: -8
    }
});
const textFilterOperators = ['contains', 'startswith', 'endswith', '=', '<>'];
const NetworkHierarchyGridColumns = [
    { dataField: "displayName", caption: "Name", allowHeaderFiltering: false, customizeText: textFormatter, sortOrder: 'asc', width: 200},
    { dataField: "numberOfAlerts", caption: "Alerts", allowHeaderFiltering: false, width: 70 },
    { dataField: "numberOfDevices", caption: "Devices", allowHeaderFiltering: false, width: 70 },
    //{ dataField: "state", caption: "Status", alignment: "center", allowHeaderFiltering: true, width: 110, customizeText: textFormatter, cellRender: stateRender, headerFilter: { dataSource: stateFilterData }}
];
const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;
class NetworkHierarchy extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            isCommandExecuted: false,
            popupVisible: false,
            title: '',
            content: '',
            networkHierarchyData: [],
            networkHierarchyDataTotal: 0,
            deviceQuery: null,
            alertQuery: null
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.actionsMenuHandler = this.actionsMenuHandler.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.dataGrid = null;
        this.networkHierarchyPopup = null;
        this.actionsScrollView = null;
        this.networkHierarchyStore = {
            store: new CustomStore({
                key: 'id',
                load: function(loadOptions) {
                        return fetchClient.get('/api/v1.0/es/services/hierarchies')
                            .then(res => {
                                let networkHierarchyData = [];
                                if (res.data && res.data.data) {
                                    networkHierarchyData = res.data.data;
                                    //console.log(res.data.data);
                                }
                                return networkHierarchyData;
                            })
                            .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
    }
    componentWillMount() {
        //this.getNetworkHierarchyData();
    }
    componentDidMount() {
        //this.networkHierarchyTask = setInterval(()=> this.getNetworkHierarchyData(), constants.TASK_TIME)
    };
    componentWillUnmount () {
        //clearTimeout(this.getNetworkHierarchyData());
    };
    refreshDataGrid () {
        //this.getNetworkHierarchyData()
        this.dataGrid.instance.refresh();
    };
    /**
     * onToolbarPreparing:
     * @param e
     */
    onToolbarPreparing = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items,
            searchPanel, columnChooserPanel,
            filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push(searchPanel , columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
            });
    };
    /**
     * onContentReady:
     * @param e
     */
    onContentReady = (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    renderActionsMenu = (cellInfo) => {
        return <NetworkHierarchyRowActions rowData={cellInfo.data} actionsMenuHandler={this.actionsMenuHandler} />
    };
    onSelectionChanged = (e) => {
        const selectedRowsData = e.selectedRowsData;
        this.setState({
            deviceQuery: selectedRowsData.deviceQuery,
            alertQuery: selectedRowsData.alertQuery
        });

    };
    actionsMenuHandler = (widget, rowData, title) => {
        let component;
        if (widget === 'getDevices') {
            component = <ManageDevices dName={rowData.deviceQuery} />
        }
        if (widget === 'getCurrentEvents') {
            component = <Events1 query={rowData.alertQuery} />
        }
        if (widget === 'getArchivedEvents') {
            component = <ArchivedEvents query={rowData.alertQuery} />
        }
        this.setState({
            popupVisible: true,
            content: component,
            title: title
        });
        this.networkHierarchyPopup.instance.repaint();
    };
    handlePopupHidden = () => {
        this.setState({ popupVisible: false });
    };
    customizeText = (point) => {
          return `${point.percentText}`;
    };
    chartTooltipTemplate = (info) => {
      return (
        <div className="state-tooltip">
          <div>
            <span className="">{_.capitalize(info.argumentText)}</span>: {info.percentText}
          </div>
          <div>
            <span className="">Count</span>: {formatNumber(info.value)}
          </div>
        </div>
      );
    };
    customizePoint = (point) => {
		switch (point.argument) {
			case 'Critical':
				return { color: red[500]};
			case 'Major':
				return { color: orange[500]};
			case 'Minor':
				return { color: yellow[500]};
			case 'Unknown':
				return { color: lightBlue[500]};
			case 'Normal':
				return { color: green[500]};
            default:
                //None
		}
	};
    renderSparkLineChart = (cellInfo) => {
        const states = cellInfo['data']['alerts'];
        return (
                <PieChart ref={ this.deviceSearchChartRef }
                    id='device-search-pie-chart'
                    dataSource={ states }
                    resolveLabelOverlapping='shift'
                    customizePoint={this.customizePoint}
                >
                    <Series argumentField='label' valueField='value' >
                        <Label visible={true}
                            format='fixedPoint'
                            backgroundColor='none'
                            position='outside'
                            customizeText={this.customizeText}
                        >
                            <Font size={10} />
                            <Connector visible={true} width={1}/>
                        </Label>
                    </Series>
                    <Margin right={4} />
                    <Legend visible={false}/>
                    <Tooltip enabled={true} contentRender={this.chartTooltipTemplate} cornerRadius={8} zIndex={3000}/>
                    <Size width={150} height={80} />
                </PieChart>
        )
    };
    render(){
        const { classes, raised=false, handleDock } = this.props;
        const { popupVisible, title, content } = this.state;
        return (
            <Card className={classes.root} raised={raised}>
                <CardHeader className={ classes.cardHeader } title={'Network Hierarchy'}
                    action= {
                          <IconButton className={classes.closeIcon} onClick={handleDock}>
                            <Close/>
                          </IconButton>
                        }
                />
                <TreeList id={'networkHierarchyGrid'}
                    ref={(ref) => this.dataGrid = ref}
                    height={`calc(100vh - 45px)`}
                    dataSource={this.networkHierarchyStore}
                    cacheEnabled={true}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    repaintChangesOnly={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    columnAutoWidth={true}
                    cellHintEnabled={true}
                    wordWrapEnabled={false}
                    dataStructure="plain"
                    keyExpr="id"
                    parentIdExpr="parentId"
                    onContentReady={this.onContentReady}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}
                    selectedRowKeys={['.all customers service tree (internal)']}
                    >
                    <Selection mode="single" />
                    <HeaderFilter visible={true} />
                    <FilterRow visible={false} applyFilter={'onClick'} />
                    <FilterPanel visible={false} />
                    <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} mode={'standard'}/>
                    <LoadPanel enabled={true}/>
                    <Paging enabled={true} defaultPageSize={15} />
                    <Pager showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 25, 50]}/>
                    {
                        NetworkHierarchyGridColumns.map(row => {
                          return (
                            // eslint-disable-next-line
                            <Column dataField={row.dataField} caption={row.caption} cellRender={eval(row.cellRender)} alignment={row.alignment}
                                    headerFilter={row.headerFilter} allowHeaderFiltering={row.allowHeaderFiltering} filterOperations={textFilterOperators}
                                    width={row.width} customizeText={row.customizeText} sordOrder={row.sortOrder}
                            />
                          );
                        })
                    }
                    <Column dataField="State" caption="Status" cellRender={this.renderSparkLineChart} headerFilter={{dataSource: stateFilterData}} alignment="center" width={155} minWidth={70}/>
                    <Column type="button" caption="Actions" alignment="center" allowHeaderFiltering={false}  cellRender={this.renderActionsMenu} width={70}/>
                </TreeList>
                <Popup ref={(ref) => this.networkHierarchyPopup = ref}
                    maxwidth= '95%' //{ () => window.innerWidth/1.5}
                    maxHeight= '95%'
                    showTitle={true}
                    title={title}
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    visible={popupVisible}
                    onHiding={this.handlePopupHidden}
                    resizeEnabled={true}
                >
                    <ScrollView height='100%' width='100%' direction='both' id='actions-scrollview' ref={(ref) => this.actionsScrollView = ref}>
                            <div>
                                {content}
                            </div>
                    </ScrollView>
                    {/* <LoadPanel visible={!isCommandExecuted}/> */}
                </Popup>
            </Card>
        )
    }
}

NetworkHierarchy.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(NetworkHierarchy);
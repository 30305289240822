import React, {PureComponent} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { RadioGroup } from 'devextreme-react';

//import { changeTheme as changeThemeAction } from '../actions';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});
const themes = ['Light', 'Dark'];
class Settings extends PureComponent {
    handleThemeChange = (e) => {
        this.props.changeTheme(e.value)
    };
    render() {
        const { classes, raised, selectedTheme } = this.props;
        return (
            <Card className={classes.root} raised={raised}>
                <CardHeader className={classes.cardHeader} title={'User Settings'} />
                <CardContent>
                    <div className={'form'}>
                        <div className={'dx-field'}>
                            <div className={'dx-field-label'}>Change Theme</div>
                            <div className={'dx-field-value'}>
                                <RadioGroup items={themes} defaultValue={selectedTheme} onValueChanged={this.handleThemeChange} layout={'horizontal'} />
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }
}


Settings.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(Settings);
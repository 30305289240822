import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	card1: {
	  //width: '100%',
      margin: theme.spacing(1),
	},
	cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
	    //maxHeight: '150px',
		//height: '150px',
		overflow: 'auto',
		padding: 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1.2rem',
	  //color: '#ffffff'
	},
    subHeaderTitle: {
	    fontSize: '1rem',
    },
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.80rem'
	},
	tableRow: {
		height: 25,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default
		}
	}
});
class PingJupEntTerminalView extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {};
	}
	componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
	render() {
		const { classes, raised, actionData }  = this.props;
		const status = actionData && actionData['status'] ? actionData['status'] : null;
		const ovt_state = actionData && actionData['ovt_stats'] ? actionData['ovt_stats'] : null;
		const error = actionData && actionData['error'] ? actionData['error'] : null;
        return (
        	<Fragment>
				<Card variant="outlined" className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title='Status:' />
                    <CardContent className={ classes.cardContent } >
                     <Table size="small">
                         <TableBody stripedRows>
                             { status &&
                                 <Fragment>
                                    <TableRow key='deviceId' className={ classes.tableRow }>
                                      <TableCell className={ classes.tableCell } component="head" scope="row">Device ID</TableCell>
                                      <TableCell className={ classes.tableCell }>{status['device_id']}</TableCell>
                                    </TableRow>
                                    <TableRow key='terminalStatus' className={ classes.tableRow }>
                                      <TableCell className={ classes.tableCell } component="head" scope="row">Terminal Status</TableCell>
                                      <TableCell className={ classes.tableCell }>{status['terminal_status']}</TableCell>
                                    </TableRow>
                                    <TableRow key='esn' className={ classes.tableRow }>
                                      <TableCell className={ classes.tableCell } component="head" scope="row">ESN</TableCell>
                                      <TableCell className={ classes.tableCell }>{status['esn']}</TableCell>
                                    </TableRow>
                                    <TableRow key='associatedIpGateway' className={ classes.tableRow }>
                                      <TableCell className={ classes.tableCell } component="head" scope="row">Associated IP Gateway</TableCell>
                                      <TableCell className={ classes.tableCell }>{status['associated_ip_gateway']}</TableCell>
                                    </TableRow>
                                    <TableRow key='stateCode' className={ classes.tableRow }>
                                      <TableCell className={ classes.tableCell } component="head" scope="row">State Code</TableCell>
                                      <TableCell className={ classes.tableCell }>{status['state_code']} - {status['state_description']}</TableCell>
                                    </TableRow>
                                    <TableRow key='gateway_id' className={ classes.tableRow }>
                                      <TableCell className={ classes.tableCell } component="head" scope="row">Gateway ID</TableCell>
                                      <TableCell className={ classes.tableCell }>{status['gateway_id']}</TableCell>
                                    </TableRow>
                                    <TableRow key='currentGateway' className={ classes.tableRow }>
                                      <TableCell className={ classes.tableCell } component="head" scope="row">Current Gateway</TableCell>
                                      <TableCell className={ classes.tableCell }>{status['current_gateway']}</TableCell>
                                    </TableRow>
                                </Fragment>
                             }
                         </TableBody>
                     </Table>
                    </CardContent>
					{ovt_state &&
						<Fragment>
							<CardHeader className={classes.cardHeader} classes={{title: classes.headerTitle}}
										title='OVT Stats:'/>
							<CardContent className={classes.cardContent}>
								<Table size="small">
									<TableBody stripedRows>
										<Fragment>
											<TableRow key='uplinkEsNo' className={classes.tableRow}>
												<TableCell className={classes.tableCell} component="head" scope="row">Uplink EsNo</TableCell>
												<TableCell className={classes.tableCell}>{ovt_state['uplink_esno']}</TableCell>
											</TableRow>
											<TableRow key='downLinkEsNo' className={classes.tableRow}>
												<TableCell className={classes.tableCell} component="head" scope="row">Downlink EsNo</TableCell>
												<TableCell className={classes.tableCell}>{ovt_state['downlink_esno']}</TableCell>
											</TableRow>
											<TableRow key='packetLossRate' className={classes.tableRow}>
												<TableCell className={classes.tableCell} component="head" scope="row">Packet Loss Rate</TableCell>
												<TableCell className={classes.tableCell}>{ovt_state['packet_loss_rate']}</TableCell>
											</TableRow>
											<TableRow key='lastRegistrationTime' className={classes.tableRow}>
												<TableCell className={classes.tableCell} component="head" scope="row">Last Registration Time</TableCell>
												<TableCell
													className={classes.tableCell}>{ovt_state['last_registration_time']}</TableCell>
											</TableRow>
											<TableRow key='delay' className={classes.tableRow}>
												<TableCell className={classes.tableCell} component="head" scope="row">Delay</TableCell>
												<TableCell className={classes.tableCell}>{ovt_state['delay']}</TableCell>
											</TableRow>
										</Fragment>
									</TableBody>
								</Table>
							</CardContent>
						</Fragment>
					}
					{error &&
						<Fragment>
							<CardHeader className={classes.cardHeader} classes={{title: classes.headerTitle}} title='Error:'/>
							<CardContent className={classes.cardContent}>
								<Alert severity='error'>{error}</Alert>
							</CardContent>
						</Fragment>
					}
				</Card>
			</Fragment>
			)
	}
}

PingJupEntTerminalView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(PingJupEntTerminalView);
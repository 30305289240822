import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Grid as Grid1 } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { LoadPanel } from 'devextreme-react/load-panel';
import moment from 'moment';
import { Chart, Series, ArgumentAxis, Label, Export, Size, Grid, ValueAxis, Legend, Title, Tick, Tooltip } from 'devextreme-react/chart';
import fetchClient from "../../axios";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup);

const styles = theme => ({
	root: {
	  width: '100%',
      height: '100%'
	},
	paper: {
		padding: theme.spacing(1),
		border: '1px solid rgba(224, 224, 224, 1)',
		boxShadow: "none"
	},
	legendPaper: {
		display: "flex",
    	//border: `1px solid ${theme.palette.divider}`,
    	flexWrap: "wrap",
		padding: theme.spacing(1),
		height: 0,
		//marginTop: 48
	},
	dateText: {
		fontSize: "1rem"
	},
    userCountChart: {
	    height: '100%',
        width: '100%'
    }
});
const dateFormat = 'MM/DD/YYYY HH:mm:ss';

class DeviceSearchUserCountGraph extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
            isDataLoading: true,
			timeRange: 24,
			startDateTime: moment().subtract(24, 'hours').format(dateFormat),
			endDateTime: moment().format(dateFormat),
			userCountGraphData: [],
			dateTickInterval: 24,
			height: '100%',
			width: '100%'
		};
		this.userCountChartRef = React.createRef();
	}
	componentWillMount() {
        this.getUserCountChartData();
    }
    componentDidMount() {
        //this.setChartSize();
    };
    componentWillUnmount () {

    };
    /**
     * getUserCountChartData:
     */
    getUserCountChartData = () => {
    	const { deviceData } = this.props;
    	const { startDateTime, endDateTime } = this.state;
    	this.setState({isDataLoading: true});
    	const device_id = deviceData['device_id'].split('_')[1];
    	const stack_id = deviceData['device_id'].split('_')[0];
    	const data_type_name = 'fgVpnTunnelUpCount';
    	const params = {
    		stack_id: stack_id,
    		start_timestamp: moment(startDateTime, dateFormat).valueOf()/1000,
			end_timestamp: moment(endDateTime, dateFormat).valueOf()/1000,
    	};
		fetchClient({
			method: 'get',
			url: `/api/v1.0/em7/device/${device_id}/performance_data/${data_type_name}/data`,
			params: params,
		}).then(res => {
				let userCountGraphData = [];
				//let length = 0;
				if (res.data && res.data.data) {
					userCountGraphData = res.data.data;
					//length = res.data.data['total'];
				}
				this.setState({
					userCountGraphData: userCountGraphData,
					isDataLoading: false
				});
		}).catch (() => {
				this.setState({
					userCountGraphData: [],
					isDataLoading: false
				});
			throw new Error('Data Loading Error');
		});
    };
    setChartSize = () => {
        const height = this.userCountChartRef.current.offsetHeight;
		const width = this.userCountChartRef.current.offsetWidth;
        this.setState({
            height: height+'px',
            width: width+'px'
        }, () => { this.getUserCountChartData(); });
    };
	handleToggle = (e, val) => {
        this.setState({
			timeRange: val,
			startDateTime: moment().subtract(val, 'hours').format(dateFormat),
			endDateTime: moment().format(dateFormat),
		}, () => { this.getUserCountChartData(); });
    };
	handlePreviousDate = () => {
		const { startDateTime, endDateTime, timeRange } = this.state;
        this.setState({
			startDateTime: moment(startDateTime).subtract(timeRange, 'hours').format(dateFormat),
			endDateTime: moment(endDateTime).subtract(timeRange, 'hours').format(dateFormat)
		}, () => { this.getUserCountChartData(); });
	};
	handleNextDate = () => {
		const { startDateTime, endDateTime, timeRange } = this.state;
        this.setState({
			startDateTime: moment(startDateTime).add(timeRange, 'hours').format(dateFormat),
			endDateTime: moment(endDateTime).add(timeRange, 'hours').format(dateFormat)
		}, () => { this.getUserCountChartData(); });
	};
    chartTooltipTemplate = (info) => {
      return (
        <div className="state-tooltip">
          <div>
            <span className="">Date/Time</span>: {info.argumentText }
          </div>
          <div>
            <span className="">Value</span>: {info.value}
          </div>
        </div>
      );
    };
    customizeXText = (info) => {
		const  { timeRange } = this.state;
		let ddate = '';
		//let numTickes = '';
		switch(timeRange) {
			case 1:
			case 6:
            case 12:
			case 24:
				ddate = moment(info.value, dateFormat).format("HH:MM");
				//numTickes = 24;
			break;
			case 120:
				ddate = moment(info.value, dateFormat).format("MM/DD/YYYY");
			break;
			case 720:
				ddate = moment(info.value, dateFormat).format("DD-MMM");
				break;
			case 2160:
				ddate = moment(info.value, dateFormat).format("DD-MMM");
				break;
			case 8760:
				ddate = moment(info.value, dateFormat).format("MMM-YYYY");
			break;
			default:
				ddate = info.value
		}
		return ddate;
	};
	render() {
		const { classes } = this.props;
		const {  isDataLoading,  timeRange, startDateTime, endDateTime, userCountGraphData, height, width } = this.state;
		return (
			<div className={classes.root} ref={this.userCountChartRef}>
						<Grid1 container spacing={1} direction="row" justify="center" alignContent="center" alignItems="center">
							<Grid1 item>
								<div>Zoom:</div>
							</Grid1>
							<Grid1 item>
								<StyledToggleButtonGroup exclusive value={timeRange} size="small" variant="text" color="primary" onChange={this.handleToggle}>
									<ToggleButton value={1}>1h</ToggleButton>
									<ToggleButton value={6}>6h</ToggleButton>
                                    <ToggleButton value={12}>12h</ToggleButton>
									<ToggleButton value={24}>1d</ToggleButton>
									<ToggleButton value={120}>5d</ToggleButton>
									<ToggleButton value={720}>1m</ToggleButton>
									<ToggleButton value={2160}>3m</ToggleButton>
									<ToggleButton value={8760}>1y</ToggleButton>
								</StyledToggleButtonGroup>
							</Grid1>
						</Grid1>
						<Grid1 container spacing={1} direction="row" justify="center" alignContent="center"  alignItems="center">
							<Grid1 item>
								<div>Date Range:</div>
							</Grid1>
							<Grid1 item>
								<IconButton color='primary' onClick={this.handlePreviousDate} >
                                    <span className="mdi mdi-chevron-left mdi-24px" />
								</IconButton>
							</Grid1>
							<Grid1 item>
								<Typography className={classes.dateText} variant="h6" component="h6">{startDateTime} - {endDateTime}</Typography>
							</Grid1>
							<Grid1 item>
								<IconButton color='primary' onClick={this.handleNextDate} >
									<span className="mdi mdi-chevron-right mdi-24px" />
								</IconButton>
							</Grid1>
						</Grid1>
							<Fragment>
								<Paper className={classes.paper} id="userCountChart">
                                    {!isDataLoading &&
										<Chart className={classes.userCountChart} id="chart" dataSource={userCountGraphData}
											   theme="material.blue.light">
											<Series valueField="value" argumentField="date_string" type="spline" maxLabelCount={12}
													pallete="material" color="#4caf50"/>
											<ArgumentAxis tickInterval={this.state.dateTickInterval}>
												<Title text={"Date/Time"}/>
												<Tick width={3}/>
												<Grid visible={true}/>
												<Label wordwrap="none" customizeText={this.customizeXText}/>
											</ArgumentAxis>
											<ValueAxis tickInterval={10}>
												<Title text={"User Counts"}/>
												<Tick width={3}/>
												<Grid visible={true}/>
                                                {/*<VisualRange startValue={0} endtValue={110}/>*/}
											</ValueAxis>
											<Tooltip zIndex={2000} enabled={true} contentRender={this.chartTooltipTemplate}/>
											{/*<Tooltip zIndex={2000} enabled={true} />*/}
											<Export enabled={false}/>
											<Legend visible={false}/>
											<Size width={width} height={height}/>
										</Chart>
                                    }
									<LoadPanel
									  shadingColor="rgba(0,0,0,0.4)"
									  position={{ of: '#userCountChart'}}
									  visible={isDataLoading}
									/>
								</Paper>
							</Fragment>
				</div>
			)
	}
}

DeviceSearchUserCountGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DeviceSearchUserCountGraph);
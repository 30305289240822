import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
import { DataGrid, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, Scrolling } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';

import fetchClient  from '../../axios';
//import * as constants from '../../constants';
//import { dateFormatter } from "../common/utils";

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

class DeviceChangeLog extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            app_name: 'cpe',
            CPE_COLUMNS: [
                            {dataField: 'devname', caption: 'SAN', sortable: true, width: 100},
                            {dataField: '_time', caption: 'Date', sortable: true, dateFormatter: 'dateFormatter', width: 150, sortOrder:'desc'},
                            {dataField: 'cfgpath', caption: 'Category', sortable: true, width: 100},
                            {dataField: 'action', caption: 'Action', sortable: true, width: 100},
                            {dataField: 'user', caption: 'User Name', sortable: true, width: 150},
                            {dataField: 'msg', caption: 'Notes', sortable: true, width: 250},
                            {dataField: 'ui', caption: 'Source Address', sortable: true, width: 200},
                            {dataField: 'cfgattr', caption: 'Details', sortable: true}
                        ],
            SF_COLUMNS: [
                            {dataField: 'customer', caption: 'Customer', sortable: true, width: 100},
                            {dataField: 'san', caption: 'SAN', sortable: true, width: 100},
                            {dataField: '@timestamp', caption: 'Date Created', sortable: true, dateFormatter: 'dateFormatter', width: 150, sortOrder:'desc'},
                            {dataField: 'closed', caption: 'Date Closed', sortable: true, dateFormatter: 'dateFormatter', width: 150},
                            {dataField: 'id', caption: 'Ticket ID', sortable: true, width: 100},
                            {dataField: 'customer_locid', caption: 'Customer Loc ID', sortable: true, width: 100},
                            {dataField: 'request_type', caption: 'Request Type', sortable: true, width: 100},
                            {dataField: 'subtype', caption: 'Sub Type', sortable: true, width: 100},
                            {dataField: 'action', caption: 'Action', sortable: true, width: 100},
                            {dataField: 'actual_issue', caption: 'Actual Issue', sortable: true, width: 100}
                        ],
            EMS_COLUMNS: [
                            {dataField: 'siteid', caption: 'SAN', sortable: true, width: 100},
                            {dataField: '@timestamp', caption: 'Date', sortable: true, dateFormatter: 'dateFormatter', width: 150, sortOrder:'desc'},
                            {dataField: 'parameter', caption: 'Parameter', sortable: true, width: 100},
                            {dataField: 'valueold', caption: 'Old Value', sortable: true, width: 100},
                            {dataField: 'valuenew', caption: 'New Value', sortable: true, width: 100},
                            {dataField: 'username', caption: 'User Name', sortable: true, width: 150},
                            {dataField: 'error', caption: 'Error', sortable: true}
                        ],

        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.chageLogDataGrid = null;
    }
    componentWillMount() {
        //this.getDevicesData();
    }

    componentDidMount() {
        //this.devicesTask = setInterval(()=> this.getDevicesData(), constants.TASK_TIME)
    };
    componentWillUnmount () {
        //clearTimeout(this.devicesTask);
    };
    refreshDataGrid () {
        this.chageLogDataGrid.instance.refresh();
    };
    applicationChanged (e) {
        this.setState({
            app_name: e.value
        })
    };
    onToolbarPreparing(e) {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                if(item.name === 'searchPanel') {
                    items.splice(i, 1);
                    searchPanel = item;
                    searchPanel.location = 'after';
                    searchPanel.locateInMenu = 'auto';
                }
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.location = 'after';
                    columnChooserPanel.locateInMenu = 'auto';
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'applyFilterButton') {
                    items.splice(i, 1);
                    filterButton = item;
                    filterButton.options.icon = 'apply-filter';
                }
            }
            toolbarItems.push(searchPanel , columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                },{
                    location: 'before',
                    widget: 'dxSelectBox',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        width: 200,
                        items: [{value: 'cpe', text: 'CPE'}],
                        //items: [{value: 'cpe', text: 'CPE'},{value: 'salesforce', text: 'Salesforce'},{value: 'ems', text: 'EMS'}],
                        displayExpr: 'text',
                        valueExpr: 'value',
                        value: 'cpe',
                        onValueChanged: this.applicationChanged.bind(this)
                    }
                });
    }
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    render(){
        const { classes, theme, raised, deviceData } = this.props;
        const { app_name, CPE_COLUMNS, SF_COLUMNS, EMS_COLUMNS } = this.state;
        const COLUMN_CONFIG = (app_name === 'cpe') ? CPE_COLUMNS : app_name === 'salesforce' ? SF_COLUMNS : EMS_COLUMNS;
        const dataStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                    let params = { };
                    let url;
                        if(app_name === 'cpe') {
                            url = '/api/v1.0/splunk/cpe';
                            params.device_name = deviceData.name;
                            params.nmd = deviceData.nmd
                        } else {
                            url = '/api/v1.0/es/logs/application/'+app_name;
                            params.device_name = deviceData.name;

                        }
                        if (loadOptions.sort) {
                            params.sort = loadOptions.sort[0].selector;
                            params.order = loadOptions.sort[0].desc ? 'desc' : 'asc';
                        }
                        //params.offset = loadOptions.skip;
                        params.limit = 1000;//loadOptions.take;
                        return fetchClient.get(url, { params: params })
                            .then(res => {
                                let deviceChangeData = [];
                                //let deviceChangeDataTotal = 0;
                                if (res.data && res.data.data) {
                                    deviceChangeData = res.data.data;
                                    //deviceChangeDataTotal = res.data.total;
                                }
                                if(app_name === 'salesforce'){
                                    deviceChangeData = deviceChangeData.map(({ 'case.customer': customer,
                                   'case.san': san, 'timestamp.closed': closed, 'ticket.id': id,
                                   'case.customer_locid': customer_locid, 'case.request_type': request_type,
                                   'case.subtype': subtype, 'case.action': action, 'case.actual_issue': actual_issue ,
                                   ...rest }) => ({customer, san, closed, id, customer_locid, request_type, subtype, action, actual_issue, ...rest}));
                                }
                                if(app_name === 'ems'){
                                    deviceChangeData = deviceChangeData.map(({ 'change.siteid': siteid,
                                   'change.parameter': parameter, 'parameter.valueold': valueold, 'parameter.valuenew': valuenew,
                                   'change.username': username, 'change.error': error, ...rest }) => ({siteid, parameter, valueold, valuenew, username, error, ...rest}));
                                }
                                return {
                                    data: deviceChangeData//,
                                    //totalCount: deviceChangeDataTotal
                                };
                            })
                            .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
        return (
            <Card className={classes.root} raised={raised}>
                <DataGrid id={'deviceChangeLogGrid'}
                    ref={(ref) => this.chageLogDataGrid = ref}
                    height={`calc(100vh - ${theme.mixins.gridOffset}px)`}
                    dataSource={dataStore}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    repaintChangesOnly={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'nextColumn'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    columnMinWidth={70}
                    cellHintEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onContentReady={this.onContentReady}
                    >
                    <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    {
                        COLUMN_CONFIG.map(row => {
                          return (
                            // eslint-disable-next-line
                            <Column dataField={row.dataField} caption={row.caption} sortOrder={row.sortOrder} width={row.width} customizeText={eval(row.customizeText)}/>
                          );
                        })
                    }
                    {/*<RemoteOperations sorting={true} paging={true} />*/}
                    <Paging defaultPageSize={25}/>
                    <Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[15, 25, 50, 100]}/>
                </DataGrid>
            </Card>
        )
    }
}

DeviceChangeLog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(DeviceChangeLog);
import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

const styles = theme => ({
    root: {
        width: '100%'
    },
	cardHeader: {
		padding: theme.spacing.unit,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing.unit * 2,
			paddingRight: theme.spacing.unit * 2,
		},
		  height: theme.mixins.cardHeader.height,
		  borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	  cardContent : {
		padding: theme.spacing.unit,
        overflow: 'auto',
        height: `calc(100vh - ${theme.mixins.gridOffset}px)`,
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	  },
});
class Usage extends Component {
  constructor(props) {
      super(props);
      this.state = {

      };
  };
  componentDidMount() {

  };
  render() {
    const { classes, raised=false } = this.props;
    //const title = "API USAGE";
    return (
        <Card className={classes.root} raised={raised}>
            {/*<CardHeader className={ classes.cardHeader } title={title} />*/}
            <CardContent className={ classes.cardContent }>
                <div id="api-usage" />
            </CardContent>
        </Card>
    );
  }
}

Usage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withOktaAuth(withStyles(styles, {withTheme: true})(Usage));
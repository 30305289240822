import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from '@material-ui/core/Typography';
import { Square } from "mdi-material-ui";
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 0,
    margin: theme.spacing.unit/2,
    width: 200,
    '& > *': {
      padding: '2px 2px 0 2px'
    }
  },
  formControl: {
    margin: theme.spacing(0,1,0,0)
  },
  formGroup: {
    justifyContent: 'center',
    marginBottom: '4px'
  }
}));

export default function StateLegend({data, orientation, background=true}) {
  const classes = useStyles();
  if(background) {
        return (
          <Paper elevation={4} className={classes.root}>
            <FormGroup className={classes.formGroup} row={ true }>
                {
                  data.map(itm => {
                    return (
                      <FormControlLabel className={classes.formControl} disabled control={<Square style={{ fill: itm.color, fontSize: "1rem", marginRight: "4px"}} />}
                        label={<Typography style={{fontSize: "0.8rem"}}>{itm.label}</Typography>} />
                    );
                  })
                }
            </FormGroup>
          </Paper>
        );
  } else {
      return (
        <FormGroup className={classes.formGroup} row={ true }>
            {
              data.map(itm => {
                return (
                  <FormControlLabel className={classes.formControl} disabled control={<Square style={{ fill: itm.color, fontSize: "1rem", marginRight: "4px"}} />}
                    label={<Typography style={{fontSize: "0.8rem"}}>{itm.label}</Typography>} />
                );
              })
            }
        </FormGroup>
      );
  }
}
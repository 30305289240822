import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import DataGrid from 'devextreme-react/data-grid';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	card1: {
	  //width: '100%',
      margin: theme.spacing(1),
	},
	cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		overflow: 'auto',
		padding: 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	cardContent1: {
	    maxHeight: '400px',
		height: '400px',
		overflow: 'auto',
		padding: 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1.2rem',
	  //color: '#ffffff'
	},
    summaryViewIconButton: {
	    padding: 6
    },
    summaryViewActions: {
	    marginBottom: -6,
        marginTop: 3
    },
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.80rem'
	},
	tableRow: {
		height: 25,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default
		}
	}
});
class PingManagementView extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {};
	}
	componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
	render() {
		const { classes, raised, title, actionData, setHeight, deviceName, managementIp, poller, nmd }  = this.props;
        const rtt_ms = actionData && actionData['rtt_ms'] ? actionData['rtt_ms'] : [];
        const pings = actionData && actionData['responses'] ? actionData['responses'] : [];
        let pingsData = [];
		pings ?
            _.each(pings, function (value, idx) {
				pingsData.push({"# Ping": idx, "RTT": value.split('in')[1]});
			}) : pingsData = [];
		return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
                    <CardContent className={ setHeight ? classes.cardContent1 : classes.cardContent} >
                     <Table size="small">
                         <TableBody stripedRows>
							<TableRow key='deviceName' className={ classes.tableRow }>
							  <TableCell className={ classes.tableCell } component="head" scope="row">Device Name</TableCell>
							  <TableCell className={ classes.tableCell }>{deviceName}</TableCell>
							</TableRow>
							<TableRow key='deviceName' className={ classes.tableRow }>
							  <TableCell className={ classes.tableCell } component="head" scope="row">Management IP</TableCell>
							  <TableCell className={ classes.tableCell }>{managementIp}</TableCell>
							</TableRow>
							<TableRow key='deviceName' className={ classes.tableRow }>
							  <TableCell className={ classes.tableCell } component="head" scope="row">Poller</TableCell>
							  <TableCell className={ classes.tableCell }>{poller}</TableCell>
							</TableRow>
							<TableRow key='deviceName' className={ classes.tableRow }>
							  <TableCell className={ classes.tableCell } component="head" scope="row">NMD</TableCell>
							  <TableCell className={ classes.tableCell }>{nmd}</TableCell>
							</TableRow>
							<TableRow key='averageLatency' className={ classes.tableRow }>
							  <TableCell className={ classes.tableCell } component="head" scope="row">Average Latency</TableCell>
							  <TableCell className={ classes.tableCell }>{rtt_ms['avg']} ms</TableCell>
							</TableRow>
							<TableRow key='maxLatency' className={ classes.tableRow }>
							  <TableCell className={ classes.tableCell } component="head" scope="row">Max Latency</TableCell>
							  <TableCell className={ classes.tableCell }>{rtt_ms['max']} ms</TableCell>
							</TableRow>
							<TableRow key='minLatency' className={ classes.tableRow }>
							  <TableCell className={ classes.tableCell } component="head" scope="row">Min Latency</TableCell>
							  <TableCell className={ classes.tableCell }>{rtt_ms['min']} ms</TableCell>
							</TableRow>
                         </TableBody>
                     </Table>
					 { pings && pingsData ?
						 <Card className={classes.card1}>
						  <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Pings'}/>
						  <DataGrid
							dataSource={pingsData}
							width="100%"
							showBorders={true}
						 />
						</Card>
							: ""
					 }
                    </CardContent>
                </Card>
			)
	}
}

PingManagementView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(PingManagementView);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import compose from 'recompose/compose';
import fetchClient from "../../axios";
import axios from "axios/index";
import DbDetailsView from "../../components/common/views/DbDetailsView"
import _ from 'lodash';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11Props(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: 48,//theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    tabs: {
        float: 'left'
    }
});

class AllDbDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            dmnData: [],
            eitData: [],
            htsData: [],
            smartsData: [],
            isDataLoading: false
        };
        this.getDeviceData = this.getDeviceData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillMount() {
        this.getDeviceData();
    }
    handleChange = (event, newValue) => {
        this.setState({value: newValue})
    };
    updateData = dbData => {
        let dbArray = [];
        _.forEach(dbData, function(val, key) {
            dbArray.push({'name': _.upperCase(key), 'value': val})
        });
        //console.log(dbArray);
        return _.sortBy(dbArray, o => o.name);
    };
    /**
     * getDeviceData:
     */
    getDeviceData = () => {
    	const { deviceName } = this.props;
    	if (deviceName) {
			this.setState({isDataLoading: true});
			//let params = {device_name: deviceName};
			let url1 = '/api/v1.0/oracle/device/dmn_device';
			let url2 = '/api/v1.0/oracle/device/active_device';
			let url3 = '/api/v1.0/oracle/device/eit_device';
			let url4 = '/api/v1.0/oracle/device/hts_device';
			axios.all([
				fetchClient.get(url1, {params: {device_name: deviceName}}),
				fetchClient.get(url2, {params: {device_name: deviceName}}),
				fetchClient.get(url3, {params: {device_name: deviceName}}),
				fetchClient.get(url4, {params: {device_name: deviceName}})
			  ])
			  .then(axios.spread((res1, res2, res3, res4) => {
					this.setState({
						dmnData: res1.data ? this.updateData(res1.data) : [],
						activeData: res2.data ? this.updateData(res2.data) : [],
						eitData: res3.data ? this.updateData(res3.data) : [],
						htsData: res4.data ? this.updateData(res4.data) : [],
						isDataLoading: false
					});
			  }))
			 .catch( (error) => {
				console.log('Error: ' + error);
                return false
			 });
		}
    };
render() {
        const { classes } = this.props;
        const { value, dmnData, activeData,  eitData, htsData} = this.state;
        const title = '';
        return (
                <Card className={classes.root} >
                    <CardHeader className={classes.cardHeader} title={title}
                        action={
                            <div className={classes.tabs} id={'tabs'}>
                                <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                                  <Tab value={0} label="DMN" {...a11Props(0)} />
                                  <Tab value={1} label="EIT" {...a11Props(1)} />
                                    <Tab value={2} label="HTS" {...a11Props(2)} />
                                  <Tab value={3} label="SMARTS" {...a11Props(3)} />
                                </Tabs>
                            </div>
                        }
                    />
                    <div id={'alldbdetails-tabs'}>
                      <TabPanel value={value} index={0}>
                          <DbDetailsView dbData={dmnData}/>
                      </TabPanel>
                      <TabPanel value={value} index={1} >
                       <DbDetailsView dbData={eitData}/>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <DbDetailsView  dbData={htsData}/>
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        <DbDetailsView dbData={activeData}/>
                      </TabPanel>
                    </div>
                </Card>
        )
    }
}

AllDbDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(AllDbDetails);
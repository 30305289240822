import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { DataGrid, Column, Scrolling } from 'devextreme-react/data-grid';
import fetchClient from '../../axios';
import PingInterfacesView from '../common/views/PingInterfacesView';

class CiscoSwitchInfo extends PureComponent {
    constructor(props){
        super(props);
        this.state= {
            deviceName: this.props.deviceData.name,
            devicesSameLocationData:this.props.devicesSameLocationData,
            isCiscoSwitch: false,
            deviceInfo:[],
            switchResponse:[],
        }
    }
    componentDidMount() {
      const deviceInfoArray = [{
        name: this.props.deviceData.name,
        productCategory: this.props.deviceData.productCategory,
        parentSite: this.props.deviceData.psiteid,
        nocId: this.props.deviceData.nocid,
        vendor: this.props.deviceData.vendor
      }]
      this.state.devicesSameLocationData.forEach((item) => {
        let i={
            name: item.name,
            productCategory:item.productCategory,
            parentSite:item.psiteid,
            nocId: item.nocid,
            vendor: item.vendor
        }
        deviceInfoArray.push(i)
      })
      this.setState({deviceInfo:deviceInfoArray})
    }

    componentDidUpdate(prevProps,prevState) {
        if(prevProps.hnsLocationId !== this.props.hnsLocationId) {
            const deviceInfoArray = [{
                name: this.props.deviceData.name,
                productCategory: this.props.deviceData.productCategory,
                parentSite: this.props.deviceData.psiteid,
                nocId: this.props.deviceData.nocid,
                vendor: this.props.deviceData.vendor
              }]
              this.props.devicesSameLocationData.forEach((item) => {
                let i={
                    name: item.name,
                    productCategory:item.productCategory,
                    parentSite:item.psiteid,
                    nocId: item.nocid,
                    vendor: item.vendor
                }
                deviceInfoArray.push(i)
              })
              this.setState({deviceInfo:deviceInfoArray,
                                switch:false
                            })
        }
        if(prevState.deviceInfo!==this.state.deviceInfo){
            const deviceInfo = this.state.deviceInfo
            deviceInfo.forEach((item) => {
                if(item.productCategory === 'Switch' && item.nocId==='TangoW' && item.vendor==='CISCO'){
                    this.getSwitchInfo(item.name)
                }
                else {
                    this.setState({
                        switchResponse:[],
                        isCiscoSwitch:false,

                    })
                }
            })
        }
    }

    getSwitchInfo = (deviceName) => {
        let params = {device_name:deviceName}
        this.setState({switch:true}, async () => {
            const response = await fetchClient.get('api/v1.0/action/device/pingInterfaces', {params:params})
            this.setState({
                deviceName:deviceName,
                switchResponse:response.data.results,
                isCiscoSwitch:true
            })
        })
    }

    render() { 
        const deviceName = this.state.deviceName;
        const isCiscoSwitch= this.state.isCiscoSwitch;
        const switchResponse = this.state.switchResponse;
        let switchColumns = [
            {dataField:'interface', caption:'Interface'},
            {dataField:'mac', caption:'MAC'},
            {dataField:'vendor', caption:'Vendor'},
            {dataField:'vlan', caption:'VLANID'},

        ]
        return (  
            <div>
                {
                    isCiscoSwitch?           
                        <div>
                            <h2>Real Time Data for Cisco Switch {deviceName}</h2>
                            <PingInterfacesView raised={false} actionData={switchResponse} deviceName={deviceName} title="Fortilan Status"/>;
                        </div>: 
                            null
                }
            </div>
  
        );
    }
}
 
export default CiscoSwitchInfo;
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Fullscreen from "react-full-screen";
import numeral from 'numeral';
import Grid1 from '@material-ui/core/Grid';
import 'devextreme/ui/select_box';
import { Chart, Series, CommonAxisSettings, ArgumentAxis, ValueAxis, Label,
    Size, Legend, Export, Tooltip, Grid } from 'devextreme-react/chart';
import Toolbar, { Item } from 'devextreme-react/toolbar';

import fetchClient  from '../../../axios';
//import * as constants from '../../../constants';
//import { textFormatter, dateFormatter, stateRender } from "../../common/utils";
import EventsStatsWidget from './EventsStatsWidget';

const styles = theme => ({
    root: {
        width: '100%',
        height: `calc((100vh - 134px)/2)`
    },
    cardHeader: {
        padding: `${theme.spacing.unit} !important`,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    summaryContainer: {
        paddingTop: 16
    },
    chartContainer: {
        paddingRight: 8
    },
    eventsSummaryActions: {
	    marginBottom: -6,
        marginTop: 3
    }
});

class EventsSummary extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            value: 0,
            isFullScreen: false,
            query: '',
            last24Hours: 0,
            last7Days: 0,
            last30Days: 0,
            last1Year: 0,
            last24HoursData: [],
            last7DaysData: [],
            last30DaysData: [],
            last1YearData: [],
            dateFormat: 'YYYY-MM-DDTHH:mm:ss[Z]',
            selectedTimeRange: 1
        };
        this.getLast24HoursAlertsData = this.getLast24HoursAlertsData.bind(this);
        this.getLast7DaysAlertsData = this.getLast7DaysAlertsData.bind(this);
        this.getLast30DaysAlertsData = this.getLast30DaysAlertsData.bind(this);
        this.getLast1YearAlertsData = this.getLast1YearAlertsData.bind(this);
        this.timeRangeTypes = [{
          id: 1,
          text: 'Last 24 Hours'
        },{
          id: 2,
          text: 'Last 7 Days'
        },{
          id: 3,
          text: 'Last 30 Days'
        },{
          id: 4,
          text: 'Last 1 Year'
        }]
    }
    componentWillMount() {
        this.getLast24HoursAlertsData();
        this.getLast7DaysAlertsData();
        this.getLast30DaysAlertsData();
        this.getLast1YearAlertsData();
    }

    componentDidMount() {
        //this.devicesTask = setInterval(()=> this.getEventsData(), constants.TASK_TIME)
    };
    componentWillUnmount () {
        //clearTimeout(this.devicesTask);
    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
    handleTimeRangeChange = (event) => {
        this.setState({selectedTimeRange: event.value})
    };
    refreshEventsSummaryData = () => {

    };
    /**
     * getLast24HoursAlertsData:
     */
    getLast24HoursAlertsData = () => {
        const { query, dateFormat } = this.state;
        const params = {
                start_date: moment().subtract(24, 'hours').format(dateFormat),
                end_date: moment().format(dateFormat),
                interval: '1h'
            };
        if (query) {
            params.query = query
        }
      fetchClient.get('/api/v1.0/es/alert-count',{ params: params })
          .then(res => {
              let last24HoursData = [];
              let last24Hours = 0;
              if (res.data) {
                  last24HoursData = res.data['alert_counts_by_1h'];
                  last24Hours = res.data['alert_count']
              }
              this.setState({
                  last24HoursData: last24HoursData,
                  last24Hours: last24Hours
              });
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  last24HoursData: [],
                  last24Hours: 0
              });
          });
    };
    /**
     * getLast7DaysAlertsData:
     */
    getLast7DaysAlertsData = () => {
        const { query, dateFormat } = this.state;
        const params = {
                start_date: moment().subtract(7, 'days').format(dateFormat),
                end_date: moment().format(dateFormat),
                interval: '1d'
            };
        if (query) {
            params.query = query
        }
      fetchClient.get('/api/v1.0/es/alert-count',{ params: params })
          .then(res => {
              let last7DaysData = [];
              let last7Days = [];
              if (res.data) {
                  last7DaysData = res.data['alert_counts_by_1d'];
                  last7Days = res.data['alert_count']
              }
              this.setState({
                  last7DaysData: last7DaysData,
                  last7Days: last7Days
              });
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  last7DaysData: [],
                  last7Days: 0
              });
          });
    };
    /**
     * getLast30DaysAlertsData:
     */
    getLast30DaysAlertsData = () => {
        const { query, dateFormat } = this.state;
        const params = {
                start_date: moment().subtract(30, 'days').format(dateFormat),
                end_date: moment().format(dateFormat),
                interval: '1d'
            };
        if (query) {
            params.query = query
        }
      fetchClient.get('/api/v1.0/es/alert-count',{ params: params })
          .then(res => {
              let last30DaysData = [];
              let last30Days = [];
              if (res.data) {
                  last30DaysData = res.data['alert_counts_by_1d'];
                  last30Days = res.data['alert_count']
              }
              this.setState({
                  last30DaysData: last30DaysData,
                  last30Days: last30Days
              });
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  last30DaysData: [],
                  last30Days: 0
              });
          });
    };
    /**
     * getLast1YearAlertsData:
     */
    getLast1YearAlertsData = () => {
        const { query, dateFormat } = this.state;
        const params = {
                start_date: moment().subtract(1, 'year').format(dateFormat),
                end_date: moment().format(dateFormat),
                interval: '1M'
            };
        if (query) {
            params.query = query
        }
      fetchClient.get('/api/v1.0/es/alert-count',{ params: params })
          .then(res => {
              let last1YearData = [];
              let last1Year = [];
              if (res.data) {
                  last1YearData = res.data['alert_counts_by_1M'];
                  last1Year = res.data['alert_count']
              }
              this.setState({
                  last1YearData: last1YearData,
                  last1Year: last1Year
              });
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  last1YearData: [],
                  last1Year: 0
              });
          });
    };
    chartTooltipTemplate = (info) => {
      return (
        <div className="state-tooltip">
          <div>
            <span className="">TIME</span>: {info.argumentText}
          </div>
          <div>
            <span className="">EVENT COUNT</span>: {info.value}
          </div>
        </div>
      );
    };
    render(){
        const { classes, raised=false, isFullScreen } = this.props;
        const {  last24HoursData, last7DaysData, last30DaysData, last1YearData, last24Hours, last7Days, last30Days, last1Year, selectedTimeRange } = this.state;
        //const height = `calc((100vh - 164px)/2)`;
        return (
            <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                <div className="full-screenable-node">
                    <Card className={classes.root} raised={raised}>
                        <CardHeader className={classes.cardHeader} title={"Event Stats"}
                            action={
                                <Fragment>
                                    <Toolbar>
                                      <Item location="after"
                                        locateInMenu="never"
                                        widget="dxSelectBox"
                                        showText="inMenu"
                                        options={{
                                          width: 170,
                                          elementAttr: {'id': 'eventsSummarySelect'},
                                          items: this.timeRangeTypes,
                                          valueExpr: 'id',
                                          displayExpr: 'text',
                                          value: selectedTimeRange,
                                          onValueChanged: this.handleTimeRangeChange
                                        }} />
                                      <Item location="after"
                                        locateInMenu="never"
                                        widget="dxButton"
                                        showText="inMenu"
                                        options={{
                                            type: 'default',
                                            icon: 'mdi mdi-refresh',
                                            hint: 'REFRESH',
                                            text: 'REFRESH',
                                            onClick: this.refreshEventsSummaryData.bind(this)
                                        }} />
                                      <Item location="after"
                                        locateInMenu="never"
                                        widget="dxButton"
                                        showText="inMenu"
                                        options={{
                                            type: 'default',
                                            icon: isFullScreen ? 'mdi mdi-fullscreen-exit' : 'mdi mdi-fullscreen',
                                            hint: isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
                                            text: isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
                                            onClick: this.handleFullScreen.bind(this)
                                        }} />
                                    </Toolbar>
                                </Fragment>
                            }
                        />
                        <Grid1 container className={classes.chartContainer} spacing={1}>
                            <Grid1 item sm={3} xs={12} >
                                <Grid1 container className={classes.summaryContainer} direction="column" justify="center" alignItems="center" spacing={1}>
                                    <Grid1 item xs={12} >
                                        <EventsStatsWidget metric="Last 24 Hours" color="#03a9f4" total={numeral(last24Hours).format('0,0')}></EventsStatsWidget>
                                    </Grid1>
                                    <Grid1 item xs={12} >
                                        <EventsStatsWidget metric="Last 7 Days" color="#00bcd4" total={numeral(last7Days).format('0,0')}></EventsStatsWidget>
                                    </Grid1>
                                    <Grid1 item xs={12} >
                                        <EventsStatsWidget metric="Last 30 Days" color="#009688" total={numeral(last30Days).format('0,0')}></EventsStatsWidget>
                                    </Grid1>
                                    <Grid1 item xs={12} >
                                        <EventsStatsWidget metric="Last 1 Year" color="#4caf50" total={numeral(last1Year).format('0,0')}></EventsStatsWidget>
                                    </Grid1>
                                </Grid1>
                            </Grid1>
                            <Grid1 item sm={9} xs={12} >
                                { selectedTimeRange === 1 &&
                                    <Chart id="last24Hours" dataSource={last24HoursData} >
                                        <Series
                                          valueField="doc_count"
                                          argumentField="key_as_string"
                                          type="bar"
                                          pallete="material"
                                          color="#03a9f4"  />
                                          <CommonAxisSettings>
                                              <Grid visible={false}/>
                                          </CommonAxisSettings>
                                          <ValueAxis title="Number of Events" />
                                          <ArgumentAxis title="Hour">
                                              <Label wordwrap="none"
                                                     //overlappingBehavior="rotate"
                                                     //rotationAngle={45}
                                                    customizeText={ ({value, valueText}) => { return moment(value, "YYYY-MM-DD HH").format("HH")}}
                                              />
                                          </ArgumentAxis>
                                          <Size height={270} />
                                          <Tooltip enabled={true} contentRender={this.chartTooltipTemplate} />
                                          <Export enabled={false} />
                                          <Legend visible={false}/>
                                      </Chart> }
                                { selectedTimeRange === 2 &&
                                    <Chart id="last7Days" dataSource={last7DaysData}>
                                        <Series
                                          valueField="doc_count"
                                          argumentField="key_as_string"
                                          type="bar"
                                          pallete="material"
                                          color="#00bcd4"  />
                                          <CommonAxisSettings>
                                              <Grid visible={false}/>
                                          </CommonAxisSettings>
                                          <ValueAxis title="Number of Events" />
                                          <ArgumentAxis title="Day" />
                                          <Size height={300} />
                                          <Tooltip enabled={true} contentRender={this.chartTooltipTemplate}/>
                                          <Export enabled={false} />
                                          <Legend visible={false}/>
                                      </Chart> }
                                { selectedTimeRange === 3 &&
                                      <Chart id="last30Days" dataSource={last30DaysData}>
                                        <Series
                                          valueField="doc_count"
                                          argumentField="key_as_string"
                                          name="Hourly"
                                          type="bar"
                                          pallete="material"
                                          color="#009688"/>
                                          <CommonAxisSettings>
                                              <Grid visible={false}/>
                                          </CommonAxisSettings>
                                          <ValueAxis title="Number of Events" />
                                          <ArgumentAxis title="Day">
                                              <Label wordwrap="none"
                                                     overlappingBehavior="rotate"
                                                     rotationAngle={45}
                                                    //customizeText={ (value, valueText) => { return moment(value, "YYYY-MM-DD HH").format("HH")}}
                                              />
                                          </ArgumentAxis>
                                          <Size height={300} />
                                          <Tooltip enabled={true} contentRender={this.chartTooltipTemplate}/>
                                          <Export enabled={false} />
                                          <Legend visible={false}/>
                                      </Chart> }
                                { selectedTimeRange === 4 &&
                                      <Chart id="last1Year" dataSource={last1YearData}>
                                        <Series
                                          valueField="doc_count"
                                          argumentField="key_as_string"
                                          name="Hourly"
                                          type="bar"
                                          pallete="material"
                                          color="#4caf50"/>
                                          <CommonAxisSettings>
                                              <Grid visible={false}/>
                                          </CommonAxisSettings>
                                          <ValueAxis title="Number of Events" />
                                          <ArgumentAxis title="Month">
                                              <Label wordwrap="none"
                                                     //overlappingBehavior="rotate"
                                                     //rotationAngle={45}
                                                     customizeText={ ({value, valueText}) => { return moment(value, "YYYY-MM-DD HH").format("MMM")}}
                                              />
                                          </ArgumentAxis>
                                          <Size height={300} />
                                          <Tooltip enabled={true} contentRender={this.chartTooltipTemplate}/>
                                          <Export enabled={false} />
                                          <Legend visible={false}/>
                                      </Chart> }
                            </Grid1>
                        </Grid1>
                    </Card>
                </div>
            </Fullscreen>
        )
    }
}

EventsSummary.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(EventsSummary);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import { DataGrid, RemoteOperations, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, Scrolling } from 'devextreme-react/data-grid';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import CustomStore from 'devextreme/data/custom_store';

import fetchClient  from '../../axios';
import {
    textFormatter,
    stateRender,
    epochDateFormatter,
    secondsToStringFormatter,
    calculateEventDuration,
    eventStatusFormatter,
    handleErrors
} from "../common/utils";
import EventDetails from './EventDetails';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

class DeviceEvents extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            query: "NOT nmd:'' AND active:true",
            isFullScreen: false,
            popupVisible: false,
            title: '',
            content: ''
        };
        this.deviceEventsGridColumns = [
            //{ dataField: "elementName", caption: "Device", customizeText: textFormatter, fixed: true},
            { dataField: "instanceDisplayName", caption: "Part Name", customizeText: textFormatter, fixed: true},
            { dataField: "severity", caption: "Severity", width: 100, customizeText: textFormatter, alignment: 'center', cellRender: stateRender, fixed: true, sortOrder: 'asc' },
            { dataField: "productCode", caption: "Product Name", customizeText: textFormatter, },
            { dataField: "productCategory", caption: "Device Category", customizeText: textFormatter, },
            { dataField: "active", caption: "Status", width: 70, dataType: 'string', customizeText: eventStatusFormatter },
            { dataField: "eventDisplayName", caption: "Event", customizeText: textFormatter, },
            { dataField: "eventText", caption: "Event Description", customizeText: textFormatter, },
            { dataField: "lastNotifiedAt", caption: "Last Notify", cellRender: epochDateFormatter },
            { dataField: "clearedAt", caption: "Last Clear", cellRender: epochDateFormatter},
            { dataField: "duration", caption: "Duration", cellRender: calculateEventDuration, },
            { dataField: "count", caption: "Count", width: 70, },
            { dataField: "troubleTicketID", caption: "Case ID", customizeText: textFormatter, },
            { dataField: "visibility", caption: "Visibility", customizeText: textFormatter, }
        ];
        this.deviceEventsGridColumns1 = [
            //{ dataField: "elementName", caption: "Device", customizeText: textFormatter, fixed: true},
            { dataField: "instanceDisplayName", caption: "Part Name", customizeText: textFormatter, fixed: true},
            { dataField: "severity", caption: "Severity", width: 100, customizeText: textFormatter, alignment: 'center', cellRender: stateRender, fixed: true, sortOrder: 'asc' },
            { dataField: "vendor", caption: "Vendor", customizeText: textFormatter, },
            { dataField: "active", caption: "Status", width: 70, dataType: 'string', customizeText: eventStatusFormatter, },
            { dataField: "eventDisplayName", caption: "Event", customizeText: textFormatter, },
            { dataField: "eventText", caption: "Event Description", customizeText: textFormatter, },
            { dataField: "lastNotifiedAt", caption: "Last Notify", cellRender: epochDateFormatter },
            { dataField: "clearedAt", caption: "Last Clear", cellRender: epochDateFormatter},
            { dataField: "duration", caption: "Duration", cellRender: secondsToStringFormatter, },
            { dataField: "count", caption: "Count", width: 70, },
            { dataField: "troubleTicketID", caption: "Case ID", customizeText: textFormatter, },
            { dataField: "visibility", caption: "Visibility", customizeText: textFormatter, }
        ];
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.deviceEventsPopupRef = React.createRef();
        this.deviceEventsGrid = null;
        this.deviceEventsStore = {
            store: new CustomStore({
				key: 'name',
                load: (loadOptions) => {
                    const params = {
                        device_name: this.props.deviceName,
                        visibility: this.props.visibility
                    };
                    if (loadOptions.sort) {
                        params.sort = loadOptions.sort[0].selector;
                        params.order = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    params.offset = loadOptions.skip;
                    params.limit = loadOptions.take;
                    return fetchClient({
                            method: 'get',
                            url: '/api/v1.0/es/device/alerts',
                            params: params,
                        })
                        .then(handleErrors)
                        .then(res => {
                                let eventsData = [];
                                let eventsDataTotal = 0;
                                if (res.data && res.data.data) {
                                    eventsData = res.data.data;
                                    eventsDataTotal = res.data.total;
                                }
                                return {
                                    data: eventsData,
                                    totalCount: eventsDataTotal
                                };
                        })
                        .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
    }
    componentWillMount() {
        //this.getDevicesData();
    }

    componentDidMount() {
        //this.devicesTask = setInterval(()=> this.getDevicesData(), constants.TASK_TIME)
    };
    componentWillUnmount () {
        //clearTimeout(this.devicesTask);
    };
    componentWillReceiveProps () {
      this.refreshDataGrid();
    };
    refreshDataGrid () {
        this.deviceEventsGrid.instance.refresh();
    };
    handleDeviceNameCellClick = (e) => {
         if(e.rowType === 'data') {
             if (e.column.dataField === 'elementName') {
                 this.setState({ popupVisible: true, title: `Event Details of ${e.data['name']}`, content: <EventDetails eventData={e.data} /> });
             }
         }
    };
    handlePopupHidden = () => {
        this.setState({popupVisible: false, content: ''})
    };
    renderDeviceName = (data) => {
            // eslint-disable-next-line
        return (<a className='dx-link'>{data.value}</a>);
    };
    onRowPrepared = (e) => {
        if(e.rowType === 'data' && e.data.active) {
            switch (e.data.severity) {
                case 1:
                    e.rowElement.style.backgroundColor = '#f44336';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Critical';
                    break;
                case 2:
                    e.rowElement.style.backgroundColor = '#ff9800';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Major';
                    break;
                case 3:
                    e.rowElement.style.backgroundColor = '#ffeb3b';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Minor';
                    break;
                case 4:
                    e.rowElement.style.backgroundColor = '#03a9f4';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Unknown';
                    break;
                case 5:
                    e.rowElement.style.backgroundColor = '#008000';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Normal';
                    break;
                default:
                    //None
            }
        }
    };
    onToolbarPreparing = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push(searchPanel , columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    };
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    render(){
        const { classes, raised, deviceData } = this.props;
        const { popupVisible, title, content } = this.state;
        return (
            <Card className={classes.root} raised={raised}>
                <DataGrid id={'deviceEventsGrid'}
                    ref={(ref) => this.deviceEventsGrid = ref}
                    height={`calc((100vh - 134px)/2)`}
                    dataSource={this.deviceEventsStore}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    repaintChangesOnly={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    columnMinWidth={70}
                    cellHintEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onRowPrepared={this.onRowPrepared}
                    onContentReady={this.onContentReady}
                    onCellClick={this.handleDeviceNameCellClick}
                    >
                    <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    <Column dataField='elementName' caption='Device' width={100} alignment='center' customizeText={textFormatter} cellRender={this.renderDeviceName} fixed={true} />
                    { (deviceData && deviceData['assetId'] === 'Currently Unavailable') ?
                        this.deviceEventsGridColumns1.map(row => {
                          return (
                              // eslint-disable-next-line
                            <Column dataField={row.dataField} caption={row.caption} width={row.width} alignment={row.alignment} dataType={row.dataType} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)} fixed={row.fixed} sortOrder={row.sortOrder}/>
                          );
                        }) :
                        this.deviceEventsGridColumns.map(row => {
                          return (
                              // eslint-disable-next-line
                            <Column dataField={row.dataField} caption={row.caption} width={row.width} alignment={row.alignment} dataType={row.dataType} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)} fixed={row.fixed} sortOrder={row.sortOrder}/>
                          );
                        })
                    }
                    <RemoteOperations sorting={true} paging={true} />
                    <Paging defaultPageSize={25}/>
                    <Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[15, 25, 50, 100]}/>
                </DataGrid>
                  <Popup ref={ this.deviceEventsPopupRef }
					  maxwidth= { () => window.innerWidth/2 }
					  maxHeight= '95%'
					  width='50%'
					  showTitle={true}
					  title={title}
					  dragEnabled={true}
					  closeOnOutsideClick={false}
					  visible={popupVisible}
					  onHiding={this.handlePopupHidden}
					  resizeEnabled={true}
					>
					  <ScrollView height='100%' width='100%' direction='both' id='actions-scrollview' >
						  <div>
							 {content}
						  </div>
					  </ScrollView>
				  </Popup>
            </Card>
        )
    }
}

DeviceEvents.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(DeviceEvents);
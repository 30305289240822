import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
        border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
        color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});
class HANConfiguration extends PureComponent {
	// constructor(props) {
	// 	super(props);
	// }
	render() {
		const { classes, raised, deviceData, devicesSameLocationData, deviceAdditionalData} = this.props;
		const title = 'HAN Configuration';
		let neighborDeviceActivePath =  'Disabled';
		_.each(devicesSameLocationData, (row) => {
			if(row.aaCfOpermode === '3') {
				neighborDeviceActivePath = 'Single CPE Enabled';
				return false;
			} else if(row.aaCfOpermode === '4') {
				neighborDeviceActivePath =  'Dual CPE Enabled';
				return false;
			}
		});
		const hanType = (deviceData.cpehan === 'DTSC') ? 'Dual Transport, Single CPE' : 'Dual Transport, Dual CPE';
		const hnsConfig = [
			{dataField: 'HNS_BUAdminStatus', caption: 'Backup Transport Admin Status'},
			{dataField: 'HNS_BUOperStatus', caption: 'Backup Transport Operational Status'},
			{dataField: 'HNS_BUInUse', caption: 'Backup Transport In Use Status'},
			{dataField: 'HNS_PTAdminStatus', caption: 'Primary Transport Admin Status'},
			{dataField: 'HNS_PTOperStatus', caption: 'Primary Transport Operational Status'},
			{dataField: 'HNS_PTInUse', caption: 'Primary Transport In Use Status'},
		];
		return (
			<Card className={classes.root} raised={raised}>
				<CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
				<CardContent className={ classes.cardContent }>
					 <Table size="small">
						 <TableBody>
							{ deviceData ?
								<Fragment>
                                    { deviceData['nocid'] !== 'FORTIMGR' && deviceData['poller'] !== 'PAM-EXTERNAL-AM' &&
                                        <TableRow key="han" className={classes.tableRow}>
                                            <TableCell className={classes.tableCell} variant="head" scope="row">High Availability Network</TableCell>
                                            <TableCell className={classes.tableCell}>{deviceData['han']}</TableCell>
                                        </TableRow>
                                    }
									<TableRow key="hanType" className={ classes.tableRow }>
									  <TableCell className={ classes.tableCell } variant="head" scope="row">HAN Type</TableCell>
									  <TableCell className={ classes.tableCell }>{hanType}</TableCell>
									</TableRow>
                                    {deviceData['cpehan'] === 'DTSC' &&
                                        <Fragment>
                                            <TableRow key="primaryNapSource" className={ classes.tableRow }>
                                              <TableCell className={ classes.tableCell } variant="head" scope="row">Primary NAP Source</TableCell>
                                                <TableCell className={ classes.tableCell }>{deviceData['napSource']}</TableCell>
                                            </TableRow>
                                            <TableRow key="backupNapSource" className={ classes.tableRow }>
                                              <TableCell className={ classes.tableCell } variant="head" scope="row">Backup NAP Source</TableCell>
                                                <TableCell className={ classes.tableCell }>{deviceData['provider']}</TableCell>
                                            </TableRow>
                                        </Fragment>
                                    }
                                    { !deviceData['model'].startsWith('FortiGate', 0) &&
                                        <TableRow key="activePathConfiguration" className={classes.tableRow}>
                                            <TableCell className={classes.tableCell} variant="head" scope="row">Active Path Configuration</TableCell>
                                            <TableCell className={classes.tableCell}>{neighborDeviceActivePath}</TableCell>
                                        </TableRow>
                                    }
									{ (deviceData['cpehan'] === 'DTSC' && neighborDeviceActivePath !== 'Single CPE Enabled' ) ?
										hnsConfig.map(row => {
										  return (
											<TableRow key={row.dataField} className={ classes.tableRow }>
											  <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
											  <TableCell className={ classes.tableCell }>{deviceAdditionalData[row.dataField]}</TableCell>
											</TableRow>
										  );
										}) : null
									}
								</Fragment>
								: ""
							}
						</TableBody>
					  </Table>
				</CardContent>
			</Card>
			)
	}
}

HANConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(HANConfiguration);
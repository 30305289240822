import React, {  PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
//import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
        overflow: 'auto',
        padding: 0,
        '&:last-child': {
            paddingBottom: theme.spacing(1)
        }
    },
    headerTitle: {
      fontSize: '1.2rem',
      //color: '#ffffff'
    },
    summaryViewIconButton: {
        padding: 6
    },
    summaryViewActions: {
        marginBottom: -6,
        marginTop: 3
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableCellBold: {
        padding: '4px 16px',
        lineHeight: '0.80rem',
                fontWeight: 'bold'
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5',
                fontWeight: 'bold'
    }
});
const targetDateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
const dateFormat = 'MM/DD/YYYY hh:mm A';
class PingOrCurrentStatusWattboxView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            disableResetButton: false
        };
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    textFormatter = (cellInfo) => {
        return (cellInfo.value === true) ? 'Yes': 'No';
    };
    handleReset = () => {
        this.setState({disableResetButton: true})
    };
    render() {
        const { classes,  raised, title, actionData }   = this.props;
        //const { disableResetButton } = this.state;
        const statusProps = [
            {fieldName: 'autoReboot', label: 'Auto-Reboot'},
            {fieldName: 'powerValue', label: 'Power'},
            {fieldName: 'currentValue',  label: 'Current'},
            {fieldName: 'voltageValue', label: 'Voltage'},
            {fieldName: 'hasUps', label: 'Has Ups?'},
            {fieldName: 'uptime', label: 'Up Time'},
            {fieldName: 'powerLost', label: 'Power Lost'},
            {fieldName: 'voltageHighValue', label: 'Voltage High'},
            {fieldName: 'voltageLowValue', label: 'Voltage Low'},
            {fieldName: 'batteryCharge',  label: 'Battery Charge'},
            {fieldName: 'batteryHealth', label: 'Battery Health'},
            {fieldName: 'batteryLoad', label: 'Battery Load'},
            {fieldName: 'batteryRunTime', label: 'Battery Run Time'}];
        const detailsProps = [
            {fieldName: 'deviceId', label: 'Device Id'},
            {fieldName: 'name', label: 'Device Name'},
            {fieldName: 'macAddress', label: 'MAC Address'},
            {fieldName: 'brand', label: 'Manufacturer'},
            {fieldName: 'model',  label: 'Model'},
            {fieldName: 'connected',  label: 'Status'},
            {fieldName: 'serialNumber',  label: 'Serial Number'},
            {fieldName: 'serviceTag', label: 'Service Tag'},
            {fieldName: 'firmware', label: 'Firmware'},
            {fieldName: 'firstSeenTime', label: 'PRO Device First Seen'},
            {fieldName: 'lastSeenTime', label: 'PRO Device Last Seen'}];
        const networkSettingsProps = [
            {fieldName: 'ipType', label: 'IP Type'},
            {fieldName: 'deviceIpAddress', label: 'LAN IP'},
            {fieldName: 'ipAddress', label: 'WAN IP'},
            {fieldName: 'deviceSubnetMask', label: 'Subnet Mask'},
            {fieldName: 'deviceDefaultGateway', label: 'Device Gateway'},
            {fieldName: 'dnsServer1',  label: 'DNS Server 1'},
            {fieldName: 'dnsServer2',  label: 'DNS Server 2'}];
        const pings_columns = [
            {dataField:'name', caption: 'Name'},
            {dataField:'host', caption: 'Host'},
            {dataField:'protocol', caption: 'Protocol'},
            {dataField:'responseTime', caption: 'Response Time'}
        ];
        const outlets = actionData && actionData['outlets'] ? actionData['outlets'] : [];
        const details = actionData && actionData['details'] ? actionData['details'] : {};
        const networkSettings = actionData && actionData['network_settings'] ? actionData['network_settings'] : {};
        const pings = actionData && actionData['pings'] ? actionData['pings'] : [];
        return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
                    <CardContent className={ classes.cardContent } >
                     <Table size="small">
                         <TableBody stripedRows>
                            <TableRow key='detailsHeader' className={ classes.tableRow1 }>
                              <TableCell colSpan={2} align="center" className={ classes.tableCellBold }>Device Details</TableCell>
                            </TableRow>
                             { details ?
                                  _.map(detailsProps, function (value, key) {
                                      let fieldName = value['fieldName'];
                                        let val = details[fieldName];
                                        let label = value['label'];
                                      if (['firstSeenTime', 'lastSeenTime'].includes(fieldName)) {
                                            val = moment.utc(val, targetDateFormat).local().format(dateFormat);
                                      }

                                      // convert connected into UP/DOWN status
                                      if (fieldName === 'connected') {
                                          val = val === true? "UP" : "DOWN";
                                      }

                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                          <TableCell className={ classes.tableCell }>{val}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                            <TableRow key='networkHeader' className={ classes.tableRow1 }>
                              <TableCell colSpan={2} align="center" className={ classes.tableCellBold }>Network Settings</TableCell>
                            </TableRow>
                             { networkSettings ?
                                  _.map(networkSettingsProps, function (value, key) {
                                      let fieldName = value['fieldName'];
                                        let val = networkSettings[fieldName];
                                        let label = value['label'];
                                      if (fieldName === 'ipAddress') {
                                            val = details[fieldName];
                                      }
                                      if (fieldName === 'ipType') {
                                            val = networkSettings['dhcpEnabled'] === true ? 'DHCP' : 'Static';
                                      }
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                          <TableCell className={ classes.tableCell }>{val}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                            <TableRow key='statusHeader' className={ classes.tableRow1 }>
                              <TableCell colSpan={2} align="center" className={ classes.tableCellBold }>Device Status</TableCell>
                            </TableRow>
                             { actionData ?
                                  _.map(statusProps, function (value, key) {
                                        let fieldName = value['fieldName'];
                                        let val = actionData[fieldName];
                                        let label = value['label'];
                                        if (['hasUps', 'powerLost'].includes(fieldName)) {
                                            val = (val === true) ? 'Yes' : 'No';
                                      }
                                      if (fieldName === 'autoReboot') {
                                            val = (val === true) ? 'Enabled' : 'Disabled';
                                      }
                                      if (fieldName === 'powerValue' && val) {
                                            val = `${val}W`;
                                      }
                                      if (fieldName === 'currentValue' && val) {
                                            val = `${val/10}A`;
                                      }
                                        if (['voltageValue', 'voltageHighValue', 'voltageLowValue'].includes(fieldName)) {
                                            val = val? `${val/10}V` : '';
                                      }
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                          <TableCell className={ classes.tableCell }>{val}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                         </TableBody>
                     </Table>
                         { pings ?
                             <Card className={classes.card1}>
                              <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Pings'}/>
                              <DataGrid
                                dataSource={pings}
                                columns={pings_columns}
                                width="100%"
                                showBorders={true}
                             />
                            </Card>
                             : ""
                         }
                         { outlets ?
                             <Card className={classes.card1}>
                              <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Outlets'}/>
                              <DataGrid
                                dataSource={outlets}
                                width="100%"
                                showBorders={true}
                             >
                                  {/*<Column type="buttons" caption="Reset">
                                    <Button text={disableResetButton ? 'Reseting...' : 'Reset'} icon={ disableResetButton ? 'mdi mdi-restore mdi-spin' : 'mdi mdi-restore'}
                                    hint={disableResetButton ? 'Reseting...' : 'Reset'} onClick={this.handleReset} />
                                  </Column>*/}
                                  <Column dataField='number' caption="Outlet #" />
                                  <Column dataField='name' caption="Name" />
                                  <Column dataField='status' caption="Status" dataType='string' customizeText={this.textFormatter} />
                                  <Column dataField='outletMethod' caption="Outlet Method" />
                                  <Column dataField='dualBay' caption="Dual Bay" dataType='string' customizeText={this.textFormatter} />
                                  <Column dataField='clockwiseRotation' caption="Clockwise Rotation" />
                                  <Column dataField='powerOnDelay' caption="Power On Delay" />
                              </DataGrid>
                            </Card>
                             : ""
                         }
                    </CardContent>
                </Card>
            )
    }
}

PingOrCurrentStatusWattboxView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(PingOrCurrentStatusWattboxView);

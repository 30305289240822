import React, {  PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
        border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
		color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	},
	networkAlert: {
		fontSize: '13px',
		padding: '0 16px'
	},
	networkAlertDiv: {
		padding: 4,
		marginBottom: 4,
		color: '#a94442',
		backgroundColor: '#f2dede',
		borderColor: '#ebccd1',
		textAlign: 'center'
	}
});
class DeviceNetworkConfiguration extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
		};
	}
	render() {
		//const { isFullScreen } = this.state;
		const { classes, raised, deviceData,  deviceDmnData, deviceNetworkEventsData} = this.props;
		const title = 'Network Configuration';
		const tableColumns = [
		    { caption: 'Gateway ID', dataField: 'gwid' },
		    { caption: 'LAN IP Address', dataField: 'seedip' },
            { caption: 'Management IP Address', dataField: 'managementIp' },
            { caption: 'Datacenter', dataField: 'dataCenter' },
            //{ caption: 'Number of IPs', dataField: 'numberOfIPs' },
            { caption: 'Serial Number', dataField: 'diuSerialNum' },
            { caption: 'VPN Gateway ID', dataField: 'vpgw' },
            { caption: 'Vendor', dataField: 'vendor' },
            { caption: 'Adapter Model', dataField: 'model' },
			{ caption: 'GILBARCO NAT IP', dataField: 'GILBARCO_NAT_IP'},
			{ caption: 'VERIFON NAT IP', dataField: 'VERIFON_NAT_IP'},
            { caption: 'NAP Source', dataField: 'napSource' },
			{ caption: 'Diversity GW', dataField: 'gwidDivCurrent' }
        ];
		return (
			<Card className={classes.root} raised={raised}>
				<CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
				<CardContent className={ classes.cardContent }>
					{deviceNetworkEventsData.length > 0 &&
						<div className={classes.networkAlertDiv}>There is a <b>Network Event</b> for this
                        device, Please use Case Id: {deviceNetworkEventsData[0]['caseId']}</div>
					}
					 <Table size="small">
						 <TableBody>
							{(deviceData) ?
                                // eslint-disable-next-line
								tableColumns.map(row => {
									if (row.dataField === 'gwid') {
										if (deviceData['nocid'] !== 'FORTIMGR' && deviceData['poller'] !== 'PAM-EXTERNAL-AM') {
											return (
												<TableRow key='napSource' className={classes.tableRow}>
													<TableCell className={classes.tableCell} variant="head" scope="row">{row.caption}</TableCell>
													<TableCell className={classes.tableCell}>{deviceData['gwid']}</TableCell>
												</TableRow>
											);
										}
									} else if (row.dataField === 'vpgw' ) {
										if ( deviceData['nocid'] !== 'FORTIMGR' && deviceData['poller'] !== 'PAM-EXTERNAL-AM') {
											return (
												<TableRow key='napSource' className={classes.tableRow}>
													<TableCell className={classes.tableCell} variant="head" scope="row">{row.caption}</TableCell>
													<TableCell className={classes.tableCell}>{deviceData['vpgw']}</TableCell>
												</TableRow>
											);
										}
									} else if (row.dataField === 'napSource') {
										if (deviceData['className'] === 'HNS_Terrestrial' && deviceData['cpehan'] !== 'DTSC') {
                                            return (
                                                <TableRow key='napSource' className={classes.tableRow}>
                                                    <TableCell className={classes.tableCell} variant="head" scope="row">{row.caption}</TableCell>
                                                    <TableCell className={classes.tableCell}>{deviceData['napSource']}</TableCell>
                                                </TableRow>
                                            );
                                        }
                                    } else if (row.dataField === 'GILBARCO_NAT_IP') {
										if (deviceDmnData['GILBARCO_NAT_IP'] && deviceDmnData['GILBARCO_NAT_IP'] !== '') {
                                            return (
                                                <TableRow key='napSource' className={classes.tableRow}>
                                                    <TableCell className={classes.tableCell} variant="head" scope="row">{row.caption}</TableCell>
                                                    <TableCell className={classes.tableCell}>{deviceDmnData['GILBARCO_NAT_IP']}</TableCell>
                                                </TableRow>
                                            );
                                        }
                                    } else if (row.dataField === 'VERIFON_NAT_IP') {
                                    	if (deviceDmnData['VERIFON_NAT_IP'] && deviceDmnData['VERIFON_NAT_IP'] !== '') {
                                            return (
                                                <TableRow key='napSource' className={classes.tableRow}>
                                                    <TableCell className={classes.tableCell} variant="head" scope="row">{row.caption}</TableCell>
                                                    <TableCell className={classes.tableCell}>{deviceDmnData['VERIFON_NAT_IP']}</TableCell>
                                                </TableRow>
                                            );
                                        }
                                    } else {
                                        return (
                                            <TableRow key={row.dataField} className={classes.tableRow}>
                                                <TableCell className={classes.tableCell} variant="head" scope="row">{row.caption}</TableCell>
                                                <TableCell className={classes.tableCell}>{deviceData[row.dataField]}</TableCell>
                                            </TableRow>
                                        );
                                    }
								}) :
								tableColumns.map(row => {
								  return (
									<TableRow key={row.dataField} className={ classes.tableRow }>
									  <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
									  <TableCell className={ classes.tableCell } >{"Unknown"}</TableCell>
									</TableRow>
								  );
								})
							}
						</TableBody>
					  </Table>
				</CardContent>
			</Card>
			)
	}
}

DeviceNetworkConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DeviceNetworkConfiguration);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import compose from "recompose/compose";
import DeviceDetails from '../components/device-details/DeviceDetails';

const styles = theme => ({
	  root: {
	    height: '100%',
		overFlowY: 'scroll'
	  }
	});


class DeviceDetailsContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		    hasErrors: false,
			userInfo: null,
			groups: []
		};
		this.getCurrentUser = this.getCurrentUser.bind(this);
	};
    async getCurrentUser () {
		const userInfo = await this.props.oktaAuth.getUser();
		const groups = userInfo['groups'];
		//console.log(userInfo);
        this.setState({ userInfo, groups });
    }
    componentDidMount() {
		this.getCurrentUser();
    };
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    };
	render() {
		const { classes, match } = this.props;
		const { hasErrors, userInfo } = this.state;
		let dName  = match['params']['dName'];
		return (
				<div className={classes.root}>
					{hasErrors ?
						<div>Page has erros, please fix!!!</div>
					:
						<DeviceDetails raised={true} userInfo={userInfo} dName={dName} />
					}
				</div>
		)
	}
}
DeviceDetailsContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default withOktaAuth(withRouter(enhance(DeviceDetailsContainer)));
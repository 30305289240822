import React from 'react';


const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;

export default function SummaryChartTooltipTemplate(info) {
  return (
    <div className="state-tooltip">
      <div>
        <span className="">{info.argumentText}</span>: {info.percentText}
      </div>
      <div>
        <span className="">Count</span>: {formatNumber(info.value)}
      </div>
    </div>
  );
}
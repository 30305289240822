import React from 'react';

const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;

function calculateTotal(pieChart) {
  return formatNumber(pieChart.getAllSeries()[0].getVisiblePoints().reduce((s, p) => s + p.originalValue, 0));
}

export default function SummaryChartCenterTemplate(pieChart) {
  return (
    <svg>
      <circle cx="100" cy="100" r={pieChart.getInnerRadius() - 4} fill="#607d8b"></circle>
      <text textAnchor="middle" x="100" y="100" style={{ fontSize: 16, fill:"#ffffff" }}>
        <tspan x="100" dy="-10px" style={{ fontWeight: 600 }}>TOTAL</tspan>
      </text>
      <text textAnchor="middle" x="100" y="100" style={{ fontSize: 14, fill:"#ffffff" }}>
        <tspan x="100" dy="5px" style={{ fontWeight: 600 }}>------------</tspan>
      </text>
      <text textAnchor="middle" x="100" y="100" style={{ fontSize: 18, fill:"#ffffff" }}>
        <tspan x="100" dy="20px" style={{ fontWeight: 600 }}>{ calculateTotal(pieChart) }</tspan>
      </text>
    </svg>
  );
}
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
//import { CheckBox, SelectBox, NumberBox } from 'devextreme-react';
import Form, { ButtonItem, GroupItem, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';
//import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
//import Card from '@material-ui/core/Card';
import 'devextreme-react/autocomplete';
import { Alert } from '@material-ui/lab';
//import Validator, { ValidationRule } from 'devextreme-react/validator';
import fetchClient from "../../axios";
//import axios from "axios/index";
import moment from 'moment';
import { LoadPanel } from 'devextreme-react/load-panel';
const styles = theme => ({
	root: {
	  width: '100%',
		padding: 14
	}
});

class PamSuspend extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isDataLoading: false,
			showAdd: true,
			showRemove: false,
            pamSuspendData: {},
			showSuccessAlert: false,
			showErrorAlert: false,
			errorMsg: '',
			successMsg: ''
        };
	 	this.handleSubmit = this.handleSubmit.bind(this);
	 	this.addPamSuspend = this.addPamSuspend.bind(this);
	 	this.dateFormat = 'MM/DD/YYYY HH:mm:ss';
		this.startDateBoxOptions = {
		  width: '100%',
		  type: 'datetime',
		  displayFormat: 'MM/dd/yyyy HH:mm:ss',
		  dateSerializationFormat: 	'MM/dd/yyyy HH:mm:ss',
		  invalidDateMessage: 'The date must have the following format: MM/dd/yyyy HH:mm:ss (23:00:00)'
		};
		this.endDateBoxOptions = {
		  width: '100%',
		  type: 'datetime',
		  displayFormat: 'MM/dd/yyyy HH:mm:ss',
		  dateSerializationFormat: 	'MM/dd/yyyy HH:mm:ss',
		  invalidDateMessage: 'The date must have the following format: MM/dd/yyyy HH:mm:ss (23:00:00)'
		};
		this.minStartDateTime = moment().format(this.dateFormat);
		this.maxStartDateTime = moment().add(8, 'm').format(this.dateFormat);
		this.minEndDateTime = moment().add(2, 'h').format(this.dateFormat);
		this.maxEndDateTime = moment().add(10, 'y').format(this.dateFormat);
		this.validateForm = (e) => {
			  e.component.validate();
		};
    };
	componentWillMount() {
        this.getPamSuspendData();
    }
    /**
     * getPamSuspendData:
     */
    getPamSuspendData = () => {
    	const { deviceName } = this.props;
    	let { showAdd, showRemove } = this.state;
    	if (deviceName) {
			this.setState({isDataLoading: true});
			let url = '/api/v1.0/action/devices/pam-suspend';
			let params = {
			    device_names: deviceName,
                active: true
            };
			fetchClient.get(url, { params: params })
			  .then((res) => {
			  		let pamSuspendData={};
			  		if(res.data && res.data.data && res.data.data.length > 0) {
			  			const data = res.data.data[0];
			  			const status = data.status;
			  			if (status === 'sched' || status === 'pendsus' || status === 'sus') {
			  				showAdd = true;
			  				showRemove = true;
						}
						pamSuspendData = {
							status: status === 'sus' ? 'PAM Suspended': status === 'pendsus' ? 'PAM Suspend is pending' : 'PAM Suspend is scheduled',
							start_time: moment.unix(data.start_time).format(this.dateFormat),
							end_time: moment.unix(data.end_time).format(this.dateFormat),
							info: data.info
						}
					} else {
						pamSuspendData = {
							status: 'PAM not suspended',
							start_time: moment().format(this.dateFormat),
							end_time: moment().add(2, 'h').format(this.dateFormat),
							info: 'Reason to suspend'
						}
					}
					this.setState({
						pamSuspendData: pamSuspendData,
						isDataLoading: false,
						showAdd: showAdd,
						showRemove: showRemove
					});
			  })
			 .catch( (error) => {
					console.log('Error: ' + error);
					let errorMsg = 'Some error has occurred, Please try again later';
					if (error.response){
						if (error.response.status === 404) {
							errorMsg = `No PAM Schedule found for ${deviceName}`
						}
					} else {
						errorMsg = error.message;
					}
					this.setState({
						pamSuspendData: {
							status: 'PAM Not Suspended',
							start_time: moment().format(this.dateFormat),
							end_time: moment().add(2, 'h').format(this.dateFormat),
							info: 'Reason for PAM suspend'
						},
						errorMsg: errorMsg,
						showSuccessAlert: false,
						showErrorAlert: true,
						isDataLoading: false
					});
			 });
		}
    };
	handleSubmit =(e) => {
		const {pamSuspendData} = this.state;
		console.log(pamSuspendData);
		notify({
		  message: 'You have submitted the form',
		  position: {
			my: 'center top',
			at: 'center top'
		  }
		}, 'success', 3000);
		e.preventDefault();
  	};
	addPamSuspend = () => {
		const {pamSuspendData} = this.state;
		const { deviceName, userInfo } = this.props;
		let url = '/api/v1.0/action/devices/pam-suspend?';
		this.setState({isDataLoading: true});
		let params = [{
		    schedule_type: 'device',
			device_name: deviceName,
			info: pamSuspendData.info,
			start_time: moment(pamSuspendData.start_time, this.dateFormat).valueOf()/1000,
			end_time: moment(pamSuspendData.end_time, this.dateFormat).valueOf()/1000,
			user: userInfo['nickname']
		}];
		fetchClient.post(url, params)
		  .then((res) => {
				this.setState({
					//pamSuspendData: {},
					successMsg: 'PAM Suspend created successfully',
					showErrorAlert: false,
					showSuccessAlert: true,
					isDataLoading: false
				});
		  })
		 .catch( (error) => {
			console.log('Error: ' + error);
				this.setState({
					//pamSuspendData: {},
					errorMsg: `PAM Suspend not created, Please try again later.`,
					showErrorAlert: true,
					showSuccessAlert: false,
					isDataLoading: false
				});
		 });
	};
	removePamSuspend = () => {
		const { deviceName, userInfo } = this.props;
		let url = '/api/v1.0/action/devices/pam-suspend';
		this.setState({isDataLoading: true});
		let params = {
			device_names: deviceName,
			user_name: userInfo['nickname']
		};
		fetchClient.delete(url, { params: params })
		  .then((res) => {
				this.setState({
					successMsg: 'PAM Suspend removed successfully',
					showErrorAlert: false,
					showSuccessAlert: true,
					isDataLoading: false
				});
		  })
		 .catch( (error) => {
			console.log('Error: ' + error);
				this.setState({
					errorMsg: `PAM Suspend not removed, Please try again later.`,
					showErrorAlert: true,
					showSuccessAlert: false,
					isDataLoading: false
				});
		 });
	};
	render() {
        const { pamSuspendData, showAdd, showRemove, showSuccessAlert, showErrorAlert,  successMsg, errorMsg, isDataLoading} = this.state;
		const { classes } = this.props;
		return (
			    <div id="pam-suspend-container-id" className={classes.root} >
					<form>
						<Form
							height={'100%'}
							formData={pamSuspendData}
							readOnly={false}
							showColonAfterLabel={true}
							onContentReady={this.validateForm}
							colCount={1}
						>
							<GroupItem>
							  <SimpleItem dataField="status" editorOptions={{disabled: true}} >
								  <Label text="Current Status" />
							  </SimpleItem>
							  <SimpleItem dataField="start_time"
								editorType="dxDateBox"
								editorOptions={this.startDateBoxOptions}>
								<Label text="Start Date" />
								<RequiredRule max={this.maxStartDateTime} min={this.minStartDateTime} message="Start date is required" />
							  </SimpleItem>
							  <SimpleItem dataField="end_time"
								editorType="dxDateBox"
								editorOptions={this.endDateBoxOptions}>
								<Label text="End Date" />
								<RequiredRule message="End date is required" />
								<RequiredRule max={this.maxEndDateTime} min={this.minEndDateTime} message="End date must be less than 10 years" />
							  </SimpleItem>
							  <SimpleItem dataField="info" editorType="dxTextArea" editorOptions={{ height: 100 }}>
								  <Label text="Info" />
								  <RequiredRule message="Info is required" />
							  </SimpleItem>
							</GroupItem>
							<GroupItem colCount={2}>
							  <ButtonItem horizontalAlignment='right' verticalAlignment='center' visible={showAdd} buttonOptions={{hint: 'Create PAM Suspend', type: 'default', text: 'Create', onClick:this.addPamSuspend.bind(this) }} />
							  <ButtonItem horizontalAlignment='left' verticalAlignment='center' visible={showRemove} buttonOptions={{hint: 'Remove PAM Suspend', type: 'danger', text: 'Remove', onClick:this.removePamSuspend.bind(this) }}/>
							</GroupItem>
						</Form>
					</form>
					{ showSuccessAlert && <Alert elevation={1} variant="outlined" icon={false} severity="success">{successMsg}</Alert> }
					{ showErrorAlert && <Alert elevation={1} variant="outlined" icon={false} severity="error">{errorMsg}</Alert> }
					<LoadPanel
						shadingColor="rgba(0,0,0,0.4)"
						position={{ of: '#pam-suspend-container-id'}}
						visible={isDataLoading}
					/>
                </div>
			)
	}
}

PamSuspend.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(PamSuspend);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
import SummaryViewSvn from './widgets/SummaryViewSvn';
import SummaryView from './widgets/SummaryView';
import NetworkEvents from './network-monitoring/NetworkEvents';
//import ArchivedEvents from './network-monitoring/ArchivedEvents';

const styles = theme => ({

});

class DashBoardSummary extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            gridHeight: '50vh'
        };
        this.networkGridRef = React.createRef();
    }

    componentWillMount() {

    }

    componentDidMount() {
        const gridHeight = this.networkGridRef.current.offsetHeight - 9;
        this.setState({ gridHeight: gridHeight })
    };
    componentWillUnmount () {

    };
render() {
        //const { classes, theme } = this.props;
        const { gridHeight } = this.state;
        return (
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryViewSvn raised={true} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryView raised={true} title='Summary View - ALL' query='discovered:true'/>
                    </Grid>
                    <Grid ref={this.networkGridRef} item lg={6} md={4} sm={12} xs={12} >
                        <NetworkEvents  gridHeight={ gridHeight } raised={true}  query='*:*'/>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryView raised={true} title='Summary View - Active Teck' query='discovered:true AND className:HNS_AccelApp'/>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryView raised={true} title='Summary View - VSAT' query='discovered:true AND className:HNS_VSAT'/>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryView raised={true} title='Summary View - Terrestrial' query='discovered:true AND className:HNS_Terrestrial'/>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryView raised={true} title='Summary View - VOIP ATA' query='discovered:true AND className:HNS_VOIP_AP'/>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryView raised={true} title='Summary View - Multimedia Player' query='discovered:true AND className:HNS_MMPlayer'/>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryView raised={true} title='Summary View - WIFI AP' query='discovered:true AND className:HNS_WIFIAP'/>
                    </Grid>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <SummaryView raised={true} title='Summary View - Other Appliances' query='discovered:true AND NOT className:(HNS_AccelApp OR HNS_VSAT OR HNS_Terrestrial OR HNS_VOIP_AP OR HNS_MMPlayer OR HNS_WIFIAP)'/>
                    </Grid>
                </Grid>
        )
    }
}

DashBoardSummary.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(DashBoardSummary);
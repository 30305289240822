import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Cradlepoint from '../components/cradlepoint/Cradlepoint';

const styles = theme => ({
	  root: {
	    height: '100%'
	  }
	});


class CradlepointContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		    hasErrors: false
		}
	};
    componentDidMount() {

    };
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Error: '+info);
    };
	render() {
		const { classes } = this.props;
		const { hasErrors } = this.state;
		return (
				<div className={classes.root}>
					{hasErrors ?
						<div>Page has erros, please fix!!!</div>
					:
						<Cradlepoint raised={true}/>
					}
				</div>
		)
	}
}
CradlepointContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(CradlepointContainer));
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import fetchClient from '../../axios';
import PingOrCurrentStatusMerakiView from '../common/views/PingOrCurrentStatusMerakiView';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardHeader1: {
        border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
        border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
		color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});

class MerakiDeviceData extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            deviceName: this.props.deviceName,
            devicesSameLocationData:this.props.devicesSameLocationData,
            isMeraki:false,
            deviceInfo:[],
            pingCurrentStatusResponse:[],
        }
    }
    componentDidMount() {
        const deviceInfoArray = [{
            name: this.props.deviceData.name,
            productCategory: this.props.deviceData.productCategory,
            parentSite: this.props.deviceData.psiteid,
            nocId: this.props.deviceData.nocid
          }]
        this.state.devicesSameLocationData.forEach((item) => {
            let i={
                name: item.name,
                productCategory:item.productCategory,
                parentSite:item.psiteid,
                nocId:item.nocid
            }
            deviceInfoArray.push(i)
        })
        this.setState({deviceInfo:deviceInfoArray})
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.hnsLocationId !== this.props.hnsLocationId) {
            const deviceInfoArray = [{
                name: this.props.deviceData.name,
                productCategory: this.props.deviceData.productCategory,
                parentSite: this.props.deviceData.psiteid,
                nocId: this.props.deviceData.nocid
              }]
            this.props.devicesSameLocationData.forEach((item) => {
                let i={
                    name: item.name,
                    productCategory:item.productCategory,
                    parentSite:item.psiteid,
                    nocId:item.nocid
                }
                deviceInfoArray.push(i)
            })
            this.setState({deviceInfo:deviceInfoArray})
        }
        if(prevState.deviceInfo!==this.state.deviceInfo) {
            const deviceInfo = this.state.deviceInfo
            deviceInfo.forEach((item) => {
                if(item.nocId === 'MERAKI' && item.productCategory === 'Router'){
                    this.getpingCurrentStatus(item.name)
                }
                else {
                    this.setState({
                        isMeraki:false,
                        pingCurrentStatusResponse:[]
                    })
                }
              })
        }
    }

    getpingCurrentStatus = (deviceName) => {
        let params = {device_name:deviceName}
        this.setState({isMeraki:true}, async () => {
            const response = await fetchClient.get('api/v1.0/action/device/pingOrCurrentStatusMeraki', {params:params})
            this.setState({
                deviceName:deviceName,
                // pingCurrentStatusResponse:response.data.results.device_status[0],
                pingCurrentStatusResponse:response.data.results,
                isMeraki:true
            })
        })
    }
    render() { 
        const deviceData = this.props.deviceData
        const deviceClassName = deviceData['className'];
        const{deviceName,pingCurrentStatusResponse,isMeraki} = this.state
        // let tableColumns = [
        //     {caption: 'Device Name', dataField: 'name'},
        //     {caption: 'Serial Number', dataField: 'serial'},
        //     {caption: 'Device Type', dataField: 'productType'},
        //     {caption: 'Device Model', dataField: 'model'},
        //     {caption: 'Mac Address', dataField: 'mac'},
        //     {caption: 'Public Ip Address', dataField: 'publicIp'},
        //     {caption: 'Last Reported' , dataField: 'lastReportedAt'},
        //     {caption: 'Status' , dataField: 'status'}
        // ]
        return ( 
            <div>
                {isMeraki? 
                    <div>
                        <h2> Meraki Device Information-{deviceName}</h2>
                        {/* <Card>
                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        {(pingCurrentStatusResponse) ?
                                            tableColumns.map(row => {
                                                const keyValue = row.dataField
                                                return (
                                                    <TableRow key={row.dataField} className={classes.tableRow}>
                                                        <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
                                                        <TableCell className={ classes.tableCell }>{pingCurrentStatusResponse[keyValue]}</TableCell>
                                                    </TableRow>
                                                );
                                            }) :
                                            tableColumns.map(row => {
                                                return (
                                                        <TableRow key={row.dataField} className={ classes.tableRow }>
                                                            <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
                                                            <TableCell className={ classes.tableCell }>Unknown</TableCell>
                                                        </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>                    
                            </CardContent>
                        </Card> */}
                    <PingOrCurrentStatusMerakiView raised={false} actionData={ pingCurrentStatusResponse } deviceClassName={deviceClassName} deviceName={deviceName}  title="Ping / Current Status" />
                    </div>:
                     null
                }
            </div>            
        );
    }
}

MerakiDeviceData.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
}; 
const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(MerakiDeviceData);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Fullscreen from "react-full-screen";
import compose from 'recompose/compose';
import { Fullscreen as FScreen, FullscreenExit, TimerOffOutline, TimerOutline,  Refresh } from 'mdi-material-ui';
import { LoadPanel } from 'devextreme-react/load-panel';
import FormControl from '@material-ui/core/FormControl';
import SelectBox from 'devextreme-react/select-box';
import fetchClient from "../../../axios";
//import * as constants from "../../../constants";
import SunBurst from '../../d3-charts/SunBurst';
//import NetworkChartData from '../../service-view-navigator/data1';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
	    height: '100%',
		padding: theme.spacing(1),
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	cardContentFull: {
	    height: '100vh',
		padding: theme.spacing(1),
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1.2rem',
	  //color: '#ffffff'
	},
    summaryViewIconButton: {
	    padding: 6
    },
    summaryViewActions: {
	    marginBottom: -6,
        marginTop: 3
    },
    networkTypeSelect: {
	    //zIndex: 2000
    }
});
const networkTypeList = [{label: 'All Devices', value: 'All_Devices'}, {label: 'All Datacenters', value: 'All_Datacenters'},
	{label: 'All Customers', value: 'All_Customers'}, {label: 'All Geo Providers', value: 'All_Geo_Providers'}, {label: 'All HRGWs', value: 'All_HRGWs'}];
class SummaryViewSvn extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
            isDataLoading: false,
			runTask: false,
            isFullScreen: false,
			networkType: networkTypeList[0]['value'],
			networkChartData: [],
			chartHeight: 370,
			chartWidth: 400
		};
		this.chartRef = React.createRef();
        this.dropDownOption = {}
	}
	componentWillMount() {
        this.getNetworkData();
    }
    componentDidMount() {
        const { runTask } = this.state;
        if (runTask) {
            clearInterval(this.summaryViewSvnTask);
            this.summaryViewSvnTask = setInterval(()=> this.getNetworkData(), 60000)
        }
    };
    componentWillUnmount () {
        clearTimeout(this.summaryViewSvnTask);
    };
    /**
     * getNetworkData:
     */
    getNetworkData = () => {
    	this.setState({isDataLoading: true});
    	const params = {network_type: this.state.networkType};
    	fetchClient.get('/api/v1.0/svn/network/chart', {params: params})
		.then(res => {
			let networkChartData = [];
				if (res.data) {
					networkChartData = res.data;
				}
				//console.log('networkChartData'+networkChartData)
				this.setState({
					networkChartData: networkChartData,
					isDataLoading: false
				});
		}).catch (() => {
				this.setState({
					networkChartData: [],
					isDataLoading: false
				});
			throw new Error('Data Loading Error');
		});
    };
    handleConfiguration = (e) => {
        const { runTask } = this.state;
        this.setState({runTask: !runTask});
        if (!runTask) {
            clearInterval(this.summaryViewSvnTask);
            this.summaryViewSvnTask = setInterval(()=> this.getNetworkData(), 60000)
        } else {
            clearInterval(this.summaryViewSvnTask);
        }
        e.component.option('text', runTask ? 'Start Timer' : 'Stop Timer');
        e.component.option('icon', runTask ? 'mdi mdi-timer-off-outline' : 'mdi mdi-timer-outline');
    };
    handleFullScreen = () => {
		this.setState({ isFullScreen: !this.state.isFullScreen }, () => { this.getNetworkData();});
    };
	handleNetworkTypeChange = (e) => {
        this.setState({networkType: e.value}, () => { this.getNetworkData(); });
    };
	setChartSize = () => {
	    //const isFullScreen = this.state;
		const height = this.chartRef.current.parentElement.offsetHeight;
		const width = this.chartRef.current.parentElement.offsetWidth;
		this.setState({ chartHeight: height, chartWidth: width });
	};
	render() {
		const { classes, raised } = this.props;
		const { runTask, isDataLoading, isFullScreen, networkChartData, networkType, chartHeight, chartWidth } = this.state;
		const title = 'Network Status';
		return (
            <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                <div className="full-screenable-node">
                    <Card className={classes.root} raised={raised}>
                        <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title}
                             action={
                                <div className={classes.summaryViewActions}>
									<FormControl>
                                        <SelectBox
                                           className={classes.networkTypeSelect}
										   dataSource={networkTypeList}
										   defaultValue={networkType}
										   selectedItem={networkType}
										   displayExpr='label'
										   valueExpr='value'
                                           width={150}
                                           elementAttr={{ id: 'summary-view-svn-select' }}
                                           onValueChanged={this.handleNetworkTypeChange}
                                           dropDownOptions={this.dropDownOption}
                                        />
                                    </FormControl>
                                    <IconButton className={classes.summaryViewIconButton} onClick={this.handleConfiguration} title={ runTask ? 'Stop Timer' : 'Start Timer'} >
                                        { runTask ? <TimerOutline/> : <TimerOffOutline /> }
                                    </IconButton>
                                    <IconButton className={classes.summaryViewIconButton} onClick={this.getNetworkData}>
                                     <Refresh/>
                                    </IconButton>
                                    <IconButton className={classes.summaryViewIconButton} onClick={this.handleFullScreen}>
                                        { isFullScreen ? <FullscreenExit /> : <FScreen /> }
                                    </IconButton>
                                </div>
                            }
                        />
                        <CardContent id="sunburstCahrtId" ref={this.chartRef} className={ isFullScreen ? classes.cardContentFull : classes.cardContent}>
							{ !isDataLoading && <SunBurst includeServiceDetails={false} data={networkChartData} width={ isFullScreen ? 800 : chartWidth } height={ isFullScreen ? 900 :  chartHeight } onZoomChanged={ () => {} }/> }
                        </CardContent>
						<LoadPanel
						  shadingColor="rgba(0,0,0,0.4)"
						  position={{of: '#sunburstCahrtId' }}
						  visible={isDataLoading}
						/>
                    </Card>
                </div>
            </Fullscreen>
			)
	}
}

SummaryViewSvn.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(SummaryViewSvn);
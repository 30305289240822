const hostname = window && window.location && window.location.hostname;
/*##################### DEVELOPMENT & STAGING ####################################################*/
if (hostname === 'localhost' || hostname === 'vmwpamdeploy1.nadops.net' || hostname === 'vmwpamabdev1.nadops.net' || hostname === 'vmwpamabdev2.nadops.net' || hostname === 'vmwpamabuat1.nadops.net'
|| hostname === 'vmwpamabuat2.nadops.net' || hostname === 'nmstoolsuat.hnops.net' ) {
    module.exports = Object.freeze({
        TASK_TIME: 30000,
        NMC_TASK_TIME: 30000,
        PERFWARDEN_REPORTS_URL: 'https://nmp-sandbox.hughes.net/perfwarden/reports',
        ACTIVE_PATH_REPORTS_URL: 'https://nmp-sandbox.hughes.net/activepath/reports',
        NMP_URL: 'https://nmp-preprod.hughes.net',
        MESH_REPORT_URL: 'https://phpapp.hughes.net/MESH/report.php',
        PROXY_SCRIPT_URL: 'https://luiproxy.hughes.net/proxyscript',
        GMAPS_KEY: "AIzaSyCyCVzh6l6FY4y7RcJQPzXCV2_mM1p8pmM",
        DATADOG_APPLICATION_ID: 'ee2d19dc-e396-48b8-8c36-8ccd319ab590',
        DATADOG_CLIENT_TOKEN: 'pube6cd60d8f7c94c87619c831662487cf3',
        DATADOG_ENV: 'uat',
        SIGN_IN_CONFIG: {
          baseUrl: 'https://echostar.oktapreview.com',
          clientId: '0oawm3r3b26FQbcE10h7',
          redirectUri: window.location.origin + '/login/callback',
          authParams: {
            scopes: ['openid', 'profile', 'email', 'offline_access', 'groups'],
            // If your app is configured to use the Implicit Flow
            // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
            // you will need to uncomment the below line
            pkce: false
          }
        },
        AUTH_CONFIG: {
          // Note: If your app is configured to use the Implicit Flow
          // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
          // you will need to add `pkce: false`
          issuer: 'https://echostar.oktapreview.com/oauth2/default',
          clientId: '0oawm3r3b26FQbcE10h7',
          redirectUri: window.location.origin + '/login/callback',
          scopes: ['openid', 'profile', 'email', 'offline_access', 'groups'],
          tokenManager: {
            autoRenew: true//,
            //expireEarlySeconds: 300 // 5 minutes early
          },
          pkce: false
        }
        /*SIGN_IN_CONFIG: {
          baseUrl: 'https://hugheson.oktapreview.com',
          clientId: '0oa2c2i54sHqpjD2R1d7',
          redirectUri: window.location.origin + '/login/callback',
          authParams: {
            scopes: ['openid', 'profile', 'email', 'offline_access', 'groups'],
            // If your app is configured to use the Implicit Flow
            // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
            // you will need to uncomment the below line
            pkce: false
          }
        },
        AUTH_CONFIG: {
          // Note: If your app is configured to use the Implicit Flow
          // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
          // you will need to add `pkce: false`
          issuer: 'https://hugheson.oktapreview.com/oauth2/default',
          clientId: '0oa2c2i54sHqpjD2R1d7',
          redirectUri: window.location.origin + '/login/callback',
          scopes: ['openid', 'profile', 'email', 'offline_access', 'groups'],
          tokenManager: {
            autoRenew: true//,
            //expireEarlySeconds: 300 // 5 minutes early
          },
          pkce: false
        }*/
        /* RR OKTA Dev */
        /*SIGN_IN_CONFIG: {
          baseUrl: 'https://dev-5210505.okta.com',
          clientId: '0oa42iqeqA4pL8d7y5d6',
          redirectUri: window.location.origin + '/login/callback',
          authParams: {
            scopes: ['openid', 'profile', 'email', 'groups'],
            // If your app is configured to use the Implicit Flow
            // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
            // you will need to uncomment the below line
            pkce: false
          }
        },
        AUTH_CONFIG: {
          // Note: If your app is configured to use the Implicit Flow
          // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
          // you will need to add `pkce: false`
          issuer: 'https://dev-5210505.okta.com/oauth2/default',
          clientId: '0oa42iqeqA4pL8d7y5d6',
          redirectUri: window.location.origin + '/login/callback',
          scopes: ['openid', 'profile', 'email', 'groups'],
          tokenManager: {
            autoRenew: true//,
            //expireEarlySeconds: 300 // 5 minutes early
          },
          pkce: false
        }*/
        /* RR OKTA Dev */
    });
}
/*##################### PRODUCTION ####################################################*/
if (hostname === 'vmwpamabprod1.nadops.net' || hostname === 'vmwpamabprod2.nadops.net' || hostname === 'nmstools.hnops.net' ) {
    module.exports = Object.freeze({
        TASK_TIME: 30000,
        NMC_TASK_TIME: 30000,
        PERFWARDEN_REPORTS_URL: 'https://nmp.hughes.net/perfwarden/reports',
        ACTIVE_PATH_REPORTS_URL: 'https://nmp.hughes.net/activepath/reports',
        NMP_URL: 'https://nmp.hugheson.com',
        MESH_REPORT_URL: 'https://phpapp.hughes.net/MESH/report.php',
        PROXY_SCRIPT_URL: 'https://luiproxy.hughes.net/proxyscript',
        GMAPS_KEY: "AIzaSyCyCVzh6l6FY4y7RcJQPzXCV2_mM1p8pmM",
        DATADOG_APPLICATION_ID: 'ee2d19dc-e396-48b8-8c36-8ccd319ab590',
        DATADOG_CLIENT_TOKEN: 'pube6cd60d8f7c94c87619c831662487cf3',
        DATADOG_ENV: 'prod',
        SIGN_IN_CONFIG: {
          baseUrl: 'https://echostar.okta.com',
          clientId: '0oaqp5cer5VM3JZPE0x7',
          redirectUri: window.location.origin + '/login/callback',
          authParams: {
            scopes: ['openid', 'profile', 'email', 'offline_access', 'groups'],
            pkce: false
          }
        },
        AUTH_CONFIG: {
          issuer: 'https://echostar.okta.com/oauth2/default',
          clientId: '0oaqp5cer5VM3JZPE0x7',
          redirectUri: window.location.origin + '/login/callback',
          scopes: ['openid', 'profile', 'email', 'offline_access', 'groups'],
          tokenManager: {
            autoRenew: true,
            //expireEarlySeconds: 300 // 5 minutes early
          },
          pkce: false
        }
    });
}
import cyan from '@material-ui/core/colors/cyan';
// import lightBlue from '@material-ui/core/colors/lightBlue';
// import blue from '@material-ui/core/colors/blue';
// import indigo from '@material-ui/core/colors/indigo';
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
//import grey from '@material-ui/core/colors/grey';
//import { fade } from '@material-ui/core/styles/colorManipulator';

const LightBaseTheme = {
		type: 'light',
		typography: {
			useNextVariants: true,
			//fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
		},
		//spacing: 8,
		palette: {
			type: 'light',
			//primary: blue,
			primary: {
				light: "#6893c6",
				main: "#4378b8",
				dark: "#2e5480",
				maindiv: "#D6EAF8"
			},
			/*secondary: {
				light: "#62efff",
				main: "#00bcd4",
				dark: "#008ba3"
			},*/
		    //secondary: indigo,
			secondary: purple,
		    error: red,
			contrastThresold: 3,
			tonalOffset: 0.2,
			action: {
				selected: cyan[500],
				//active: lightBlue[500]
			},
			rowBackgroundColor: '#f5f5f5'//grey[300]
	  	},
		mixins: {
		  toolbar: {
			  minHeight: 45,
			  '@media (min-width:600px)': {
				  minHeight: 45
			  }
		  },
		  cardHeader: {
			height: 45,
			secondHeight: 36
		  },
		  gridOffset: 106
		},
		overrides: {
			MuiButton: { // Name of the component ⚛️ / style sheet
				text: { // Name of the rule

				},
			},
			MuiTableRow: {
				root: {
					height: 36,
				},
				head: {
					height: 40,
				},
				footer: {
					height: 40,
				}
			},
			MuiTableCell: {
				body: {

				}
			}
		},
};

export default LightBaseTheme;
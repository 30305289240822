import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { Template } from 'devextreme-react/core/template';

import { DataGrid, RemoteOperations, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, Scrolling, Export } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import Fullscreen from "react-full-screen";

import fetchClient  from '../../../axios';
//import * as constants from '../../../constants';
import { textFormatter, epochDateFormatter } from "../../common/utils";
import NetworkEventsRowActionsMenu from './NetworkEventsRowActionsMenu';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: `${theme.spacing.unit} !important`,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

const NetworkEventsGridColumns = [
    { dataField: "name", caption: "Impacted Group", customizeText: textFormatter},
    { dataField: "impact", caption: "Impact", customizeText: textFormatter},
    { dataField: "caseId", caption: "Case ID", customizeText: textFormatter},
    { dataField: "startTime", caption: "Start Time", cellRender: epochDateFormatter, sortOrder: 'desc'}
];

class NetworkEvents extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            isFullScreen: false,
            activeEvents: true//,
            //gridHeight: '100vh'
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.renderGridTitle = this.renderGridTitle.bind(this);
        this.dataGrid = null;
        this.networkEventsGridRef = React.createRef();
    }
    componentWillMount() {
        //this.getNetworksData();
    }

    componentDidMount() {
        //const height = this.getNetworkEventsGridHeight();
        //this.setState({ gridHeight: height })
        //this.NetworksTask = setInterval(()=> this.getNetworksData(), constants.TASK_TIME)
    };
    componentWillUnmount () {
        //clearTimeout(this.NetworksTask);
    };
    refreshDataGrid () {
        this.dataGrid.instance.refresh();
    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
    renderGridTitle = () => {
        const { activeEvents } = this.state;
        const title = activeEvents ? 'Active Network Events' : 'Network Events';
        return (
            <div>
                <Typography variant={"h5"} component={"h5"}>{title}</Typography>
            </div>)
    };
    onRowPrepared = (e) => {
        if(e.rowType === 'data') {
            e.rowElement.style.backgroundColor = '#FF0000';
            e.rowElement.style.color = '#FFFFFF';
        }
    };
    handleActiveEvents = (e) => {
        this.setState({activeEvents: !this.state.activeEvents}, ()=> { this.refreshDataGrid() });
    };
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onToolbarPreparing = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, exportButton, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                        exportButton = item;
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push({
                    location: 'before',
                    template: 'gridTitleTemplate'
                },
                {
                    widget: 'dxCheckBox',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        //icon: 'mdi mdi-refresh',
                        hint: 'Active Events',
                        text: 'Active Events',
                        value: this.state.activeEvents,
                        onValueChanged: this.handleActiveEvents.bind(this)
                    }
                }, searchPanel , exportButton, columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                }, {
                    location: 'after',
                    locateInMenu: 'never',
                    widget: 'dxButton',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: this.state.isFullScreen ? 'mdi mdi-fullscreen-exit' : 'mdi mdi-fullscreen',
                        hint: this.state.isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
                        text: this.state.isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
                        onClick: this.handleFullScreen.bind(this)
                    }
                });
    };
    renderNetworkEventsActionsMenu = (cellInfo) => {
        return <NetworkEventsRowActionsMenu rowData={cellInfo.data} />
    };
    getNetworkEventsGridHeight = () => {
        return this.networkEventsGridRef.current.offsetHeight;
    };
    render(){
        const { classes, raised=false, isFullScreen, gridHeight } = this.props;
        const { activeEvents } = this.state;
        const networkEventsDataStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                        let params = { active: activeEvents };
                        if (loadOptions.sort) {
                            params.sort = loadOptions.sort[0].selector;
                            params.order = loadOptions.sort[0].desc ? 'desc' : 'asc';
                        }
                        params.offset = loadOptions.skip ? loadOptions.skip : 0;
                        params.limit = loadOptions.take ? loadOptions.take : 10;
                        return fetchClient.get('/api/v1.0/es/network-events', {params: params})
                            .then(res => {
                                let NetworkEventsData = [];
                                let NetworkEventsDataTotal = 0;
                                if (res.data && res.data.data) {
                                    NetworkEventsData = res.data.data;
                                    NetworkEventsDataTotal = res.data.total;
                                }
                                return {
                                    data: NetworkEventsData,
                                    totalCount: NetworkEventsDataTotal
                                };
                            })
                            .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
        return (
           <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
            <div className="full-screenable-node">
                <Card ref={this.networkEventsGridRef} className={classes.root} raised={raised}>
                    <DataGrid id={'NetworkEventsGrid'}
                        ref={(ref) => this.dataGrid = ref}
                        height={isFullScreen? '100%' : gridHeight }
                        dataSource={networkEventsDataStore}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        rowAlternationEnabled={false}
                        repaintChangesOnly={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={'widget'}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        wordWrapEnabled={false}
                        //columnWidth={100}
                        //columnMinWidth={70}
                        cellHintEnabled={true}
                        onContentReady={this.onContentReady}
                        onToolbarPreparing={this.onToolbarPreparing}
                        onRowPrepared={this.onRowPrepared}
                        >
                        <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                        <ColumnChooser enabled={true} mode={'select'}/>
                        <Scrolling showScrollbar={'always'} useNative={false} />
                        <LoadPanel enabled={true}/>
                        <Export enabled={true} fileName={'ActiveNetworkEvents'} />
                        <Column type="button" caption="Actions" alignment="center" width={70} cellRender={this.renderNetworkEventsActionsMenu} />
                        {
                            NetworkEventsGridColumns.map(row => {
                              return (
                                // eslint-disable-next-line
                                <Column dataField={row.dataField} caption={row.caption} width={row.width} alignment={row.alignment} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)} fixed={row.fixed} sortOrder={row.sortOrder}/>
                              );
                            })
                        }
                        <RemoteOperations sorting={true} paging={true} />
                        <Paging defaultPageSize={10}/>
                        <Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20, 40, 50, 100]}/>
                        <Template name="gridTitleTemplate" render={this.renderGridTitle}/>
                    </DataGrid>
                </Card>
            </div>
           </Fullscreen>
        )
    }
}

NetworkEvents.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(NetworkEvents);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardContent from '@material-ui/core/CardContent';
// import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import moment from 'moment';
import compose from 'recompose/compose';
import { DataGrid, RemoteOperations, Paging, Pager, Column, MasterDetail, Scrolling, ColumnChooser, LoadPanel,
    Export, SearchPanel } from 'devextreme-react/data-grid';
//import PieChart, { LoadingIndicator, Series, Label, Connector, Size, Font, Legend, Tooltip } from 'devextreme-react/pie-chart';
import Chart, { Series, Label, Size, Legend, Tooltip, CommonSeriesSettings, CommonAxisSettings } from 'devextreme-react/chart';
//import { Item } from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
//import { formatDate } from 'devextreme/localization';
//import axios from 'axios';
import fetchClient  from '../../axios';
//import * as constants from '../../constants';
import HughesDatacentersTemplateView from './HughesDatacentersTemplateView';
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import lightBlue from "@material-ui/core/colors/lightBlue";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});
const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;
class HughesOverview extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            pageSizes: [5, 10, 15, 25, 50, 100],
            limit: 100,
            offset: 0,
            sort: 'dttm',
            order: 'desc'
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.renderSparkLineChart = this.renderSparkLineChart.bind(this);
        this.dataGrid = null;
    }

    componentWillMount() {

    }

    componentDidMount() {

    };
    componentWillUnmount () {

    };
    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }
    onRowPrepared(e) {

    };
    onToolbarPreparing(e) {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, exportButton, columnChooserPanel, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                if(item.name === 'searchPanel') {
                    items.splice(i, 1);
                    searchPanel = item;
                }
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'exportButton') {
                    items.splice(i, 1);
                    exportButton = item;
                }
            }
            toolbarItems.push(searchPanel, exportButton, columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    }
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onCellPrepared = (e) => {
      /*if(e.rowType === 'header' && e.column.command === 'edit') {
          e.cellElement.textContent='Actions';
      }*/
    };
    customizeText = (point) => {
          return `${point.percentText}`;
    };
    chartTooltipTemplate = (info) => {
        let count = 0;
        if(info['point'] && info['point']['data'] && info['point']['data'][`${info.seriesName}_counts`]) {
            count = info['point']['data'][`${info.seriesName}_counts`]
        }
      return (
        <div className="state-tooltip">
          <div>
            <span className="">{_.capitalize(info.seriesName)}</span>: {info.percentText}
          </div>
          <div>
            <span className="">Count</span>: {formatNumber(count)}
          </div>
        </div>
      );
    };
    customizePoint = (point) => {
		//switch (point.argument) {
        switch (point.seriesName) {
			case 'Critical':
				return { color: red[500]};
			case 'Major':
				return { color: orange[500]};
			case 'Minor':
				return { color: yellow[500]};
			case 'Unknown':
				return { color: lightBlue[500]};
			case 'Normal':
				return { color: green[500]};
            default:
                //None
		}
	};
    customizeLabel = (arg) => {
        if (arg.value > 20) {
            return {
                visible: true,
                position: 'inside',
                customizeText: function(e) {
                    return `${ formatNumber(e.valueText) }%`
                }
            }
        }
    };
    renderSparkLineChart = (cellInfo) => {
        let states = cellInfo['data']['device_count_by_state'];
        let mStates = [];
        let rObj = {};
        // eslint-disable-next-line
        states.map(obj => {
            rObj[obj['label']] = obj['percent'];
            rObj[`${obj['label']}_counts`] = obj['value'];
            rObj['label'] = 'Status';
        });
        mStates.push(rObj);
        return (
                <Chart ref={ this.dataCentersChartRef }
                        id='hughes-overview-chart'
                        dataSource={ mStates }
                        rotated={true}
                        customizePoint={this.customizePoint}
                        customizeLabel={this.customizeLabel}
                    >
                    <CommonAxisSettings grid={{visible: false}} tick={{ visible: false }} visible={false} label={{ visible: false}}/>
                    <CommonSeriesSettings argumentField='label' type='fullstackedbar' minBarSize={2}>
                        <Label visible={false} font={{size: 11}} showForZeroValues={true}/>
                    </CommonSeriesSettings>
                    <Series valueField='Critical' name='Critical' />
                    <Series valueField='Major' name='Major' />
                    <Series valueField='Minor' name='Minor' />
                    <Series valueField='Unknown' name='Unknown' />
                    <Series valueField='Normal' name='Normal' />
                    <Legend visible={false}/>
                    <Tooltip enabled={true} contentRender={this.chartTooltipTemplate} cornerRadius={4} zIndex={3000}/>
                    <Size width={200} height={40} />
                </Chart>
        )
    };
render() {
        const { classes, theme, raised } = this.props;
        const { pageSize, pageSizes } = this.state;
        const hughesViewStore = {
            store: new CustomStore({
                key: 'location_id',
                load: (loadOptions) => {
                        let params = {'start_timestamp': Math.round(moment().subtract(10, 'minutes').valueOf()/1000), 'end_timestamp': Math.round(moment().valueOf()/1000)};

                        //return fetchClient.get('/api/v1.0/svn/svi_hughes_locations', { params: params })
                        return fetchClient.get('/api/v1.0/svn/svi_hughes_locations_hierarchy', { params: params })
                            .then(res => {
                                let hughesLocations = [];
                                let hughesLocationsTotal = 0;
                                if (res.data && res.data.data) {
                                    hughesLocations = res.data.data;
                                    hughesLocationsTotal = res.data.total;
                                }
                                //return hughesLocations
                                // return {
                                //     data: hughesLocations.slice(0,2)
                                // };
                                return {
                                    data: hughesLocations,
                                    totalCount: hughesLocationsTotal
                                };
                            })
                            .catch ((e) => { throw new Error(`Data Loading Error, ${e}`);});
                }
            })
        };
        return (
            <Card className={classes.root} raised={raised}>
                {/*<CardHeader className={classes.cardHeader} title={title} />*/}
                <DataGrid id={'hughesViewGrid'}
                    ref={(ref) => this.dataGrid = ref}
                    height={`calc(100vh - ${theme.mixins.gridOffset}px)`}
                    dataSource={hughesViewStore}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    repaintChangesOnly={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    columnMinWidth={70}
                    cellHintEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onContentReady={this.onContentReady}
                    onCellPrepared={this.onCellPrepared}
                    onRowPrepared={this.onRowPrepared}
                    >
                    <MasterDetail
                        enabled={true}
                        component={HughesDatacentersTemplateView}
                    />
                    <SearchPanel visible={true} width={200} placeholder={'Search for hughes locations'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    <Export enabled={true} fileName={'svi_overview'} />
                    <Column dataField='name' caption='Name' dataType='string' sortOrder='asc' validationRules={[{ type: 'required'}]} width={200}  />
                    {/*<Column dataField='device_count' caption='Total Devices' dataType='number' format={{ type: 'fixedPoint', precision:0 }}  />*/}
                    <Column dataField='active' caption='Active' dataType='string' width={150} />
                    <Column dataField="State" caption="Status" cellRender={this.renderSparkLineChart} alignment="center" width={215}/>
                    <Column dataField='street' caption='Street' dataType='string'  />
                    <Column dataField='suit' caption='Suit' dataType='string'  />
                    <Column dataField='city' caption='City' dataType='string'  />
                    <Column dataField='state' caption='State' dataType='string'  />
                    <Column dataField='zip' caption='Zip' dataType='string'  />
                    <Column dataField='country' caption='Country' dataType='string'  />
                    <Column dataField='longitude' caption='Longitude' dataType='number'  />
                    <Column dataField='latitude' caption='Latitude' dataType='number'  />
                    <Column dataField='primary_poc' caption='Primary POC' dataType='string'  />
                    <Column dataField='secondary_poc' caption='Secondary POC' dataType='string'  />
                    <Column dataField='primary_phone' caption='Primary Phone' dataType='string'  />
                    <Column dataField='secondary_phone' caption='Secondary Phone' dataType='string'  />
                    <Column dataField='owner_company' caption='Company Owner' dataType='string'  />
                    <Column dataField='contact_info' caption='Contact Info' dataType='string'  />
                    <Column dataField='t19v_acronym' caption='T19V Acronym' dataType='string' />
                    <Column dataField='supporting_company' caption='Supporting Company' dataType='string' />
                    <Column dataField='local_facility_contact' caption='Local Facility Contact' dataType='string' width={150} />
                    <Column dataField='other_shared_services_platforms' caption='Othe Shared Services or Platforms' dataType='string' width={150} />
                    <Column dataField='support_differences' caption='Support Differences' dataType='string' width={150} />
                    <Column dataField='is_osa_established' caption='is OSA Established?' dataType='boolean' />
                    <Column dataField='date_of_production' caption='Date of Production' dataType='date' />
                    <Column dataField='launch_ticket_no' caption='Launch Ticket #' dataType='string' />
                    <Column dataField='noc_contact' caption='NOC Contact' dataType='string' width={150} />
                    <Column dataField='management_escalation' caption='Management Escalation' dataType='string' width={150} />
                    <Column dataField='is_facility_manned' caption='is Facility Manned?' dataType='string' width={150} />
                    <Column dataField='hours_of_availability' caption='Hours of Availability' dataType='string' />
                    <Column dataField='access_instructions' caption='Access Instructions' dataType='string' width={150} />
                    <Column dataField='escalation_notes' caption='Escalation Notes' dataType='string' width={150} />
                    <Column dataField='ticket_creation_method' caption='Ticket Creation Method' dataType='string' width={150} />
                    <Column dataField='login_info' caption='Login Info' dataType='string' width={150} />
                    <Column dataField='backhaul_info' caption='Backhaul Info' dataType='string' width={150} />
                    <Column dataField='shipment_instructions' caption='Shipment Instructions' dataType='string' width={150} />
                    <Column dataField='tog_physical_location' caption='TOG Physical Location' dataType='string' width={150} />
                    <Column dataField='notes' caption='Notes' dataType='string'  width={150} />
                    <RemoteOperations sorting={false} paging={false} />
                    <Paging defaultPageSize={pageSize}/>
                    <Pager visible={true} showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={pageSizes}/>
                </DataGrid>
            </Card>
        )
    }
}

HughesOverview.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(HughesOverview);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import { LoadPanel } from 'devextreme-react/load-panel';
import FormControl from '@material-ui/core/FormControl';
import SelectBox from 'devextreme-react/select-box';
import fetchClient from "../../axios";

const styles = theme => ({
	root: {
	  width: '100%',
      height: '100%'
	},
	cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
	    height: '100%',
		border: '1px solid rgba(224, 224, 224, 1)',
		padding: theme.spacing(2),
		'&:last-child': {
			paddingBottom: theme.spacing(2)
		}
	},
	apChart: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	headerTitle: {
	  fontSize: '1.2rem'
	},
	startTimeSelect: {

	},
    chartViewActions: {
	    marginBottom: -6,
        marginTop: 3
    }
});
const startTimeList = [{label: 'Last 2 Hours', value: '7200'}, {label: 'Last 24 Hours', value: '86400'}, {label: 'Last 5 Days', value: '604800'},];
class ApGraph extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
            isDataLoading: false,
			startTime: startTimeList[0]['value'],
			apChart: ''
		};
	}
	componentWillMount() {
        this.getApChart();
    }
    componentDidMount() {
		//this.getApChart()
    };
    componentWillUnmount () {

    };
    /**
     * getApChart:
     */
    getApChart = () => {
    	const { graph_type, deviceData } = this.props;
    	const { startTime } = this.state;
    	this.setState({isDataLoading: true});
    	const params = {
    		graph_type: graph_type,
			noc_id: deviceData['nocid'],
			ap_id: deviceData['userdef1'],
			start_time: startTime
    	};
		fetchClient({
			method: 'get',
			url: '/api/v1.0/action/device/graph/ap',
			responseType: 'blob',
			params: params,
		}).then(res => {
			let apChart = '';
				if (res.data) {
					apChart = URL.createObjectURL(res.data)
				}
				this.setState({
					apChart: apChart,
					isDataLoading: false
				});
		}).catch (() => {
				this.setState({
					apChart: '',
					isDataLoading: false
				});
			throw new Error('Data Loading Error');
		});
    };
	handleStartTimeChange = (e) => {
        this.setState({startTime: e.value}, () => { this.getApChart(); });
    };
	render() {
		const { classes } = this.props;
		const {  isDataLoading,  apChart, startTime } = this.state;
		//const title = 'Network Status';
		return (
				<Card className={classes.root} >
					<CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={""}
						 action={
							<div className={classes.chartViewActions}>
								<FormControl>
									<SelectBox
									   className={classes.startTimeSelect}
									   dataSource={startTimeList}
									   defaultValue={startTime}
									   selectedItem={startTime}
									   displayExpr='label'
									   valueExpr='value'
									   width={150}
									   onValueChanged={this.handleStartTimeChange}
									/>
								</FormControl>
							</div>
						}
					/>
					<CardContent className={ classes.cardContent }>
						{ !isDataLoading && <div><img alt="" className={classes.apChart} src={apChart} /></div> }
					</CardContent>
					<LoadPanel
					  shadingColor="rgba(0,0,0,0.4)"
					  position={{my: 'center', at: 'center'}}
					  visible={isDataLoading}
					/>
				</Card>
			)
	}
}

ApGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(ApGraph);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import DataGrid from 'devextreme-react/data-grid';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	card1: {
	  //width: '100%',
      margin: theme.spacing(1),
	},
	cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		overflow: 'auto',
		padding: 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1.2rem',
	  //color: '#ffffff'
	},
    summaryViewIconButton: {
	    padding: 6
    },
    summaryViewActions: {
	    marginBottom: -6,
        marginTop: 3
    },
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.80rem'
	},
	tableRow: {
		height: 25,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default
		}
	}
});
class PingIpView extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {};
	}
	componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
	render() {
		const { classes, raised, title, actionData }  = this.props;
        const ping_stats = actionData && actionData['ping_stats'] ? actionData['ping_stats'] : [];
        const icmp_replies = actionData && actionData['icmp_replies'] ? actionData['icmp_replies'] : [];
        const icmp_columns = [
        	{dataField:'destination', caption: 'Destination'},
			{dataField:'icmp_seq', caption: 'ICMP Sequence'},
			{dataField:'bytes', caption: 'Bytes'},
			{dataField:'ttl', caption: 'TTL'},
			{dataField:'time', caption: 'Time'},
			{dataField:'duplicate', caption: 'Duplicate'}
		];
		return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
                    <CardContent className={ classes.cardContent } >
                     <Table size="small">
                         <TableBody stripedRows>
                             { ping_stats ?
                                  _.map(ping_stats, function (value, key) {
                                      return (
                                        <TableRow key={key} className={ classes.tableRow }>
                                          <TableCell className={ classes.tableCell } component="head" scope="row">{key}</TableCell>
                                          <TableCell className={ classes.tableCell }>{value}</TableCell>
                                        </TableRow>
                                      );
                                 })
                                 : ""
                             }
                         </TableBody>
                     </Table>
						 { icmp_replies &&
							 <Card className={classes.card1}>
							  <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'ICMP Replies'}/>
							  <DataGrid
								dataSource={icmp_replies}
								columns={icmp_columns}
								showBorders={true}
							 />
							</Card>
						 }
                    </CardContent>
                </Card>
			)
	}
}

PingIpView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(PingIpView);
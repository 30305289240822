import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { DataGrid, Column, Scrolling } from 'devextreme-react/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import fetchClient from '../../axios';

class NICDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            nic_details: [],
            message:'',
            loading: false
        }
    }

    stateRender = (stat) => {
        let val = stat.data.status;
		let state, label;
		switch (val) {
			case 'up':
				state = 'normal';
				label = 'up';
				break;
			case 'down':
				state = 'critical';
				label = 'down';
				break;
            default:
                state = 'unknown';
                label = val;
                break;
		}
		return (<span className={"label label-status alert-"+state}>{label}</span>)
	};

    adminStateRender = (stat) => {
        let val = stat.data.admin;
        let state, label;
        switch (val) {
            case 'up':
                state = 'normal';
                label = 'up';
                break;
            case 'down':
                state = 'critical';
                label = 'down';
                break;
            default:
                state = 'unknown';
                label = val;
                break;
        }
        return (<span className={"label label-status alert-"+state}>{label}</span>)
    }

    async componentDidMount() {
        await this.getNicInfo(this.props.deviceName);
    }

    async componentDidUpdate(prevProps, prevState) {
        if(prevProps.deviceName !== this.props.deviceName && prevProps.hnsLocationId !== this.props.hnsLocationId) {
            await this.getNicInfo(this.props.deviceName)
        }
    }

    getNicInfo = (deviceName) => {
        let params = {device_name:deviceName}
        this.setState({loading:true}, async () => {
            const response = await fetchClient.get('api/v1.0/action/device/diagHardwareNicInfo', {params:params});
            if(response.data.results){
                this.setState({
                    nic_details:response.data.results.data,
                    message:'',
                    loading: false
                });
            }
            else if(response.data.note) {
                this.setState({
                    nic_details: [],
                    message: response.data.note,
                    loading: false
                })
            }
        }); 
    }
    render() { 
        const{nic_details, loading} = this.state
		const title = 'NIC';
        let arpColumns = [
            {dataField:'interface', caption:'Interface'},
            {dataField:'admin', caption:'Admin Status',cellRender:this.adminStateRender},
            {dataField:'status', caption:'Status', cellRender:this.stateRender}
        ]
        if(this.state.message===''){
            return (
                <Card>
                    <CardHeader  title={title} />
                    <CardContent >
                        <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                                }}>
                            {loading? <CircularProgress /> : <div>
                            <DataGrid 
                                          dataSource={nic_details}
                                          showColumnLines={true}
                                          showRowLines={true}
                                          showBorders={true}
                                          rowAlternationEnabled={true}
                                          repaintChangesOnly={true}
                                          allowColumnReordering={true}
                                          allowColumnResizing={true}
                                          columnResizingMode={'widget'}
                                          columnAutoWidth={true}
                                          wordWrapEnabled={false}
                                          columnMinWidth={50}
                                          cellHintEnabled={true}
                                          noDataText={'No NIC informaion available to display'}
                                >
                                    <Scrolling showScrollbar={'always'} useNative={false}/>
                                    {
                                        arpColumns.map(row => {
                                            return (
                                                // eslint-disable-next-line
                                                <Column dataField={row.dataField} caption={row.caption} alignment={row.alignment} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)}/>
                                            );
                                        })
                                    }
                                </DataGrid>
                                                            </div>}
                        </div>
                    </CardContent>
                </Card>
            );
        }
        else {
            return (
                // <Card>
                //     <CardHeader  title={title} />
                //     <CardContent >
                //         <div style={{
                //                 display: "flex",
                //                 justifyContent: "center",
                //                 alignItems: "center"
                //                 }}>
                //             {loading? <CircularProgress /> : <div>
                //                 <p>{message}</p>
                //                                             </div>}
                //         </div>
                //     </CardContent>
                // </Card>
                null
            )

        }
    }
}
 
export default NICDetails;
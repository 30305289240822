import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import Admin from '../components/admin/Admin';
import compose from "recompose/compose";

const styles = theme => ({
	  root: {
	    height: '100%'
	  }
	});


class AdminContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		    hasErrors: false,
			userInfo: null,
			groups: []
		};
		this.getCurrentUser = this.getCurrentUser.bind(this);
	};
    async getCurrentUser () {
		const userInfo = await this.props.oktaAuth.getUser();
		const groups = userInfo['groups'];
        this.setState({ userInfo, groups });
    }
    componentDidMount() {
		this.getCurrentUser();
    };
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Error: '+info);
    };
	render() {
		const { classes } = this.props;
		const { hasErrors, userInfo, groups } = this.state;
		return (
				<div className={classes.root}>
					{hasErrors ?
						<div>Page has erros, please fix!!!</div>
					:
						<Admin raised={true} userInfo={userInfo} groups={groups} />
					}
				</div>
		)
	}
}
AdminContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default withOktaAuth(withRouter(enhance(AdminContainer)));
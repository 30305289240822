import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
import { DataGrid, RemoteOperations, Paging, Pager, Column, Editing, Scrolling, ColumnChooser, LoadPanel,
    Export, SearchPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import fetchClient  from '../../axios';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

class HughesLocations extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            pageSizes: [5, 10, 15, 25, 50, 100],
            limit: 100,
            offset: 0,
            sort: 'dttm',
            order: 'desc',
            gridColumns: [
                //{dataField: 'location_id', caption: 'Location ID', dataType: 'number', visible: false },
                {dataField: 'name', caption: 'Name', dataType: 'string', sortOrder:'asc', validationRules: [{ type: 'required'}], width: 100 },
                {dataField: 'street', caption: 'Street', dataType: 'string' },
                {dataField: 'suit', caption: 'Suit', dataType: 'string' },
                {dataField: 'city', caption: 'City', dataType: 'string' },
                {dataField: 'state', caption: 'State', dataType: 'string' },
                {dataField: 'zip', caption: 'Zip', dataType: 'string' },
                {dataField: 'country', caption: 'Country', dataType: 'string' },
                {dataField: 'longitude', caption: 'Longitude', dataType: 'number' },
                {dataField: 'latitude', caption: 'Latitude', dataType: 'number' },
                {dataField: 'primary_poc', caption: 'Primary POC', dataType: 'string' },
                {dataField: 'secondary_poc', caption: 'Secondary POC', dataType: 'string' },
                {dataField: 'primary_phone', caption: 'Primary Phone', dataType: 'string' },
                {dataField: 'secondary_phone', caption: 'Secondary Phone', dataType: 'string' },
                {dataField: 'owner_company', caption: 'Company Owner', dataType: 'string' },
                {dataField: 'contact_info', caption: 'Contact Info', dataType: 'string', formItem: {editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 't19v_acronym', caption: 'T19V Acronym', dataType: 'string' },
                {dataField: 'supporting_company', caption: 'Supporting Company', dataType: 'string' },
                {dataField: 'local_facility_contact', caption: 'Local Facility Contact', dataType: 'string', width: 150 },
                {dataField: 'other_shared_services_platforms', caption: 'Othe Shared Services or Platforms', dataType: 'string', width: 150 },
                {dataField: 'support_differences', caption: 'Support Differences', dataType: 'string', width: 150 },
                {dataField: 'is_osa_established', caption: 'is OSA Established?', dataType: 'boolean' },
                {dataField: 'date_of_production', caption: 'Date of Production', dataType: 'date' },
                {dataField: 'launch_ticket_no', caption: 'Launch Ticket #', dataType: 'string' },
                {dataField: 'noc_contact', caption: 'NOC Contact', dataType: 'string', width: 150, formItem: {editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'management_escalation', caption: 'Management Escalation', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'is_facility_manned', caption: 'is Facility Manned?', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'hours_of_availability', caption: 'Hours of Availability', dataType: 'string' },
                {dataField: 'access_instructions', caption: 'Access Instructions', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'escalation_notes', caption: 'Escalation Notes', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'ticket_creation_method', caption: 'Ticket Creation Method', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'login_info', caption: 'Login Info', dataType: 'string' },
                {dataField: 'backhaul_info', caption: 'Backhaul Info', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'shipment_instructions', caption: 'Shipment Instructions', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'tog_physical_location', caption: 'TOG Physical Location', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} },
                {dataField: 'notes', caption: 'Notes', dataType: 'string', width: 150, formItem: { editorType: 'dxTextArea', editorOptions: {height: 100}} }
            ]
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.dataGrid = null;
    }

    componentWillMount() {

    }

    componentDidMount() {

    };
    componentWillUnmount () {

    };
    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }
    onRowPrepared(e) {

    };
    onToolbarPreparing(e) {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, exportButton, columnChooserPanel, addRowButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                if(item.name === 'searchPanel') {
                    items.splice(i, 1);
                    searchPanel = item;
                }
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'addRowButton') {
                    items.splice(i, 1);
                    addRowButton = item;
                    addRowButton.options.type = 'default';
                }
                if(item.name === 'exportButton') {
                    items.splice(i, 1);
                    exportButton = item;
                }
            }
            toolbarItems.push(searchPanel, addRowButton, exportButton, columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    }
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onCellPrepared = (e) => {
      /*if(e.rowType === 'header' && e.column.command === 'edit') {
          e.cellElement.textContent='Actions';
      }*/
    };
render() {
        const { classes, theme, raised } = this.props;
        const { pageSize, pageSizes, limit, offset, sort, order, gridColumns } = this.state;
        //const title = 'Hughes Locations';
        const HughesLocationsStore = {
            store: new CustomStore({
                key: 'location_id',
                load: (loadOptions) => {
                    let params = { limit, offset, sort, order };
                        if (loadOptions.sort) {
                            params.sort = loadOptions.sort[0].selector;
                            params.order = loadOptions.sort[0].desc ? 'desc' : 'asc';
                        }
                        params.offset = loadOptions.skip || 0;
                        params.limit = loadOptions.take || 100;
                        //return fetchClient.get('/api/v1.0/svn/svi_hughes_locations', { params: params })
                        return fetchClient.get('/api/v1.0/svn/svi_hughes_locations')
                            .then(res => {
                                let hughesLocations = [];
                                let hughesLocationsTotal = 0;
                                if (res.data && res.data.data) {
                                    hughesLocations = res.data.data;
                                    hughesLocationsTotal = res.data.total;
                                }
                                //return hughesLocations
                                // return {
                                //     data: hughesLocations.slice(0,2)
                                // };
                                return {
                                    data: hughesLocations,
                                    totalCount: hughesLocationsTotal
                                };
                            })
                            .catch ((e) => { throw new Error(`Data Loading Error, ${e}`);});
                },
                insert: (values) => {
                    return fetchClient.post('/api/v1.0/svn/svi_hughes_locations', values)
                        .then(res => {

                        })
                        .catch ((e) => { throw new Error(`Not able to create, ${e}`);});
                },
                update: (key, values) => {
                    return fetchClient.put('/api/v1.0/svn/svi_hughes_locations/'+key, values)
                        .then(res => {

                        })
                        .catch ((e) => { new Error(`Not able to update, ${e}`);});
                },
                remove: (key) => {
                    return fetchClient.delete('/api/v1.0/svn/svi_hughes_locations/'+key)
                        .then(res => {

                        })
                        .catch ((e) => { new Error(`Not able to delete, ${e}`);});
                }
            })
        };
        return (
            <Card className={classes.root} raised={raised}>
                {/*<CardHeader className={classes.cardHeader} title={title} />*/}
                <DataGrid id={'hughesLocationsGrid'}
                    ref={(ref) => this.dataGrid = ref}
                    height={`calc(100vh - ${theme.mixins.gridOffset}px)`}
                    dataSource={HughesLocationsStore}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    repaintChangesOnly={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    columnMinWidth={70}
                    cellHintEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onContentReady={this.onContentReady}
                    onCellPrepared={this.onCellPrepared}
                    onRowPrepared={this.onRowPrepared}
                    >
                    <SearchPanel visible={true} width={200} placeholder={'Search for hughes locations'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    <Editing
                        mode={'popup'}
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true} >
                    </Editing>
                    <Export enabled={true} fileName={'svi_hughes_locations'} />
                    <Column caption={'Actions'} type={'buttons'} buttons={['edit', 'delete']} />
                    {
                        gridColumns.map(row => {
                          return (
                            <Column dataField={row.dataField} caption={row.caption} visible={row.visible} dataType={row.dataType} sortOrder={row.sortOrder}
                                    validationRules={row.validationRules} formItem={row.formItem} width={row.width} />
                          );
                        })
                    }
                    <RemoteOperations sorting={false} paging={false} />
                    <Paging defaultPageSize={pageSize}/>
                    <Pager visible={true} showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={pageSizes}/>
                </DataGrid>
            </Card>
        )
    }
}

HughesLocations.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(HughesLocations);
import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { DotsHorizontal } from 'mdi-material-ui';
import PropTypes from "prop-types";
import compose from "recompose/compose";

const styles = theme => ({
    button: {
        padding: 0,
        color: '#03A9F4'
    }
});

class DeviceSearchRowActionsMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            anchorEl: null
        };
        this.handleShowEvents = this.handleShowEvents.bind(this);
        this.handleShowUserCounts = this.handleShowUserCounts.bind(this);
    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleShowEvents = () => {
        const deviceData = this.props.rowData;
        this.props.actionsMenuHandler('deviceEvents', deviceData, `Events for ${deviceData.name}`)
    };
    handleShowUserCounts = () => {
        const deviceData = this.props.rowData;
        this.props.actionsMenuHandler('userCounts', deviceData, `User Counts for ${deviceData.name}`)
    };
  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    return (
          <Fragment>
              <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClick}
                className={classes.button} >
                <DotsHorizontal />
              </IconButton>
               <Menu
                 id="simple-menu"
                 anchorEl={anchorEl}
                 open={Boolean(anchorEl)}
                 onClose={() => this.setState({ anchorEl: null })}
               >
                   <MenuItem key="events" onClick={this.handleShowEvents}>Show Events</MenuItem>
                   <MenuItem key="showUserCounts"  onClick={this.handleShowUserCounts}>Show User Counts</MenuItem>
                </Menu>
          </Fragment>
    );
  }
}

DeviceSearchRowActionsMenu.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(DeviceSearchRowActionsMenu);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import DataGrid from 'devextreme-react/data-grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import _ from 'lodash';

const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
        maxHeight: '400px',
        height: '400px',
        overflow: 'auto',
        padding: 0,
        '&:last-child': {
            paddingBottom: theme.spacing(1)
        }
    },
    headerTitle: {
      fontSize: '1.2rem',
      //color: '#ffffff'
    },
    summaryViewIconButton: {
        padding: 6
    },
    summaryViewActions: {
        marginBottom: -6,
        marginTop: 3
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableCellBold: {
        padding: '4px 16px',
        lineHeight: '0.80rem',
        fontWeight: 'bold',
        width: '300px'
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        fontWeight: 'bold',
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5'
    }
});
const dateFormat = 'MM/DD/YYYY hh:mm:ss A';
class CurrentClientStatsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {};
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    render() {
        const { classes, raised, title, actionData }  = this.props;
        const fortiap_columns = [
            {dataField:'ssid', caption: 'SSID'},
            {dataField:'radio_type', caption: 'Radio'},
            {dataField:'channel', caption: 'Channel'},
            {dataField:'bandwidth_rx', caption: 'Bandwidth RX'},
            {dataField:'bandwidth_tx', caption: 'Bandwidth TX'},
            {dataField:'association_time', caption: 'Connect Time'},
            {dataField:'sta_idle_time', caption: 'Idle Time'},
            {dataField:'sta_rate', caption: 'Client Rate'},
            {dataField:'host', caption: 'Client Hostname'},
            {dataField:'sta_ip', caption: 'Client IP Address'},
            {dataField:'sta_mac', caption: 'Client MAC Address'},
            {dataField:'noise', caption: 'Noise'},
            {dataField:'snr', caption: 'Signal Quality'},
            {dataField:'signal', caption: 'Signal Strength'},
            {dataField:'vendor', caption: 'Client Vendor'},
            {dataField:'vci', caption: 'Client Info'}];
        const fortilan_columns = [
            {dataField:'mac', caption: 'MAC Address'},
            {dataField:'ip', caption: 'IP Address'},
            {dataField:'hostname', caption: 'Hostname'},
            {dataField:'vendor', caption: 'Vendor'},
            {dataField:'ssid', caption: 'SSID'},
            {dataField:'auth', caption: 'Authentication'},
            {dataField:'band', caption: 'Band'},
            {dataField:'usageTotal', caption: 'Usage'},
            {dataField:'throughput', caption: 'Throughput'},
            {dataField:'vlan', caption: 'VLAN'},
            {dataField:'signal', caption: 'Signal'},
            {dataField:'snr', caption: 'Quality'},
            {dataField:'idleTime', caption: 'Idle'}];
        const deviceProps = [
            {fieldName: 'name', label: 'Device Name'},
            {fieldName: 'status', label: 'Status'},
            {fieldName: 'serial', label: 'Serial Number'},
            {fieldName: 'localIp', label: 'Local IP'},
            {fieldName: 'remoteIp', label: 'Remote IP'},
            {fieldName: 'upSince', label: 'Up Since'},
            {fieldName: 'clients', label: 'Clients'}];

        const sourceEMS = actionData && "sourceEMS" in actionData? actionData["sourceEMS"] : "Fortigate";
        const current_client_stats_columns = sourceEMS === "Fortigate"? fortiap_columns : fortilan_columns;
        const clientInfo = actionData["clientInfo"];
       
        return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                  <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
                  <CardContent className={ classes.cardContent } >
                  <Table size="small">
                    <TableBody stripedRows>
                      <TableRow key='detailsHeader' className={classes.tableRow1}>
                        <TableCell colSpan={2} align="center" className={classes.tableCellBold}>Device Details</TableCell>
                      </TableRow>
                      { _.map(deviceProps, function (value, key) {
                          let fieldName = value['fieldName'];
                          let val = actionData[fieldName];
                          let label = value['label'];

                          // convert epoch time stamps to human readable
                          if (fieldName === 'connectTime' || fieldName === 'upSince') {
                              val = moment.unix(val).format(dateFormat);
                          }

                          return (
                              <TableRow key={key} className={ classes.tableRow }>
                                <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                <TableCell className={ classes.tableCell }>{val}</TableCell>
                              </TableRow>
                          );
                        })
                      }
                      <TableRow key='clientsHeader' className={classes.tableRow1}>
                        <TableCell colSpan={2} align="center" className={classes.tableCellBold}>Connected Clients</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                      {clientInfo &&
                          <DataGrid
                            dataSource={clientInfo}
                            columns={current_client_stats_columns}
                            width="100%"
                            showBorders={true}
                          />
                      }
                  </CardContent>
                </Card>
            )
    }
}

CurrentClientStatsView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(CurrentClientStatsView);

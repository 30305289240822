import React, { Fragment, PureComponent } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { TimerOffOutline, TimerOutline,  Refresh} from 'mdi-material-ui';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';
import SelectBox from 'devextreme-react/select-box';
//import SunBurst from '../d3-charts/SunBurst';
//import ServiceViewDetails from './ServiceViewDetails';
//import { getNetworkQuery } from '../common/utils';


import fetchClient  from '../../axios';
import { LoadPanel } from 'devextreme-react/load-panel';
//import * as constants from '../../constants';

const styles = theme => ({
	cardHeader: {
		padding: theme.spacing.unit,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing.unit * 2,
			paddingRight: theme.spacing.unit * 2,
		},
		  height: theme.mixins.cardHeader.height,
		  borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	cardContent : {
		padding: theme.spacing.unit,
		overflow: 'auto',
		//height: `calc(100vh - ${theme.mixins.gridOffset * 2}px)`,
		height: `calc(100vh - 155px)`,
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		},
		margin: 'auto'
	},
  svnNetworkTypeSelect: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1)
  },
	alert: {
		padding: "0 8px"
	}
});

const svnNetworkTypeList = [{label: 'All Services', value: 'All_Services'}, {label: 'All Satellites', value: 'All_Satellites'},
	{label: 'All Datacenters', value: 'All_Datacenters'}, {label: 'All Circuits', value: 'All_Circuits'}, {label: 'All Locations', value: 'All_Locations'}];

class SvnServiceViewNavigator extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			isDataLoading: false,
			runTask: false,
			snvNetworkType: svnNetworkTypeList[0]['value'],
			svnNetworkChartData: [],
			chartHeight: 600,
			chartWidth: 600,
			query: 'memberOf:*',
			serviceName: "All_Services"
		};
		this.chartRef = React.createRef();
	}

    componentWillMount() {
		this.getSvnNetworkData();
    };

    componentDidMount() {
		const { runTask } = this.state;
		let height = this.getChartHeight();
		let width = this.getChartWidth();
		this.setState({
			chartHeight: height,
			chartWidth: width
		});
        if (runTask) {
            clearInterval(this.serviceViewNavigatorTask);
            this.serviceViewNavigatorTask = setInterval(()=> this.getSvnNetworkData(), 60000)
        }
    };
    componentWillUnmount () {
        clearTimeout(this.serviceViewNavigatorTask);
    };
    /**
     * getSvnNetworkData:
     */
    getSvnNetworkData = () => {
    	this.setState({isDataLoading: true});
    	const params = {svn_network_type: this.state.snvNetworkType};
    	fetchClient.get('/api/v1.0/svn/network/chart', {params: params})
		.then(res => {
			let nsvnNetworkChartData = [];
				if (res.data) {
					nsvnNetworkChartData = res.data;
				}
				//console.log('networkChartData'+networkChartData)
				this.setState({
					svnNetworkChartData: nsvnNetworkChartData,
					isDataLoading: false
				});
		}).catch (() => {
				this.setState({
					svnNetworkChartData: [],
					isDataLoading: false
				});
			    throw new Error('Data Loading Error');
		});
    };
    /**
     * handleRefresh:
     */
    handleRefresh = () => {
    	this.getSvnNetworkData();
    };
    /**
     * handleConfiguration:
     */
    handleConfiguration = (e) => {
        const { runTask } = this.state;
        this.setState({runTask: !runTask});
        if (!runTask) {
            clearInterval(this.serviceViewNavigatorTask);
            this.serviceViewNavigatorTask = setInterval(()=> this.getSvnNetworkData(), 60000)
        } else {
            clearInterval(this.serviceViewNavigatorTask);
        }
        e.component.option('text', runTask ? 'Start Timer' : 'Stop Timer');
        e.component.option('icon', runTask ? 'mdi mdi-timer-off-outline' : 'mdi mdi-timer-outline');
    };
	handleSvnNetworkTypeChange = (e) => {
        this.setState({snvNetworkType: e.value}, () => { this.getSvnNetworkData(); });
    };
	getChartWidth = () => {
		return this.chartRef.current.parentElement.offsetWidth;
	};
	getChartHeight = () => {
		return this.chartRef.current.parentElement.offsetHeight;
	};
	onZoomChanged = (query, serviceName) => {
		//console.log('SVN:NetworkName'+zoomedNetworkName);
		//const query = getNetworkQuery(zoomedNetworkName);
		this.setState({query: query, serviceName: serviceName})
	};
	render() {
		const { classes, raised=false } = this.props;
		const { isDataLoading, runTask, snvNetworkType, query, serviceName } = this.state;
		return (
			    <Card className={classes.root} raised={raised}>
			    	<CardHeader className={ classes.cardHeader } title={""}
		    			action={
				    			<Fragment>
									<FormControl>
                                        <SelectBox
                                           className={classes.svnNetworkTypeSelect}
										   dataSource={svnNetworkTypeList}
										   defaultValue={snvNetworkType}
										   selectedItem={snvNetworkType}
										   displayExpr='label'
										   valueExpr='value'
                                           width={150}
                                           onValueChanged={this.handleSvnNetworkTypeChange}
                                        />
                                    </FormControl>
                                    <IconButton onClick={this.handleConfiguration} title={ runTask ? 'Stop Timer' : 'Start Timer'} >
                                        { runTask ? <TimerOutline/> : <TimerOffOutline /> }
                                    </IconButton>
			    	              <IconButton onClick={this.handleRefresh}>
			    	                <Refresh/>
			    	              </IconButton>
						        </Fragment>
		    	            }
                    />
					<CardContent id="sunburstCahrtId" ref={this.chartRef} className={ classes.cardContent }>
						<Grid container spacing={1}>
							<Grid item lg={6} md={6} sm={12} xs={12} >
								<div id="sunburstNetworkCahrtId">
									{ /* !isDataLoading && <SunBurst includeServiceDetails={false} data={svnNetworkChartData} width={chartWidth/2 - 8} height={chartHeight - 133} onZoomChanged={this.onZoomChanged}/> */}
								</div>
								<Alert className={classes.alert} icon={false} severity="info">
									<strong>Service Name:</strong> {serviceName}, <strong>Query:</strong> {query}
								</Alert>
							</Grid>
						</Grid>
						<LoadPanel
						  shadingColor="rgba(0,0,0,0.4)"
						  position={{of: '#sunburstNetworkCahrtId' }}
						  visible={isDataLoading}
						/>
					</CardContent>
			    </Card>
			)
	}
}
SvnServiceViewNavigator.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withOktaAuth(withStyles(styles, {withTheme: true})(SvnServiceViewNavigator));
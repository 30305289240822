import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
// import IconButton from '@material-ui/core/IconButton';
// import { Fullscreen as FScreen, FullscreenExit, TimerOffOutline, TimerOutline,  Refresh, Close } from 'mdi-material-ui';
// import { LoadPanel } from 'devextreme-react/load-panel';
import DataGrid from 'devextreme-react/data-grid';
// import moment from 'moment';
//import _ from 'lodash';
//import green from "@material-ui/core/colors/green";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
        overflow: 'auto',
        padding: 0,
        '&:last-child': {
            paddingBottom: theme.spacing(1)
        }
    },
    headerTitle: {
      fontSize: '1.2rem',
    },
    summaryViewIconButton: {
        padding: 6
    },
    summaryViewActions: {
        marginBottom: -6,
        marginTop: 3
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableCellBold: {
        padding: '4px 16px',
        lineHeight: '0.80rem',
        fontWeight: 'bold'
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        fontWeight: 'bold',
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5'
    },
    statusCell: {
        fontWeight: 'bold',
        padding: '4px 16px',
        lineHeight: '0.80rem',
        color: 'green'
    }
});

const formatWanCells = (e) => {
    if (e.rowType === "data") {
        if (e.column.dataField === "state" && e.data.state === 'connected') {
            e.cellElement.style.cssText = "color: green; fontWeight: bold";
        } else if (e.column.dataField === "state") {
            e.cellElement.style.cssText = "color: red; fontWeight: bold";
        }
    }
}

class PingEcmView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {};
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    render() {
        const { classes, raised, title, actionData }  = this.props;
        const wan_int_cols = [
            {dataField:'name', caption: 'Interface'},
            {dataField:'ipv4_address', caption: 'IP'},
            {dataField:'state', caption: 'State'},
            {dataField:'mdn', caption: 'MDN'},
            {dataField:'pin_status', caption: 'SIM State'},
            {dataField:'carrier', caption: 'Carrier'},
            {dataField:'uptime', caption: 'Uptime'}];
        let wanData = actionData && actionData['wan_interfaces'] ? actionData['wan_interfaces'] : [];

        return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                <CardHeader className={classes.cardHeader} classes={{title: classes.headerTitle}} title={title} />
                <CardContent className={classes.cardContent} >
                  <Table size="small">
                    <TableBody stripedRows>
                      <TableRow key='detailsHeader' className={classes.tableRow1}>
                        <TableCell colSpan={2} align="center" className={classes.tableCell}>Cradlepoint Device Details</TableCell>
                      </TableRow>
                      { actionData &&
                          <Fragment>
                            <TableRow key='deviceId' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">ECM ID</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['ecm_id']}</TableCell>
                            </TableRow>
                            <TableRow key='serial_number' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Serial Number</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['serial_number']}</TableCell>
                            </TableRow>
                            <TableRow key='current_state' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold  } component="head" scope="row">Current State</TableCell>
                                { actionData['current_state'] === 'online' ?
                                    <TableCell className={ classes.statusCell }>Online</TableCell>
                                    :
                                    <TableCell className={ classes.tableCell }>{actionData['current_state']}</TableCell>
                                }
                            </TableRow>
                              <TableRow key='locality' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Locality</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['locality']}</TableCell>
                            </TableRow>
                            <TableRow key='mac_address' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">MAC Address</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['mac_address']}</TableCell>
                            </TableRow>
                            <TableRow key='product_name' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Product Name</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['product_name']}</TableCell>
                            </TableRow>
                            <TableRow key='configuration_status' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Configuration Status</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['configuration_status']}</TableCell>
                            </TableRow>
                            <TableRow key='service_type' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Service Type</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['service_type']}</TableCell>
                            </TableRow>
                            <TableRow key='signal_strength' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Signal Strength</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['signal_strength']}</TableCell>
                            </TableRow>
                            <TableRow key='signal_power' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Signal Power</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['signal_power']}</TableCell>
                            </TableRow>
                            <TableRow key='signal_quality' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Signal Quality</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['signal_quality']}</TableCell>
                            </TableRow>
                            <TableRow key='sinr' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">SINR</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['sinr']}</TableCell>
                            </TableRow>
                            <TableRow key='uptime' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Uptime</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['uptime']}</TableCell>
                            </TableRow>
                            
                           <TableRow key='LocsHeader' className={ classes.tableRow1 }>
                             <TableCell colSpan={2} align="center" className={ classes.tableCell}>Location Details</TableCell>
                           </TableRow>
                           <TableRow key='coordinates' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Coordinates</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['location_coords']}</TableCell>
                            </TableRow>
                           <TableRow key='accuracy' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Accuracy</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['location_accuracy']}</TableCell>
                            </TableRow>
                           <TableRow key='method' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Method</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['location_method']}</TableCell>
                            </TableRow>
                           <TableRow key='updated' className={ classes.tableRow }>
                              <TableCell className={ classes.tableCellBold } component="head" scope="row">Last Updated</TableCell>
                              <TableCell className={ classes.tableCell }>{actionData['location_updated']}</TableCell>
                            </TableRow>

                           <TableRow key='wansHeader' className={ classes.tableRow1 }>
                             <TableCell colSpan={2} align="center" className={ classes.tableCell}>WAN Interfaces</TableCell>
                           </TableRow>
                           <TableRow key='wans' className={ classes.tableRow }>
                             <TableCell colSpan={2} className={ classes.tableCell } >
                               {wanData &&
                                 <DataGrid
                                   dataSource={wanData}
                                   columns={wan_int_cols}
                                   showBorders={false}
                                   onCellPrepared={formatWanCells}
                                 />
                               }
                             </TableCell>
                           </TableRow>
                         </Fragment>
                        }
                    </TableBody>
                  </Table>
                </CardContent>
                </Card>
            )
    }
}

PingEcmView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(PingEcmView);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Fullscreen from "react-full-screen";
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import compose from 'recompose/compose';
import PieChart, { Series, Label, Connector, Export, Font, Legend, Tooltip } from 'devextreme-react/pie-chart';
import { Fullscreen as FScreen, FullscreenExit, TimerOffOutline, TimerOutline,  Refresh } from 'mdi-material-ui';
import fetchClient from "../../../axios";
import * as constants from "../../../constants";
import SummaryChartCenterTemplate from './SummaryChartCenterTemplate';
import SummaryChartTooltipTemplate from './SummaryChartTooltipTemplate';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: theme.spacing(1),
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	cardContentFull: {
	    height: '100vh',
		padding: theme.spacing(1),
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1.2rem',
	  //color: '#ffffff'
	},
    summaryViewIconButton: {
	    padding: 6
    },
    summaryViewActions: {
	    marginBottom: -6,
        marginTop: 3
    }
});
class SummaryView extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			summaryData: [],
			runTask: false,
            isFullScreen: false
		};
		this.summaryViewChartRef = React.createRef();
		this.pointClickHandler = this.pointClickHandler.bind(this);
		this.legendClickHandler = this.legendClickHandler.bind(this);
	}
	componentWillMount() {
        this.getDeviceSummaryData();
    }
    componentDidMount() {
        const { runTask } = this.state;
        if (runTask) {
            clearInterval(this.summaryViewTask);
            this.summaryViewTask = setInterval(()=> this.getDeviceSummaryData(), constants.TASK_TIME)
        }
    };
    componentWillUnmount () {
        clearTimeout(this.summaryViewTask);
    };
	customizePoint = (point) => {
		switch (point.argument) {
			case 'Critical':
				return { color: red[500]};
			case 'Major':
				return { color: orange[500]};
			case 'Minor':
				return { color: yellow[500]};
			case 'Unknown':
				return { color: lightBlue[500]};
			case 'Normal':
				return { color: green[500]};
            default:
                //None
		}
	};
    /**
     * getDeviceSummaryData:
     */
    getDeviceSummaryData = () => {
      //this.summaryViewChart.current.instance.showLoadingIndicator();
      const { query } = this.props;
      fetchClient.get('/api/v1.0/es/devices/summary?query='+query)
          .then(res => {
              let sData = {};
              let summaryData = [];
              if (res.data && res.data.data) {
                  sData = res.data.data;
                  summaryData.push(
					  {label: 'Critical', value: sData['critical']},
					  {label: 'Major', value: sData['major']},
					  {label: 'Minor', value: sData['minor']},
					  {label: 'Normal', value: sData['normal']},
					  {label: 'Unknown', value: sData['unknown']});
              }
              this.setState({
                  summaryData: summaryData
              });
              //this.summaryViewChart.current.instance.hideLoadingIndicator();
          })
          .catch(error => {
              console.error('Error:', error);
              this.setState({
                  summaryData: []
              });
              //this.summaryViewChart.current.instance.hideLoadingIndicator();
          });
    };
    pointClickHandler = (e) => {
		this.toggleVisibility(e.target);
	};
    drawnHandler = (e) => {
        this.summaryViewChartRef.current.instance.refresh();
    };
    legendClickHandler = (e) => {
		let arg = e.target;
		let item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
		this.toggleVisibility(item);
	};
    toggleVisibility = (item) => {
    	item.isVisible() ? item.hide() : item.show();
	};
    customizeText = (arg) => {
    	return `${arg.percentText}`;
        //return `${arg.valueText}\n(${arg.percentText})`;
	};
    handleConfiguration = (e) => {
        const { runTask } = this.state;
        this.setState({runTask: !runTask});
        if (!runTask) {
            clearInterval(this.summaryViewTask);
            this.summaryViewTask = setInterval(()=> this.getDeviceSummaryData(), constants.TASK_TIME)
        } else {
            clearInterval(this.summaryViewTask);
        }
        e.component.option('text', runTask ? 'Start Timer' : 'Stop Timer');
        e.component.option('icon', runTask ? 'mdi mdi-timer-off-outline' : 'mdi mdi-timer-outline');
    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
	render() {
		const { classes, raised, title } = this.props;
		const { summaryData, runTask, isFullScreen } = this.state;
		return (
                <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                    <div className="full-screenable-node">
                        <Card className={classes.root} raised={raised}>
                            <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title}
                                action={
                                    <div className={classes.summaryViewActions}>
                                        <IconButton className={classes.summaryViewIconButton} onClick={this.handleConfiguration} title={ runTask ? 'Stop Timer' : 'Start Timer'} >
                                            { runTask ? <TimerOutline/> : <TimerOffOutline /> }
                                        </IconButton>
                                        <IconButton className={classes.summaryViewIconButton} onClick={this.getDeviceSummaryData}>
                                         <Refresh/>
                                        </IconButton>
                                        <IconButton className={classes.summaryViewIconButton} onClick={this.handleFullScreen}>
                                            { isFullScreen ? <FullscreenExit /> : <FScreen /> }
                                        </IconButton>
                                    </div>
                                }
                            />
                            <CardContent className={ isFullScreen ? classes.cardContentFull : classes.cardContent}>
                                <PieChart ref={ this.summaryViewChartRef }
                                    id='summary-view-pie'
                                    type='doughnut'
                                    dataSource={ summaryData }
                                    palette='Bright'
                                    title=''
                                    //theme='material.blue.light'
                                    resolveLabelOverlapping='shift'
                                    innerRadius={0.50}
                                    sizeGroup="piesGroup"
                                    redrawOnResize={true}
                                    onPointClick = { this.pointClickHandler }
                                    onLegendClick={ this.legendClickHandler }
                                    //onDone={this.drawnHandler}
                                    customizePoint={this.customizePoint}
                                    centerRender={SummaryChartCenterTemplate}
                                >
                                    <Legend
                                        orientation='horizontal'
                                        itemTextPosition='right'
                                        horizontalAlignment='center'
                                        verticalAlignment='bottom'
                                        rowCount={1} />
                                    <Series argumentField='label' valueField='value' >
                                        <Label visible={true}
                                           format='fixedPoint'
                                            position='columns'
                                            backgroundColor='none'
                                            customizeText={this.customizeText}
                                        >
                                            <Font size={16} />
                                            <Connector visible={true} width={1}/>
                                        </Label>
                                    </Series>
                                    <Tooltip enabled={true} contentRender={SummaryChartTooltipTemplate} cornerRadius={8} zIndex={3000}/>
                                    {/*<Size width={500} />*/}
                                    <Export enabled={true} />
                                </PieChart>
                            </CardContent>
                        </Card>
                    </div>
                </Fullscreen>
			)
	}
}

SummaryView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(SummaryView);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from "recompose/compose";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import numeral from 'numeral';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
    paper: {
      height: 65,
      width: '100%',
      color: theme.palette.common.white,
      padding: "8px 18px",
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    metric1: {
        fontSize: '1.70rem',
        fontWeight: 400,
    },
    metric2: {
        fontSize: '1.20rem',
        fontWeight: 500,
    },
    alertIcon: {
      color: theme.palette.common.white,
      marginRight: 8,
      fontSize: 32
    },
    divider: {
        backgroundColor: '#ffffff',
        height: 4
    },
});

class DeviceSummary extends PureComponent {
  render() {
    const { classes, summaryData } = this.props;
    const total = summaryData['total'] ? summaryData['total'] : 0;
    const critical =  summaryData['critical'] ? summaryData['critical'] : 0;
    const major =  summaryData['major'] ? summaryData['major'] : 0;
    const minor = summaryData['minor'] ? summaryData['minor'] : 0;
    const normal = summaryData['normal'] ? summaryData['normal'] : 0;
    const unknown = summaryData['unknown'] ? summaryData['unknown'] : 0;
    return (
        <Grid container spacing={1}>
            <Grid item lg={2} md={3} sm={6} xs={6} >
                <Paper className={classes.paper} style={{backgroundColor: teal[500] }} elevation={2} >
                    {/*<CloseBox className={classes.alertIcon} />*/}
                        <div>
                          <Typography variant="h6" component="h6"># DEVICES</Typography>
                        </div>
                        <div className={classes.rightDiv}>
                            <span className={classes.metric2}> {numeral(total).format('0,0')}</span>
                        </div>
                </Paper>
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={6} >
                <Paper className={classes.paper} style={{backgroundColor: red[500] }} elevation={2} >
                        <div>
                          <Typography variant="h6" component="h6">CRITICAL</Typography>
                        </div>
                        <div className={classes.rightDiv}>
                            <span className={classes.metric1}>{numeral(critical).format('0,0')}</span>
                            <span className={classes.metric2}> {numeral(critical/total).format('0.00%')}</span>
                        </div>
                </Paper>
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={6} >
                <Paper className={classes.paper} style={{backgroundColor: orange[500] }} elevation={2} >
                        <div>
                          <Typography variant="h6" component="h6">MAJOR</Typography>
                        </div>
                        <div className={classes.rightDiv}>
                            <span className={classes.metric1}>{numeral(major).format('0,0')}</span>
                            <span className={classes.metric2}>{numeral(major/total).format('0.00%')}</span>
                        </div>
                </Paper>
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={6} >
                <Paper className={classes.paper} style={{backgroundColor: yellow[500] }} elevation={2} >
                        <div>
                          <Typography variant="h6" component="h6">MINOR</Typography>
                        </div>
                        <div className={classes.rightDiv}>
                            <span className={classes.metric1}>{numeral(minor).format('0,0')}</span>
                            <span className={classes.metric2}>{numeral(minor/total).format('0.00%')}</span>
                        </div>
                </Paper>
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={6} >
                <Paper className={classes.paper} style={{backgroundColor: lightBlue[500] }} elevation={2} >
                        <div>
                          <Typography variant="h6" component="h6">UNKNOWN</Typography>
                        </div>
                        <div className={classes.rightDiv}>
                            <span className={classes.metric1}>{numeral(unknown).format('0,0')}</span>
                            <span className={classes.metric2}>{numeral(unknown/total).format('0.00%')}</span>
                        </div>
                </Paper>
            </Grid>
            <Grid item lg={2} md={3} sm={6} xs={6} >
                <Paper className={classes.paper} style={{backgroundColor: green[500] }} elevation={2} >
                        <div>
                          <Typography variant="h6" component="h6">NORMAL</Typography>
                        </div>
                        <div className={classes.rightDiv}>
                            <span className={classes.metric1}>{numeral(normal).format('0,0')}</span>
                            <span className={classes.metric2}>{numeral(normal/total).format('0.00%')}</span>
                        </div>
                </Paper>
            </Grid>
        </Grid>
    );
  }
}

DeviceSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(DeviceSummary);
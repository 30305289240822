import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Box, { Item } from 'devextreme-react/box';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import { EventsGridColumns } from '../../components/common/ColumnConfigs';
import { updateDeviceData } from "../common/utils";

const styles = theme => ({
	root: {
	  width: '100%'
	},
	eventCard: {
		margin: 8
	},
	cardHeader: {
		//backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
		textAlign: 'center',
		border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableBody: {
		border: '1px solid rgba(224, 224, 224, 1)',
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
		color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	},
	tableRow1: {
		height: 18,
		padding: 8,
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});
class EventDetails extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			isFullScreen: false
		};
	}
	stateRender = (state) => {
		switch (state) {
			case 'Critical':
				return '#f44336';
			case 'Major':
				return '#ff9800';
			case 'Minor':
				return '#ffeb3b';
			case 'Unknown':
				return '#03a9f4';
			case 'Normal':
				return '#008000';
			default:
				return '#03a9f4';
		}
	};
	dateRender = (date) => {
		return (date === 0 || date === '0') ? 'Never' : moment.unix(date/1000).format('MM/DD/YYYY HH:mm:ss');
	};
	render() {
		const { classes,  raised, eventData } = this.props;
		let eData = updateDeviceData(eventData);
		const columns1 = _.slice(EventsGridColumns, 0,8);
		const columns2 = _.drop(EventsGridColumns, 8);
		const columns3 = [
			{ dataField: 'createdAt', caption:  'First Notified At' },
			{ dataField: 'lastNotifiedAt', caption:  'Last Notified At' },
			{ dataField: 'changedAt', caption:  'Last Changed At' },
			{ dataField: 'clearedAt', caption:  'Last Cleared At' },
			{ dataField: 'count', caption:  'Count' }
		];
		const eventDivStyle = { backgroundColor: this.stateRender(eventData['severity']) };
		return (
				<Card className={classes.root} raised={raised}>
					<CardContent className={ classes.cardContent }>
						<Box direction="row" width="100%" height={144}>
							<Item ratio={2}>
								 <Table size="small">
									 <TableBody className={classes.tableBody}>
										{eventData ?
											columns1.map(row => {
											  return (
												<TableRow key={row.dataField} className={ classes.tableRow }>
												  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
												  <TableCell className={ classes.tableCell }>{eData[row.dataField]}</TableCell>
												</TableRow>
											  );
											}) :
											columns1.map(row => {
											  return (
												<TableRow key={row.dataField} className={ classes.tableRow }>
												  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
												  <TableCell className={ classes.tableCell }>{'Unknown'}</TableCell>
												</TableRow>
											  );
											})
										}
									</TableBody>
								  </Table>
							</Item>
							<Item ratio={1}>
								<Card className={classes.eventCard}>
									<CardHeader className={ classes.cardHeader } style={ eventDivStyle } classes={{title: classes.headerTitle}} title={eData['severity']} />
									<CardContent className={ classes.cardContent }>
										 <Table size="small">
											 <TableBody>
												{eventData ?
													columns3.map(row => {
													  return (
														<TableRow key={row.dataField} className={ classes.tableRow }>
														  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
														  <TableCell className={ classes.tableCell }>{row.dataField !== 'count' ? this.dateRender(eData[row.dataField]) : eData[row.dataField] }</TableCell>
														</TableRow>
													  );
													}) :
													columns3.map(row => {
													  return (
														<TableRow key={row.dataField} className={ classes.tableRow }>
														  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
														  <TableCell className={ classes.tableCell }>{'Unknown'}</TableCell>
														</TableRow>
													  );
													})
												}
											</TableBody>
										  </Table>
									</CardContent>
								</Card>
							</Item>
						</Box>
						<Table size="small">
							<TableBody className={classes.tableBody}>
								{eventData ?
									columns2.map(row => {
									  return (
										<TableRow key={row.dataField} className={ classes.tableRow1 }>
										  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
										  <TableCell className={ classes.tableCell }>{eData[row.dataField]}</TableCell>
										</TableRow>
									  );
									}) :
									columns2.map(row => {
									  return (
										<TableRow key={row.dataField} className={ classes.tableRow1 }>
										  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
										  <TableCell className={ classes.tableCell }>{'Unknown'}</TableCell>
										</TableRow>
									  );
									})
								}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
		)
	}
}

EventDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(EventDetails);
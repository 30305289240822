import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import List, { SearchEditorOptions } from 'devextreme-react/list';


const styles = theme => ({
    dataRow: {
        fontSize: '13px'
    },
    dbName: {
	    float: 'left',
        width: '50%'
    },
    dbValue: {
	    float: 'right',
        width: '50%'
    },
});
class DbDetailsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchMode: 'contains'
        };
    }
    componentWillMount() {

    }
    listItemRendered = (e) => {
        if(e.itemIndex % 3 === 0) {
            //e.itemElement.css('background-color', '#f5f5f5');
        }
    };
	render() {
		const { classes, dbData } = this.props;
		    const itemTemplate = (data) =>{
                return (
                    <div id='db-details-list-id' className={classes.dataRow}>
                        <div className={classes.dbName}>{data.name}</div>
                        <div className={classes.dbValue}>{data.value}</div>
                    </div>
                )
            };
		return (
		    <div>
                <List
                    dataSource={dbData}
                    itemRender={itemTemplate}
                    searchExpr={["name", "value"]}
                    searchEnabled={true}
                    searchMode={this.state.searchMode}
                    displayExpr="name"
                    valueExpr="value"
                    height={'80vh'}
                    showScrollbar={'always'}
                    onItemRendered={ this.listItemRendered }
                    elementAttr={{id: 'db-details-list', class: 'db-details-list-cls'}}
                    hoverStateEnabled={false}
                    onContentReady={this.onContentReady}
                >
                    <SearchEditorOptions
                        elementAttr={{id: 'db-details-search', class: 'db-details-search-cls'}}
                        placeholder={'Search for field name or values...'}
                    />
                </List>
            </div>
			)
	}
}

DbDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DbDetailsView);
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment/moment";

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
		border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
		color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});
class DeviceMonitoringConfiguration extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
		};
	}
	render() {
		const { classes, raised, deviceData } = this.props;
		const title = 'Monitoring Configuration';
		const tableColumns = [
		    { caption: 'Discovered', dataField: 'discovered' },
		    { caption: 'Element Management System', dataField: 'nocid' },
            { caption: 'Monitoring Method', dataField: 'poller' },
            { caption: 'SAM', dataField: 'sam' },
            { caption: 'Seed IP', dataField: 'seedip' },
            { caption: 'First Discovered', dataField: 'discoveredFirstAt' },
            { caption: 'Last Discovered', dataField: 'discoveredLastAt' },
            { caption: 'Discovery Error', dataField: 'discoveryDescription' }
        ];
		let monitoringMethod = `Directly polled by ${deviceData['poller']}`;
		if (deviceData['poller'] === 'PAM-ST-OI' || deviceData['poller'] === 'Currently Unavailable' || deviceData['poller'] === 'NotFound' || deviceData['poller'] === 'NotApplicable' || deviceData['poller'] === 'Not Applicable') {
			monitoringMethod = (deviceData['eitService_Mode'] && deviceData['eitService_Mode'] !== 'Currently Unavailable') ? deviceData['eitService_Mode'] : 'EMS Monitored'
		}
		let deviceRemovedNote = '';
		if (deviceData['oldPoller'] && deviceData['oldPoller'].includes('DOWN')) {
			let dt = deviceData['oldPoller'].split(':')[1];
			dt = moment(dt, 'YYYYMMDDHHmm').format('MM/DD/YYYY HH:mm:ss');
			deviceRemovedNote = `This device is auto-removed on ${dt}`
		}
		return (
			<Card className={classes.root} raised={raised}>
				<CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
				<CardContent className={ classes.cardContent }>
				 <Table size="small">
					 <TableBody stripedRows>
						{(deviceData) ?
							<Fragment>
								<TableRow key="discovered" className={ classes.tableRow }>
								  <TableCell className={ classes.tableCell } variant="head" scope="row">Discovered</TableCell>
								  <TableCell className={ classes.tableCell }>{deviceData['discovered']}</TableCell>
								</TableRow>
								<TableRow key="nocid" className={ classes.tableRow }>
								  <TableCell className={ classes.tableCell } variant="head" scope="row">Element Management System</TableCell>
								  <TableCell className={ classes.tableCell }>{deviceData['nocid']}</TableCell>
								</TableRow>
								<TableRow key="monitorinMethod" className={ classes.tableRow }>
								  <TableCell className={ classes.tableCell } variant="head" scope="row">Monitoring Method</TableCell>
								  <TableCell className={ classes.tableCell }>{monitoringMethod}</TableCell>
								</TableRow>
								<TableRow key="sam" className={ classes.tableRow }>
								  <TableCell className={ classes.tableCell } variant="head" scope="row">SAM</TableCell>
								  <TableCell className={ classes.tableCell }>{deviceData['sam']}</TableCell>
								</TableRow>
								<TableRow key="seedip" className={ classes.tableRow }>
								  <TableCell className={ classes.tableCell } variant="head" scope="row">Seed IP</TableCell>
								  <TableCell className={ classes.tableCell }>{deviceData['seedip']}</TableCell>
								</TableRow>
                                { (deviceData['discovered'] === true) ?
                                    <Fragment>
                                        <TableRow key="firstDiscovered" className={classes.tableRow}>
                                            <TableCell className={classes.tableCell} variant="head" scope="row">First Discovered</TableCell>
                                            <TableCell className={classes.tableCell}>{deviceData['discoveredFirstAt']}</TableCell>
                                        </TableRow>
                                        <TableRow key="lastDiscovered" className={classes.tableRow}>
                                            <TableCell className={classes.tableCell} variant="head" scope="row">Last Discovered</TableCell>
                                            <TableCell className={classes.tableCell}>{deviceData['discoveredLastAt']}</TableCell>
                                        </TableRow>
									</Fragment>
                                        :
                                        <TableRow key="discoveryError" className={classes.tableRow}>
                                            <TableCell className={classes.tableCell} variant="head" scope="row">Discovery Error</TableCell>
                                            <TableCell className={classes.tableCell}>{deviceData['discoveryDescription']}</TableCell>
                                        </TableRow>
                                }
								{ (deviceData['oldPoller'] && deviceData['oldPoller'].includes('DOWN')) &&
                                        <TableRow key="discoveryError" className={classes.tableRow}>
                                            <TableCell className={classes.tableCell} variant="head" scope="row">Note</TableCell>
                                            <TableCell className={classes.tableCell}>{deviceRemovedNote}</TableCell>
                                        </TableRow>
								}
							</Fragment>
							:
							tableColumns.map(row => {
							  return (
								<TableRow key={row.dataField} className={ classes.tableRow }>
								  <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
								  <TableCell className={ classes.tableCell }>{"Unknown"}</TableCell>
								</TableRow>
							  );
							})
						}
					</TableBody>
				  </Table>
				</CardContent>
			</Card>
			)
	}
}

DeviceMonitoringConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DeviceMonitoringConfiguration);
import React, { Fragment, PureComponent } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { TimerOffOutline, TimerOutline,  Refresh, Fullscreen as FScreen, FullscreenExit } from 'mdi-material-ui';
import FormControl from '@material-ui/core/FormControl';
import { SelectBox, Button as SelectBoxButton } from 'devextreme-react/select-box';
import Fullscreen from "react-full-screen";
import CustomStore from 'devextreme/data/custom_store';
import _ from 'lodash';
import fetchClient  from '../axios';
import { LoadPanel } from 'devextreme-react/load-panel';
import MuiAlert from '@material-ui/lab/Alert';
import * as constants from '../constants';
import DeviceStatus from './device-details/DeviceStatus';
import DeviceActions from './device-details/DeviceActions';
import DeviceNetworkConfiguration from './device-details/DeviceNetworkConfiguration';
import HANConfiguration from './device-details/HANConfiguration';
import DeviceMonitoringConfiguration from './device-details/DeviceMonitoringConfiguration';
import DeviceVADBConfiguration from './device-details/DeviceVADBConfiguration';
import DeviceActivePathInfo from './device-details/DeviceActivePathInfo';
import DeviceEITProdInfo from './device-details/DeviceEITProdInfo';
import DeviceLocationInformation from './device-details/DeviceLocationInformation';
//import DeviceHealthStats from './DeviceHealthStats';
import DeviceEvents from './device-details/DeviceEvents';
import NetworkEvents from './device-details/NetworkEvents';
import DeviceArchivedEvents from './device-details/DeviceArchivedEvents';
import DeviceChangeLog from './device-details/DeviceChangeLog';
//import AuditLog from './DeviceAuditLog';
import DeviceAdditionalDetails from './device-details/DeviceAdditionalDetails';
import ARPDetails from './device-details/ARPDetails';
import NICDetails from './device-details/NICDetails';
import LinkMonitorDetails from './device-details/LinkMonitorDetails';
import IPSecDetails from './device-details/IPSecDetails';
import ReachabilityandConfiguration from './device-details/ReachabilityAndConfiguration';
import LocationForLumos from './device-details/LocationForLumos';
import TransportStatus from './device-details/TransportStatus';
import FortiSwitchInfo from './device-details/FortiSwitchInfo';
import MerakiDeviceData from './device-details/MerakiDeviceData';
import VeloCloudData from './device-details/VeloCloudData';
import FortiLanInfo from './device-details/FortiLanInfo';
import CiscoSwitchInfo from './device-details/CiscoSwitchInfo';
import { updateDeviceData } from './common/utils';
//import moment from "moment/moment";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const styles = theme => ({
	main: {
		backgroundColor:theme.palette.primary.maindiv
	},
    root: {
    },
    root1: {
    	marginTop: 8
    },
	cardHeader: {
		padding: theme.spacing.unit,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing.unit * 2,
			paddingRight: theme.spacing.unit * 2,
		},
		height: theme.mixins.cardHeader.height,
		borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	cardHeader1: {
		padding: theme.spacing.unit/2,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing.unit * 2,
			paddingRight: theme.spacing.unit * 2,
		},
		height: theme.mixins.cardHeader.height,
		border: '1px solid rgba(224, 224, 224, 1)'
	},
	cardContent : {
		padding: theme.spacing.unit,
		overflow: 'auto',
		height: `calc(100vh - ${theme.mixins.gridOffset}px)`,
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	 },
	cardContent1 : {
		padding: 0,
		'&:last-child': {
			paddingBottom: 0
		}

	 },
	cardContentFull : {
		padding: theme.spacing.unit,
		overflow: 'auto',
		height: '100%',
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	 },
	 divContent: {
		display: "inline-block",
		paddingRight: 20,
		paddingBottom:20
	 },
	ddForm: {
		marginTop: 8,
		backgroundColor: '#FFFFFF'
	},
	selectBox: {
		border: '1px solid #2196f3'
	},
	tabs: {
        float: 'left'
    },
    expander: {
        float: 'right'
    },
	ddAlert: {
    	marginBottom: 4
	},
	gridMain: {
		// width: `calc(100% + 10px)`,
		width: '100%',
    	margin: '-4px 4px -4px -4px !important'
	},
    externalFilterSelect: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1)
	},
});
const externalFilterList = [{label: 'External', value: 'external'}, {label: 'Internal', value: 'internal'}];
    // eslint-disable-next-line
let query = '';
class Lumos extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			deviceData: [],
			devicesSameLocationData: [],
			deviceGroups: [],
			deviceHealthData: [],
			deviceActions: [],
            deviceEitData: {},
            deviceDmnData: {},
            deviceHtsData: {},
			troubleShootingActions: [],
			advancedActions: [],
			performanceGraphs: [],
			hanReports: [],
			usageReports: [],
			secureActions: [],
			csrTools: [],
			deviceNetworkEventsData: [],
			deviceName: this.props.dName,
			isDataLoading: false,
			runTask: false,
			value: 0,
			hidden: true,
			open: false,
			isDockVisible: true,
			isPolycomDevice: false,
			neighborDevicePoller: '',
			isFullScreen: false,
            anchorEl: null,
            query: '',
			isSearchDataLoading: false,
			externalFilter: externalFilterList[0]['value'],
			pageType: 'lumos',
			deviceNameList:[]
		};
		this.deviceDetailsSearch = null;
		this.deviceDetailsLoadPanel = null;
		this.selectBoxLoadingIcon = null;
		this.handleDeviceNameClicked = this.handleDeviceNameClicked.bind(this);
		this.handleDeviceNameChange = this.handleDeviceNameChange.bind(this);
		this.handleExternalFilterChange = this.handleExternalFilterChange.bind(this);
		this.searchStore = {
            store: new CustomStore({
				key: 'name',
                load: (loadOptions) => {
					//this.setState({ isSearchDataLoading: true });
					let params = { field: 'name', query: loadOptions.searchValue, all_fields: true };
					return fetchClient.get('/api/v1.0/es/devices/search', {params: params})
						.then(res => {
							let deviceNames = [];
							if (res.data) {
								deviceNames = res.data;
							}
							//this.setState({ isSearchDataLoading: false });
							return deviceNames
						})
						//.catch (() => { this.setState({ isSearchDataLoading: false }); throw 'Data Loading Error';});
					    .catch (() => { throw new Error('Data Loading Error');});
                },
                byKey: (key) => {
				    //let params = { field: 'name', query: key.toString() };
                    /*return fetchClient.get('/api/v1.0/es/devices/search', {params: params})
                        .then(res => {
                            let deviceNames = [];
                            if (res.data) {
                                deviceNames = res.data;
                            }
                            return deviceNames
                        })
                        .catch (() => { throw 'Data Loading Error';});*/
                }
            })
        };
	}

    componentWillMount() {
    	//this.getDeviceData();
    };

    componentDidMount() {
    	// const { handle } = this.props.match.params;
    	// console.log('Route info:', handle);
		const { runTask } = this.state;
        if (runTask) {
            clearInterval(this.statusTask);
            this.statusTask = setInterval(()=> this.getDeviceData(), constants.TASK_TIME)
        }
        this.getDeviceData();
    };
    componentWillUnmount () {
        clearTimeout(this.statusTask);
    };
    /**
     * getDeviceData:
     */
    getDeviceData = () => {
    	//const dateFormat = 'MM/DD/YYYY HH:mm:ss';
    	const { deviceName } = this.state;
    	if (deviceName) {
    		//this.deviceDetailsLoadPanel.instance.show();
			this.setState({isDataLoading: true});
			fetchClient({
				method: 'get',
				url: '/api/v1.0/device/device-details',
				params: { device_id: deviceName },
			}).then(res => {
					let isPolycomDevice = false;
                    let neighborDevicePoller = null;
					const deviceData = (res.data && res.data.data) ? updateDeviceData(res.data.data) : {};
					const devicesSameLocationData = deviceData['neighbor_devices']['data'] ? updateDeviceData(deviceData['neighbor_devices']['data']) : [];
                    const deviceGroups = deviceData['device_groups']  ? deviceData['device_groups']  : [];
                    const deviceEitData = deviceData['eit_device_details']  ? deviceData['eit_device_details']  : {};
                    const deviceDmnData = deviceData['dmn_device_details']  ? deviceData['dmn_device_details']  : {};
                    const deviceHtsData = deviceData['hts_device_details']  ? deviceData['hts_device_details']  : {};
                    const activeDevicesData = deviceData['active_devices']  ? deviceData['active_devices']  : {};
                    const deviceNetworkEventsData = deviceData['network_events']  ? deviceData['network_events']  : [];
                    const deviceAdditionalData = deviceData['additional_details']  ? updateDeviceData(deviceData['additional_details'])  : [];
                    if (_.size(deviceData) > 0 && _.startsWith(deviceData.model, 'Polycom') && _.startsWith(deviceData.productCode, 'Polycom')) {
                        _.forEach(devicesSameLocationData, function(item) {
                            if (_.startsWith(item.model, 'HR') && _.startsWith(item.productCode, 'HR')) {
                                isPolycomDevice = true;
                                neighborDevicePoller = item.poller;
                                return false;
                            }
                        })
                    }
                    const availableActions = deviceData['available_actions']  ? deviceData['available_actions']  : [];
                    const troubleShootingActions = availableActions['trouble_shooting_actions']  ? availableActions['trouble_shooting_actions']  : [];
                    const advancedActions = availableActions['advanced_actions']  ? availableActions['advanced_actions']  : [];
                    const performanceGraphs = availableActions['performance_graphs']  ? availableActions['performance_graphs']  : [];
                    const hanReports = availableActions['han_reports']  ? availableActions['han_reports']  : [];
                    const usageReports = availableActions['usage_reports']  ? availableActions['usage_reports']  : [];
                    const secureActions = availableActions['secure_actions']  ? availableActions['secure_actions']  : [];
                    const csrTools = availableActions['csr_tools']  ? availableActions['csr_tools']  : [];
					const deviceNameList=[deviceName];
					devicesSameLocationData.forEach((item) => {
						deviceNameList.push(item.name)
					})
					// this.getAvailableActions(deviceName);
					this.setState({
						isPolycomDevice: isPolycomDevice,
						neighborDevicePoller: neighborDevicePoller,
						deviceData: deviceData,
						devicesSameLocationData: devicesSameLocationData,
						deviceGroups: deviceGroups,
						deviceEitData: deviceEitData,
						deviceDmnData: deviceDmnData,
						deviceHtsData: deviceHtsData,
						activeDevicesData: activeDevicesData,
						deviceNetworkEventsData: deviceNetworkEventsData,
                        deviceAdditionalData: deviceAdditionalData,
						troubleShootingActions: troubleShootingActions,
						advancedActions: advancedActions,
						performanceGraphs: performanceGraphs,
						hanReports: hanReports,
						usageReports: usageReports,
						secureActions: secureActions,
						csrTools: csrTools,
						isDataLoading: false,
						deviceName: deviceName,
						deviceNameList: deviceNameList
					});
			}).catch ((error) => {
				console.log('Error: ' + error);
				this.setState({
					deviceName: '',
					deviceData: [],
					devicesSameLocationData: [],
					deviceGroups: [],
                    deviceEitData: {},
                    deviceDmnData: {},
                    deviceHtsData: {},
					activeDevicesData: {},
					deviceNetworkEventsData: [],
                    deviceAdditionalData: {},
					troubleShootingActions: [],
					advancedActions: [],
					performanceGraphs: [],
					hanReports: [],
					usageReports: [],
					secureActions: [],
					csrTools: [],
					isDataLoading: false
				});
			});
		}
    };

	// getAvailableActions = (deviceName) =>  {
	// 	console.log("the device name inside get available actions is "+deviceName)
	// 	const availableActions=[];
	// 	let params = {name:deviceName}
	// 	async () => {
	// 		const response= await fetchClient.get('/api/v1.0/action/device/available-actions', {params:params});
	// 		response.data.map(action => 
	// 			availableActions.push(action.action))
	// 	}

	// 	console.log("the list of available actions are "+availableActions)
	// }

    /**
     * handleRefresh:
     */
    handleRefresh = () => {
    	this.getDeviceData();
    };
    /**
     * handleConfiguration:
     */
    handleConfiguration = () => {
        const { runTask } = this.state;
        this.setState({runTask: !runTask});
        if (runTask) {
            clearInterval(this.statusTask);
        } else {
            this.statusTask = setInterval(()=> this.getDeviceData(), constants.TASK_TIME)
        }
    };
    handleDeviceNameChange = ({val}) => {
    	query = val;
	};
	onValueChanged(e) {
		console.log('the value change event is'+e)
	}
    handleDeviceNameClicked = (e, deviceName) => {
    	if (deviceName) {
    		//console.log(this.deviceDetailsSearch.instance.option())
			//this.deviceDetailsSearch.instance.option['value'] = deviceName ;
    		this.deviceDetailsSearch.instance.option('value', deviceName);
    		this.setState({ deviceName: deviceName }, () => this.getDeviceData());
		} else {
    		let dName = e.selectedItem ? e.selectedItem['name']: '';
    		this.setState({ deviceName: dName}, () => this.getDeviceData())
		}
	};
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
	handleChange = (event, newValue) => {
        this.setState({value: newValue})
    };
	handleOnEnterKey = (options) => {
		const deviceName = this.deviceDetailsSearch.instance.option('value');
		this.deviceDetailsSearch.instance.option('value', deviceName);

		if (deviceName){
			this.setState({ deviceName: deviceName }, () => this.getDeviceData())
		}
	};
	handleOnPaste = (options) => {
		// console.log('paste'+ options.event.originalEvent.clipboardData.getData('text'));
		const deviceName = options.event.originalEvent.clipboardData.getData('text');
		this.deviceDetailsSearch.instance.option('value', deviceName);
		if (deviceName){
			this.setState({ deviceName: deviceName }, () => this.getDeviceData())
		}
	};
	handleExternalFilterChange = (e) => {
        this.setState({ externalFilter: e.value }, () => this.getDeviceData())
    };
	customItemCreating = (args) => {
		if(!args.text){
			args.customItem = null;
			return;
		}
		const newItem = { name: args.text };
		if (this.searchStore.store()) {
			args.customItem = this.searchStore.store().insert(newItem)
				.then(() => this.searchStore.reload())
				.then(() => newItem)
				.catch((error) => {
					throw error;
				})
		}

	};
	render() {
		const { classes, raised=false, userInfo} = this.props;
		const {deviceData, devicesSameLocationData, isDataLoading, runTask, value,
            isPolycomDevice, neighborDevicePoller, deviceName, isFullScreen, deviceEitData,  deviceDmnData, deviceHtsData, deviceNetworkEventsData, deviceAdditionalData,
			troubleShootingActions, advancedActions, performanceGraphs, hanReports, usageReports, secureActions, csrTools, isSearchDataLoading, externalFilter, pageType, deviceNameList} = this.state;
		const hnsLocationID = deviceData.hnsLocationID
		const title = "LUMOS";
		return (
			<Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
				<div className="full-screenable-node">
						<Card className={classes.main} raised={raised}>
							<CardHeader className={ classes.cardHeader } title={title}
								action={
										<div>
											<FormControl className={classes.ddForm}>
												<SelectBox
													className={classes.selectBox}
												    ref={(ref) => this.deviceDetailsSearch = ref}
													dataSource={this.searchStore}
													width='auto'
													acceptCustomValue={true}
													placeholder={'Search for a device...'}
													value={deviceName}
													showClearButton={true}
													minSearchLength={3}
													searchEnabled={true}
													showDropDownButton={false}
													searchExpr={['name']}
													valueExpr={'name'}
													displayExpr={'name'}
													valueChangeEvent='input change keyup enter paste'
													onValueChanged={ this.onValueChanged}
													onSelectionChanged={this.handleDeviceNameClicked }
													onCustomItemCreating={this.customItemCreating}
													onEnterKey={ this.handleOnEnterKey }
													onPaste={ this.handleOnPaste }
												>
													{/*<Template name='loadIndicator' render={this.renderLoadIndicator} />*/}
													<SelectBoxButton
														name="search-button"
														location="before"
														options={{
														  icon: 'mdi mdi-magnify',
														  type: 'default'
														}}
													/>
													<SelectBoxButton
														ref={(ref) => this.selectBoxLoadingIcon = ref}
														name="loading"
														location="after"
														options={{ icon: isSearchDataLoading ? 'mdi mdi-loading' : "", type: 'default'}}
													/>
													<SelectBoxButton
														name="clear"
														location="after"
													/>
												</SelectBox>
											</FormControl>
											<IconButton onClick={this.handleConfiguration} title={ runTask ? 'Stop Timer' : 'Start Timer'} >
												{ runTask ? <TimerOutline/> : <TimerOffOutline /> }
											</IconButton>
											<IconButton onClick={this.handleRefresh}>
											 <Refresh/>
											</IconButton>
											<IconButton onClick={this.handleFullScreen}>
												{ isFullScreen ? <FullscreenExit /> : <FScreen /> }
											</IconButton>
										</div>
									}
							/>
							<CardContent id="deviceDetailsContentId" className={ isFullScreen ? classes.cardContentFull : classes.cardContent }>
							{ !deviceName &&
								<MuiAlert className={classes.ddAlert} severity="info" elevation={1} variant="outlined">
									Please search and select a device to view <b>Device Details</b>
								</MuiAlert>
							}
							{(deviceName && !isDataLoading && deviceData && _.size(deviceData) === 0) &&
								<MuiAlert className={classes.ddAlert} severity="warning" elevation={1} variant="outlined">
									Device <b>{deviceName} does not exists...</b>
								</MuiAlert>
							}
							{(deviceName && deviceData && _.size(deviceData) > 0) &&
								<Fragment>
									<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
										<Tabs value={value} onChange={this.handleChange} aria-label="basic tabs example">
											<Tab label="Real Time Data" {...a11yProps(0)} />
											<Tab label="General Information" {...a11yProps(1)} />
											<Tab label="Additional Information" {...a11yProps(2)} />
										</Tabs>
									</Box>
									<TabPanel value={value} index={0}>
										<div>
										<LocationForLumos raised={false} deviceData={deviceData} 
														handleDeviceNameClicked={this.handleDeviceNameClicked}
													/>
										</div>
										<div>
										<h2> Configuration and Reachability</h2>
										{deviceNameList.map(deviceName => 
											<div className={classes.divContent}>
											<ReachabilityandConfiguration deviceName={deviceName}/>
											</div>																		
										)}
										</div>
										<div>
										<TransportStatus deviceName={deviceName} devicesSameLocationData={devicesSameLocationData} deviceData={deviceData} hnsLocationId={hnsLocationID}/>
										</div>
											{deviceNameList.map(deviceName => 
													<div>
														<Grid className={classes.gridMain} container spacing={1} direction="row">
															<Grid item lg={4} md={3} sm={12} xs={12}>
																<ARPDetails deviceName={deviceName} hnsLocationId={hnsLocationID} />
															</Grid>
			
															<Grid item container spacing={1} lg={4} md={3} sm={12} xs={12} direction="column">
																<Grid item>
																	<IPSecDetails deviceName={deviceName} hnsLocationId={hnsLocationID}/>
																</Grid>												
																<Grid item>
																	<LinkMonitorDetails deviceName={deviceName} hnsLocationId={hnsLocationID}/>
																</Grid>
															</Grid>
			
															<Grid item lg={4} md={3} sm={12} xs={12}>
																<NICDetails deviceName={deviceName} hnsLocationId={hnsLocationID}/>
															</Grid>
														</Grid>
													</div>
											)}
													<div>
														<MerakiDeviceData deviceName={deviceName} devicesSameLocationData={devicesSameLocationData} deviceData={deviceData} hnsLocationId={hnsLocationID}/>
													</div>
													<div>
														<VeloCloudData deviceName={deviceName} devicesSameLocationData={devicesSameLocationData} deviceData={deviceData} hnsLocationId={hnsLocationID}/>
													</div>
													<div>
														<FortiSwitchInfo deviceName={deviceName} devicesSameLocationData={devicesSameLocationData} deviceData={deviceData} hnsLocationId={hnsLocationID}/>
													</div>
													<div>
														<FortiLanInfo deviceName={deviceName} devicesSameLocationData={devicesSameLocationData} deviceData={deviceData} hnsLocationId={hnsLocationID}/>
													</div>
													<div>
														<CiscoSwitchInfo deviceName={deviceName} devicesSameLocationData={devicesSameLocationData} deviceData={deviceData} hnsLocationId={hnsLocationID}/>
													</div>
										
									</TabPanel>
									<TabPanel value={value} index={1}>
									<Grid className={classes.gridMain} container spacing={1}>
										<Grid item container spacing={1} lg={4} md={6} sm={12} xs={12} direction="column">
											<Grid item>
												<DeviceStatus raised={false} deviceData={deviceData}
															  isDataLoading={isDataLoading}/>
											</Grid>
											<Grid item>
												<DeviceLocationInformation raised={false} deviceData={deviceData}
																		   handleDeviceNameClicked={this.handleDeviceNameClicked}
																		   devicesSameLocationData={devicesSameLocationData}
																		   isDataLoading={isDataLoading} pageType={pageType}/>
											</Grid>
											{/* <Grid item>
												<DeviceNetworks raised={false} deviceData={deviceData}
																deviceGroups={deviceGroups} isDataLoading={isDataLoading}/>
											</Grid> */}
											{/*(deviceData.className === 'HNS_VSAT' || deviceData.className === 'HNS_Terrestrial') &&
											<Grid item>
												<DeviceHealthStats raised={false} deviceHealthData={deviceHealthData}
																   deviceData={deviceData} isDataLoading={isDataLoading}/>
											</Grid>
											*/}
											{(deviceData.aaCfOpermode === 3 || deviceData.aaCfOpermode === 4) &&
											<Grid item>
												<DeviceActivePathInfo raised={false} deviceData={deviceData}
																	  isDataLoading={isDataLoading}/>
											</Grid>
											}
											<Grid item>
												{_.size(deviceEitData) > 0 &&
												<DeviceEITProdInfo raised={false} deviceData={deviceData}
																   deviceEitData={deviceEitData}
																   isDataLoading={isDataLoading}/>
												}
											</Grid>
										</Grid>
										<Grid item container spacing={1} lg={4} md={6} sm={12} xs={12} direction="column">
											<Grid item>
												<DeviceNetworkConfiguration raised={false} deviceData={deviceData}
																			deviceEitData={deviceEitData}
																			deviceDmnData={deviceDmnData}
																			deviceHtsData={deviceHtsData}
																			deviceNetworkEventsData={deviceNetworkEventsData}
																			isDataLoading={isDataLoading}/>
											</Grid>
											{(deviceData.han !== 'Currently Unavailable') &&
											<Grid item>
												<HANConfiguration raised={false} deviceData={deviceData} deviceAdditionalData={deviceAdditionalData}
																  devicesSameLocationData={devicesSameLocationData}
																  isDataLoading={isDataLoading}/>
											</Grid>
											}
											<Grid item>
												<DeviceMonitoringConfiguration raised={false} deviceData={deviceData}
																			   isDataLoading={isDataLoading}/>
											</Grid>
											<Grid item>
												<DeviceActions raised={false} deviceData={deviceData}
															   troubleShootingActions={troubleShootingActions}
															   advancedActions={advancedActions}
															   performanceGraphs={performanceGraphs} hanReports={hanReports}
															   usageReports={usageReports} secureActions={secureActions}
															   csrTools={csrTools} isDataLoading={isDataLoading}
															   isPolycomDevice={isPolycomDevice}
															   neighborDevicePoller={neighborDevicePoller}
															   userInfo={userInfo}/>
											</Grid>
											{(deviceData.vadbPrimaryPhoneNumber !== null && deviceData.vadbPrimaryPhoneNumber !== 'Currently Unavailable') &&
											<Grid item>
												<DeviceVADBConfiguration raised={false} deviceData={deviceData}
																		 isDataLoading={isDataLoading}/>
											</Grid>
											}

										</Grid>
									</Grid>
									</TabPanel>
									<TabPanel value={value} index={2}>
											
									<Card className={classes.root1} raised={true}>
										<CardHeader className={classes.cardHeader1} title={''}
											action={
												<Fragment>
													<div className={classes.tabs} id={'tabs'}>
														<Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
														 <Tab value={0} label="Events" {...a11yProps(0)} disabled />
														  <Tab value={1} label="Network Events" {...a11yProps(1)} disabled />
														  <Tab value={2} label="Archived Events" {...a11yProps(2)} />
														  <Tab value={3} label="Change Log" {...a11yProps(3)} disabled />
													  <Tab value={4} label="Additional Device Details" {...a11yProps(4)} disabled />
														</Tabs>
													</div>
													<FormControl>
														<SelectBox
														   className={classes.externalFilterSelect}
														   dataSource={externalFilterList}
														   defaultValue={externalFilter}
														   selectedItem={externalFilter}
														   displayExpr='label'
														   valueExpr='value'
														   width={150}
														   onValueChanged={this.handleExternalFilterChange}
														/>
													</FormControl>
												</Fragment>
											}
										/>
										<CardContent id="deviceDetailsOtherContentId" className={ classes.cardContent1 }>
											<div id={'device-details-tabs'}>
											  <TabPanel value={value} index={0}>
											   <DeviceEvents deviceName={deviceName} deviceData={deviceData} visibility={externalFilter} />
											  </TabPanel>
											  <TabPanel value={value} index={1}>
											   <NetworkEvents deviceName={deviceName} />
											  </TabPanel>
											  <TabPanel value={value} index={2}>
												<DeviceArchivedEvents deviceName={deviceName} visibility={externalFilter} />
											  </TabPanel>
											  <TabPanel value={value} index={3}>
												<DeviceChangeLog deviceData={deviceData} />
											  </TabPanel>
											  <TabPanel value={value} index={4}>
												<DeviceAdditionalDetails deviceName={deviceName} />
											  </TabPanel>
											</div>
										</CardContent>
									</Card>
									</TabPanel>
								</Fragment>
								}
							</CardContent>
								 <LoadPanel
									 ref={(ref) => this.deviceDetailsLoadPanel = ref}
									shadingColor="rgba(0,0,0,0.4)"
									position={{ of: '#deviceDetailsContentId'}}
									visible={isDataLoading}
								/>
						</Card>
				</div>
			</Fullscreen>
		)
	}
}

Lumos.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withOktaAuth(withStyles(styles, {withTheme: true})(Lumos));

import React, { PureComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { DataGrid, Column, Scrolling } from 'devextreme-react/data-grid';
import fetchClient from '../../axios'

class FortiSwitchInfo extends PureComponent {
    constructor(props){
        super(props);
        this.state= {
            deviceName: this.props.deviceData.name,
            devicesSameLocationData:this.props.devicesSameLocationData,
            switch: false,
            deviceInfo:[],
            switchResponse:[],
            switchSerial:''
        }
    }
    componentDidMount() {
    console.log("the device same location data inside switch info is "+JSON.stringify(this.props.devicesSameLocationData))
      const deviceInfoArray = [{
        name: this.props.deviceData.name,
        productCategory: this.props.deviceData.productCategory,
        parentSite: this.props.deviceData.psiteid
      }]
      this.state.devicesSameLocationData.forEach((item) => {
        let i={
            name: item.name,
            productCategory:item.productCategory,
            parentSite:item.psiteid
        }
        deviceInfoArray.push(i)
      })
      this.setState({deviceInfo:deviceInfoArray})
    }

    componentDidUpdate(prevProps,prevState) {
        if(prevProps.hnsLocationId !== this.props.hnsLocationId) {
            const deviceInfoArray = [{
                name: this.props.deviceData.name,
                productCategory: this.props.deviceData.productCategory,
                parentSite: this.props.deviceData.psiteid
              }]
              this.props.devicesSameLocationData.forEach((item) => {
                let i={
                    name: item.name,
                    productCategory:item.productCategory,
                    parentSite:item.psiteid
                }
                deviceInfoArray.push(i)
              })
              this.setState({deviceInfo:deviceInfoArray,
                                switch:false
                            })
        }
        if(prevState.deviceInfo!==this.state.deviceInfo){
            const deviceInfo = this.state.deviceInfo
            deviceInfo.forEach((item) => {
                if(item.productCategory === 'Switch' && item.parentSite !== 'notset'){
                    this.getSwitchInfo(item.parentSite, item.name)
                }
                else {
                    this.setState({
                        switchResponse:[],
                        switch:false
                    })
                }
            })
        }
    }

    getSwitchInfo = (deviceName, switchName) => {
        let params = {device_name:deviceName}
        this.setState({switch:true}, async () => {
            const response = await fetchClient.get('api/v1.0/action/device/diagSwitchControllerDumpClient', {params:params})
            this.setState({
                deviceName:switchName,
                switchResponse:response.data.results.data.fsw_1_data,
                switchSerial:response.data.results.data.fsw_1_serial,
                switch:true
            })
        })
    }

    render() { 
        const deviceName = this.state.deviceName;
        const isSwitch = this.state.switch;
        const {switchResponse} = this.state;
        let switchColumns = [
            {dataField:'interface', caption:'Interface'},
            {dataField:'mac', caption:'MAC'},
            {dataField:'vendor', caption:'Vendor'},
            {dataField:'vlan', caption:'VLANID'},

        ]
        return (  
            <div>
                {
                    isSwitch?           
                        <div>
                            <h2>Real Time Data for FortiSwitch {deviceName}</h2>
                            <Card>
                                <CardContent>
                                <DataGrid 
                                            dataSource={switchResponse}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            showBorders={true}
                                            rowAlternationEnabled={true}
                                            repaintChangesOnly={true}
                                            allowColumnReordering={true}
                                            allowColumnResizing={true}
                                            columnResizingMode={'widget'}
                                            columnAutoWidth={true}
                                            wordWrapEnabled={false}
                                            columnMinWidth={50}
                                            cellHintEnabled={true}
                                            noDataText={'waiting for data'}
                                    >
                                        <Scrolling showScrollbar={'always'} seNative={false}/>
                                        {
                                            switchColumns.map(row => {
                                                return (
                                                    // eslint-disable-next-line
                                                    <Column dataField={row.dataField} caption={row.caption} alignment={row.alignment} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)}/>
                                                );
                                            })
                                        }
                                    </DataGrid>
                                </CardContent>
                            </Card>
                        </div>: 
                            null
                }
            </div>
  
        );
    }
}
 
export default FortiSwitchInfo;
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto'
import './index.css';
import App from './App';
import injectTapEventPlugin from 'react-tap-event-plugin';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import * as constants from './constants';

try {
    injectTapEventPlugin();
} catch (e) {
    // do nothing
}

/*datadogRum.init({
    applicationId: process.env.DATADOG_APPLICATION_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'nms-tools',
    env: process.env.DATADOG_ENV,
    version: process.env.APP_VERSION,
    sampleRate: 100,
    trackInteractions: true
});*/
datadogRum.init({
    applicationId: constants['DATADOG_APPLICATION_ID'],
    clientToken: constants['DATADOG_CLIENT_TOKEN'],
    site: 'datadoghq.com',
    service: 'nmstools',
    env: constants['DATADOG_ENV'],
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.8',
    sampleRate: 100,
    trackInteractions: true
});
ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

registerServiceWorker();

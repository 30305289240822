import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import { withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
//import { getUserInfo } from '../components/common/jwtUtil';
import Paper from '@material-ui/core/Paper';

//import topNConversationsSankey from '../help/topNConversationsSankey.JPG';

const styles = theme => ({
	  root: {
	    flexGrow: 1
	  },
	  card: {
		  height: '100%'
	  },
	  leftIcon: {
	    marginRight: theme.spacing.unit,
	  },
	  avatar: {
	    margin: 10,
	    color: '#fff',
	    backgroundColor: green[500],
	  },
	  media: {
	    height: 500,
	  },
	  paper: theme.mixins.gutters({
	    paddingTop: theme.spacing.unit,
	    paddingBottom: theme.spacing.unit
	  }),
	});


class HelpContainer extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
		    hasErrors: false
		}
	};
	/**
	 * componentDidMount: 
	 */
    componentDidMount() {

    };
    /**
     * componentDidCatch: 
     */
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Error: ', info);
    };
	render() {
		const { classes } = this.props;
		const { hasErrors} = this.state;
		return (
				<div className={classes.root}>
					{hasErrors ?
						(<Grid container spacing={8}>
							<div>Page has erros, please fix!!!</div>
						</Grid>)
					:
						(<Grid container spacing={8}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
						        <Card className={classes.card}>
						          <CardHeader
						            avatar={
					        	      <Avatar className={classes.avatar}>
					        	        <HelpIcon />
					        	      </Avatar>
						            }
						            title={"Help"}
						            subheader={Moment().format("MM/DD/YYYY HH:mm A")}
						          />
						        </Card>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
						      <Paper className={classes.paper} elevation={4}>
						        <Typography variant="h5" component="h3">
						          Host
						        </Typography>
						      </Paper>
							</Grid>
					    </Grid>)
					}
				</div>
		)
	}
}
HelpContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
	  theme: PropTypes.object.isRequired
};
export default withRouter(withStyles(styles, {withTheme: true})(HelpContainer));

import React from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import moment from 'moment';
import NMSTools_Logo from '../images/brand/NMS_Tools_2_400x90.png';
import intro from '../images/intro.gif';
import NMS_TOOLS_BACKGROUND_IMAGE from '../images/brand/nmstoolsbackground.jpg';
import NMS_TOOLS_BACKGROUND_IMAGE_1 from '../images/brand/nmstoolsbackground-1.jpg';
const useStyles = makeStyles(theme => ({
  root: {
      height: '100vh',
      backgroundImage: `url(${NMS_TOOLS_BACKGROUND_IMAGE})`
  },
  root1: {
      height: '100vh',
      backgroundImage: `url(${NMS_TOOLS_BACKGROUND_IMAGE_1})`
  },
  container: {
      height: '100vh'
  },
  cardContent: {
    height: '60vh',
    textAlign: 'center',
    padding: 8
  },
  cardActions: {
    width: "100%"
  },
  paper: {
    margin: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80vh',
    //border: '1px solid rgba(224, 224, 224, 1)',
    //padding: 18
  },
  media: {
      // height: 120,
      // marginLeft: '25%',
      // width: '50%'
      backgroundColor: '#4378b8',
      borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  footer: {
      padding: 8,
      borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  alert: {
      padding: 8
  }
}));
const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const { authState } = useOktaAuth();
  let greet = 'Good Day';
  const curDate = moment();
  const year = curDate.get('year');
  const hour = curDate.get('hour');
  //console.log('Hour', hour);
    if (hour < 12) {
        greet = 'Good Morning';
    } else if (hour >= 12 && hour <= 17) {
        greet = 'Good Afternoon'
    } else if (hour >= 17 && hour <= 24) {
        greet = 'Good Evening';
    }
  if (authState.isPending) return <div>Loading....</div>;
/*  if (authState.isAuthenticated) {
    history.push('/dash-board')
  }*/

  const login = async () => history.push('/login');
  return authState.isAuthenticated ?
      <Redirect to={{ pathname: '/dash-board' }}/> :
      <div className={ (hour <12 ||  (hour >= 17 && hour <= 24)) ? classes.root : classes.root1}>
          <Container component="main" maxWidth="sm" className={classes.container}>
            <Card className={classes.paper} raise={true}>
                <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="NMS Tools"
                      className={classes.media}
                      image={NMSTools_Logo}
                      title="NMS Tools"
                    />
                </CardActionArea>
                <CardContent className={classes.cardContent}>
                    <Typography gutterBottom component="h2" variant="h5">
                        {greet}
                    </Typography>
                    <CardMedia
                      component="img"
                      className={classes.media}
                      image={intro}
                    />
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button fullWidth variant="contained" color="primary" onClick={login}>
                      Log In
                    </Button>
                </CardActions>
                <div className={classes.alert}>
                    <MuiAlert severity="info" elevation={1} variant="outlined">
                        Login using Okta credentials. If you do not have access, please contact <a href={"mailto:NADNMS@hughes.com"}>NAD NMS Team</a>
                    </MuiAlert>
                </div>
                <div className={classes.footer}>
                    <Typography align='center' component="p" variant="body2" color="textSecondary">
                      &#169;{year} Hughes Network Systems, LLC, an EchoStar company.
                    </Typography>
                    <Typography align='center' component="p" variant="body2" color="textSecondary">
                      All rights reserved.
                    </Typography>
                </div>
            </Card>
          </Container>
      </div>
};
export default Home;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import _ from 'lodash';
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { DataGrid } from 'devextreme-react/data-grid';



const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
                overflow: 'auto',
                padding: 0,
                '&:last-child': {
                        paddingBottom: theme.spacing(1)
                }
    },
    headerTitle: {
      fontSize: '1.2rem',
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    orange: {
        color: '#fff',
        backgroundColor: orange[500],
    },
    grey: {
        color: '#fff',
        backgroundColor: grey[500],
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableCellBold: {
        padding: '4px 16px',
        lineHeight: '0.80rem',
        fontWeight: 'bold',
        width: '300px'
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        fontWeight: 'bold',
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5'
    }
});
const dateFormat = 'MM/DD/YYYY hh:mm:ss A';
class PingOrCurrentStatusFortilanView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
    
        };
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    render() {
        const { classes, raised, title, actionData }  = this.props;
        let switchProps = [
            {fieldName: 'name', label: 'Name'},
            {fieldName: 'model', label: 'Model'},
            {fieldName: 'status', label: 'Status'},
            {fieldName: 'serial', label: 'Serial No'},
            {fieldName: 'localIp', label: 'Local IP'},
            {fieldName: 'remoteIp', label: 'Remote IP'},
            {fieldName: 'mac', label: 'MAC'},
            {fieldName: 'connectTime', label: 'Connected At'},
            {fieldName: 'osVer', label: 'OS Version'},
            {fieldName: 'firmwareStatus', label: 'Firmware Status'},
            {fieldName: 'cpuUsage', label: 'CPU Usage'},
            {fieldName: 'memoryUsage', label: 'Memory Usage'},
            {fieldName: 'pcbTemp', label: 'Temperature'}];
        let wifiProps = [
            {fieldName: 'name', label: 'Device Name'},
            {fieldName: 'status', label: 'Status'},
            {fieldName: 'serial', label: 'Serial Number'},
            {fieldName: 'localIp', label: 'Local IP'},
            {fieldName: 'remoteIp', label: 'Remote IP'},
            {fieldName: 'mac', label: 'MAC Address'},
            {fieldName: 'connectTime', label: 'Connected At'},
            {fieldName: 'upSince', label: 'Up Since'},
            {fieldName: 'osVer', label: 'Firmware Version'},
            {fieldName: 'firmwareStatus', label: 'Firmware Status'},
            {fieldName: 'contractEnd', label: 'License Expires'},
            {fieldName: 'clients', label: 'Connected Clients'},
            {fieldName: 'cpuUsage', label: 'CPU Usage'},
            {fieldName: 'memoryUsage', label: 'Memory Usage'}];
        let radioProps = [
            {fieldName: 'radioType', label: 'Radio Type'},
            {fieldName: 'mode', label: 'Radio Mode'},
            {fieldName: 'radioClients', label: 'Connected Clients'},
            {fieldName: 'operChannel', label: 'Operating Channel'},
            {fieldName: 'operTxPower', label: 'Transmit Power'},
            {fieldName: 'radioMac', label: 'MAC Address'},
            {fieldName: 'channelWidth', label: 'Channel Width'},
            {fieldName: 'channels', label: 'Channels'},
            {fieldName: 'bandwidthRx', label: 'Receive Bandwidth'},
            {fieldName: 'bandwidthTx', label: 'Transmit Bandwidth'},
            {fieldName: 'bytesRx', label: 'Bytes Received'},
            {fieldName: 'bytesTx', label: 'Bytes Transmitted'},
            {fieldName: 'errorsRx', label: 'Receive Errors'},
            {fieldName: 'errorsTx', label: 'Transmit Errors'},
            {fieldName: 'noise', label: 'Noise'},
            {fieldName: 'ssids', label: 'SSIDs'}];
        let port_columns = [
            {dataField:'name', caption: 'Port'},
            {dataField:'status', caption: 'Admin Status'},
            {dataField:'linkStatus', caption: 'Link Status'},
            {dataField:'speed', caption: 'Speed'},
            {dataField:'poeStatus', caption: 'PoE'},
            {dataField:'nativeVlan', caption: 'Native VLAN'},
            {dataField:'allowedVlans', caption: 'Allowed VLANs'},
            {dataField:'macs', caption: 'Device Info'}];

        const ports = actionData && actionData['ports'] ? actionData['ports'] : [];

        let devtype            = actionData && actionData['deviceType']? actionData['deviceType'] : "Unknown";
        let isSwitch           = devtype === "Switch"? true : false;
        let isAP               = isSwitch? false : true;
        let switchType         = isSwitch && actionData['model'].slice(-3) === "POE"? "POE" : "Non-POE";
        let deviceProps        = isSwitch? switchProps : wifiProps;
        let deviceDetailHeader = devtype + ' Details'

        // Remove poe port column for non POE switch
        if (isSwitch && switchType !== "POE") {
            let new_port_columns = port_columns.filter(function(value, index, arr){ return value["caption"] !== 'PoE'; });
            port_columns = new_port_columns;
        }

        return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                <CardHeader className={classes.cardHeader} classes={{title: classes.headerTitle}} title={title} />
                <CardContent className={classes.cardContent} >
                  <Table size="small">
                    <TableBody stripedRows>
                      <TableRow key='detailsHeader' className={classes.tableRow1}>
                        <TableCell colSpan={2} align="center" className={classes.tableCellBold}>{deviceDetailHeader}</TableCell>
                      </TableRow>
                      { _.map(deviceProps, function (value, key) {
                              let fieldName = value['fieldName'];
                              let val = actionData[fieldName];
                              let label = value['label'];

                              // convert epoch time stamps to human readable
                              if (fieldName === 'connectTime' || fieldName === 'upSince') {
                                  val = moment.unix(val).format(dateFormat);
                              } 

                              return (
                                     <TableRow key={key} className={ classes.tableRow }>
                                       <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                       <TableCell className={ classes.tableCell }>{val}</TableCell>
                                     </TableRow>
                                     );
                        })
                      }
                    </TableBody>
                  </Table>
      
                      { isAP ? 
                          actionData['radio'].map((item, index) => {
                              return (
                                <Table size="small">
                                  <TableBody stripedRows>
                                    <TableRow key='radioHeader' className={ classes.tableRow1 }>
                                      <TableCell colSpan={2} align="center" className={ classes.tableCellBold }>Radio {index + 1} Details</TableCell>
                                    </TableRow>
                                    { _.map(radioProps, function (value, key) {
                                      let fieldName = value['fieldName'];
                                      let val = item[fieldName];
                                      let label = value['label'];

                                      if (fieldName === 'ssids') {
                                          val = item[fieldName].join(', ');
                                      }

                                      if (/bytesRx|bytesTx|bandwidthRx|bandwidthTx/.test(fieldName)) {
                                          if (val > 1024) {
                                              val = (val / 1024).toLocaleString('en-US', {maximumFractionDigits: 2}) + " KB";
                                          } else {
                                              val = val + " Bytes"
                                          }
                                      }


                                      return (
                                             <TableRow key={key} className={ classes.tableRow }>
                                               <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                               <TableCell className={ classes.tableCell }>{val}</TableCell>
                                             </TableRow>
                                             );
                                    })}
                                  </TableBody>
                                </Table>
                              );
                         }) : ""
                      }
                      { isSwitch && ports ?
                          <Card className={classes.card1}>
                            <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Ports'}/>
                            <DataGrid
                              dataSource={ports}
                              columns={port_columns}
                              width="100%"
                              showBorders={true}
                            />
                          </Card>
                          : ""
                      }
                </CardContent>
                </Card>
        )
    }
}

PingOrCurrentStatusFortilanView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(PingOrCurrentStatusFortilanView);

import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import { SelectBox, Button as SelectBoxButton } from 'devextreme-react/select-box';
import _ from 'lodash';


import fetchClient from "../../axios";
import { LoadPanel } from 'devextreme-react/load-panel';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import ReactJson from 'react-json-view';
import ReactHtmlParse from 'react-html-parser';
import * as CryptoJS from 'crypto-js';

import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import * as constants from "../../constants";
import PamSuspendView from '../common/pamSuspend'
import AllDbDetails from '../../components/device-details/AllDbDetails';
import PacketCaptureForm from './PacketCaptureForm';
import ApGraph from './ApGraph';
import ApgTimeSeriesGraph from './ApgTimeSeriesGraph';
import PingView from '../../components/common/views/PingView';
import PingOrCurrentStatusView from '../../components/common/views/PingOrCurrentStatusView';
import PingInterfacesView from '../../components/common/views/PingInterfacesView';
import ArpCacheView from '../../components/common/views/ArpCacheView';
import TracerouteView from '../../components/common/views/TracerouteView';
import PingIpView from '../../components/common/views/PingIpView';
import CurrentClientStatsView from '../../components/common/views/CurrentClientStatsView';
import PingOrCurrentStatusFortiAPView from '../../components/common/views/PingOrCurrentStatusFortiAPView';
import PingOrCurrentStatusVeloCloudView from '../../components/common/views/PingOrCurrentStatusVeloCloudView';
import PingOrCurrentStatusFortilanView from '../../components/common/views/PingOrCurrentStatusFortilanView';
import PingOrCurrentStatusApeView from '../../components/common/views/PingOrCurrentStatusApeView';
import PingHtsView from '../../components/common/views/PingHtsView';
import PingEcmView from '../../components/common/views/PingEcmView';
import PingAwmsView from '../../components/common/views/PingAwmsView';
import PingManagementView from '../../components/common/views/PingManagementView';
import PingJupEntTerminalView from '../../components/common/views/PingJupEntTerminalView';
import HtsEnterpriseRfMetricsGraph from './HtsEnterpriseRfMetricsGraph';
import PingOrCurrentStatusWattboxView from '../../components/common/views/PingOrCurrentStatusWattboxView';
import PingOrCurrentStatusFortiManagerView from '../../components/common/views/PingOrCurrentStatusFortiManagerView';
import PingOrCurrentStatusFortiManagerApplianceView from '../../components/common/views/PingOrCurrentStatusFortiManagerApplianceView';
import PingOrCurrentStatusMerakiView from '../../components/common/views/PingOrCurrentStatusMerakiView';

const PROXY_SCRIPT_URL = constants['PROXY_SCRIPT_URL']; //process.env.PROXY_SCRIPT_URL;
const MESH_REPORT_URL = constants['MESH_REPORT_URL']; //process.env.MESH_REPORT_URL;
const NMP_URL = constants['NMP_URL']; //process.env.NMP_URL;
const PERFWARDEN_REPORTS_URL = constants['PERFWARDEN_REPORTS_URL']; //process.env.PERFWARDEN_REPORTS_URL;
const displayLineBreak = {whiteSpace: 'pre-line'}
const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: theme.palette.primary.light,
        padding: '4px 16px',
        height: '30px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
       }
    },
    cardContent: {
       padding: 8,
        border: '1px solid rgba(224, 224, 224, 1)',
        '&:last-child': {
            paddingBottom: theme.spacing(1)
        }
    },
    headerTitle: {
       fontSize: '1rem',
       color: '#ffffff'
    },
    iconButton: {
       padding: '3px 6px',
       color: '#ffffff'
    },
    form: {
       flexDirection: 'column'
    },
    formControl: {
       margin: theme.spacing(1),
       flexDirection: 'row',
       width: '97%'
    },
    formControl1: {
        margin: '4px 8px',
        flexDirection: 'row',
        width: '45%'
    },
    pingTypeSelect: {
        width: '100%'
    },
    pingNumberSelect: {
        width: '72%',
        marginLeft: 8
    },
    fieldSet: {
        margin: 4
    },
    fieldLabel: {
        fontSize: "13px !important",
        width: "30% !important",
        color: "#616161",
        fontWeight: 500
    },
    fieldValue: {
        fontSize: "13px !important",
        width: "70% !important",
        color: "#616161"
    },
    fieldValue1: {
        fontSize: "13px !important",
        width: "70% !important",
        color: "#616161"
    },
    deviceActionsAlertDiv: {
        padding: '4px',
        margin: '-10px -8px 4px -8px',
        color: '#a94442',
        backgroundColor: '#f2dede',
        borderColor: '#ebccd1',
        textAlign: 'center'
    }

});
class DeviceActions extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            interfaces: [{label:'ANY', value:'any'}],
            wattbox_outlets_config: [],
            ape_outlets_config: [],
            selectedTSAction: '',
            selectedTSActionLabel: '',
            selectedAdvancedAction: '',
            selectedAdvancedActionLabel: '',
            selectedPerformanceGraph: '',
            selectedPerformanceGraphLabel: '',
            selectedHanReport: '',
            selectedHanReportLabel: '',
            selectedUsageReport: '',
            selectedUsageReportLabel: '',
            selectedSecureAction: '',
            selectedSecureActionLabel: '',
            selectedCSRTool: 'allDatabaseDetails',
            selectedCSRToolLabel: '',
            popupVisible: false,
            selectedTSActionExecuting: false,
            selectedAdvancedActionExecuting: false,
            selectedPerformanceGraphExecuting: false,
            selectedHanReportExecuting: false,
            selectedUsageReportExecuting: false,
            selectedSecureActionExecuting: false,
            selectedCSRToolExecuting: false,
            title: 'Results for Ping',
            selectedPingType: 'lan',
            selectedPingNumber: 5,
            content: '',
            selectedBackbone: 0,
            isDataLoading: false,
            selectedWattboxOutlet: '',
            selectedApeOutlet: ''
        };
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.executeAction = this.executeAction.bind(this);
        this.handlePopupHidden = this.handlePopupHidden.bind(this);
        this.handleTargetIpChange = this.handleTargetIpChange.bind(this);
        this.getInterfaces = this.getInterfaces.bind(this);
        this.getWattboxOutletsConfig = this.getWattboxOutletsConfig.bind(this);
        this.getApeOutletsConfig = this.getApeOutletsConfig.bind(this);
        this.actionsScrollView = null;
        this.deviceDetailsPopup = null;
    }
    componentWillMount() {

    };
    componentDidMount() {
        const { deviceData } = this.props;
        if(deviceData['className'] !== 'HNS_AccelApp') {
            this.getInterfaces(deviceData['name']);
        }
        if(deviceData['nocid'] === 'OVRC') {
        const { deviceData } = this.props;
            this.getWattboxOutletsConfig(deviceData['name']);
        }
        if(deviceData['nocid'] === 'APE' || deviceData['model'] === 'HS54') {
        const { deviceData } = this.props;
            this.getApeOutletsConfig(deviceData['name']);
        }
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps['deviceData'] !== this.props['deviceData'] && nextProps['deviceData']['className'] !== 'HNS_AccelApp') {
            this.getInterfaces(nextProps['deviceData']['name']);
        }
    }
    handlePopupHidden = () => {
        this.setState({ popupVisible: false, content: '' });
        this.deviceDetailsPopup.instance.resetOption('width');
    };
    handleSelectionChange = (name, obj) => {
        const selectedItem = obj['selectedItem'];
        //console.log(selectedItem);
        if (selectedItem['type'] === 'performanceGraphs') {
            this.setState({
                [name]: selectedItem['action'],
                [name+'Label']: selectedItem['label'],
                [name+'Format']: selectedItem['format'],
                apgObject: selectedItem
            })
        } else {
            this.setState({
                [name]: selectedItem['action'],
                [name+'Label']: selectedItem['label'],
                [name+'Format']: selectedItem['format']
            })
        }
    };
    handlePingTypeChange = (e) => {
        this.setState({selectedPingType: e.target.value});
    };
    handlePingNumberChange = (e) => {
       this.setState({selectedPingNumber: e.target.value});
    };
    handleIpAddressChange = (e) => {
    this.setState({ipAddress: e.target.value});
    };
    handleTargetIpChange = (e) => {
    this.setState({targetIp: e.target.value});
    };
    getInterfaces = (deviceName) => {
        let url = '/api/v1.0/action/device/hardNic';
        if (deviceName) {
            //this.setState({isDataLoading: true});
            fetchClient.get(url, { params: { device_name: deviceName} })
              .then((res) => {
                    if (res.data && res.data.results) {
                        let interfaces = [{label:'ANY', value:'any'}];
                        let int_arr = _.map(res.data.results, 'interface');
                        _.forEach(int_arr, function(val) {
                            interfaces.push({label: _.upperCase(val), value: val })
                        });
                        this.setState({
                            interfaces: interfaces,
                            //isDataLoading: false
                        });
                    }
              })
             .catch( (error) => {
                console.log('Error: ' + error);
                this.setState({
                    interfaces: [],
                    //isDataLoading: false
                });
             });
        }
    };
    getWattboxOutletsConfig = (deviceName) => {
        let url = '/api/v1.0/wattbox/device/all_outlets_config';
        if (deviceName) {
            fetchClient.get(url, { params: { device_name: deviceName} })
              .then((res) => {
                    if (res.data && res.data.outlets) {
                        this.setState({
                            wattbox_outlets_config: res.data.outlets,
                            selectedWattboxOutlet: res.data.outlets[0]['number']
                            //isDataLoading: false
                        });
                    }
              })
             .catch( (error) => {
                console.log('Error: ' + error);
                this.setState({
                    interfaces: [],
                    //isDataLoading: false
                });
             });
        }
    };
    getApeOutletsConfig = (deviceName) => {
        let url = '/api/v1.0/action/device/pingOrCurrentStatusHughesApe';
        if (deviceName) {
            fetchClient.get(url, { params: { device_name: deviceName} })
              .then((res) => {
                    if (res.data.results && res.data.results.outlets) {
                        this.setState({
                            ape_outlets_config: res.data.results.outlets,
                            selectedApeOutlet: res.data.results.outlets[0]['outlet_number']
                            //isDataLoading: false
                        });
                    }
              })
             .catch( (error) => {
                console.log('Error: ' + error);
                this.setState({
                    ape_outlets_config: []
                    //isDataLoading: false
                });
             });
        }
    };
    executeAction = (actionType) => {
        const { interfaces } = this.state;
        const { deviceData, userInfo } = this.props;
        const action = this.state[actionType];
        let title = 'Results for ' + this.state[actionType+'Label'];
        const format = this.state[actionType+'Format'];
        const deviceName = deviceData['name'];
        const managementIp = deviceData['managementIp'];
        const poller = deviceData['poller'];
        const nmd = deviceData['nmd'];
        const deviceClassName = deviceData['className'];
        if (deviceName) {
            let url = '/api/v1.0/action/device/'+action;
            let params = {device_name: deviceName};
            if (action === 'launchLui') {
                let url = `${PROXY_SCRIPT_URL}?deviceid=${deviceName}&nmpuser=${userInfo['nickname']}`;
                window.open(url, '_blank');
                return;
            }
            if(action === 'pamSuspend') {
                title = 'PAM Suspend for '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <PamSuspendView deviceName={deviceName} userInfo={userInfo} /> });
                this.deviceDetailsPopup.instance.option('width', '400px');
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if(action === 'allDatabaseDetails') {
                title = 'All Database Details for '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <AllDbDetails deviceName={deviceData.name}/> });
                this.deviceDetailsPopup.instance.option('width', '750px');
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if(action === 'packetCapture') {
                title = 'Packet Capture';
                this.setState({ popupVisible: true, title: title, content: <PacketCaptureForm deviceData={deviceData} userInfo={userInfo} interfaces={interfaces} /> });
                this.deviceDetailsPopup.instance.option('width', '450px');
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if(action === 'ping') {
                params['ping_type'] = this.state['selectedPingType'];
                params['num_pings'] = this.state['selectedPingNumber'];
            }
            if(action === 'pingIp') {
                params['ip'] = this.state['ipAddress'];
                url = '/api/v1.0/action/device/pingIp';
            }
            if(action === 'clearDhcpLease') {
                params['target_ip'] = this.state['targetIp'];
                url = '/api/v1.0/action/device/clearDhcpLease';
            }
            if(action === 'resetWattboxOutlet') {
                params['outlet'] = this.state['selectedWattboxOutlet'];
                url = '/api/v1.0/action/device/resetWattboxOutlet';
            }
            if(action === 'resetHughesApeOutlet') {
                params['outlet'] = this.state['selectedApeOutlet'];
                url = '/api/v1.0/action/device/resetHughesApeOutlet';
            }
            if(action === 'getPepBackbones') {
                params['backbone'] = this.state['selectedBackbone'];
                url = '/api/v1.0/action/device/getPepBackbones';
            }
            if(action === 'discover') {
                params = {};
                params['device_names'] = deviceName;
                params['rundisco'] = true;
                url = '/api/v1.0/action/devices/discover';
            }
            if(action === 'rediscover') {
                params = {};
                params['device_names'] = deviceName;
                params['rundisco'] = true;
                url = '/api/v1.0/action/devices/rediscover';
            }
            this.setState({ [actionType+'Executing']: true, title: title });
             fetchClient.get(url, {params: params})
             .then(res => {
                let actionData;
                if (res.data) {
                    actionData = res.data;
                }
                if(format === 'json') {
                   const aData = actionData['results'] ? actionData['results'] : actionData;
                   let content = '';
                   switch(action) {
                      case 'ping':
                          content = this.state['selectedPingType'] === 'management' ? <PingManagementView raised={false} actionData={ aData } deviceName={deviceName} managementIp={managementIp} poller={poller} nmd={nmd} title="Management Ping" /> : <PingView raised={false} actionData={ aData } title="Ping" />;
                           break;
                      case 'pingOrCurrentStatus':
                          content = <PingOrCurrentStatusView raised={false} actionData={ aData } title="Ping / Current Status" />;
                          break;
                      case 'pingInterfaces':
                          content = <PingInterfacesView raised={false} actionData={ aData } deviceName={deviceName} title="Interface Status" />;
                          break;
                      case 'arpCache':
                            content = <ArpCacheView raised={false} actionData={ aData } deviceName={deviceName} title="ARP Cache" />;
                            break;
                          case 'traceroute':
                            content = <TracerouteView raised={false} actionData={ aData } deviceName={deviceName}  title="Traceroute" />;
                            break;
                          case 'pingIp':
                            content = <PingIpView raised={false} actionData={ aData } deviceName={deviceName}  title="Ping IP" />;
                            break;
                          case 'currentFortilanClientStats':
                            content = <CurrentClientStatsView raised={false} actionData={ aData } deviceName={deviceName} title="Current Fortinet Client Sessions" />;
                            break;
                          case 'currentClientStats':
                            content = <CurrentClientStatsView raised={false} actionData={ aData } deviceName={deviceName} title="Current Client Sessions" />;
                            break;
                          case 'pingOrCurrentStatusFortiAP':
                            content = <PingOrCurrentStatusFortiAPView raised={false} actionData={aData} deviceName={deviceName} title="Ping / Current Status"/>;
                            break;
                          case 'pingOrCurrentStatusVeloCloud':
                            content = <PingOrCurrentStatusVeloCloudView raised={false} actionData={aData} deviceName={deviceName} title="VeloCloud Status"/>;
                            break;
                          case 'pingOrCurrentStatusFortilan':
                            content = <PingOrCurrentStatusFortilanView raised={false} actionData={aData} deviceName={deviceName} title="Fortilan Status"/>;
                            break;
                          case 'pingOrCurrentStatusHughesApe':
                            content = <PingOrCurrentStatusApeView raised={false} actionData={aData} deviceName={deviceName} title="Hughes Active Power Edge Status"/>;
                            break;
                          case 'pingHts':
                            content = <PingHtsView raised={false} actionData={ aData } deviceName={deviceName}  title="Ping / Current Status" />;
                            break;
                          case 'pingEcm':
                            content = <PingEcmView raised={false} actionData={ aData } deviceName={deviceName}  title="Ping / Current Status" />;
                            break;
                          case 'pingAwms':
                            content = <PingAwmsView raised={false} actionData={ aData } deviceName={deviceName}  title="Ping / Current Status" />;
                            break;
                          case 'pingJupEntTerminal':
                            content = <PingJupEntTerminalView raised={false} actionData={ aData } deviceName={deviceName}  title="Ping / Current Status" />;
                            break;
                          case 'pingOrCurrentStatusWattbox':
                            content = <PingOrCurrentStatusWattboxView raised={false} actionData={ aData } deviceName={deviceName}  title="Ping / Current Status" />;
                            break;
                          case 'pingOrCurrentStatusFortiManager':
                            content = <PingOrCurrentStatusFortiManagerView raised={false} actionData={ aData } deviceName={deviceName}  title="Ping / Current Status" />;
                            break;
                          case 'pingOrCurrentStatusFortiManagerAppliance':
                            content = <PingOrCurrentStatusFortiManagerApplianceView raised={false} actionData={ aData } deviceName={deviceName}  classtitle="Ping / Current Status" />;
                            break;
                          case 'pingOrCurrentStatusMeraki':
                            content = <PingOrCurrentStatusMerakiView raised={false} actionData={ aData } deviceClassName={deviceClassName} deviceName={deviceName}  title="Ping / Current Status" />;
                            break;
                          default:
                            content = <ReactJson src={actionData} />
                        }
                        this.setState({
                            [actionType+'Executing']: false,
                            popupVisible: true,
                            content: content
                        });
                    } else {
                        const aData1 = actionData['results'];
                        const aData2 = aData1['Results'] ? aData1['Results'] : aData1;
                        aData2.replaceAll(' ', <>&nbsp;</>);
                        this.setState({
                            [actionType+'Executing']: false,
                            popupVisible: true,
                            content: <div style={displayLineBreak}>{ ReactHtmlParse(aData2) }</div>
                        });
                    }
                    //this.actionsScrollView.instance.update();
                })
                .catch(error => {
                console.error('Error:', error);
                this.setState({
                        [actionType+'Executing']: false
                });
            });
        }
    };
    renderReport = (reportType) => {
        const { deviceData } = this.props;
        const action = this.state[reportType];
        const apgObject = this.state.apgObject;
        let title = 'Results for ' + this.state[reportType+'Label'];
        const deviceName = deviceData['name'];
        const nmd = deviceData['nmd'];
        if (deviceName) {
            if(reportType === 'selectedPerformanceGraph' && apgObject.sub_type && apgObject.sub_type === 'apgGraphs') {
                title = apgObject.label;
                this.setState({ popupVisible: true, title: title, content: <ApgTimeSeriesGraph apgObject={apgObject} deviceData={deviceData} /> });
                this.deviceDetailsPopup.instance.option('width', '850px');
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if(action === 'apUsage') {
                title = 'Usage for '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <ApGraph graph_type={'ap_bandwidth'} deviceData={deviceData} /> });
                this.deviceDetailsPopup.instance.option('width', '650px');
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if(action === 'apUserCount') {
                title = 'User Count for '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <ApGraph graph_type={'ap_client_count'} deviceData={deviceData} /> });
                this.deviceDetailsPopup.instance.option('width', '650px');
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if(action === 'activeQosReport') {
                const nmdLC = deviceData.nmd.toLowerCase();
                let pwstring = nmdLC + "287963ADFDSDRE58696" + deviceData.name;
                if ( deviceData.aaCfOpermode === 4 ) {
                    pwstring = nmdLC + "287963ADFDSDRE58696" + deviceData.aaVirtualSiteId;
                }
                let pwhash = CryptoJS.MD5(pwstring);
                let url = `${PERFWARDEN_REPORTS_URL}/${nmdLC}?params=${nmdLC}/${deviceData.name}/${pwhash}`;
                if ( deviceData.aaCfOpermode === 4 ) {
                    url = `${PERFWARDEN_REPORTS_URL}/${nmdLC}?params=${nmdLC}/${deviceData.aaVirtualSiteId}/${pwhash}`
                }
                title = 'Active QoS Report for '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <iframe  title='1' id="activeQosReport" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if(action === 'activePathReport') {
                const nmdLC = deviceData.nmd.toLowerCase();
                let pwstring = nmdLC + "287963ADFDSDRE58696" + deviceData.name;
                if ( deviceData.aaCfOpermode === 4 ) {
                    pwstring = nmdLC + "287963ADFDSDRE58696" + deviceData.aaVirtualSiteId;
                }
                let pwhash = CryptoJS.MD5(pwstring);
                let url = `${PERFWARDEN_REPORTS_URL}/${nmdLC}?params=${nmdLC}/${deviceData.aaVirtualSiteId}/${pwhash}`;
                if ( deviceData.aaCfOpermode === 3 ) {
                    url = `${PERFWARDEN_REPORTS_URL}/${nmdLC}?params=${nmdLC}/${deviceData.name}/${pwhash}`
                }
                title = 'Active Path Report for '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <iframe  title='2' id="activeQosReport" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'dataUsage') {
                let url = `${NMP_URL}/data-usage?deviceid=${deviceName}&reportDuration=1`;
                title = 'Data Usage of '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <iframe  title='3' id="dataUsage" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'signalStrength') {
                let url = `${NMP_URL}/signal-strength?deviceid=${deviceName}&reportDuration=1`;
                title = 'Signal Strength of '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <iframe  title='4' id="dataUsage" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'signalQuality') {
                let url = `${NMP_URL}/sinr?deviceid=${deviceName}&reportDuration=1`;
                title = 'Signal Quality of '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <iframe  title='5' id="dataUsage" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'serviceType') {
                let url = `${NMP_URL}/lte?deviceid=${deviceName}&reportDuration=1`;
                title = 'Service Type of '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <iframe  title='6' id="dataUsage" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'htsEnterpriseRfMetrics') {
                title = 'HTS Enterprise RF Metrics for '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <HtsEnterpriseRfMetricsGraph deviceName={deviceName} /> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'hanReport') {
                let url = `${NMP_URL}/han-report?ddlCompanyID=${nmd}&txtSiteID=${deviceName}&hanDuration=1`;
                title = 'HAN Availability Graph for '+deviceName;
                this.setState({ popupVisible: true, title: title, content: <iframe  title='7' id="dataUsage" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'bytesVsTime') {
                let url = `${NMP_URL}/bytes-time?ddlCompanyID=${nmd}&txtSiteID=${deviceName}&ddlTrafficVolume=BOTH&grpDurations=CURWEEK`;
                title = 'Bytes Vs Time';
                //window.open(url, '_blank');
                this.setState({ popupVisible: true, title: title, content: <iframe  title='8' id="dataUsage" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'fapMinutes') {
                let url = `${NMP_URL}/fap?ddlCompanyID=${nmd}&txtSiteID=${deviceName}&ddlTrafficVolume=BOTH&&grpDurations=CURWEEK`;
                title = 'FAP Minutes';
                this.setState({ popupVisible: true, title: title, content: <iframe  title='9' id="dataUsage" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
                return;
            }
            if (action === 'meshTraffic') {
                let url = `${MESH_REPORT_URL}?ddlCompanyID=${deviceData.nmd}&txtSiteID=${deviceName}&ddlReportType=MESH&ddlTrafficVolume=BOTH&grpDurations=CURWEEK&TransportTypes=MESHVSAT`;
                title = 'MESH Traffic';
                this.setState({ popupVisible: true, title: title, content: <iframe  title='10' id="dataUsage" src={url}  frameborder="0" height="650px" width="100%"/> });
                this.deviceDetailsPopup.instance.repaint();
            }
        }

    };
    handleBackboneChange = (e) => {
        this.setState({selectedBackbone: e.target.value});
    };
    handleWattboxOutletChange = ({selectedItem}) => {
        this.setState({selectedWattboxOutlet: selectedItem['number']});
    };
    handleApeOutletChange = ({selectedItem}) => {
        this.setState({selectedApeOutlet: selectedItem['outlet_number']});
    };
    render() {
        const { selectedTSAction, selectedAdvancedAction, selectedHanReport, selectedPerformanceGraph,
            selectedUsageReport, selectedSecureAction, selectedCSRTool, popupVisible,
            title, selectedTSActionExecuting, selectedAdvancedActionExecuting, selectedPerformanceGraphExecuting,
            selectedHanReportExecuting, selectedUsageReportExecuting, selectedSecureActionExecuting, 
            selectedCSRToolExecuting, content, selectedPingType, selectedPingNumber, ipAddress, targetIp, 
            selectedBackbone, isDataLoading, wattbox_outlets_config, selectedWattboxOutlet, 
            ape_outlets_config, selectedApeOutlet } = this.state;
        const { classes, raised, deviceData, isPolycomDevice, neighborDevicePoller,
        troubleShootingActions, advancedActions, performanceGraphs, hanReports, usageReports, secureActions, csrTools} = this.props;
        const showManagementOption = ((['DSL', 'VSAT', 'FORTIMGR', 'FORTIMGRCLUST'].includes(deviceData.dmnType)) &&  deviceData.managementIp !== '0:0:0:0' && deviceData.managementIp !== 'notset' && deviceData.managementIp !== '');
        const showLanOption = (deviceData.poller !== 'PAM-ST-OI' && deviceData.poller !== 'Currently Unavailable' &&
            deviceData.poller !== 'notset' && deviceData.poller !== 'NotApplicable') || (isPolycomDevice && neighborDevicePoller);
        //let isCommandExecutig = false;
        //isCommandExecutig = (selectedTSActionExecuting || selectedAdvancedActionExecuting || selectedPerformanceGraphExecuting || selectedHanReportExecuting || selectedUsageReportExecuting || selectedSecureActionExecuting || selectedCSRToolExecuting);
        let selectedTSAction1 = selectedTSAction === '' ? (troubleShootingActions && troubleShootingActions.length > 0) ? troubleShootingActions[0]['action'] : '' : selectedTSAction;
        let selectedAdvancedAction1 = selectedAdvancedAction === '' ? (advancedActions && advancedActions.length > 0) ? advancedActions[0]['action'] : '' : selectedAdvancedAction;
        let selectedHanReport1 = selectedHanReport === '' ? (hanReports && hanReports.length > 0) ? hanReports[0]['action'] : '' : selectedHanReport;
        let selectedPerformanceGraph1 = selectedPerformanceGraph === '' ? (performanceGraphs && performanceGraphs.length > 0) ? performanceGraphs[0]['action'] : '' : selectedPerformanceGraph;
        let selectedUsageReport1 = selectedUsageReport === '' ? (usageReports && usageReports.length > 0) ? usageReports[0]['action'] : '' : selectedUsageReport;
        let selectedSecureAction1 = selectedSecureAction === '' ? (secureActions && secureActions.length > 0) ? secureActions[0]['action'] : '' : selectedSecureAction;
        let selectedCSRTool1 = selectedCSRTool === '' ? (csrTools && csrTools.length > 0) ? csrTools[0]['action'] : '' : selectedCSRTool;
        return (
                <Card className={classes.root} raised={raised}>
                    <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title='Actions' />
                    <CardContent id="deviceActionsPanel" className={ classes.cardContent }>
                            {(deviceData && _.size(deviceData) > 0 && deviceData['discovered'] === 'No') &&
                                <div className={classes.deviceActionsAlertDiv}>
                                    <b>Device is not discovered yet.</b>
                                </div>
                            }
                            {troubleShootingActions.length > 0 &&
                                <div className={"dx-fieldset " + classes.fieldSet}>
                                    <div className="dx-field">
                                        <div className={"dx-field-label " + classes.fieldLabel}>Troubleshooting</div>
                                        <div className={"dx-field-value " + classes.fieldValue}>
                                            <SelectBox id="custom-templates"
                                                       dataSource={troubleShootingActions}
                                                       value={selectedTSAction1}
                                                       valueExpr="action"
                                                       displayExpr="label"
                                                       stylingMode="filled"
                                                       hint="Select Troubleshooting Action"
                                                       placeholder="Select Troubleshooting Action"
                                                       onSelectionChanged={(obj) => this.handleSelectionChange('selectedTSAction', obj)}
                                            >
                                                <SelectBoxButton
                                                    name="dropDown"
                                                    location="after"
                                                />
                                                <SelectBoxButton
                                                    name="execute-button"
                                                    location="after"
                                                    options={{
                                                        icon: selectedTSActionExecuting ? 'mdi mdi-cog-outline mdi-24px mdi-spin mdi-color' : 'mdi mdi-cog-play-outline mdi-24px',
                                                        //icon: selectedTSActionExecuting ? 'mdi mdi-loading mdi-spin mdi-24px' : 'mdi mdi-play-circle-outline mdi-24px',
                                                        //icon: this.state.isCommandExecuted ? 'mdi mdi-play-circle-outline mdi-24px' : 'mdi mdi-play-pause-outline mdi-spin mdi-24px',
                                                        type: 'default',
                                                        disabled: (!selectedTSAction || selectedAdvancedActionExecuting || selectedPerformanceGraphExecuting || selectedHanReportExecuting || selectedUsageReportExecuting || selectedSecureActionExecuting || selectedCSRToolExecuting),
                                                        onClick: () => this.executeAction('selectedTSAction')
                                                    }}
                                                >
                                                </SelectBoxButton>
                                            </SelectBox>
                                            {selectedTSAction === 'ping' &&
                                            <Fragment>
                                                <FormControl className={classes.formControl1}>
                                                    <InputLabel htmlFor='pingType'>Ping Type</InputLabel>
                                                    <Select labelId='pingType' value={selectedPingType}
                                                            className={classes.pingTypeSelect}
                                                            onChange={(e) => this.handlePingTypeChange(e)}>
                                                        {showLanOption &&
                                                        <MenuItem key="pingType" value="lan" name="pingType">LAN</MenuItem>}
                                                        {showManagementOption &&
                                                        <MenuItem key="management" value="management"
                                                                  name="pingType">Management</MenuItem>}
                                                    </Select>
                                                </FormControl>
                                                <FormControl className={classes.formControl1}>
                                                    <InputLabel htmlFor='pingNumber'># Pings</InputLabel>
                                                    <Select labelId='pingNumber' value={selectedPingNumber}
                                                            className={classes.pingNumberSelect}
                                                            onChange={(e) => this.handlePingNumberChange(e)}>
                                                        <MenuItem key="5" value="5" name="pingNumber">5</MenuItem>
                                                        <MenuItem key="10" value="10" name="pingNumber">10</MenuItem>
                                                        <MenuItem key="25" value="25" name="pingNumber">25</MenuItem>
                                                        <MenuItem key="50" value="50" name="pingNumber">50</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Fragment>
                                            }
                                            {selectedTSAction === 'pingIp' &&
                                            <Fragment>
                                                <FormControl className={classes.formControl1}>
                                                    <InputLabel htmlFor='ip-address'>IP Address</InputLabel>
                                                    <TextField required id="ip-address" label="IP Address" value={ipAddress}
                                                               onChange={this.handleIpAddressChange}
                                                               autoComplete="IP Address"/>
                                                </FormControl>
                                            </Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {advancedActions.length > 0 &&
                                <div className={"dx-fieldset " + classes.fieldSet}>
                                <div className="dx-field">
                                    <div className={"dx-field-label " + classes.fieldLabel}>Advanced Actions</div>
                                    <div className={"dx-field-value " + classes.fieldValue}>
                                        <SelectBox id="custom-templates"
                                                   dataSource={advancedActions}
                                                   value={selectedAdvancedAction1}
                                                   valueExpr="action"
                                                   displayExpr="label"
                                                   stylingMode="filled"
                                                   hint="Select Advanced Action"
                                                   placeholder="Select Advanced Action"
                                                   onSelectionChanged={(obj) => this.handleSelectionChange('selectedAdvancedAction', obj)}
                                        >
                                            <SelectBoxButton
                                                name="dropDown"
                                                locatiopn="after"
                                            />
                                            <SelectBoxButton
                                                name="execute-button"
                                                locatiopn="after"
                                                options={{
                                                    icon: selectedAdvancedActionExecuting ? 'mdi mdi-cog-outline mdi-24px mdi-spin' : 'mdi mdi-cog-play-outline mdi-24px',
                                                    type: 'default',
                                                    disabled: (!selectedAdvancedAction || selectedTSActionExecuting || selectedPerformanceGraphExecuting || selectedHanReportExecuting || selectedUsageReportExecuting || selectedSecureActionExecuting || selectedCSRToolExecuting),
                                                    onClick: () => this.executeAction('selectedAdvancedAction')
                                                }}
                                            >
                                            </SelectBoxButton>
                                        </SelectBox>
                                        {selectedAdvancedAction === 'getPepBackbones' &&
                                        <FormControl className={classes.formControl1}>
                                            <InputLabel htmlFor='backboneNumber'>Backbone #</InputLabel>
                                            <Select labelId='backboneNumber' value={selectedBackbone}
                                                    className={classes.pingNumberSelect}
                                                    onChange={(e) => this.handleBackboneChange(e)}>
                                                <MenuItem key="0" value="0" name="backboneNumber">0</MenuItem>
                                                <MenuItem key="1" value="1" name="backboneNumber">1</MenuItem>
                                                <MenuItem key="2" value="2" name="backboneNumber">2</MenuItem>
                                                <MenuItem key="3" value="3" name="backboneNumber">3</MenuItem>
                                                <MenuItem key="4" value="4" name="backboneNumber">4</MenuItem>
                                                <MenuItem key="5" value="5" name="backboneNumber">5</MenuItem>
                                                <MenuItem key="6" value="6" name="backboneNumber">6</MenuItem>
                                                <MenuItem key="7" value="7" name="backboneNumber">7</MenuItem>
                                            </Select>
                                        </FormControl>
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                            {performanceGraphs.length > 0 &&
                                <div className={"dx-fieldset " + classes.fieldSet}>
                                <div className="dx-field">
                                    <div className={"dx-field-label " + classes.fieldLabel}>Performance Graphs</div>
                                    <div className={"dx-field-value " + classes.fieldValue}>
                                        <SelectBox id="custom-templates"
                                                   dataSource={performanceGraphs}
                                                   value={selectedPerformanceGraph1}
                                                   valueExpr="action"
                                                   displayExpr="label"
                                                   stylingMode="filled"
                                                   hint="Select Performance Graph"
                                                   placeholder="Select Performance Graph"
                                                   onSelectionChanged={(obj) => this.handleSelectionChange('selectedPerformanceGraph', obj)}
                                        >
                                            <SelectBoxButton
                                                name="dropDown"
                                                locatiopn="after"
                                            />
                                            <SelectBoxButton
                                                name="execute-button"
                                                locatiopn="after"
                                                options={{
                                                    icon: selectedPerformanceGraphExecuting ? 'mdi mdi-cog-outline mdi-24px mdi-spin' : 'mdi mdi-cog-play-outline mdi-24px',
                                                    type: 'default',
                                                    disabled: (!selectedPerformanceGraph || selectedTSActionExecuting || selectedAdvancedActionExecuting || selectedHanReportExecuting || selectedUsageReportExecuting || selectedSecureActionExecuting || selectedCSRToolExecuting),
                                                    onClick: () => this.renderReport('selectedPerformanceGraph')
                                                }}
                                            >
                                            </SelectBoxButton>
                                        </SelectBox>
                                    </div>
                                </div>
                            </div>
                            }
                            {hanReports.length > 0 &&
                                <div className={"dx-fieldset " + classes.fieldSet}>
                                <div className="dx-field">
                                    <div className={"dx-field-label " + classes.fieldLabel}>HAN Reports</div>
                                    <div className={"dx-field-value " + classes.fieldValue}>
                                        <SelectBox id="custom-templates"
                                                   dataSource={hanReports}
                                                   value={selectedHanReport1}
                                                   valueExpr="action"
                                                   displayExpr="label"
                                                   stylingMode="filled"
                                                   hint="Select HAN Report"
                                                   placeholder="Select HAN Report"
                                                   onSelectionChanged={(obj) => this.handleSelectionChange('selectedHanReport', obj)}
                                        >
                                            <SelectBoxButton
                                                name="dropDown"
                                                locatiopn="after"
                                            />
                                            <SelectBoxButton
                                                name="execute-button"
                                                locatiopn="after"
                                                options={{
                                                    icon: selectedHanReportExecuting ? 'mdi mdi-cog-outline mdi-24px mdi-spin' : 'mdi mdi-cog-play-outline mdi-24px',
                                                    type: 'default',
                                                    disabled: (!selectedHanReport || selectedTSActionExecuting || selectedAdvancedActionExecuting || selectedPerformanceGraphExecuting || selectedUsageReportExecuting || selectedSecureActionExecuting || selectedCSRToolExecuting),
                                                    onClick: () => this.renderReport('selectedHanReport')
                                                }}
                                            >
                                            </SelectBoxButton>
                                        </SelectBox>
                                    </div>
                                </div>
                            </div>
                            }
                            {usageReports.length > 0 &&
                                <div className={"dx-fieldset " + classes.fieldSet}>
                                <div className="dx-field">
                                    <div className={"dx-field-label " + classes.fieldLabel}>Usage Reports</div>
                                    <div className={"dx-field-value " + classes.fieldValue}>
                                        <SelectBox id="custom-templates"
                                                   dataSource={usageReports}
                                                   value={selectedUsageReport1}
                                                   valueExpr="action"
                                                   displayExpr="label"
                                                   stylingMode="filled"
                                                   hint="Select Usage Report"
                                                   placeholder="Select Usage Report"
                                                   onSelectionChanged={(obj) => this.handleSelectionChange('selectedUsageReport', obj)}
                                        >
                                            <SelectBoxButton
                                                name="dropDown"
                                                locatiopn="after"
                                            />
                                            <SelectBoxButton
                                                name="execute-button"
                                                locatiopn="after"
                                                options={{
                                                    icon: selectedUsageReportExecuting ? 'mdi mdi-cog-outline mdi-24px mdi-spin' : 'mdi mdi-cog-play-outline mdi-24px',
                                                    type: 'default',
                                                    disabled: (!selectedUsageReport || selectedTSActionExecuting || selectedAdvancedActionExecuting || selectedPerformanceGraphExecuting || selectedHanReportExecuting || selectedSecureActionExecuting || selectedCSRToolExecuting),
                                                    onClick: () => this.renderReport('selectedSecureAction')
                                                }}
                                            >
                                            </SelectBoxButton>
                                        </SelectBox>
                                    </div>
                                </div>
                            </div>
                            }
                            {secureActions.length > 0 &&
                                <div className={"dx-fieldset " + classes.fieldSet}>
                                <div className="dx-field">
                                    <div className={"dx-field-label " + classes.fieldLabel}>Secure Actions</div>
                                    <div className={"dx-field-value " + classes.fieldValue}>
                                        <SelectBox id="custom-templates"
                                                   dataSource={secureActions}
                                                   value={selectedSecureAction1}
                                                   valueExpr="action"
                                                   displayExpr="label"
                                                   stylingMode="filled"
                                                   hint="Select Secure Action"
                                                   placeholder="Select Secure Action"
                                                   onSelectionChanged={(obj) => this.handleSelectionChange('selectedSecureAction', obj)}
                                        >
                                            <SelectBoxButton
                                                name="dropDown"
                                                locatiopn="after"
                                            />
                                            <SelectBoxButton
                                                name="execute-button"
                                                locatiopn="after"
                                                options={{
                                                    icon: selectedSecureActionExecuting ? 'mdi mdi-cog-outline mdi-24px mdi-spin' : 'mdi mdi-cog-play-outline mdi-24px',
                                                    type: 'default',
                                                    disabled: (!selectedSecureAction || selectedTSActionExecuting || selectedAdvancedActionExecuting || selectedPerformanceGraphExecuting || selectedHanReportExecuting || selectedUsageReportExecuting || selectedCSRToolExecuting),
                                                    onClick: () => this.executeAction('selectedSecureAction')
                                                }}
                                            >
                                            </SelectBoxButton>
                                        </SelectBox>
                                        {selectedSecureAction === 'clearDhcpLease' &&
                                        <Fragment>
                                            <FormControl className={classes.formControl1}>
                                                <TextField required id="target-ip" label="Target IP" value={targetIp}
                                                           onChange={this.handleTargetIpChange}
                                                           autoComplete="Target IP"/>
                                            </FormControl>
                                        </Fragment>
                                        }
                                        {selectedSecureAction === 'resetWattboxOutlet' &&
                                        <Fragment>
                                            <div className={"dx-fieldset " + classes.fieldSet}>
                                                <div className="dx-field">
                                                    <div className={"dx-field-label " + classes.fieldLabel}>Outlet:</div>
                                                    <div className={"dx-field-value " + classes.fieldValue1}>
                                                        <SelectBox id="wattox-outlet"
                                                                   dataSource={wattbox_outlets_config}
                                                                   value={selectedWattboxOutlet}
                                                                   valueExpr="number"
                                                                   displayExpr="name"
                                                                   stylingMode="filled"
                                                                   hint="Select Wattbox Outlet to reset"
                                                                   placeholder="Select Wattbox Outlet"
                                                                   onSelectionChanged={(e) => this.handleWattboxOutletChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        }
                                        {selectedSecureAction === 'resetHughesApeOutlet' &&
                                        <Fragment>
                                            <div className={"dx-fieldset " + classes.fieldSet}>
                                                <div className="dx-field">
                                                    <div className={"dx-field-label " + classes.fieldLabel}>Outlet:</div>
                                                    <div className={"dx-field-value " + classes.fieldValue1}>
                                                        <SelectBox id="ape-outlet"
                                                                   dataSource={ape_outlets_config}
                                                                   value={selectedApeOutlet}
                                                                   valueExpr="outlet_number"
                                                                   displayExpr="name"
                                                                   stylingMode="filled"
                                                                   hint="Select Outlet to reset"
                                                                   placeholder="Select Outlet"
                                                                   onSelectionChanged={(e) => this.handleApeOutletChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                            <div className={"dx-fieldset " + classes.fieldSet}>
                                <div className="dx-field">
                                    <div className={"dx-field-label " + classes.fieldLabel}>CSR Tools</div>
                                    <div className={"dx-field-value " + classes.fieldValue}>
                                        <SelectBox id="custom-templates"
                                                   dataSource={csrTools}
                                                   value={selectedCSRTool1}
                                                   valueExpr="action"
                                                   displayExpr="label"
                                                   stylingMode="filled"
                                                   hint="Select CSR Tool"
                                                   placeholder="Select CSR Tools"
                                                   onSelectionChanged={(obj) => this.handleSelectionChange('selectedCSRTool', obj)}
                                        >
                                            <SelectBoxButton
                                                name="dropDown"
                                                locatiopn="after"
                                            />
                                            <SelectBoxButton
                                                name="execute-button"
                                                locatiopn="after"
                                                options={{
                                                    icon: selectedCSRToolExecuting ? 'mdi mdi-cog-outline mdi-24px mdi-spin' : 'mdi mdi-cog-play-outline mdi-24px',
                                                    type: 'default',
                                                    disabled: (!selectedCSRTool || selectedTSActionExecuting || selectedAdvancedActionExecuting || selectedPerformanceGraphExecuting || selectedHanReportExecuting || selectedUsageReportExecuting || selectedSecureActionExecuting),
                                                    onClick: () => this.executeAction('selectedCSRTool')
                                                }}
                                            >
                                            </SelectBoxButton>
                                        </SelectBox>
                                    </div>
                                </div>
                            </div>
                            <Fragment>
                                <Popup ref={(ref) => this.deviceDetailsPopup = ref}
                                       maxwidth={() => window.innerWidth / 1.5}
                                       maxHeight='95%'
                                       showTitle={true}
                                       title={title}
                                       dragEnabled={true}
                                       closeOnOutsideClick={false}
                                       visible={popupVisible}
                                       onHiding={this.handlePopupHidden}
                                       resizeEnabled={true}
                                >
                                    <ScrollView height='100%' width='100%' direction='both' id='actions-scrollview'
                                                ref={(ref) => this.actionsScrollView = ref}>
                                        <div>
                                            {content}
                                        </div>
                                    </ScrollView>
                                    {/*<LoadPanel visible={!isCommandExecuted}/>*/}
                                </Popup>
                                <LoadPanel
                                    shadingColor="rgba(0,0,0,0.4)"
                                    position={{of: '#deviceActionsPanel'}}
                                    visible={isDataLoading}
                                />
                            </Fragment>
                    </CardContent>
                </Card>
        )
    }
}

DeviceActions.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(DeviceActions);

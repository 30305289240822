import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import compose from 'recompose/compose';
import Fullscreen from "react-full-screen";
import { DataGrid, RemoteOperations, Paging, Pager, Column, Scrolling, ColumnChooser, LoadPanel,
    Export, SearchPanel, } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import fetchClient  from '../../axios';
import * as constants from '../../constants';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});
const statusFilterData =[{value: "all", text: "ALL"},
    {value: "Director Approved and Scheduled", text: "Director Approved and Scheduled"}, {value: "Approval Denied", text: "Approval Denied"}, {value: "Pending Review", text: "Pending Review"},
    {value: "Passed Review", text: "Passed Review"},{value: "Investigation Open", text: "Investigation Open"},{value: "Pending Acceptance", text: "Pending Acceptance"},
    {value: "Failed QA", text: "Failed QA"}, {value: "Resolved or Completed", text: "Resolved or Completed"}, {value: "Pending Security Scan", text: "Pending Security Scan"},
    {value: "MRB Approved", text: "MRB Approved"}, {value: "Complete - Pending QA", text: "Complete - Pending QA"}, {value: "Testing", text: "Testing"}, {value: "In Progress", text: "In Progress"},
    {value: "Escalated", text: "Escalated"}, {value: "Build Out Released", text: "Build Out Released"}, {value: "Pending Customer Verification", text: "Pending Customer Verification"},
    {value: "Assigned", text: "Assigned"}, {value: "Pending Documentation", text: "Pending Documentation"}, {value: "Qualified", text: "Qualified"},
    {value: "Troubleshooting", text: "Troubleshooting"}, {value: "EMRB Approved", text: "EMRB Approved"}, {value: "Pending - Customer Confirmation", text: "Pending - Customer Confirmation"},
    {value: "Scheduled", text: "Scheduled"}, {value: "Installed", text: "Installed"}, {value: "Pending Confirmation", text: "Pending Confirmation"},
    {value: "Hold", text: "Hold"}, {value: "Failed Review", text: "Failed Review"}, {value: "Pending Customer Approval", text: "Pending Customer Approval"},
    {value: "Pending Approval", text: "Pending Approval"}, {value: "Hold-Awaiting Information", text: "Hold-Awaiting Information"}, {value: "Planning", text: "Planning"},
    {value: "Pending Resolution", text: "Pending Resolution"}, {value: "Open - Troubleshooting", text: "Open - Troubleshooting"}
];
const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';
class SFCases extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            pageSizes: [5, 10, 15, 25, 50, 100],
            isFullScreen: false,
            runTask: false,
            gridColumns: [
                //{dataField: 'id', caption: 'ID', dataType: 'number', visible: false },
                {dataField: 'case_number', caption: 'Case Number', dataType: 'string', allowSorting: true, width: '100'},
                {dataField: 'case_type', caption: 'Case Type', dataType: 'string', allowSorting: true, width: '100' },
                {dataField: 'case_subtype', caption: 'Case Subtype', dataType: 'string', allowSorting: true, width: '100' },
                {dataField: 'status', caption: 'Status', dataType: 'string',allowSorting: true, width: '100' },
                {dataField: 'hsvc_start_time', caption: 'Start Date Time', dataType: 'datetime', allowSorting: true, sortOrder: 'desc', width: '100' },
                {dataField: 'hsvc_end_time', caption: 'End Date Time', dataType: 'datetime', allowSorting: true, width: '100' },
                {dataField: 'hsvc_service_type', caption: 'Service Type', dataType: 'string', allowSorting: true, width: '100' },
                {dataField: 'subject', caption: 'Subject', dataType: 'string', allowSorting: true, width: '200' },
                {dataField: 'hsvc_customers_affected', caption: 'Customers Affected', dataType: 'string', allowSorting: false, width: '150' },
                {dataField: 'hsvc_location', caption: 'Locations', dataType: 'string', allowSorting: false, width: '150' },
                {dataField: 'hsvc_beams', caption: 'Beams', dataType: 'string', allowSorting: false, width: '*' }
            ],
            filterStatus: statusFilterData[0].value,
            // start_date: moment().subtract(24, 'hours').format(dateFormat),
            // end_date: moment().format(dateFormat),
            start_date: moment().subtract(24, 'hours'),
            end_date: moment.now()
        };
        //this.clearRequests = this.clearRequests.bind(this);
        //this.handleRefreshModeChange = this.handleRefreshModeChange.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.dataGrid = null;
        //Editing

    }

    componentWillMount() {

    }

    componentDidMount() {
        const { runTask } = this.state;
        if (runTask) {
            clearInterval(this.sfCasesTask);
            this.sfCasesTask = setInterval(()=> this.dataGrid.instance.refresh(), constants.NMC_TASK_TIME)
        }
    };
    componentWillUnmount () {
        clearTimeout(this.sfCasesTask);
    };
    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }
    handleConfiguration = (e) => {
        const { runTask } = this.state;
        this.setState({runTask: !runTask});
        if (!runTask) {
            clearInterval(this.sfCasesTask);
            this.sfCasesTask = setInterval(()=> this.dataGrid.instance.refresh(), constants.NMC_TASK_TIME)
        } else {
            clearInterval(this.sfCasesTask);
        }
        e.component.option('text', runTask ? 'Start Timer' : 'Stop Timer');
        e.component.option('icon', runTask ? 'mdi mdi-timer-off-outline' : 'mdi mdi-timer-outline');
    };
    onRowPrepared = (e) => {

    };
    onToolbarPreparing = (e) => {
        const runTask = this.state.runTask;
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, columnChooserPanel, exportButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                /*if(item.name === 'searchPanel') {
                    items.splice(i, 1);
                    searchPanel = item;
                }*/
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'exportButton') {
                    items.splice(i, 1);
                    exportButton = item;
                }
            }
            toolbarItems.push({
                    widget: 'dxDateBox',
                    location: 'after',
                    locateInMenu: 'auto',
                    options: {
                        type: 'datetime',
                        width: 180,
                        elementAttr: {'id': 'sfStartDate'},
                        value: this.state.start_date,
                        onValueChanged: (args) => {this.handleStartDateChange(args)}
                    }
                },{
                    widget: 'dxDateBox',
                    location: 'after',
                    locateInMenu: 'auto',
                    options: {
                        type: 'datetime',
                        width: 180,
                        elementAttr: {'id': 'sfEndDate'},
                        value: this.state.end_date,
                        onValueChanged: (args) => {this.handleEndDateChange(args)}
                    }
                },{
                    widget: 'dxSelectBox',
                    location: 'after',
                    locateInMenu: 'auto',
                    options: {
                        type: 'default',
                        width: 180,
                        elementAttr: {'id': 'sfCasesFilterSelect'},
                        items: statusFilterData,
                        valueExpr: 'value',
                        displayExpr: 'text',
                        value: this.state.filterStatus,
                        onValueChanged: (args) => {this.handleSFCasesSelectChange(args)}
                    }
                }, exportButton,
                {
                    widget: 'dxButton',
                    location: 'after',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        elementAttr: {'id': 'SFCasesConfigBtn'},
                        hint: runTask ? 'Stop Timer' : 'Start Timer',
                        icon: runTask ? 'mdi mdi-timer-outline' : 'mdi mdi-timer-off-outline',
                        text: runTask ? 'Stop Timer' : 'Start Timer',
                        onClick: this.handleConfiguration.bind(this)
                    }
                },
                columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    };
    onContentReady = (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onCellPrepared = (e) => {
      /*if(e.rowType === 'header' && e.column.command === 'edit') {
          e.cellElement.textContent='Actions';
      }*/
    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
    handleSFCasesSelectChange = (args) => {
        this.setState({ filterStatus: args.value });
    };
    handleStartDateChange = (args) => {
        this.setState({ start_date: args.value });
    };
    handleEndDateChange = (args) => {
        this.setState({ end_date: args.value });
    };
render() {
        const { classes, theme, raised } = this.props;
        const { gridColumns, isFullScreen, pageSize, pageSizes, filterStatus, start_date, end_date } = this.state;
        //const title = "SF Cases";
        const dataStore = {
            store: new CustomStore({
                key: 'case_id',
                load: (loadOptions) => {
                    let params = { status: filterStatus };
                    params.start_date = moment(start_date).format(dateFormat);
                    params.end_date = moment(end_date).format(dateFormat);
                        if (loadOptions.sort) {
                            params.sort = loadOptions.sort[0].selector;
                            params.order = loadOptions.sort[0].desc ? 'desc' : 'asc';
                        }
                        params.offset = loadOptions.skip || 0;
                        params.limit = loadOptions.take || 100;
                        return fetchClient.get('/api/v1.0/nmc/sf_cases', { params: params })
                        //return fetchClient.get('/api/v1.0/nmc/sf_Cases')
                            .then(res => {
                                let sfCases = [];
                                let sfCasesTotal = 0;
                                if (res.data && res.data.data) {
                                    sfCases = res.data.data;
                                    sfCasesTotal = res.data.total;
                                }
                                return {
                                    data: sfCases,
                                    totalCount: sfCasesTotal
                                };
                            })
                            //.catch ((res) => { throw 'Data Loading Error';});
                            .catch (res => {
                                let sfCases = [];
                                let sfCasesTotal = 0;
                                if (res.data && res.data.data) {
                                    sfCases = res.data.data;
                                    sfCasesTotal = res.data.total;
                                }
                                return {
                                    data: sfCases,
                                    totalCount: sfCasesTotal
                                };
                            });
                }
            })
        };
        return (
            <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                <div className="full-screenable-node">
                    <Card className={classes.root} raised={raised}>
                        {/*<CardHeader className={classes.cardHeader} title={title}
                            action={
                                <div>
                                    <IconButton onClick={this.handleFullScreen}>
                                        { isFullScreen ? <FullscreenExit /> : <FScreen /> }
                                    </IconButton>
                                </div>
                            }
                        />*/}
                        <DataGrid id={'sfCasesGrid'}
                                ref={(ref) => this.dataGrid = ref}
                                height={isFullScreen ? '100vh' : `calc(100vh - ${theme.mixins.gridOffset}px)`}
                                dataSource={dataStore}
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                repaintChangesOnly={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnResizingMode={'widget'}
                                columnAutoWidth={true}
                                columnHidingEnabled={true}
                                wordWrapEnabled={false}
                                columnMinWidth={150}
                                cellHintEnabled={true}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onContentReady={this.onContentReady}
                                onCellPrepared={this.onCellPrepared}
                                onRowPrepared={this.onRowPrepared}
                            >
                            <SearchPanel visible={true} width={220} placeholder={'Search for sf cases'}/>
                            <ColumnChooser enabled={true} mode={'select'}/>
                            <Scrolling showScrollbar={'always'} useNative={false} />
                            <LoadPanel enabled={true}/>
                            <Export enabled={true} fileName={'sf_cases'} />
                            {
                                gridColumns.map(row => {
                                  return (
                                    <Column dataField={row.dataField} caption={row.caption} visible={row.visible} dataType={row.dataType}
                                            allowHeaderFiltering={row.allowHeaderFiltering} headerFilter={row.headerFilter} validationRules={row.validationRules}
                                            // eslint-disable-next-line
                                            width={row.width} allowSorting={row.allowSorting} sortOrder={row.sortOrder} customizeText={eval(row.customizeText)} />
                                  );
                                })
                            }
                            <RemoteOperations sorting={true} paging={true} />
                            <Paging defaultPageSize={pageSize}/>
                            <Pager visible={true} showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={pageSizes}/>
                        </DataGrid>
                    </Card>
                </div>
            </Fullscreen>
        )
    }
}

SFCases.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(SFCases);
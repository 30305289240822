import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Form, { ButtonItem, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import 'devextreme-react/autocomplete';
//import Validator, { ValidationRule } from 'devextreme-react/validator';
import fetchClient from "../../axios";
// import axios from "axios/index";
// import moment from 'moment';
// import _ from "lodash";
const styles = theme => ({
	root: {
	  width: '100%',
      border: 16
	},
	formButtons: {
		display: 'flex',
		justifyContent: 'center'
	}
});

class PacketCaptureForm extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isDataLoading: false,
			disableDownloadButton: false,
            pcapFileName: ''
        };
        this.userInfo = {};
        this.durations = [10, 20, 30,60,120,240];
        this.protocols = [{label: 'All', value: 'all'}, {label: 'All IP', value: 'allip'}, {label: 'All UDP', value: 'alludp'},
			{label: 'Non IP', value: 'nonip'}, {label: 'ICMP', value: 'icmp'}, {label: 'TCP', value: 'tcp'}];
        this.ipTypes =[{label: 'Any', value: 'host'}, {label: 'Source', value: 'source'}, {label: 'Destination', value: 'destination'}];
        this.verbose_levels = [{label: 'Header with IP(2)', value: 2}, {label: 'Header with Ethernet (3)', value: 3 }, {label: 'Header with IP and Interface Name (5)', value: 5 }, {label: 'Header with Ethernet and Interface Name(6)', value: 6 }];
        this.packetCaptureData = {
                device_name: this.props.deviceData['name'],
				management_ip: this.props.deviceData['managementIp'],
                nmd: this.props.deviceData['nmd'],
				user_name: this.props.userInfo['nickname'],
				interface: 'any',
				verbose_level: this.verbose_levels[0]['value'],
				max_packets: 4,
				host_filter: '',
				port_filter: '',
                protocol_filter: '',
                custom_filter: ''
        };
		this.packetCaptureDataAA = {
                device_name: this.props.deviceData['name'],
				management_ip: this.props.deviceData['managementIp'],
				user_name: this.props.userInfo['nickname'],
				duration: this.durations[0],
				protocol: this.protocols[0]['value'],
				ip_type: this.ipTypes[0]['value'],
				ip_address: '',
				port: ''
		};
        this.handleDownload = this.handleDownload.bind(this);
	 	this.handleCaptureAA = this.handleCaptureAA.bind(this);
	 	this.handleCapture = this.handleCapture.bind(this);
		this.validateForm = (e) => {
			  e.component.validate();
		};
    };
	componentWillMount() {

    }
	handleCaptureAA = () => {
    	//const { deviceData } = this.props;
    	//const { disableDownloadButton } = this.state;
        let url = '/api/v1.0/action/device/packet-capture-aa';
        this.setState({ disableDownloadButton: true });
        fetchClient.get(url, { params: this.packetCaptureDataAA })
          .then((res) => {
                if(res.data && res.data['file_name']) {
                    this.setState({
                        pcapFileName: res.data['file_name'],
                        disableDownloadButton: false
                    });
                }
          })
         .catch( (error) => {
            console.log('Error: ' + error);
                this.setState({
                    pcapFileName: '',
                    disableDownloadButton: true
                });
         });
	};
	handleCapture = () => {
    	//const { deviceData } = this.props;
    	//const { disableDownloadButton } = this.state;
    	this.setState({ disableDownloadButton: true });
        let url = '/api/v1.0/action/device/packet-capture';
        fetchClient.get(url, { params: this.packetCaptureData })
          .then((res) => {
                if(res.data && res.data['file_name']) {
                    this.setState({
                        pcapFileName: res.data['file_name'],
                        disableDownloadButton: false
                    });
                }
          })
         .catch( (error) => {
            console.log('Error: ' + error);
                this.setState({
                    pcapFileName: '',
                    disableDownloadButton: true
                });
         });
    };
	handleDownload = () => {
    	//const { deviceData } = this.props;
    	const { pcapFileName } = this.state;
    	const params = { file_name: pcapFileName };
        fetchClient({
			method: 'get',
			url: '/api/v1.0/action/device/packet-capture/download',
			responseType: 'blob',
			params: params
          }).then(res => {
                const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                const d_link = document.createElement('a');
                d_link.href = downloadUrl;
                d_link.setAttribute('download', pcapFileName);
                document.body.appendChild(d_link);
                d_link.click();
                document.body.removeChild(d_link);
          })
         .catch( (error) => {
            console.log('Error: ' + error);
         });
	};
	onDurationChanged = (e) => {
        this.packetCaptureDataAA['duration'] = e.value;
    };
	onProtocolChanged = (e) => {
	    this.packetCaptureDataAA['protocol'] = e.value;
    };
	onIpTypeChanged = (e) => {
	    this.packetCaptureDataAA['ip_type'] = e.value;
    };
	onIpAddressChanged = (e) => {
	    this.packetCaptureDataAA['ip_address'] = e.value;
    };
	onPortChanged = (e) => {
	    this.packetCaptureDataAA['port'] = e.value;
    };
	onInterfaceChanged = (e) => {
	    this.packetCaptureData['interface'] = e.value;
    };
	onVerboseLevelChanged = (e) => {
	    this.packetCaptureData['verbose_level'] = e.value;
    };
	onMaxPacketsChanged = (e) => {
	    this.packetCaptureData['max_packets'] = e.value;
    };
	onHostFilterChanged = (e) => {
	    this.packetCaptureData['host_filter'] = e.value;
    };
	onPortFilterChanged = (e) => {
        this.packetCaptureData['port_filter'] = e.value;
    };
	onProtocolFilterChanged = (e) => {
	    this.packetCaptureData['protocol_filter'] = e.value;
    };
 	render() {
        const { disableDownloadButton } = this.state;
		const { classes, deviceData, interfaces} = this.props;
		const isAADevice = (deviceData['className'] === 'HNS_AccelApp');
		return (
			    <div className={classes.root}>
                    {isAADevice ?
							<Form
								height={'100%'}
								formData={this.packetCaptureDataAA}
								labelLocation='top'
								colCount={1}
								readOnly={false}
								showColonAfterLabel={true}
								onContentReady={this.validateForm}
							>
								<SimpleItem dataField='duration' editorType='dxSelectBox'
                                    editorOptions={{ items: this.durations, value: this.packetCaptureDataAA['duration'], onValueChanged: this.onDurationChanged }}>
									<Label text='Duration (Seconds)'/>
								</SimpleItem>
								<SimpleItem dataField='protocol' editorType='dxSelectBox'
                                    editorOptions={{ dataSource: this.protocols, valueExpr: 'value', displayExpr: 'label', value: this.packetCaptureDataAA['protocol'], onValueChanged: this.onProtocolChanged  }} >
									<Label text='Protocol'/>
								</SimpleItem>
								<SimpleItem dataField='ip_type' editorType='dxSelectBox'
                                    editorOptions={{ dataSource: this.ipTypes, valueExpr: 'value', displayExpr: 'label', value: this.packetCaptureDataAA['ip_type'], onValueChanged: this.onIpTypeChanged }}>
									<Label text='IP Type'/>
								</SimpleItem>
								<SimpleItem dataField='ip_address'
                                    editorOptions={{ placeholder: 'IP Address (Optional)', onValueChanged: this.onIpAddressChanged }} >
									<Label text='IP Address'/>
								</SimpleItem>
								<SimpleItem dataField='port'
                                    editorOptions={{ placeholder: 'Port (Optional)', onValueChanged: this.onPortChanged }}>
									<Label text='Port'/>
								</SimpleItem>
								<GroupItem colCount={2}>
 									<ButtonItem horizontalAlignment='left' buttonOptions={{
										type: 'default',
										text: 'Capture',
                                        hint: 'Capture',
										width: '100%',
                                        icon: disableDownloadButton ? 'mdi mdi-cog-outline mdi-spin' : 'mdi mdi-cog-outline',
										onClick: this.handleCaptureAA.bind(this)
									}}/>
									<ButtonItem horizontalAlignment='right' buttonOptions={{
										type: 'success',
										text: 'Download',
                                        hint: 'Download',
										width: '100%',
                                        disabled: disableDownloadButton,
										onClick: this.handleDownload.bind(this)
									}}/>
								</GroupItem>
							</Form>
                        :
							<Form
								height={'100%'}
								formData={this.packetCaptureData}
								labelLocation='top'
								colCount={1}
								readOnly={false}
								showColonAfterLabel={true}
								onContentReady={this.validateForm}
							>
								<SimpleItem dataField='interface'
                                    editorType='dxSelectBox'
                                    editorOptions={{ dataSource: interfaces, valueExpr: 'value', displayExpr: 'label', value: this.packetCaptureData['interface'], onValueChanged: this.onInterfaceChanged }}>
									<Label text='Interface'/>
								</SimpleItem>
								<SimpleItem dataField='verbose_level' editorType='dxSelectBox'
                                    editorOptions={{ dataSource: this.verbose_levels, valueExpr: 'value', displayExpr: 'label', value: this.packetCaptureData['verbose_level'], onValueChanged: this.onVerboseLevelChanged }} >
									<Label text='Verbose Level'/>
								</SimpleItem>
								<SimpleItem dataField='max_packets'
                                    editorOptions={{ value: this.packetCaptureData['max_packets'], onValueChanged: this.onMaxPacketsChanged }} >
									<Label text='Max Packets'/>
								</SimpleItem>
								<SimpleItem dataField='host_filter'
                                    editorOptions={{ placeholder: 'Comma separated host IP Address', onValueChanged: this.onHostFilterChanged }}>
									<Label text='Host(s)'/>
								</SimpleItem>
								<SimpleItem dataField='port_filter'
                                    editorOptions={{ placeholder: 'Comma separated Ports', onValueChanged: this.onPortFilterChanged }}>
									<Label text='Port(s)'/>
								</SimpleItem>
								<SimpleItem dataField='protocol_filter'
                                    editorOptions={{ placeholder: 'Comma separated Protocols', onValueChanged: this.onProtocolFilterChanged }}>
									<Label text='Protocol(s)'/>
								</SimpleItem>
								<GroupItem colCount={2}>
									<ButtonItem horizontalAlignment='left' buttonOptions={{
										type: 'default',
										text: 'Capture',
                                        hint: 'Capture',
										width: '100%',
                                        icon: disableDownloadButton ? 'mdi mdi-cog-outline mdi-spin' : 'mdi mdi-cog-outline',
										onClick: this.handleCapture.bind(this)
									}}/>
									<ButtonItem horizontalAlignment='right' buttonOptions={{
										type: 'success',
										text: 'Download',
                                        hint: 'Download',
										width: '100%',
                                        disabled: disableDownloadButton,
										onClick: this.handleDownload.bind(this)
									}}/>
								</GroupItem>
                            </Form>
                    }
                </div>
			)
	}
}

PacketCaptureForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(PacketCaptureForm);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
const useStyles = makeStyles(theme => ({
  root: {
      height: '100vh'
  },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
  cardContent: {
    height: '100%',
  }
}));
const LuceneHelp = (props) => {
      const classes = useStyles();
      const filterOptions = ['han', 'elementName', 'count', 'impact', 'plsiteid', 'inMaintenance', 'description', 'eventText', 'rsInsertedAt',
                    'napSource', 'subCustomerId', 'instanceDisplayName', 'isProblem', 'lastNotifiedAt', 'eventState', 'certainty', 'sourceDomainName',
                    'elementClassName', 'category', 'classDisplayName', 'owner', 'active', 'eventName', 'siteTypeDescription', 'satName', 'state', 'managementIp',
                    'eventType', 'clearedAt', 'systemRedundancyGroup', 'userDefined10', 'eventDisplayName', 'createdAt', 'name', 'rsUpdatedAt', 'className',
                    'zip', 'nmd', 'hanSiteName', 'vendor', 'dslProvider', 'isRoot', 'troubleTicketID', 'provider', 'severity', 'dataCenter', 'gwid', 'stateUSA',
                    'dslType', 'willExpireAt', 'userDefined1', 'customerLocationId', 'source', 'userDefined3', 'instanceName', 'userDefined2',
                    'rsDatasource', 'userDefined5', 'acknowledged', 'userDefined4', 'userDefined7', 'userDefined6', 'userDefined9', 'elementDisplayName',
                    'userDefined8', 'discovered', 'changedAt', 'rsAlias', 'id'];
      const topologyAttributes = ['psiteid', 'han', 'cpehan', 'dmnType', 'oldPoller', 'discoveryDescription', 'sam', 'city', 'plsiteid','timezone',
                        'vpgw', 'rsInsertedAt', 'subCustomerId', 'napSource', 'longitude', 'vadbBackupPhoneNumber', 'accessMode', 'userdef2', 'discoveryChangedAt',
                        'managedByRoss', 'seedip', 'vadbPrimaryPhoneNumber', 'diuSerialNum', 'userdef1', 'discoveryState', 'timeOfDayGroupRsId', 'totalSuspendTime',
                        'pamSuspendStartDate', 'latitude', 'siteTypeDescription', 'satName', 'state', 'managementIp', 'pamSuspendEndDate', 'systemRedundancyGroup',
                        'isManaged', 'channelid', 'provisionStatus', 'poller', 'name', 'rsUpdatedAt', 'softwareVersion', 'className', 'zip', 'nmd', 'vendor', 'hanSiteName',
                        'dslProvider', 'readCommunity', 'provider', 'gwid', 'dataCenter', 'stateUSA', 'dslType', 'customerLocationId', 'source', 'address', 'vlan', 'discovered',
                        'nocid', 'napKey', 'rsAlias', 'id'];
  return (
      <div className={ classes.root }>
            <Card  raise={true}>
                {/*<CardHeader className={ classes.cardHeader } title={'Lucene Help'}
                        action={
                                <IconButton onClick={props.handleDock}>
                                  <Close/>
                                </IconButton>
                            }
                    />*/}
                <CardContent className={classes.cardContent}>
                    <div>
                        <section>
                            <h4>Any of the query boxes will accept queries:</h4>
                            <p><code>(name:JLH*12* OR name:JLH*13*) AND className:HNS_VOIP_AP</code>
                                Note that the <b>AND</b> and <b>OR</b> between query fields must be <b>UPPER</b> case.
                            </p>
                        </section>
                        <section>
                            <h4>Filter Options and Attributes for Notifications:</h4>
                            <div>
                                {
                                    filterOptions.map(row => {
                                      return (
                                        <dd className="col-sm-2 col-md-2 col-lg-2">{row}</dd>
                                      );
                                    })
                                }
                            </div>
                        </section>
                        <section>
                            <h4>For Notifications:</h4>
                            <h4>Couple of choices:</h4>
                            <p>Append, <code> AND lastNotifiedAt:[currentTime-17days TO currentTime-11days] </code> to
                                the query.</p>
                            <p>Remember there are two timestamps related to events: </p>
                            <p><b>First Notified</b> This is the timestamp that the event first started.</p>
                            <p><b>Last Notified</b> This is the timestamp that the event last occurred. This is equal to
                                First notified if the count = 1 (i.e. The device went down and stayed down). </p>
                            <h4>The syntax above is to get the Last Notified time into the query. If you want when an
                                event First Notified change it to:</h4>
                            <code> AND createdAt:[currentTime-17days TO currentTime-11days] </code>
                            <p>
                                This will at least bracket it down for you to a reasonable number that you can further
                                sort in Excel.
                            </p>
                            <p>Or, you can get the Epoch timestamp from someplace like: <a  href="http://www.epochconverter.com/" target="_blank" rel="noopener noreferrer">Epoch Converter/</a>
                                and convert the GMT date to a serial number of seconds since the Epoch and add three
                                zeros to make it milliseconds
                            </p>
                            <h4> And making your query now:</h4>
                            <code> AND lastNotifiedAt:[1314417600000 TO 1314849600000] </code>
                        </section>
                        <section>
                            <h4>Example of how to find particular provider outage from yesterday:</h4>
                            <ul>
                                <li>Using Covad in California for this example.</li>
                                <li>Use the .Service Tree. branch of the NMP Summary View</li>
                                <li>Expand Terrestrial by Provider to the Provider (and State if desired)</li>
                                <li> Select, "Get Archived Events" from the branch you are researching</li>
                                <li>This provides the entire list of archived events for devices that are using the
                                    branch from all time. Filter using additional search syntax.
                                </li>
                                <li>For yesterday, put a range for the time the event was created using the
                                    lastNotifiedAt attribute. Use the .currentTime value modified using minus (-) number
                                    of days/hours/minutes.
                                </li>
                                <li><code>(eventDisplayName:Down OR eventDisplayName:HNS_PTState) AND
                                    lastNotifiedAt:[currentTime-34hours TO currentTime-10hours] AND ...</code> Plus the
                                    rest of the pre-built query.
                                </li>
                            </ul>
                            <h4>Entire query for this example:</h4>
                            <code>(eventDisplayName:Down OR eventDisplayName:HNS_PTState) AND
                                lastNotifiedAt:[currentTime-34hours TO currentTime-10hours] AND NOT nmd:"" AND
                                (elementClassName:HNS_Terrestrial) AND napSource:"Covad"</code>
                        </section>
                        <section>
                            <h4>Topology Attributes:</h4>
                            <div>
                                {
                                    topologyAttributes.map(row => {
                                      return (
                                        <dd className="col-sm-2 col-md-2 col-lg-2">{row}</dd>
                                      );
                                    })
                                }
                            </div>
                        </section>
                    </div>
                </CardContent>
            </Card>
      </div>
  )
};
export default LuceneHelp;
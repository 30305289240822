import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import compose from 'recompose/compose';
import { DataGrid, Column } from 'devextreme-react/data-grid';
//import PieChart, { LoadingIndicator, Series, Label, Connector, Size, Font, Legend, Tooltip } from 'devextreme-react/pie-chart';
import { Chart, Series, Label, Size, Legend, Tooltip, CommonSeriesSettings, CommonAxisSettings } from 'devextreme-react/chart';
// import { Item } from 'devextreme-react/form';
// import CustomStore from 'devextreme/data/custom_store';
// import { formatDate } from 'devextreme/localization';
// import axios from 'axios';
// import fetchClient  from '../../axios';
// import * as constants from '../../constants';
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import lightBlue from "@material-ui/core/colors/lightBlue";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";
const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;
const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    masterDetailCaption: {
        padding: '0 0 5px 10px',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    masterDetailCaption1: {
        padding: '8px 0 5px 10px',
        fontSize: '14px',
        fontWeight: 'bold'
    }
});

class HughesServicesTemplateView extends PureComponent {
    constructor(props) {
        super(props);
        this.serviceInstances = this.props.data.data.service_instances;
        this.inroutes = this.props.data.data.inroutes;
        this.renderServicesSparkLineChart = this.renderServicesSparkLineChart.bind(this);
        this.renderInroutesSparkLineChart = this.renderInroutesSparkLineChart.bind(this);
        this.servicesChartRef = React.createRef();
        this.inroutesChartRef = React.createRef();

    }
    customizeText = (point) => {
          return `${point.percentText}`;
    };
    chartTooltipTemplate = (info) => {
        let count = 0;
        if(info['point'] && info['point']['data'] && info['point']['data'][`${info.seriesName}_counts`]) {
            count = info['point']['data'][`${info.seriesName}_counts`]
        }
      return (
        <div className="state-tooltip">
          <div>
            <span className="">{_.capitalize(info.seriesName)}</span>: {info.percentText}
          </div>
          <div>
            <span className="">Count</span>: {formatNumber(count)}
          </div>
        </div>
      );
    };
    customizePoint = (point) => {
		switch (point.seriesName) {
			case 'Critical':
				return { color: red[500]};
			case 'Major':
				return { color: orange[500]};
			case 'Minor':
				return { color: yellow[500]};
			case 'Unknown':
				return { color: lightBlue[500]};
			case 'Normal':
				return { color: green[500]};
            default:
                //None
		}
	};
    customizeLabel = (arg) => {
        if (arg.value > 20) {
            return {
                visible: true,
                position: 'inside',
                customizeText: function(e) {
                    return `${ formatNumber(e.valueText) }%`
                }
            }
        }
    };
    renderServicesSparkLineChart = (cellInfo) => {
        let states = cellInfo['data']['device_count_by_state'];
        let mStates = [];
        let rObj = {};
        // eslint-disable-next-line
        states.map(obj => {
            rObj[obj['label']] = obj['percent'];
            rObj[`${obj['label']}_counts`] = obj['value'];
            rObj['label'] = 'Status';
        });
        mStates.push(rObj);
        return (
                <Chart ref={ this.servicesChartRef }
                        id='hughes-overview-services-chart'
                        dataSource={ mStates }
                        rotated={true}
                        customizePoint={this.customizePoint}
                        customizeLabel={this.customizeLabel}
                    >
                    <CommonAxisSettings grid={{visible: false}} tick={{ visible: false }} visible={false} label={{ visible: false}}/>
                    <CommonSeriesSettings argumentField='label' type='fullstackedbar' minBarSize={2}>
                        <Label visible={false} font={{size: 11}}/>
                    </CommonSeriesSettings>
                    <Series valueField='Critical' name='Critical' />
                    <Series valueField='Major' name='Major' />
                    <Series valueField='Minor' name='Minor' />
                    <Series valueField='Unknown' name='Unknown' />
                    <Series valueField='Normal' name='Normal' />
                    <Legend visible={false}/>
                    <Tooltip enabled={true} contentRender={this.chartTooltipTemplate} cornerRadius={4} zIndex={3000}/>
                    <Size width={200} height={40} />
                </Chart>
        )
    };
    renderInroutesSparkLineChart = (cellInfo) => {
        let states = cellInfo['data']['device_count_by_state'];
        let mStates = [];
        let rObj = {};
        // eslint-disable-next-line
        states.map(obj => {
            rObj[obj['label']] = obj['percent'];
            rObj[`${obj['label']}_counts`] = obj['value'];
            rObj['label'] = 'Status';
        });
        mStates.push(rObj);
        return (
                <Chart ref={ this.inroutesChartRef }
                        id='hughes-overview-inroutes-chart'
                        dataSource={ mStates }
                        rotated={true}
                        customizePoint={this.customizePoint}
                        customizeLabel={this.customizeLabel}
                    >
                    <CommonAxisSettings grid={{visible: false}} tick={{ visible: false }} visible={false} label={{ visible: false}}/>
                    <CommonSeriesSettings argumentField='label' type='fullstackedbar' minBarSize={2}>
                        <Label visible={false} font={{size: 11}}/>
                    </CommonSeriesSettings>
                    <Series valueField='Critical' name='Critical' />
                    <Series valueField='Major' name='Major' />
                    <Series valueField='Minor' name='Minor' />
                    <Series valueField='Unknown' name='Unknown' />
                    <Series valueField='Normal' name='Normal' />
                    <Legend visible={false}/>
                    <Tooltip enabled={true} contentRender={this.chartTooltipTemplate} cornerRadius={4} zIndex={3000}/>
                    <Size width={200} height={40} />
                </Chart>
        )
    };
render() {
        const { classes, data } = this.props;
        const datacenter = data['data']['name'];
        return (
                  <Fragment>
                    <div className={classes.masterDetailCaption}>
                        {`Services at ${datacenter}`}
                    </div>
                    <DataGrid
                        dataSource={this.serviceInstances}
                        showBorders={true}
                        columnAutoWidth={true}
                        showRowLines={true}
                        showColumnLines={true}
                        >
                        <Column dataField='name' caption='Name' dataType='string' sortOrder='asc' width={200} />
                        {/*<Column dataField='device_count' caption='Total Devices' dataType='number' format={{ type: "fixedPoint", precision: 0 }}/>*/}
                        <Column dataField='active' caption='Active' dataType='string' width={150} />
                        <Column dataField="State" caption="Status" cellRender={this.renderServicesSparkLineChart} alignment="center" width={215} />
                        <Column dataField='notes' caption='Notes' dataType='string' />
                    </DataGrid>
                    <div className={classes.masterDetailCaption1}>
                        {`Inroutes at ${datacenter}`}
                    </div>
                    <DataGrid
                        dataSource={this.inroutes}
                        showBorders={true}
                        columnAutoWidth={true}
                        showRowLines={true}
                        showColumnLines={true}
                        >
                        <Column dataField='name' caption='Name' dataType='string' sortOrder='asc' width={200} />
                        {/*<Column dataField='device_count' caption='Total Devices' dataType='number' format={{ type: "fixedPoint", precision: 0 }} />*/}
                        <Column dataField='active' caption='Active' dataType='string' width={150} />
                        <Column dataField="State" caption="Status" cellRender={this.renderInroutesSparkLineChart} alignment="center" width={215} />
                        <Column dataField='notes' caption='Notes' dataType='string' />
                    </DataGrid>
                  </Fragment>
        )
    }
}

HughesServicesTemplateView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(HughesServicesTemplateView);
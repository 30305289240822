import React, {  PureComponent } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Fullscreen from "react-full-screen";
import { Fullscreen as FScreen, FullscreenExit } from 'mdi-material-ui';


const styles = theme => ({
    root: {
    },
	cardHeader: {
		padding: theme.spacing.unit,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing.unit * 2,
			paddingRight: theme.spacing.unit * 2,
		},
		  height: theme.mixins.cardHeader.height,
		  borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	cardContent : {
		padding: theme.spacing.unit,
		overflow: 'auto',
		height: `calc(100vh - ${theme.mixins.gridOffset}px)`,
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	 },
    cardHeader1: {
        border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: '#eeeeee',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContentFull : {
		padding: theme.spacing.unit,
		overflow: 'auto',
		height: '100%',
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	 },
});

class Admin extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			isDataLoading: false,
			isFullScreen: false,
		};
	}
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
	render() {
		const { classes, raised=false } = this.props;
		const { isFullScreen } = this.state;
		return (
			<Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
				<div className="full-screenable-node">
					<Card className={classes.root} raised={raised}>
						<CardHeader className={ classes.cardHeader } title="Admin"
							action={
									<div>
										<IconButton onClick={this.handleFullScreen}>
											{ isFullScreen ? <FullscreenExit /> : <FScreen /> }
										</IconButton>
									</div>
								}
						/>
						<CardContent className={ classes.cardContent }>

						</CardContent>
					</Card>
				</div>
			</Fullscreen>
		)
	}
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withOktaAuth(withStyles(styles, {withTheme: true})(Admin));
//import React from 'react';
//import { textFormatter, dateFormatter, stateRender } from "../common/utils";
export const  EventsGridColumns = [
	//{ dataField: 'name', caption:  'Name' },
	{ dataField: 'elementName', caption:  'Element Name' },
	{ dataField: 'elementDisplayName', caption:  'Element Display Name' },
	{ dataField: 'eventName', caption:  'Event Name' },
	{ dataField: 'eventDisplayName', caption:  'Event Display Name' },
	{ dataField: 'elementClassName', caption:  'Element Class Name' },
	{ dataField: 'eventState', caption:  'Event State' },
	{ dataField: 'eventType', caption:  'Event Type' },
    //{ dataField: 'eventText', caption:  'Event Text' },
	{ dataField: 'aaCfOpermode', caption:  'AA CF Operational Mode' },
	{ dataField: 'aaVirtualSiteId', caption:  'AA Virtual Site Id' },
	{ dataField: 'acknowledged', caption:  'Acknowledged' },
	{ dataField: 'active', caption:  'Active' },
	{ dataField: 'assetId', caption:  'Asset Id' },
	{ dataField: 'beamid', caption:  'Beam Id' },
	{ dataField: 'category', caption:  'Category' },
	{ dataField: 'certainty', caption:  'Certainty' },
	//{ dataField: 'changedAt', caption:  'Changed At' },
	{ dataField: 'channelid', caption:  'Channel Id' },
	{ dataField: 'classDisplayName', caption:  'Class Display Name' },
	{ dataField: 'className', caption:  'Class Name' },
	//{ dataField: 'clearedAt', caption:  'Cleared At' },
	//{ dataField: 'count', caption:  'Count' },
	//{ dataField: 'createdAt', caption:  'Created At' },
	{ dataField: 'customerLocationId', caption:  'Customer Location Id' },
	{ dataField: 'dataCenter', caption:  'Data Center' },
	{ dataField: 'description', caption:  'Description' },
	{ dataField: 'discovered', caption:  'Discovered' },
	{ dataField: 'dslProvider', caption:  'DSL Provider' },
	{ dataField: 'dslType', caption:  'DSL Type' },
	{ dataField: 'gwid', caption:  'GWID' },
	{ dataField: 'han', caption:  'HAN' },
	{ dataField: 'hanSiteName', caption:  'HAN Site Name' },
	{ dataField: 'hnsCompanyID', caption:  'HNS Company Id' },
	{ dataField: 'hnsLocationID', caption:  'HNS Location Id' },
	{ dataField: 'hnsParentCompanyID', caption:  'HNS Parent Company Id' },
	{ dataField: 'impact', caption:  'Impact' },
	{ dataField: 'inMaintenance', caption:  'In Maintenance?' },
	{ dataField: 'instanceDisplayName', caption:  'Instance Display Name' },
	{ dataField: 'instanceName', caption:  'Instance Name' },
	{ dataField: 'isProblem', caption:  'Is Problem?' },
	{ dataField: 'isProcessed', caption:  'Is Processed?' },
	{ dataField: 'isRoot', caption:  'Is Root?' },
	//{ dataField: 'lastNotifiedAt', caption:  'Last Notified At' },
	{ dataField: 'managementIp', caption:  'Management IP' },
	{ dataField: 'memberOf', caption:  'Member Of' },
	{ dataField: 'napSource', caption:  'NAP Source' },
	{ dataField: 'nmd', caption:  'NMD' },
	{ dataField: 'owner', caption:  'Owner' },
	{ dataField: 'plsiteid', caption:  'PL Site Id' },
	{ dataField: 'productCategory', caption:  'Product Category' },
	{ dataField: 'productCode', caption:  'Product Code' },
	{ dataField: 'productFamily', caption:  'Product Family' },
	{ dataField: 'productGroup', caption:  'productGroup' },
	{ dataField: 'productType', caption:  'Product Type' },
	{ dataField: 'provider', caption:  'Provider' },
	{ dataField: 'rsDatasource', caption:  'RS Datasource' },
	{ dataField: 'rsInsertedAt', caption:  'RS Inserted At' },
	{ dataField: 'rsUpdatedAt', caption:  'RS Updated At' },
	{ dataField: 'satName', caption:  'SAT Name' },
	{ dataField: 'severity', caption:  'Severity' },
	{ dataField: 'siteTypeDescription', caption:  'Site Type Description' },
	{ dataField: 'source', caption:  'Source' },
	{ dataField: 'sourceDomainName', caption:  'Source Domain Name' },
	{ dataField: 'state', caption:  'State' },
	{ dataField: 'stateUSA', caption:  'State USA' },
	{ dataField: 'subCustomerId', caption:  'Sub Customer Id' },
	{ dataField: 'systemRedundancyGroup', caption:  'System Redundancy Group' },
	{ dataField: 'troubleTicketID', caption:  'Trouble Ticket Id' },
	{ dataField: 'userDefined1', caption:  'User Defined1' },
	{ dataField: 'userDefined2', caption:  'User Defined2' },
	{ dataField: 'userDefined3', caption:  'User Defined3' },
	{ dataField: 'userDefined4', caption:  'User Defined4' },
	{ dataField: 'userDefined5', caption:  'User Defined5' },
	{ dataField: 'userDefined6', caption:  'User Defined6' },
	{ dataField: 'userDefined7', caption:  'User Defined7' },
	{ dataField: 'userDefined8', caption:  'User Defined8' },
	{ dataField: 'userDefined9', caption:  'User Defined9' },
	{ dataField: 'userDefined10', caption: 'User Defined10' },
	{ dataField: 'vendor', caption:  'Vendor' },
	{ dataField: 'visibility', caption:  'Visibility' },
	{ dataField: 'willExpireAt', caption:  'Will Expire At?' },
	{ dataField: 'zip', caption:  'Zip' }
];
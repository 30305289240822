import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import compose from "recompose/compose";

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 0,
    margin: theme.spacing.unit/2,
    width: 150,
    '& > *': {
      padding: '0 5px'
    }
  },
  total: {
    color: theme.palette.common.white,
    fontSize: 18,
    fontWeight: 700
  },
  metric: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 500
  }
});

class MetricWidget extends PureComponent {
  render() {
    const { classes, color, metric, total } = this.props;
    return (
      <Paper className={classes.root} style={{ backgroundColor: color }}>
          <Typography className={classes.total} >{total} </Typography>
          <Typography className={classes.metric} variant="subtitle1">{metric}</Typography>
      </Paper>
    );
  }
}

MetricWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(MetricWidget);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';

import { withRouter } from 'react-router-dom';
import ManageDevices from '../components/manage-devices/ManageDevices';

const styles = theme => ({
	  root: {
	    height: '100%'
	  }
	});


class ManageDevicesContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
		    hasErrors: false
		};
		this.getCurrentUser = this.getCurrentUser.bind(this);
	};
	async getCurrentUser () {
		const userInfo = await this.props.oktaAuth.getUser();
        this.setState({ userInfo });
    }
    componentDidMount() {
		this.getCurrentUser();
    };
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Error: '+info);
    };
	render() {
		const { classes } = this.props;
		const { hasErrors, userInfo } = this.state;
		return (
				<div className={classes.root}>
					{hasErrors ?
						<div>Page has erros, please fix!!!</div>
					:
						<ManageDevices raised={true} userInfo={userInfo}/>
					}
				</div>
		)
	}
}
ManageDevicesContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
};
export default withOktaAuth(withRouter(withStyles(styles)(ManageDevicesContainer)));
import React, { PureComponent } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Template } from 'devextreme-react/core/template';
import { DataGrid, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, Scrolling, Summary, TotalItem} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import _ from 'lodash';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import fetchClient  from '../../axios';
import PieChart, { Series, Label, Connector, Size, Font, Tooltip } from 'devextreme-react/pie-chart';


const styles = theme => ({
    root: {
    },
	cardHeader: {
		padding: theme.spacing.unit,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing.unit * 2,
			paddingRight: theme.spacing.unit * 2,
		},
		  height: theme.mixins.cardHeader.height,
		  borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	cardContent : {
		padding: 0,
		overflow: 'auto',
		height: `calc(100vh - ${theme.mixins.gridOffset}px)`,
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	 },
    table: {
        border: '1px solid rgba(224, 224, 224, 1)'
    }
});

const DevicesGridColumns = [
    { dataField: "nmd", caption: "Service", width: 200},
    { dataField: "device_count", caption: "Total Devices", dataType:"number", sortOrder: 'desc', width: 200},
];
const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;
class ServiceViewDetails extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			serviceViewDetails: [],
			isDataLoading: false,
            anchorEl: null,
            totalCount: 0
		};
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.renderGridTitle = this.renderGridTitle.bind(this);
        this.renderSparkLineChart = this.renderSparkLineChart.bind(this);
		this.dataGrid = null;
		this.otherDevicesGrid = null;
		this.serviceViewChartRef = React.createRef();
	}
/*	componentDidUpdate(prevProps) {
      if (prevProps.query !== this.props.query) {
        console.log("I am updated , query "+ this.props.query)
      }
    }*/
    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }
    renderGridTitle = (title) => {
        return (
            <div>
				<Typography variant={"h6"} component={"h6"} >{title}</Typography>
            </div>)
    };
    customizeText = (point) => {
          return `${point.percentText}`;
    };
    chartTooltipTemplate = (info) => {
      return (
        <div className="state-tooltip">
          <div>
            <span className="">{_.capitalize(info.argumentText)}</span>: {info.percentText}
          </div>
          <div>
            <span className="">Count</span>: {formatNumber(info.value)}
          </div>
        </div>
      );
    };
    customizePoint = (point) => {
		switch (point.argument) {
			case 'Critical':
				return { color: red[500]};
			case 'Major':
				return { color: orange[500]};
			case 'Minor':
				return { color: yellow[500]};
			case 'Unknown':
				return { color: lightBlue[500]};
			case 'Normal':
				return { color: green[500]};
            default:
                //None
		}
	};
    renderSparkLineChart = (cellInfo) => {
        const states = cellInfo['data']['device_count_by_state'];
        return (
                <PieChart ref={ this.serviceViewChartRef }
                    id='device-search-pie-chart'
                    dataSource={ states }
                    resolveLabelOverlapping='shift'
                    customizePoint={this.customizePoint}
                >
                    <Series argumentField='label' valueField='value' >
                        <Label visible={true}
                            format='fixedPoint'
                            position='outside'
                            backgroundColor='none'
                            customizeText={this.customizeText}
                        >
                            <Font size={10} />
                            <Connector visible={true} width={1}/>
                        </Label>
                    </Series>
                    <Tooltip enabled={true} contentRender={this.chartTooltipTemplate} cornerRadius={8} zIndex={3000}/>
                    <Size width={150} height={80} />
                </PieChart>
        )
    };
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onToolbarPreparing = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push({
                    location: 'before',
                    template: 'gridTitleTemplate'
                }, searchPanel, columnChooserPanel,                 {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    };
	render() {
		const { classes, query, serviceName, gridHeight} = this.props;
		//const { isDataLoading, totalCount } = this.state;
		const gridStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                    //console.log('ServiceViewDetaisl,Query: '+query);
                    const params = {query: query, aggregation_field: 'nmd'};
                    //const params = {query: 'member_of:' + dName, aggregation_field: 'nmd'};
                    const url1 = '/api/v1.0/es/hasmat/devices/aggregations';
                    return fetchClient.get(url1, {params: params})
                        .then(res => {
                            let gridData = [];
                            let gridTotal = 0;
                            if (res.data && res.data.data) {
                                gridData = res.data.data;
                                gridTotal = res.data.total;
                            }
                            return {
                                data: gridData,
                                totalCount: gridTotal
                            }
                        })
                        .catch(() => {
                            throw new Error('Data Loading Error');
                        });
                }
            })
        };
		return (
              <Paper className={classes.table}>
                <DataGrid id={'DevicesGrid'}
                    ref={(ref) => this.dataGrid = ref}
                    //height={`calc(100vh - 114px`}
                    height={`${gridHeight}px`}
                    dataSource={gridStore}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    repaintChangesOnly={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'nextColumn'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    //columnWidth={100}
                    columnMinWidth={70}
                    cellHintEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onContentReady={this.onContentReady}
                    >
                    <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    {
                        DevicesGridColumns.map(row => {
                          return (
                                  // eslint-disable-next-line
                            <Column dataField={row.dataField} dataType={row.dataType} sortOrder={row.sortOrder} caption={row.caption} cellRender={eval(row.cellRender)} width={row.width}/>
                          );
                        })
                    }
                    {/*<Column dataField="device" caption="Status" cellRender={this.renderSparkLineChart} alignment="center" minWidth={70}/>*/}
                    <Column dataField="State" caption="Status" cellRender={this.renderSparkLineChart} alignment="center" minWidth={70}/>
                    <Paging defaultPageSize={10}/>
                    <Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 25, 50, 100]}/>
                    <Template name="gridTitleTemplate" render={this.renderGridTitle.bind(this, `Service Details of ${serviceName}`)}/>
                  <Summary>
                    <TotalItem
                      column="device_count"
                      summaryType="sum"
                      valueFormat="fixedPoint"/>
                  </Summary>
                </DataGrid>
              </Paper>
		)
	}
}

ServiceViewDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withOktaAuth(withStyles(styles, {withTheme: true})(ServiceViewDetails));
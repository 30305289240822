import React, { PureComponent } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Template } from 'devextreme-react/core/template';
import { Fullscreen as FScreen, FullscreenExit } from 'mdi-material-ui';
import { DataGrid, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, Scrolling } from 'devextreme-react/data-grid';
import FormControl from '@material-ui/core/FormControl';
//import { Autocomplete } from 'devextreme-react/autocomplete';
import SelectBox from 'devextreme-react/select-box';
import Fullscreen from "react-full-screen";
import CustomStore from 'devextreme/data/custom_store';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import _ from 'lodash';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import fetchClient  from '../../axios';
// import * as constants from '../../constants';
// import {dateFormatter, stateRender, textFormatter } from '../common/utils';
// import numeral from "numeral";
import PieChart, { Series, Label, Connector, Size, Font, Tooltip } from 'devextreme-react/pie-chart';
import DeviceSearchRowActionsMenu from './DeviceSearchRowActionsMenu';
import DeviceSearchUserCountGraph from './DeviceSearchUserCountGraph';
import DeviceEvents from './DeviceEvents';


const styles = theme => ({
    root: {
    },
	cardHeader: {
		padding: theme.spacing.unit,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing.unit * 2,
			paddingRight: theme.spacing.unit * 2,
		},
		  height: theme.mixins.cardHeader.height,
		  borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	cardContent : {
		padding: theme.spacing.unit,
		overflow: 'auto',
		height: `calc(100vh - ${theme.mixins.gridOffset}px)`,
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	 },
    cardHeader1: {
        border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: '#eeeeee',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContentFull : {
		padding: theme.spacing.unit,
		overflow: 'auto',
		height: '100%',
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	 },
	ddForm: {
		marginTop: 8
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.80rem'
	},
	tableRow: {
		height: 25,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.default
		}
	},
    table: {
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    headerTitle1: {
	  fontSize: '1.2rem',
      fontWeight: 500,
	  //color: '#ffffff'
	},
});

const DevicesGridColumns = [
    { dataField: "nmd", caption: "Service", width: 200},
    { dataField: "device_count", caption: "Total Devices", width: 200},
    ];
const OtherDevicesGridColumns = [
    { caption: 'Hostname', dataField: 'name' },
    { caption: 'Organization', dataField: 'org_name' },
    { caption: 'Device Type', dataField: 'type' },
    { caption: 'IP', dataField: 'ip' },
    ];
// eslint-disable-next-line
let query = '';
// eslint-disable-next-line
let deviceData = [];
const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;
class DeviceSearch extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			deviceData: [],
			isDataLoading: false,
			isFullScreen: false,
            anchorEl: null,
            popupVisible: false,
            content: '',
            title: '',
		};
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
		this.onToolbarPreparing1 = this.onToolbarPreparing1.bind(this);
        this.onContentReady1 = this.onContentReady1.bind(this);
        this.renderGridTitle = this.renderGridTitle.bind(this);
        this.renderGridTitle1 = this.renderGridTitle1.bind(this);
        this.renderSparkLineChart = this.renderSparkLineChart.bind(this);
        this.actionsMenuHandler = this.actionsMenuHandler.bind(this);
        this.renderActionsMenu = this.renderActionsMenu.bind(this);
		this.dataGrid = null;
		this.otherDevicesGrid = null;
		this.deviceSearchChartRef = React.createRef();
        this.manageDevicePopup = null;
        this.actionsScrollView = null;
		this.searchStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                    let params = { field: 'name', query: loadOptions.searchValue };
                        return fetchClient.get('/api/v1.0/es/hasmat/devices/search', {params: params})
                            .then(res => {
                                let deviceNames = [];
                                if (res.data && res.data.data) {
                                    deviceNames = res.data.device_names;
                                }
                                return deviceNames
                            })
                            .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
	}
    handleDeviceNameChange = ({val}) => {
    	query = val
	};
    handleDeviceNameClicked = (e, deviceName) => {
        (deviceName) ? this.setState({deviceName: deviceName }) : this.setState({deviceName: e.selectedItem })
	};
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen })
    };
    renderGridTitle = (title) => {
        return (
            <div>
				<Typography variant={"h6"} component={"h6"} >{title}</Typography>
            </div>)
    };
    renderGridTitle1 = (device) => {
        return (
            <div>
                { device && <Typography variant={"h6"} component={"h6"} >All Devices of {device['service_type']}:{device['service_id']}</Typography>}
            </div>)
    };
    customizeText = (point) => {
          return `${point.percentText}`;
    };
    chartTooltipTemplate = (info) => {
      return (
        <div className="state-tooltip">
          <div>
            <span className="">{_.capitalize(info.argumentText)}</span>: {info.percentText}
          </div>
          <div>
            <span className="">Count</span>: {formatNumber(info.value)}
          </div>
        </div>
      );
    };
    customizePoint = (point) => {
		switch (point.argument) {
			case 'Critical':
				return { color: red[500]};
			case 'Major':
				return { color: orange[500]};
			case 'Minor':
				return { color: yellow[500]};
			case 'Unknown':
				return { color: lightBlue[500]};
			case 'Normal':
				return { color: green[500]};
            default:
                //None
		}
	};
    renderSparkLineChart = (cellInfo) => {
        const states = cellInfo['data']['device_count_by_state'];
        return (
                <PieChart ref={ this.deviceSearchChartRef }
                    id='device-search-pie-chart'
                    dataSource={ states }
                    resolveLabelOverlapping='shift'
                    customizePoint={this.customizePoint}
                >
                    <Series argumentField='label' valueField='value' >
                        <Label visible={true}
                            format='fixedPoint'
                            position='outside'
                            backgroundColor='none'
                            customizeText={this.customizeText}
                        >
                            <Font size={10} />
                            <Connector visible={true} width={1}/>
                        </Label>
                    </Series>
                    <Tooltip enabled={true} contentRender={this.chartTooltipTemplate} cornerRadius={8} zIndex={3000}/>
                    <Size width={150} height={80} />
                </PieChart>
        )
    };
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onToolbarPreparing = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push({
                    location: 'before',
                    template: 'gridTitleTemplate'
                }, searchPanel, columnChooserPanel);
    };
    onContentReady1 =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onToolbarPreparing1 = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push({
                    location: 'before',
                    template: 'gridTitleTemplate'
                }, searchPanel, columnChooserPanel);
    };
    actionsMenuHandler = (widget, deviceData, title) => {
        let component;
        if (widget === 'deviceEvents') {
            const device_id = deviceData['device_id'].split('_')[1];
            const stack_id = deviceData['device_id'].split('_')[0];
            component = <DeviceEvents device_id={device_id} stack_id={stack_id}/>
        }
        if(widget === 'userCounts') {
            component = <DeviceSearchUserCountGraph deviceData={deviceData} />;
            //this.deviceSearchPopup.instance.option('width', '400px');
            //this.deviceSearchPopup.instance.repaint();
        }
        this.setState({
            popupVisible: true,
            content: component,
            title: title
        });
    };
    renderActionsMenu = (cellInfo) => {
        return <DeviceSearchRowActionsMenu rowData={cellInfo.data} actionsMenuHandler={this.actionsMenuHandler} />
    };
    handlePopupHidden = () => {
        this.setState({ popupVisible: false });
    };
	render() {
		const { classes, raised=false } = this.props;
		const { deviceName, isFullScreen, popupVisible, content, title } = this.state;
        const tableColumns = [
		    { caption: 'Hostname', dataField: 'name' },
		    { caption: 'Service Type', dataField: 'service_type' },
            { caption: 'Service ID', dataField: 'service_id' }
            ];
		const gridStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                	if (deviceName) {
                        const dName = deviceName['service_id'];
                        const params = {query: 'memberOf:"' + dName+'"', aggregation_field: 'nmd'};
                        //const params = {query: 'member_of:' + dName, aggregation_field: 'nmd'};
                        const url1 = '/api/v1.0/es/hasmat/devices/aggregations';
                        return fetchClient.get(url1, {params: params})
                            .then(res => {
                                let gridData = [];
                                let gridTotal = 0;
                                if (res.data && res.data.data) {
                                    gridData = res.data.data;
                                    gridTotal = res.data.total;
                                }
                                return {
                                    data: gridData,
                                    totalCount: gridTotal
                                }
                            })
                            .catch(() => {
                                throw new Error('Data Loading Error');
                            });
                    }
                }
            })
        };
		const otherDeviceStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                	if (deviceName) {
                        const service_type = deviceName['service_type'];
                        const service_id = deviceName['service_id'];
                        const params = {service_id: service_id, service_type: service_type};
                        const url1 = '/api/v1.0/es/hasmat/devices/other-devices';
                        return fetchClient.get(url1, {params: params})
                            .then(res => {
                                let otherDevicesData = [];
                                let otherDevicesTotal = 0;
                                if (res.data && res.data.data) {
                                    otherDevicesData = res.data.data;
                                    otherDevicesTotal = res.data.total;
                                }
                                return {
                                    data: otherDevicesData,
                                    totalCount: otherDevicesTotal
                                }
                            })
                            .catch(() => {
                                throw new Error('Data Loading Error');
                            });
                    }
                }
            })
        };
		return (
			<Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
				<div className="full-screenable-node">
					<Card className={classes.root} raised={raised}>
						<CardHeader className={ classes.cardHeader } title="Device Search"
							action={
									<div>
										<FormControl className={classes.ddForm}>
											<SelectBox
												dataSource={this.searchStore}
												width='auto'
												placeholder={'Type 3 characters to search device'}
                                                valExpr='name'
                                                displayExpr='name'
												value={deviceName}
												showClearButton={true}
                                                showDropDownButton={false}
												minSearchLength={3}
                                                searchEnabled={true}
                                                searchExpr={['name']}
												onValueChanged={this.handleDeviceNameChange}
												onSelectionChanged={this.handleDeviceNameClicked}
											/>
										</FormControl>
										<IconButton onClick={this.handleFullScreen}>
											{ isFullScreen ? <FullscreenExit /> : <FScreen /> }
										</IconButton>
									</div>
								}
						/>
						<CardContent className={ classes.cardContent }>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                                    <Table className={classes.table} size="small">
                                     <TableBody stripedRows>
                                        {deviceName ?
                                            tableColumns.map(row => {
                                                  return (
                                                    <TableRow key={row.dataField} className={ classes.tableRow }>
                                                      <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
                                                      <TableCell className={ classes.tableCell }>{deviceName[row.dataField]}</TableCell>
                                                    </TableRow>
                                                  );
                                            }) :
                                            tableColumns.map(row => {
                                              return (
                                                <TableRow key={row.dataField} className={ classes.tableRow }>
                                                  <TableCell className={ classes.tableCell } component="head" scope="row">{row.caption}</TableCell>
                                                  <TableCell className={ classes.tableCell }>{'Unknown'}</TableCell>
                                                </TableRow>
                                              );
                                            })
                                        }
                                    </TableBody>
                                  </Table>
                            </Grid>
                          </Grid>
						  <Grid container spacing={1}>
							  <Grid item md={6} xs={12}>
                                  <Paper className={classes.table}>
                                    <DataGrid id={'DevicesGrid'}
                                        ref={(ref) => this.dataGrid = ref}
                                        height={isFullScreen ? '100vh' : `calc(100vh - 206px)`}
                                        dataSource={gridStore}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        showBorders={true}
                                        rowAlternationEnabled={true}
                                        repaintChangesOnly={true}
                                        allowColumnReordering={true}
                                        allowColumnResizing={true}
                                        columnResizingMode={'nextColumn'}
                                        columnAutoWidth={true}
                                        columnHidingEnabled={true}
                                        wordWrapEnabled={false}
                                        //columnWidth={100}
                                        columnMinWidth={70}
                                        cellHintEnabled={true}
                                        onToolbarPreparing={this.onToolbarPreparing}
                                        onContentReady={this.onContentReady}
                                        >
                                        <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                                        <ColumnChooser enabled={true} mode={'select'}/>
                                        <Scrolling showScrollbar={'always'} useNative={false} />
                                        <LoadPanel enabled={true}/>
                                        {
                                            DevicesGridColumns.map(row => {
                                              return (
                                                // eslint-disable-next-line
                                                <Column dataField={row.dataField} caption={row.caption} cellRender={eval(row.cellRender)} width={row.width}/>
                                              );
                                            })
                                        }
                                        {/*<Column dataField="device" caption="Status" cellRender={this.renderSparkLineChart} alignment="center" minWidth={70}/>*/}
                                        <Column dataField="State" caption="Status" cellRender={this.renderSparkLineChart} alignment="center" minWidth={70}/>
                                        <Paging defaultPageSize={25}/>
                                        <Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 25, 50, 100]}/>
                                        <Template name="gridTitleTemplate" render={this.renderGridTitle.bind(this, 'Services')}/>
                                    </DataGrid>
                                  </Paper>
							  </Grid>
							  <Grid item md={6} xs={12}>
                                  <Paper className={classes.table}>
								    <DataGrid id={'OtherDevicesGrid'}
									ref={(ref) => this.otherDevicesGrid = ref}
									height={isFullScreen ? '100vh' : `calc(100vh - 206px)`}
									dataSource={otherDeviceStore}
									showColumnLines={true}
									showRowLines={true}
									showBorders={true}
									rowAlternationEnabled={true}
									repaintChangesOnly={true}
									allowColumnReordering={true}
									allowColumnResizing={true}
									columnResizingMode={'nextColumn'}
									columnAutoWidth={true}
									columnHidingEnabled={true}
									wordWrapEnabled={false}
									//columnWidth={100}
									columnMinWidth={70}
									cellHintEnabled={true}
									onToolbarPreparing={this.onToolbarPreparing1}
									onContentReady={this.onContentReady1}
									>
									<SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
									<ColumnChooser enabled={true} mode={'select'}/>
									<Scrolling showScrollbar={'always'} useNative={false} />
									<LoadPanel enabled={true}/>
                                    <Column type="button" caption="Actions" fixed={true} alignment="center" width={70} cellRender={this.renderActionsMenu} />
									{
										OtherDevicesGridColumns.map(row => {
										  return (
                                            // eslint-disable-next-line
											<Column dataField={row.dataField} caption={row.caption} cellRender={eval(row.cellRender)} width={row.width}/>
										  );
										})
									}
									<Paging defaultPageSize={25}/>
									<Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[15, 25, 50, 100]}/>
									<Template name="gridTitleTemplate" render={this.renderGridTitle1.bind(this, deviceName)}/>
								</DataGrid>
                                  </Paper>
							  </Grid>
						  </Grid>
						</CardContent>
					</Card>
                        <Popup ref={(ref) => this.deviceSearchPopup = ref} id="deviceSearchPopupId"
                            maxwidth= { () => window.innerWidth/1.5}
                            maxHeight= '95%'
                            showTitle={true}
                            title={title}
                            dragEnabled={true}
                            closeOnOutsideClick={false}
                            visible={popupVisible}
                            onHiding={this.handlePopupHidden}
                            resizeEnabled={true}
                        >
                            <ScrollView height='100%' width='100%' direction='both' id='actions-scrollview' ref={(ref) => this.actionsScrollView = ref}>
                                    <div>
                                        {content}
                                    </div>
                            </ScrollView>
                        </Popup>
				</div>
			</Fullscreen>
		)
	}
}

DeviceSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withOktaAuth(withStyles(styles, {withTheme: true})(DeviceSearch));
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
	root: {
        width: '100%'
      },
      cardHeader: {
          //border: '1px solid rgba(224, 224, 224, 1)',
          backgroundColor: theme.palette.primary.light,
          padding: '4px 16px',
          height: '30px',
          [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
          }
      },
      cardHeader1: {
          border: '1px solid rgba(224, 224, 224, 1)',
          backgroundColor: theme.palette.primary.light,
          padding: '4px 16px',
          height: '30px',
          [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
          }
      },
      cardContent: {
          padding: 0,
          border: '1px solid rgba(224, 224, 224, 1)',
          '&:last-child': {
              paddingBottom: theme.spacing(1)
          }
      },
      headerTitle: {
        fontSize: '1rem',
        color: '#ffffff'
      },
      tableCell: {
          padding: '4px 16px',
          lineHeight: '0.60rem',
          color: '#616161',
          fontSize: '13px'
      },
      tableRow: {
          height: 18,
          padding: 8,
          '&:nth-of-type(odd)': {
              backgroundColor: theme.palette.rowBackgroundColor
          }
      }
});
class LocationForLumos extends PureComponent {
    constructor(props){
        super(props);
    }
    render() { 
        const {classes,deviceData} = this.props;
        const title = 'Location Information';
        let tableColumns = [
		    { caption: 'Customer Location ID', dataField: 'customerLocationId' },
		    { caption: 'Address', dataField: 'address' },
            { caption: 'City', dataField: 'city' },
            { caption: 'State', dataField: 'stateUSA' },
            { caption: 'Zip', dataField: 'zip' },
            { caption: 'Latitude', dataField: 'latitude' },
            { caption: 'Longitude', dataField: 'longitude' },
            { caption: 'Hughes Location ID', dataField: 'plsiteid' }
        ];
        return ( 
            <div>
                <h2>{title}</h2>
                {/* <Grid container spacing={1} direction="row">
                    <Grid item> */}
                        <Card>
                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        {(deviceData) ?
                                            tableColumns.map(row => {
                                                return (
                                                    <TableRow key={row.dataField} >
                                                        <TableCell  variant="head" scope="row">{row.caption}</TableCell>
                                                        <TableCell>{deviceData[row.dataField]}</TableCell>
                                                    </TableRow>
                                                );
                                            }) :
                                            tableColumns.map(row => {
                                            return (
                                                    <TableRow key={row.dataField} className={ classes.tableRow }>
                                                        <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
                                                        <TableCell className={ classes.tableCell }>Unknown</TableCell>
                                                    </TableRow>
                                            );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    {/* </Grid> */}
                    {/* <Grid iem>
                        <Card>
                            <CardContent>
                                <p>Place holder for location Map</p>
                            </CardContent>
                        </Card>
                    </Grid> */}
                {/* // </Grid> */}
            </div>
            
         );
    }
}

LocationForLumos.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };
  
  const enhance = compose(
          withStyles(styles, {withTheme: true})
      );
  export default enhance(LocationForLumos);
 

// class pinLocation extends PureComponent {
//     render() {
//         return (

//         );
//     }
// }
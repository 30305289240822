//import React from 'react';
import { textFormatter, dateFormatter, stateRender } from "../common/utils";
const textFilterOperators = ['contains', 'startswith', 'endswith', '=', '<>'];
const stateFilterData = [{value: 5, text: "Normal"}, {value: 1, text: "Critical"},{value: 2, text: "Major"}, {value: 3, text: "Minor"}, {value: 4, text: "Unknown"}];
const discoveredFilterData = [{value: true, text: "Yes"},{value: false, text: "No"}];
export const  ManageDevicesGridColumns = [
                    {
                        dataField: "state",
                        caption: "Status",
                        dataType: 'string',
                        fixed: true,
                        allowHeaderFiltering: true,
                        alignment: 'center',
                        headerFilter: {
                            dataSource: stateFilterData
                        },
                        cellRender: stateRender,
                        width: 110
                    },{
                        dataField: "name",
                        caption: "Device ID",
                        dataType: 'string',
                        fixed: true,
                        sortOrder: 'asc',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        width: 150
                    },{
                        dataField: "customerLocationId",
                        caption: "Cust Loc ID",
                        dataType: 'string',
                        fixed: true,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators
                    },{
                        dataField: "gwid",
                        caption: "Gateway ID",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        hidingPriority: 25
                    }, {
                        dataField: "className",
                        caption: "Device Class",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        hidingPriority: 22
                    },{
                        dataField: "siteTypeDescription",
                        caption: "Device Type",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        hidingPriority: 21
                    },{
                        dataField: "nmd",
                        caption: "CID",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        hidingPriority: 20,
                        width: 70
                    },{
                        dataField: "provisionStatus",
                        caption: "Installation Status",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        hidingPriority: 19
                    },{
                        dataField: "seedip",
                        caption: "IP Address",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        hidingPriority: 18
                    }, {
                        dataField: "pamSuspended",
                        caption: "PAM Suspended?",
                        alignment: "center",
                        dataType: "string",
                        headerFilter: {
                            dataSource: discoveredFilterData
                        },
                        customizeText: textFormatter,
                        hidingPriority: 17
                    },{
                        dataField: "pamSuspendStartDate",
                        caption: "Suspend Start Time",
                        allowHeaderFiltering: false,
                        allowFiltering: false,
                        alignment: "center",
                        //dataType: "datetime",
                        width: 140,
                        customizeText: dateFormatter,
                        hidingPriority: 17
                    },{
                        dataField: "pamSuspendEndDate",
                        caption: "Suspend End Time",
                        allowHeaderFiltering: false,
                        allowFiltering: false,
                        alignment: "right",
                        //dataType: "datetime",
                        width: 140,
                        customizeText: dateFormatter,
                        hidingPriority: 16
                    },{
                        dataField: "poller",
                        caption: "Poller ID",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        hidingPriority: 15
                    },{
                        dataField: "discoveryDescription",
                        caption: "Last Discovery Error",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperations: textFilterOperators,
                        width: 140,
                        hidingPriority: 14
                    },{
                        dataField: "discovered",
                        caption: "Discovered",
                        dataType: 'string',
                        alignment: 'center',
                        headerFilter: {
                            dataSource: discoveredFilterData
                        },
                        allowHeaderFiltering: true,
                        //allowFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 13
                    },{
                        dataField: "model",
                        caption: "Model",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 12
                    },{
                        dataField: "stateUSA",
                        caption: "State",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 11
                    },{
                        dataField: "assetId",
                        caption: "Asset ID",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 10
                    },{
                        dataField: "han",
                        caption: "HAN",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 9
                    },{
                        dataField: "napSource",
                        caption: "Provider",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 8
                    },{
                        dataField: "nocid",
                        caption: "NOC ID",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 7
                    },{
                        dataField: "dataCenter",
                        caption: "Data Center",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 7
                    },{
                        dataField: "gwidDivCurrent",
                        caption: "Diversity GW",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 7
                    },{
                        dataField: "plsiteid",
                        caption: "HNS Location ID",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 6
                    },{
                        dataField: "vendor",
                        caption: "Vendor",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 5
                    },{
                        dataField: "productCategory",
                        caption: "Product Category",
                        dataType: 'string',
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 4
                    },{
                        dataField: "productCode",
                        caption: "Product Name",
                        dataType: 'string',
                        fixed: true,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        filterOperation: 'textFilterOperation',
                        hidingPriority: 24
                    },{
                        dataField: "productFamily",
                        caption: "Product Family",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 2
                    },{
                        dataField: "productGroup",
                        caption: "Product Group",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 1
                    },{
                        dataField: "productType",
                        caption: "Product Type",
                        dataType: 'string',
                        visible: false,
                        allowHeaderFiltering: false,
                        customizeText: textFormatter,
                        hidingPriority: 0
                }];
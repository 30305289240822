import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import MuiAlert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
//import CardHeader from '@material-ui/core/CardHeader';
//import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
//import IconButton from '@material-ui/core/IconButton';
import { HelpCircleOutline, ContentCopy, FileExcelOutline } from 'mdi-material-ui';
import CardContent from '@material-ui/core/CardContent';
const useStyles = makeStyles(theme => ({
  root: {
      //height: '100vh'
      flexGrow: 1
  },
  cardHeader: {
    padding: theme.spacing.unit,
    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    height: theme.mixins.cardHeader.height,
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  cardContent: {
    height: '100%',
  },
  horizontal: {
      display: 'flex',
      flexDirection: 'row'
  },
  divider1: {
      marginBottom: 16
  }
}));
const InfobloxHelp = (props) => {
      const classes = useStyles();
  return (
      <div className={ classes.root }>
            <Card  raise={true}>
                <CardContent className={classes.cardContent}>
                    <div>
                        <div className={classes.horizontal}>
                            <Typography variant="h5" paragraph={true}>Infoblox Search Help </Typography>
                            <HelpCircleOutline style={{color: "#03a9f4"}}/>
                        </div>
                        <Typography variant="body1" paragraph={true}>
                                This page provides an easy interface into Infoblox to perform basic searches.
                                It replaces the IPPlan interface now that Infoblox is the new IP/DNS management engine.
                                This interface <b>only</b> supports read-only operations. All actions that require
                                updates to Infoblox-managed information must be performed using the Infoblox web UI.
                        </Typography>
                        <Divider className={classes.divider1}/>
                        <div className={classes.horizontal}>
                            <Typography variant="h5" paragraph={true}>Copying Search Results to Clipboard </Typography>
                            <ContentCopy style={{color: "#03a9f4"}}/>
                        </div>
                        <Typography variant="body1" paragraph={true}>
                                Due to the way that value are formatted in the search results table, you do not always
                                end up with what you expect if you select the table entries and then copy/paste the data to another application. You
                                may end up with some carriage returns in the middle of each row, for example. Making that work is a future goal for this
                                application. However you can simply copy the entire table of search results by clicking the copy icon in the upper right corner
                                of the window. This programmatically gathers the table contents and reoganizes the fields in each row into a tab-delimited
                                string. Tabs are used because that format allows you to paste directly into Excel and each column of data will fall into a
                                separate spreadsheet column.
                        </Typography>
                        <Divider className={classes.divider1}/>
                        <div className={classes.horizontal}>
                            <Typography variant="h5" paragraph={true}>Downloading Search Results </Typography>
                            <FileExcelOutline style={{color: "#03a9f4"}} />
                        </div>
                        <Typography variant="body1" paragraph={true}>
                                You can download the entire search results table entries as a CSV file by clicking the
                                download icon in the upper right corner of the window. Column headers and data will be included.
                        </Typography>
                        <Divider className={classes.divider1}/>
                        <Typography variant="h5" paragraph={true}>
                            Regex Search Basics
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                All searches using this interface use case-insensitive, regular expression (regex) style
                                syntax. If you have not already used regular expressions, then you will need to learn a few basic concepts to perform
                                searches that return expected responses. Below are some basic tips for common searches you might
                                perform against Infoblox and should be enough knowledge to do day-to-day searches. See
                                <a href="http://www.regular-expressions.info/tutorial.html" target="_blank" rel="noopener noreferrer"> this tutorial</a>
                                to gain a more in-depth understanding of regular expression searches. They are very powerful and interesting to understand.
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Wildcards
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                You might think you search for any hostnames that contain the substring "a34-fw-ssl"
                                using this search:<br />
                                <code>a34-fw-ssl*</code><br />
                                    You would be wrong. The asterisk is a <a href="http://www.regular-expressions.info/characters.html" target="_blank" rel="noopener noreferrer"> special</a>
                                    "repetition" metacharacter in regex. It modifies something preceding it and means
                                    zero to many occurances of that preceding thing.
                                    In this example, however, you would get what you expect but only by accident.
                                    The "l*" part of the regex means, match on zero to many "l's", so it would work. Technically, it is not a
                                    correctly specified regex for what you want.
                                    Another repetition metacharacter is the plus sign "+". It means one or more occurances of the preceding thing.
                                    The 'dot' or period, is another metacharacter that means <i>"any single character"</i>.
                                    The correct regex way to specify this search is:<br />
                                <code>a34-fw-ssl.*</code><br />
                                    It means, search for any string that contains "a34-fw-ssl" followed by zero to many
                                    characters.
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Character Classes
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                <a href="http://www.regular-expressions.info/charclass.html" target="_blank" rel="noopener noreferrer">Character classes</a> allow you specify
                                a range or set of characters to search. htmlFor example, to search for an IP address that starts with 172.27,
                                has a second octet that starts with one or two, and a third octet that starts with zero or two, you could do this:<br />

                                    <code>172\.27\.[1,2].*\.[0,2]</code><br />

                                    Note the use of "\.". Remember, the dot is a special character. It does not
                                    literally mean a dot. To literally search for a
                                    dot, you have to escape the special dot character with a backslash. Otherwise, a dot
                                    by itself will match any character in
                                    that position of the search string. Of course, a search like the following also will
                                    return what you expect, any address
                                    that starts with 172.155.221 because the dot happens to be in the exact position
                                    where a period in the IP address would
                                    occur between the first three octets and the special dot character will match on
                                    that period just like it would match on
                                    any character in that position.<br />

                                    <code>172.155.221.*</code><br />
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Anchors
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                <a href="http://www.regular-expressions.info/anchors.html" target="_blank" rel="noopener noreferrer">Anchors</a> control where in the string a match
                                is constrained. There are two anchors on interest, the caret "^" and the dollar sign "$". The caret anchor
                                matches the start of a line. The dollar sign anchor matches the end of a line. So, if you wanted to find all IP
                                addresses that start with "172.27", you might be tempted to do this:<br />

                                    <code>172.27.*</code><br />

                                    However, this could return ""172.27.30.10" but it could also return "10.172.27.33"
                                    because you did not anchor the "172.27" to
                                    the start of the line. The correct regex would be like this:<br />

                                    <code>^172.27.*</code><br />

                                    The same is true for the dollar sign anchor. If you wanted all IP addresses that end
                                    in "254", you could use this regex:<br />

                                    <code>.*254$</code><br />
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Alternation
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                            <a href="http://www.regular-expressions.info/alternation.html" target="_blank" rel="noopener noreferrer">Alternation</a> allows you to match on combinations
                                of values. Suppose you want all the host names that contain either "-wdz" or "-dmz". You could do that with an alternation
                                syntax that groups the alternatives in "()" and uses the "|" character which means, "this or that", " this|that". For example,
                                the following regex will match on all host names that start with "
                                a34" followed by zero to many characters but also must contain either "-wdz" or "-dmz":<br />

                                <code>^a34.*(-wdz|-dmz)</code>
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Default Behavior
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                            One final note is concerning the default behavior used by Infoblox to execute a regex. Technically, you really could use a regex like
                            this to search for any string that contains " a34-fw-ssl" as a substring:<br />

                            <code>a34-fw-ssl</code><br />

                             You don't actually have to include a ".*" at the end or beginning because Infoblox performs a regex 'search' vs. a
                             regex 'match'. The difference is that a match requires the entire string to match against the regex whereas
                             a search only requires that any substring of the target matches against the regex. In effect, search is a partial match technique.
                             However, that does not mean that you still will not get unexpected results from certain searches so you should be aware of this fact.
                             Using a properly formed regex will ensure you get exactly what you expect.
                             Also, if you use tools like Linux grep, egrep, sed, awk, etc, then you really need to understand regular
                             expressions to fully leverage the power of those tools. So, learning regex is worth your while.
                        </Typography>
                        <Divider className={classes.divider1}/>
                        <Typography variant="h5" paragraph={true}>
                            Searches
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                            There are five types of searches that you can do:
                        </Typography>
                        <ul>
                            <li>Hostname</li>
                            <li>IPv4/6 Address</li>
                            <li>Comments</li>
                            <li>Owner</li>
                            <li>Subnet</li>
                        </ul>
                        <Typography variant="body1" paragraph={true}>
                            Due to certain behaviors and limitations of the InfoxBlox REST APIs used by this
                            application, each search behaves in unique ways.
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Hostname Searches
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                            This search searches over host records. Host records are objects in InfoxBlox that
                            represent a host that has an IP address bound to a domain name. This search expects a regular expression as the
                            search query and performs a case-insensitive search. Only records that have a domain name associated with them will be included in this search.
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            IPv4/6 Address Searches
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                This search also searches over host records but searches against the IP address of the
                                record. This search uses a simple regex based search. So, it is not, for example, subnet aware. It simply
                                searches for IP addresses that match the provided search query regex. It does, however, take into account a subnet mask,
                                should you provide on, and that works like this:
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                You can enter any valid portion of an IPv4 or IPv6 address including a CIDR value but you
                                have to at least enter two octets for IPv4 and three octets for IPv6 addresses. So, "172.30", "2001:5b0:1002",
                                "10.23.4.100", and "172.27.30.0/26" are all valid search strings. When no mask is supplied, the search string is treated as
                                a literal regex string and used.
                                When a mask is supplied, the search is not as sophisticated as you might hope.
                                Essentially, the string is converted to a regex that 'approximates' the range of IP addresses that fall under the mask. Any mask
                                that is greater than 23 is treated like a class "C" address. So, "10.22.55.0/26" ends up using a regex search of
                                "10\.22\.55\..*". Any mask that is less than 24 is treated like a class "B" address. So, "10.22.0.0/18" ends up using a regex search of
                                "10\.22\..*". There are limitations in the Infoblox REST API that don't support a true search on subnets and too many results
                                would be returned for subnets larger than class "B" anyway. So, this is a compromise but should be good enough to get you
                                what you need. Same rules apply to IPv6 addresses.
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Comment Searches
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                This search actually searches over multiple InfoBlox objects that contain comments. The
                                search includes host records, IPv4 network records, and IPv6 network records. These are object types where comments
                                can be attached. The output of this search is a bit odd in format because we are trying to group two somewhat different
                                results sets into one table.
                                For results rows that come from hits on a host records, the IPv4 and/or IPv6 columns
                                will contain the address for that host record. However, the subnet column will be empty because the subnet information is
                                not part of the host record.
                                However, for results rows that come from hits on an IPv4/6 network record, the subnet is known, which is simply the
                                definition of the network itself, so that column will contain the subnet address. But, the IPv4/6 columns will be empty
                                as they do not apply to these type of records. The remain column are common to all records and may or may not contain
                                values depending on whether those fields are populated in InfoBlox.
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Owner Searches
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                This search is over host records but searches against the name of the owner associated with a record.
                        </Typography>
                        <Typography variant="h6" paragraph={true}>
                            Subnet Searches
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                            This search searches over IPv4 and IPv6 network objects and returns a list of subnets
                            that match the search query.
                            The search query for this search using regex matching to find the subnets. So, a search of "172.19.64.0" will return a
                                single subnet of "172.19.64.0/25" because that address falls in that subnet. To broaden the search and find the other
                                subnets, you could use a search of "127.19.64" which will return both the "172.19.64.0/25" and "172.19.64.128/25" subnets
                                because that address could fall into either subnet depending on what you intend for the 4th octet.
                        </Typography>
                        <Typography variant="body1" paragraph={true}>
                                The subnet search is the only search that allows you do perform a drill-down search. Each
                                subnet returned is a link that, if clicked, will launch a drill-down search on that subnet. The results of that
                                search will list all IP addresses that fall into the selected subnet. These include unallocated and allocated addresses.
                                So, you may see a lot of blank fields for IP addresses that have not yet been bound to a host record.
                        </Typography>
                    </div>
                </CardContent>
            </Card>
      </div>
  )
};
export default InfobloxHelp;
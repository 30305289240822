import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Settings from '../components/settings/Settings';

const styles = theme => ({
	  root: {
	    height: '100%'
	  }
	});


class SettingsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
		    hasErrors: false
		}
	};
    componentDidMount() {

    };
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log('Error: '+info);
    };
	render() {
		const { classes } = this.props;
		const { hasErrors } = this.state;
		return (
				<div className={classes.root}>
					{hasErrors ?
						<div>Page has erros, please fix!!!</div>
					:
						<Settings raised={true} changeTheme={this.props.changeTheme} selectedTheme={this.props.selectedTheme}/>
					}
				</div>
		)
	}
}
SettingsContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(SettingsContainer));
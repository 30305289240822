import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
// import { Fullscreen as FScreen, FullscreenExit } from 'mdi-material-ui';
// import Fullscreen from "react-full-screen";
import EventsSummary from './network-monitoring/EventsSummary';
import Events1 from './network-monitoring/Events1';
import NetworkEvents from './network-monitoring/NetworkEvents';
import ArchivedEvents from './network-monitoring/ArchivedEvents';

const styles = theme => ({

});

class DashBoardNetworkMonitoring extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            networkEventsGridHeight: '50vh'
        };
        this.networkEventsGridRef = React.createRef();
    }

    componentWillMount() {

    }

    componentDidMount() {
        const gridHeight = this.networkEventsGridRef.current.offsetHeight - 78;
        this.setState({ networkEventsGridHeight: gridHeight })
    };
    componentWillUnmount () {

    };
render() {
        //const { classes, theme } = this.props;
        const { networkEventsGridHeight } = this.state;
        return (
            <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <EventsSummary raised={true}  query='*:*'/>
                </Grid>
                <Grid ref={this.networkEventsGridRef} item lg={6} md={6} sm={12} xs={12} >
                    <NetworkEvents gridHeight={networkEventsGridHeight} raised={true}  query='*:*'/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <Events1 raised={true}  query='*:*'/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <ArchivedEvents raised={true} query='*:*'/>
                </Grid>
            </Grid>
        )
    }
}

DashBoardNetworkMonitoring.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(DashBoardNetworkMonitoring);
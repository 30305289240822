import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
//import { OktaAuth } from '@okta/okta-auth-js';

import UserContainer from '../containers/UserContainer';
import SettingsContainer from '../containers/SettingsContainer';
import HelpContainer from '../containers/HelpContainer';
import DashboardContainer from '../containers/DashboardContainer';
//import LoginContainer from '../containers/LoginContainer';
import DeviceDetailsContainer from '../containers/DeviceDetailsContainer';
import ManageDevicesContainer from '../containers/ManageDevicesContainer';
import ServiceViewNavigatorContainer from '../containers/ServiceViewNavigatorContainer';
import NMCToolsContainer from '../containers/NMCToolsContainer';
//import SVNViewsContainer from '../containers/SVNViewsContainer';
import InfobloxContainer from '../containers/InfobloxContainer';
import DeviceSearchContainer from '../containers/DeviceSearchContainer';
import ActionBrokerContainer from '../containers/ActionBrokerContainer';
// import LumosContainer from '../containers/LumosContainer';
import Lumos from './lumos';
import Login from '../components/login/Login';
import HomeContainer from '../containers/HomeContainer'
import MaasCmdbAdminContainer from '../containers/MaasCmdbAdminContainer';
import AdminContainer from '../containers/AdminContainer';
// import HughesLocationsContainer from '../containers/HughesLocationsContainer';
// import HughesDataCentersContainer from '../containers/HughesDataCentersContainer';
// import HughesNetworksContainer from '../containers/HughesNetworksContainer';
import CradlepointContainer from '../containers/CradlepointContainer';
import FourZeroFourContainer from '../containers/FourZeroFourContainer';
import * as constants from '../constants';

/*const env = process.env.NODE_ENV;
const HOST = process.env.REACT_APP_HOST;
const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH;
const SCOPES = process.env.REACT_APP_OKTA_SCOPES;
const REDIRECT_URI = `${HOST}${CALLBACK_PATH}`;

if (!HOST || !OKTA_DOMAIN || !ISSUER || !CLIENT_ID || !CALLBACK_PATH || !SCOPES) {
    throw new Error("All environmental variables must be set")
}
const signInConfig = {
      baseUrl: OKTA_DOMAIN,
      clientId: CLIENT_ID,
      redirectUri: REDIRECT_URI,
      authParams: {
        scopes: SCOPES.split(','),
        // If your app is configured to use the Implicit Flow
        // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
        // you will need to uncomment the below line
        pkce: false
      }
};*/
const ReactRouter = ({groups, changeTheme, selectedTheme}) => {
    const isAdmin = groups && groups.length > 0 && groups.includes('nmstools_admin');
    //const isUser = groups && groups.length > 0 && groups.includes('nmstools_user');
    //const isPowerUser = groups && groups.length > 0 && groups.includes('nmstools_poweruser');
return (
        <Switch>
              <SecureRoute path='/dash-board' component={DashboardContainer} />
              { isAdmin && <SecureRoute path='/admin' component={AdminContainer} /> }
              <SecureRoute path='/user-details' component={UserContainer} />
              <SecureRoute path='/settings' render={() => <SettingsContainer changeTheme={changeTheme} selectedTheme={selectedTheme}/> } />
              <SecureRoute path='/help' component={HelpContainer} />
              <SecureRoute path='/monitoring' component={DashboardContainer} />
              <SecureRoute path='/geo-map' component={DashboardContainer} />
              <SecureRoute path='/geo-vector-map' component={DashboardContainer} />
              <SecureRoute path='/geo-vector-map-box' component={DashboardContainer} />
              <SecureRoute path='/device-details/:dName?' component={DeviceDetailsContainer} />
              <SecureRoute path='/manage-devices' component={ManageDevicesContainer} />
              <SecureRoute path='/svn' component={ServiceViewNavigatorContainer} />
              <SecureRoute path='/cmdb-admin' component={MaasCmdbAdminContainer} />
              {/*<SecureRoute path='/svn-views' component={SVNViewsContainer} />
              <SecureRoute path='/hughes-locations' component={HughesLocationsContainer} />
              <SecureRoute path='/hughes-data-centers' component={HughesDataCentersContainer} />
              <SecureRoute path='/hughes-networks' component={HughesNetworksContainer} />*/}
              <SecureRoute path='/sf-cases' component={NMCToolsContainer} />
              <SecureRoute path='/sf-active-notifications' component={NMCToolsContainer} />
              <SecureRoute path='/sf-dispatches' component={NMCToolsContainer} />
              <SecureRoute path='/active-notifications' component={NMCToolsContainer} />
              <SecureRoute path='/tech-support-tasks' component={NMCToolsContainer} />
              <SecureRoute path='/replacements-dispatches' component={NMCToolsContainer} />
              <SecureRoute path='/cradlepoint' component={CradlepointContainer} />
              <SecureRoute path='/infoblox' component={InfobloxContainer} />
              <SecureRoute path='/device-search' component={DeviceSearchContainer} />
              <SecureRoute path='/ab/getting-started' component={ActionBrokerContainer} />
              <SecureRoute path='/ab/api-documentation' component={ActionBrokerContainer} />
              <SecureRoute path='/ab/usage' component={ActionBrokerContainer} />
              <SecureRoute path='/lumos' component={Lumos} />
              <Route path='/' exact component={HomeContainer} />
              <Route path='/login' render={() => <Login config={constants.SIGN_IN_CONFIG} />} />
              <Route path='/login/callback' component={LoginCallback} />
              <Route path='*' component={FourZeroFourContainer} />
        </Switch>
   )
 };
 export default ReactRouter;

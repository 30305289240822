import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import { DataGrid, Column, Scrolling } from 'devextreme-react/data-grid';
import Sparkline from '@rowno/sparkline';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import blue from "@material-ui/core/colors/blue";
import orange from "@material-ui/core/colors/orange";
import ApgImpactedGraph from '../service-view-navigator/ApgImpactedGraph';
import MuiAlert from '@material-ui/lab/Alert';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
        height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
		border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
});
class DeviceNetworks extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			isFullScreen: false,
            popupVisible: false,
            content: '',
            chartTitle: ''
		};
		this.impactThresholdChartRef = React.createRef();
		this.impactPercentageChartRef = React.createRef();
		this.deviceNetworkPopupRef = React.createRef();
		this.deviceNetworkScrollViewRef = React.createRef();
		this.renderSparkLineChart = this.renderSparkLineChart.bind(this);
		this.handlePopupHidden = this.handlePopupHidden.bind(this);
	}
	handlePopupHidden = () => {
        this.setState({popupVisible: false})
    };
    renderSparkLineChart = (cellInfo) => {
	    let impactedData = cellInfo.data['impacted_data'];
	    let totalData = cellInfo.data['total_data'];
	    let thresholdData = cellInfo.data['threshold_data'];
	    let impactedPercentages = [];
        let impactedThreshold = [];
	    if (impactedData.length > 0 && totalData.length > 0) {
	        impactedData = impactedData[0]['avg_data'];
            totalData = totalData[0]['avg_data'];
            thresholdData = thresholdData[0]['avg_data'];
            impactedData.forEach(function(item, idx){
                let percent = (item['value']/totalData[idx]['value'])*100;
                let threshold = thresholdData[idx]['value'];
                impactedPercentages.push(percent);
                impactedThreshold.push(threshold);
            });
        }
        const lines = [
            {
              values: impactedPercentages,
              colors: {
                line: orange[500]
              }
            }, {
              values: impactedThreshold,
              colors: {
                line: blue[500]
              }
            }
        ];
        return (
            <Sparkline
                width={150}
                height={10}
                lines={lines}
            />
        );
    };
    handleImpactCellClick = (e) => {
         if(e.rowType === 'data') {
             if (e.column.dataField === 'impactPercent') {
                 this.setState({ popupVisible: true, chartTitle: `Impact History of ${e.data.group}`, content: <ApgImpactedGraph groupName={e.data.group} /> })
                 console.log("Impact cell clicked", e.data.group)
             }
         }
    };
	render() {
		const { popupVisible, content, chartTitle } = this.state;
		const { classes, raised, deviceGroups } = this.props;
		const title = 'Networks';
		let tableColumns1= [
			{ dataField: 'group', caption: 'Group'},
			{ dataField: 'impacted_text', caption: 'Impact'},
		];
		return (
				<Card className={classes.root} raised={raised}>
					<CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
					<CardContent className={ classes.cardContent }>
						{ (deviceGroups && deviceGroups.length === 0) &&
							<MuiAlert severity="info">
								This device is not associated to any group.
							</MuiAlert>
						}
                        {(deviceGroups && deviceGroups.length > 0) &&
							<DataGrid id={'deviceGroups'}
									  dataSource={deviceGroups}
								      //height="150"
									  showColumnLines={true}
									  showRowLines={true}
									  showBorders={true}
									  rowAlternationEnabled={true}
									  repaintChangesOnly={true}
									  allowColumnReordering={true}
									  allowColumnResizing={true}
									  columnResizingMode={'widget'}
									  columnAutoWidth={true}
									  wordWrapEnabled={false}
									  columnMinWidth={70}
									  cellHintEnabled={true}
									  noDataText={'This device is not associated to any group'}
									  onCellClick={this.handleImpactCellClick}
							>
								<Scrolling showScrollbar={'always'} useNative={false}/>
								{
									tableColumns1.map(row => {
										return (
											<Column dataField={row.dataField} caption={row.caption}
													alignment={row.alignment}/>
										);
									})
								}
								<Column dataField="impactPercent" caption="Impact History"
										cellRender={this.renderSparkLineChart} alignment="center" minWidth={70}/>
							</DataGrid>
                        }
					</CardContent>
				  <Popup ref={ this.deviceNetworkPopupRef }
					  maxwidth= { () => window.innerWidth/2 }
					  maxHeight= '95%'
					  width='50%'
					  showTitle={true}
					  title={chartTitle}
					  dragEnabled={true}
					  closeOnOutsideClick={false}
					  visible={popupVisible}
					  onHiding={this.handlePopupHidden}
					  resizeEnabled={true}
					>
					  <ScrollView height='100%' width='100%' direction='both' id='actions-scrollview' ref={ this.deviceNetworkScrollViewRef }>
						  <div>
							 {content}
						  </div>
					  </ScrollView>
				  </Popup>
				</Card>
			)
	}
}

DeviceNetworks.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DeviceNetworks);
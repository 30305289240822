import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
        border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
        color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});
class DeviceVADBConfiguration extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
		};
	}
	render() {
		const { classes, raised, deviceData } = this.props;
		const title = 'VADB Configuration';
		const tableColumns = [
		    { dataField: 'vadbPrimaryPhoneNumber', caption: 'VADB Primary Phone Number' },
		    { dataField: 'vadbBackupPhoneNumber', caption: 'VADB Backup Phone Number' },
            { dataField: 'hNS_VADBStatus', caption: 'VADB Status' },
            { dataField: 'lasttesttime', caption: 'VADB Last Test Date' },
            { dataField: 'lasttestresults', caption: 'VADB Last Test Results' }
        ];
		return (
				<Card className={classes.root} raised={raised}>
					<CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
					<CardContent className={ classes.cardContent }>
						 <Table size="small">
							 <TableBody>
								{(deviceData) ?
									tableColumns.map(row => {
									  return (
										<TableRow key={row.dataField} className={ classes.tableRow }>
										  <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
										  <TableCell className={ classes.tableCell } >{deviceData[row.dataField]}</TableCell>
										</TableRow>
									  );
									}) :
									tableColumns.map(row => {
									  return (
										<TableRow key={row.dataField} className={ classes.tableRow }>
										  <TableCell className={ classes.tableCell } variant="head" scope="row">{row.caption}</TableCell>
										  <TableCell className={ classes.tableCell } >{"Unknown"}</TableCell>
										</TableRow>
									  );
									})
								}
							</TableBody>
						  </Table>
					</CardContent>
				</Card>
			)
	}
}

DeviceVADBConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DeviceVADBConfiguration);
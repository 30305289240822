import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Fullscreen from "react-full-screen";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import compose from 'recompose/compose';
import { TimerOffOutline, TimerOutline,  Refresh } from 'mdi-material-ui';
// import { LoadPanel } from 'devextreme-react/load-panel';
// import moment from 'moment';
// import { dateFormatter } from "../common/utils";
import fetchClient from "../../axios";
import * as constants from "../../constants";

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		padding: '4px 16px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
        overflow: 'auto',
		height: `calc(100vh - 47px)`,
		padding: 0,
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1.2rem',
	  //color: '#ffffff'
	},
    iconButton: {
	    padding: 6
    },
    actions: {
	    marginBottom: -6,
        marginTop: 3
    },
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.80rem'
	},
	tableRow: {
		height: 25,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});
class DeviceAdditionalDetails extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			deviceAdditionalDetailsData: [],
			runTask: false,
            isFullScreen: false
		};
	}
	componentWillMount() {
        this.getDeviceAdditionalDetailsData();
    }
    componentDidMount() {
        const { runTask } = this.state;
        if (runTask) {
            clearInterval(this.deviceAdditionalDetailsViewTask);
            this.deviceAdditionalDetailsViewTask = setInterval(()=> this.getDeviceAdditionalDetailsData(), constants.TASK_TIME)
        }
    };
    componentWillUnmount () {
        clearTimeout(this.deviceAdditionalDetailsViewTask);
    };
    /**
     * getDeviceAdditionalDetailsData:
     */
    getDeviceAdditionalDetailsData = () => {
      const { deviceName } = this.props;
      fetchClient.get(`/api/v1.0/smarts/device/additional-details?name=${deviceName}`)
          .then(res => {
              let deviceAdditionalDetailsData = [];
              if (res.data && res.data.data) {
                  deviceAdditionalDetailsData = res.data.data;
              }
              this.setState({
                  deviceAdditionalDetailsData: deviceAdditionalDetailsData
              });
          })
          .catch(error => {
              console.log('Error:'+error);
              this.setState({
                  deviceAdditionalDetailsData: []
              });
          });
    };
    handleConfiguration = (e) => {
        const { runTask } = this.state;
        this.setState({runTask: !runTask});
        if (!runTask) {
            clearInterval(this.deviceAdditionalDetailsViewTask);
            this.deviceAdditionalDetailsViewTask = setInterval(()=> this.getDeviceAdditionalDetailsData(), constants.TASK_TIME)
        } else {
            clearInterval(this.deviceAdditionalDetailsViewTask);
        }
        e.component.option('text', runTask ? 'Start Timer' : 'Stop Timer');
        e.component.option('icon', runTask ? 'mdi mdi-timer-off-outline' : 'mdi mdi-timer-outline');
    };
    textFormatter = (value) => {
        switch (value) {
            case 'true':
            case true:
                return 'Yes';
            case 'false':
            case false:
                return 'No';
            case 'UNKNOWN':
            case 'unknown':
                return 'Unknown';
            case 'UP':
            case 'up':
                return 'Up';
            case 'DOWN':
            case 'down':
                return 'Down';
            case 'NotApplicable':
                return 'Not Applicable';
            case '0.0.0.0':
                return 'DHCP';
            case '_e':
            case 'notset':
            case '':
            case null:
                return 'Unavailable';
            default:
                return value;
        }
    };
	render() {
		const { classes } = this.props;
		const { deviceAdditionalDetailsData, runTask, isFullScreen } = this.state;
		const additional_device_details_fields = [
            { dataField: "aaCfOpermode", caption: 'AA CF Operational Mode' },
            { dataField: "aaVirtualSiteId", caption: 'AA Virtual Site Id' },
            { dataField: "assetId", caption: 'Asset Id' },
            { dataField: "cpehan", caption: 'CPE High Availability Network' },
            { dataField: "Description", caption: 'Description' },
            { dataField: "DiscoveredFirstAt", caption: 'Discovered First At' },
            { dataField: "DiscoveredLastAt", caption: 'Discovered Last At' },
            { dataField: "DiscoveryTime", caption: 'Discovery Time' },
            { dataField: "dmnType", caption: 'DMN Type' },
            { dataField: "firmwareVersion", caption: 'Firmware Version' },
            { dataField: "hanValidation", caption: 'HAN Validation' },
            { dataField: "hnsCompanyID", caption: 'HNS Company Id' },
            { dataField: "hnsLocationID", caption: 'HNS Location Id' },
            { dataField: "hnsParentCompanyID", caption: 'HNS Parent Company Id' },
            { dataField: "hnsService", caption: 'HNS Service' },
            { dataField: "HNS_HAN", caption: 'HNS HAN' },
            { dataField: "HNS_HAN_Type", caption: 'HNS HAN TYPE' },
            { dataField: "Imei", caption: 'IMEI' },
            { dataField: "IsUnresponsive", caption: 'Is Unresponsive' },
            { dataField: "isManaged", caption: 'Is Managed' },
            { dataField: "IsMonitored", caption: 'Is Monitored' },
            { dataField: "InstrumentedBy", caption: 'Instrumented By' },
            { dataField: "Lanip1", caption: 'LAN IP Address' },
            { dataField: "Management IP", caption: 'Management IP' },
            { dataField: "Mdn", caption: 'MDN' },
            { dataField: "accessMode", caption: 'Monitoring Protocol' },
            { dataField: "name", caption: 'Name' },
            { dataField: "nocid", caption: 'NOC Id' },
            { dataField: "productCategory", caption: 'Product Category' },
            { dataField: "productCode", caption: 'Product Code' },
            { dataField: "productFamily", caption: 'Product Family' },
            { dataField: "productGroup", caption: 'Product Group' },
            { dataField: "productType", caption: 'Product Type' },
            { dataField: "PamSuspended", caption: 'PAM Suspended' },
            { dataField: "ProcessedByHnsDeviceP", caption: 'Processed By HNS DeviceP' },
            { dataField: "discoveryState", caption: 'PAM Instantiation State' },
            { dataField: "PrimaryOwnerContact", caption: 'Primary Owner Contact' },
            { dataField: "RsUpdatedAt", VPGW: 'RS Updated' },
            { dataField: "SystemObjectID", caption: 'System Object Id' },
            { dataField: "diuSerialNum", caption: 'Serial Number' },
            { dataField: "sam", caption: 'SAM' },
            { dataField: "softwareVersion", caption: 'Software Version' },
            { dataField: "source", caption: 'Source' },
            { dataField: "state", caption: 'State' },
            { dataField: "subCustomerId", caption: 'Sub Customer Id' },
            { dataField: "timezone", caption: 'Time Zone' },
            { dataField: "userdef1", caption: 'User Defined 1' },
            { dataField: "userdef2", caption: 'User Defined 2' },
            { dataField: "vadbBackupPhoneNumber", caption: 'VADB Backup Phone Number' },
            { dataField: "vadbPrimaryPhoneNumber", caption: 'VADB Primary Phone Number' },
            { dataField: "vdomEnabled", caption: 'VDOM Enabled' },
            { dataField: "vlan", caption: 'Virtual LAN' },
            { dataField: "vpgw", caption: 'VPN Gateway Id' }
        ];
		return (
                <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                    <div className="full-screenable-node">
                        <Card className={classes.root}>
                            <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title='Additional Device Details'
                                action={
                                    <div className={classes.actions}>
                                        <IconButton className={classes.iconButton} onClick={this.handleConfiguration} title={ runTask ? 'Stop Timer' : 'Start Timer'} >
                                            { runTask ? <TimerOutline/> : <TimerOffOutline /> }
                                        </IconButton>
                                        <IconButton className={classes.iconButton} onClick={this.getDeviceAdditionalDetailsData}>
                                         <Refresh/>
                                        </IconButton>
                                    </div>
                                }
                            />
                            <CardContent className={ isFullScreen ? classes.cardContentFull : classes.cardContent}>
							 <Table size="small">
								 <TableBody stripedRows>
                                     {
                                        additional_device_details_fields.map(row => {
                                            const value = deviceAdditionalDetailsData[row.dataField];
                                            if (value !== undefined && !Array.isArray(value)) {
                                                const val = this.textFormatter(value);
                                                return (
                                                    <TableRow key={row.dataField} className={classes.tableRow}>
                                                        <TableCell style={{width: 250}} variant="head" scope="row" className={classes.tableCell}>{row.caption}</TableCell>
                                                        <TableCell className={classes.tableCell}>{val}</TableCell>
                                                    </TableRow>
                                                );
                                            } else {
                                                 return (
                                                    <TableRow key={row.dataField} className={classes.tableRow}>
                                                        <TableCell style={{width: 250}} variant="head" scope="row" className={classes.tableCell}>{row.caption}</TableCell>
                                                        <TableCell className={classes.tableCell}>Unavailable</TableCell>
                                                    </TableRow>
                                                );
                                            }
										})
                                     }
								</TableBody>
							  </Table>
                            </CardContent>
                        </Card>
                    </div>
                </Fullscreen>
			)
	}
}

DeviceAdditionalDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DeviceAdditionalDetails);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
import { DataGrid, RemoteOperations, Paging, Pager, Column, Editing, Scrolling, ColumnChooser, LoadPanel,
    Export, SearchPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import fetchClient  from '../../axios';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

class HughesToplevelServices extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            pageSizes: [5, 10, 15, 25, 50, 100],
            limit: 100,
            offset: 0,
            sort: 'dttm',
            order: 'desc',
            gridColumns: [
                //{dataField: 'id', caption: 'Satellite ID', dataType: 'number', visible: false },
                {dataField: 'name', caption: 'Service Name', dataType: 'string', sortOrder:'asc', validationRules: [{ type: 'required'}] },
                {dataField: 'type', caption: 'Type', dataType: 'string', validationRules: [{ type: 'required'}] },
                {dataField: 'notes', caption: 'Notes', dataType: 'string' }
            ]
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.dataGrid = null;
        //Editing

    }

    componentWillMount() {

    }

    componentDidMount() {

    };
    componentWillUnmount () {

    };
    refreshDataGrid() {
        this.dataGrid.instance.refresh();
    }
    onRowPrepared(e) {

    };
    onToolbarPreparing(e) {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, exportButton, addRowButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                item = items[i];
                if(item.name === 'searchPanel') {
                    items.splice(i, 1);
                    searchPanel = item;
                }
                if(item.name === 'columnChooserButton') {
                    items.splice(i, 1);
                    columnChooserPanel = item;
                    columnChooserPanel.options.type = 'default';
                }
                if(item.name === 'addRowButton') {
                    items.splice(i, 1);
                    addRowButton = item;
                    addRowButton.options.type = 'default';
                }
                if(item.name === 'exportButton') {
                    items.splice(i, 1);
                    exportButton = item;
                }
            }
            toolbarItems.push(searchPanel , addRowButton, exportButton, columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                });
    }
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onCellPrepared = (e) => {
      /*if(e.rowType === 'header' && e.column.command === 'edit') {
          e.cellElement.textContent='Actions';
      }*/
    };
render() {
        const { classes, theme, raised } = this.props;
        const { pageSize, pageSizes, limit, offset, sort, order, gridColumns } = this.state;
        //const title = 'Toplevel Services';
        const dataStore = {
            store: new CustomStore({
                key: 'toplevel_service_id',
                load: (loadOptions) => {
                    let params = { limit, offset, sort, order };
                        if (loadOptions.sort) {
                            params.sort = loadOptions.sort[0].selector;
                            params.order = loadOptions.sort[0].desc ? 'desc' : 'asc';
                        }
                        params.offset = loadOptions.skip || 0;
                        params.limit = loadOptions.take || 100;
                        //return fetchClient.get('/api/v1.0/svn/svi_hughes_networks', { params: params })
                        return fetchClient.get('/api/v1.0/svn/svi_hughes_toplevel_services')
                            .then(res => {
                                let hughesServiceInstances = [];
                                let hughesServiceInstancesTotal = 0;
                                if (res.data && res.data.data) {
                                    hughesServiceInstances = res.data.data;
                                    hughesServiceInstancesTotal = res.data.total;
                                }
                                return {
                                    data: hughesServiceInstances,
                                    totalCount: hughesServiceInstancesTotal
                                };
                            })
                            .catch ((e) => { throw new Error(`Data Loading Error, ${e}`);});
                },
                insert: (values) => {
                    return fetchClient.post('/api/v1.0/svn/svi_hughes_toplevel_services', values)
                        .then(res => {

                        })
                        .catch ((e) => { throw new Error(`Not able to create, ${e}`);});
                },
                update: (key, values) => {
                    return fetchClient.put('/api/v1.0/svn/svi_hughes_toplevel_services/'+key, values)
                        .then(res => {

                        })
                        .catch ((e) => { throw new Error(`Not able to update, ${e}`);});
                },
                remove: (key) => {
                    return fetchClient.delete('/api/v1.0/svn/svi_hughes_toplevel_services/'+key)
                        .then(res => {

                        })
                        .catch ((e) => { throw new Error(`Not able to delete, ${e}`);});
                }
            })
        };
        return (
            <Card className={classes.root} raised={raised}>
                {/*<CardHeader className={classes.cardHeader} title={title} />*/}
                <DataGrid id={'hughesToplevelServicesGrid'}
                    ref={(ref) => this.dataGrid = ref}
                    height={`calc(100vh - ${theme.mixins.gridOffset}px)`}
                    dataSource={dataStore}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    repaintChangesOnly={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    wordWrapEnabled={false}
                    columnMinWidth={70}
                    cellHintEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onContentReady={this.onContentReady}
                    onCellPrepared={this.onCellPrepared}
                    onRowPrepared={this.onRowPrepared}
                    >
                    <SearchPanel visible={true} width={200} placeholder={'Search for hughes toplevel services'}/>
                    <ColumnChooser enabled={true} mode={'select'}/>
                    <Scrolling showScrollbar={'always'} useNative={false} />
                    <LoadPanel enabled={true}/>
                    <Editing
                        mode={'form'}
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true} >
                    </Editing>
                    <Export enabled={true} fileName={'hughes_toplevel_services'} />
                    <Column caption={'Actions'} type={'buttons'} buttons={['edit', 'delete']} />
                    {
                        gridColumns.map(row => {
                          return (
                            <Column dataField={row.dataField} caption={row.caption} visible={row.visible} dataType={row.dataType} sortOrder={row.sortOrder}
                                    validationRules={row.validationRules} />
                          );
                        })
                    }
                    <RemoteOperations sorting={false} paging={false} />
                    <Paging defaultPageSize={pageSize}/>
                    <Pager visible={true} showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={pageSizes}/>
                </DataGrid>
            </Card>
        )
    }
}

HughesToplevelServices.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(HughesToplevelServices);
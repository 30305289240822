import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import compose from 'recompose/compose';
import PieChart, { Series, Label, Size, Export,  Legend, Tooltip } from 'devextreme-react/pie-chart';
import CustomMarkerChartCenterTemplate from './CustomMarkerChartCenterTemplate';
import SummaryChartTooltipTemplate from './SummaryChartTooltipTemplate';

const styles = theme => ({

});
class CustomMarker extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {

		};
		this.summaryMapChartRef = React.createRef();
	}
	componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
	customizePoint = (point) => {
		switch (point.argument) {
            case 'Critical':
                return { color: red[500]};
            case 'Major':
                return { color: orange[500]};
            case 'Minor':
                return { color: yellow[500]};
            case 'Unknown':
                return { color: lightBlue[500]};
            case 'Normal':
                return { color: green[500]};
            default:
                //None
		}
	};
    customizeText = (arg) => {
    	return `${arg.percentText}`;
        //return `${arg.valueText}\n(${arg.percentText})`;
	};
	render() {
		const { data } = this.props;
		return (
                <PieChart ref={ this.summaryMapChartRef }
                    id='summary-map-pie'
                    type='doughnut'
                    dataSource={ data }
                    palette='Bright'
                    title=''
                    resolveLabelOverlapping='shift'
                    innerRadius={0.70}
					diameter={.80}
                    sizeGroup="piesGroup"
                    //onPointClick = { this.pointClickHandler }
                    //onLegendClick={ this.legendClickHandler }
                    //onDone={this.drawnHandler}
                    customizePoint={this.customizePoint}
                    centerRender={CustomMarkerChartCenterTemplate}
                >
                    <Series argumentField='label' valueField='value' >
                        <Label visible={false}
                           format='fixedPoint'
                            position='columns'
                            backgroundColor='none'
                            customizeText={this.customizeText}
                        >
                        </Label>
                    </Series>
					<Legend visible={false}/>
                    <Tooltip enabled={true} contentRender={SummaryChartTooltipTemplate} cornerRadius={8} zIndex={3000}/>
                    <Size width={40} height={40} />
                    <Export enabled={false} />
                </PieChart>
			)
	}
}

CustomMarker.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(CustomMarker);
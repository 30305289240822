import React, { Component, Fragment } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import SwaggerUI from 'swagger-ui';
import { Alert, AlertTitle } from '@material-ui/lab';
import '../../../node_modules/swagger-ui/dist/swagger-ui.css';

const styles = theme => ({
    root: {
        width: '100%'
    },
	cardHeader: {
		padding: theme.spacing.unit,
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing.unit * 2,
			paddingRight: theme.spacing.unit * 2,
		},
		  height: theme.mixins.cardHeader.height,
		  borderBottom: '1px solid rgba(224, 224, 224, 1)'
	},
	  cardContent : {
		padding: theme.spacing.unit,
        overflow: 'auto',
        height: `calc(100vh - 125px)`,
		'&:last-child': {
			paddingBottom: theme.spacing.unit
		}
	  },
});
class ApiDocs extends Component {
  constructor(props) {
      super(props);
      this.state = {
        definitionLink: "../api/v1.0/openapi.json",
      };
  };
  componentDidMount() {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'));
    const id_token = token.idToken ? token.idToken.idToken : '';
    SwaggerUI({
      domNode: document.getElementById("api-data"),
      url: this.state.definitionLink,
      docExpansion: 'none',
      displayRequestDuration: true,
      showCommonExtensions: true,
      requestInterceptor: (req) => {
          req.headers.Authorization = `Bearer ${id_token}`;
          return req
      }
    })
  };
  render() {
    const { classes, raised=false } = this.props;
    //const title = "API Docs";
    return (
        <Card className={classes.root} raised={raised}>
            {/*<CardHeader className={ classes.cardHeader } title={title} />*/}
            <CardContent className={ classes.cardContent }>
                <Alert severity="warning" elevation={1} variant="filled">
                    <Fragment>
                        <AlertTitle>Please do not execute below actions, if you are not sure and NMS team will be notified on execution of these actions.</AlertTitle>
                        <u>Secured Actions:</u><i>  rebootPolycom, restartPolycom, resetHughes, rebootHughes, initiateVadb, terminateVadb,
                        rebootFortigate, clearArpCisco, clearMacCisco, rebootCisco, rebootEcm</i>
                        <br />
                        <u>Multi Actions:</u><i>  dcSwitch, switchToHome, switchToBackup, switchToGtn, switchToNlv</i>
                        <p>and other CRUD APIs</p>
                    </Fragment>
                </Alert>
                <div id="api-data" />
            </CardContent>
        </Card>
    );
  }
}

ApiDocs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withOktaAuth(withStyles(styles, {withTheme: true})(ApiDocs));
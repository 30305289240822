import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { DotsHorizontal } from 'mdi-material-ui';
//import _ from 'lodash';
//import fetchClient  from '../../axios';
import PropTypes from "prop-types";
import compose from "recompose/compose";

const styles = theme => ({
    button: {
        padding: 0,
        color: '#03A9F4'
    }
});

class ManageDevicesRowActionsMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            anchorEl: null
        };
        this.handleDeviceDetails = this.handleDeviceDetails.bind(this)
    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleAuditLog = () => {

    };
    handlePamSuspend = () => {
        const deviceData = this.props.rowData;
        this.props.actionsMenuHandler('pamSuspend', deviceData, `PAM Suspend for ${deviceData.name}`)
    };
    handleDeviceDetails = () => {
        const deviceData = this.props.rowData;
        this.props.actionsMenuHandler('deviceDetails', deviceData, `Device Details for ${deviceData.name}`)
    };
  render() {
    const { anchorEl } = this.state;
    const { classes, rowData } = this.props;
    return (
          <Fragment>
              <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClick}
                className={classes.button} >
                <DotsHorizontal />
              </IconButton>
               <Menu
                 id="simple-menu"
                 anchorEl={anchorEl}
                 open={Boolean(anchorEl)}
                 onClose={() => this.setState({ anchorEl: null })}
               >
                   { rowData.elementName !== '' && <MenuItem key="devicedetails" onClick={this.handleDeviceDetails}>Device Details</MenuItem> }
                   { (rowData.discovered && (rowData.poller !== 'notset' || rowData.poller !== 'PAM-ST-OI' || rowData.poller !== '') && rowData.nocid !== 'CRM-ACTV' && rowData.nmd !== 'notset' && (rowData.provisionStatus === 'ACTV' || rowData.provisionStatus === 'maint') && (rowData.eitService_Mode !== 'Unmonitored' || rowData.eitService_Mode !== 'notset')) && <MenuItem key="pamsuspend"  onClick={this.handlePamSuspend}>PAM Suspend</MenuItem> }
                </Menu>
          </Fragment>
    );
  }
}

ManageDevicesRowActionsMenu.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(ManageDevicesRowActionsMenu);
import React, {  PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import DataGrid from 'devextreme-react/data-grid';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
        overflow: 'auto',
        padding: 0,
        '&:last-child': {
            paddingBottom: theme.spacing(1)
        }
    },
    headerTitle: {
      fontSize: '1.2rem',
      //color: '#ffffff'
    },
    summaryViewIconButton: {
        padding: 6
    },
    summaryViewActions: {
        marginBottom: -6,
        marginTop: 3
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableCellBold: {
        padding: '4px 16px',
        lineHeight: '0.80rem',
        fontWeight: 'bold',
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5'
    }
});
class PingOrCurrentStatusFortiManagerApplianceView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {

        };
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    textFormatter = (cellInfo) => {
        return cellInfo.value.toUpperCase();
    };
    render() {
        const { classes,  raised, title, actionData }   = this.props;
        const wifiDetailsProps = [
            {fieldName: 'device_name',   label: 'Device Name'},
            {fieldName: 'serial_id',     label: 'Serial No'},
            {fieldName: 'firmware',      label: 'Firmware'},
            {fieldName: 'ip',            label: 'Local IP'},
            {fieldName: 'joined',        label: 'Joined'},
            {fieldName: 'last_rebooted', label: 'Last Rebooted'},
            {fieldName: 'cpu_usage',     label: 'CPU Usage'},
            {fieldName: 'mem_free',      label: 'Memory Free'},
            {fieldName: 'mem_total',     label: 'Memory Total'},
            {fieldName: 'clients',       label: 'Clients'},
            {fieldName: 'mac',           label: 'MAC'},
            {fieldName: 'psiteid',       label: 'Parent Fortigate'},
            {fieldName: 'status',        label: 'Status'}
        ];
        const radioProps = [
            {dataField: 'radio_id',     caption: 'No'},
            {dataField: 'channel',      caption: 'Channel'},
            {dataField: 'mode',         caption: 'Mode'},
            {dataField: 'radio_type',   caption: 'Type'},
            {dataField: 'ssids',        caption: 'SSIDs'},
            {dataField: 'bandwidth_rx', caption: 'Bandwidth RX'},
            {dataField: 'bandwidth_tx', caption: 'Bandwidth TX'},
            {dataField: 'bytes_rx',     caption: 'Total RX'},
            {dataField: 'bytes_tx',     caption: 'Total TX'},
            {dataField: 'txpower',      caption: 'TX Power'}
        ];
        const switchDetailsProps = [
            {fieldName: 'device_name', label: 'Device Name'},
            {fieldName: 'serial_id',   label: 'Serial No'},
            {fieldName: 'firmware',    label: 'Firmware'},
            {fieldName: 'ip',          label: 'Local IP'},
            {fieldName: 'joined',      label: 'Joined'},
            {fieldName: 'psiteid',     label: 'Parent Fortigate'},
            {fieldName: 'status',      label: 'Status'}
        ];
        const portProps = [
            {dataField: 'interface', caption: 'Name'},
            {dataField: 'speed',     caption: 'Speed'},
            {dataField: 'duplex',    caption: 'Duplex'},
            {dataField: 'vlan',      caption: 'VLAN'},
            {dataField: 'status',    caption: 'Status'}
        ];

        const device_details = actionData.hasOwnProperty('device_name') ? actionData : {};
        const device_radios  = actionData.hasOwnProperty('radios') ? actionData['radios'] : [];
        const device_ports   = actionData.hasOwnProperty('ports') ? actionData['ports'] : [];
        const detailsProps   = actionData.hasOwnProperty('appliance_type') && 
                                 actionData['appliance_type'] === "Switch" ? 
                                   switchDetailsProps : wifiDetailsProps;
        const error          = actionData && typeof actionData !== 'string' && 
                               actionData.hasOwnProperty('device_name') ? "" :
                                 actionData && typeof actionData === 'string' ?  actionData : 
                                   "Unknown error";
        return (
            <Card variant="outlined" className={classes.root} raised={raised}>
              <CardHeader className={classes.cardHeader} classes={{title: classes.headerTitle}} title={title} />
              <CardContent className={classes.cardContent} >

            { error ?
                <Table size="small">
                  <TableBody stripedRows>
                    <TableRow key="Error" className={ classes.tableRow }>
                      <TableCell className={ classes.tableCellBold } component="head" scope="row">Error:</TableCell>
                      <TableCell className={ classes.tableCell }>{error}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                :

                <Table size="small">
                  <TableBody stripedRows>
                    <TableRow key='detailsHeader' className={ classes.tableRow1 }>
                      <TableCell colSpan={2} align="center" className={classes.tableCellBold}>Device Details</TableCell>
                    </TableRow>

                   { device_details ?
                       _.map(detailsProps, function (value, key) {
                           let fieldName = value['fieldName'];
                           let val = (fieldName === 'mac') ? _.capitalize(device_details[fieldName]) : 
                                      device_details[fieldName];
                           let label = value['label'];
                           return (
                               <TableRow key={key} className={ classes.tableRow }>
                                 <TableCell className={classes.tableCellBold} component="head" scope="row">{label}</TableCell>
                                 <TableCell className={classes.tableCell}>{val}</TableCell>
                               </TableRow>
                           );
                       })

                       : ""

                   }

                  </TableBody>
                </Table>
            }

            { device_radios && device_radios.length?
                <Card className={classes.card1}>
                  <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Radios'}/>
                    <DataGrid
                      dataSource={device_radios}
                      columns={radioProps}
                      showBorders={true}
                    />
                </Card>
              : device_ports && device_ports.length?
                <Card className={classes.card1}>
                  <CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={'Ports'}/>
                    <DataGrid
                      dataSource={device_ports}
                      columns={portProps}
                      showBorders={true}
                    />
                </Card>
              : ""
            }
              </CardContent>
            </Card>
        )
    }
}

PingOrCurrentStatusFortiManagerApplianceView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(PingOrCurrentStatusFortiManagerApplianceView);

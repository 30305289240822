import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
//import { FileTree, ChevronRightBox } from 'mdi-material-ui';
//import red from '@material-ui/core/colors/red';
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import compose from "recompose/compose";
//import numeral from "numeral";

const styles = theme => ({
  checkBoxPanel: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5, 3),
  },
  expandBtn: {
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    minWidth: 24
  }
});

class MapToggleLayerPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showMarkers: true,
      showWeather: true,
      showPowerOutage: false
    };
  }
  handleExpandClick = () => {
    this.setState({ expanded: !this.state["expanded"] });
  };
  handleCheckboxChange = (e, name) => {
    e.stopPropagation();
    const obj={};
    obj[name] = e.target.checked;
    this.setState(obj);
    //console.log(obj);
    this.props.onCheckBoxChanged(name, e.target.checked);
  };
  render() {
    const { classes } = this.props;
    const { expanded, showMarkers, showWeather, showPowerOutage } = this.state;
    return (
      <div>
        <Button className={classes.expandBtn}
          color="secondary"
          variant="contained"
          disableRipple
          onClick={this.handleExpandClick.bind(this)}
          aria-expanded={this.state["expanded"] || false}
          aria-label="collapse-btn"
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </Button>
            <Collapse
              in={this.state["expanded"] || false}
              timeout="auto"
              unmountOnExit
            >
                <Paper elevation={4} className={classes.checkBoxPanel}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showMarkers}
                          onChange={(e) => this.handleCheckboxChange(e, "showMarkers")}
                          name="showMarkers"
                          color="primary"
                        />
                      }
                      label="Markers"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showWeather}
                          onChange={ (e) => this.handleCheckboxChange(e, "showWeather") }
                          name="showWeather"
                          color="primary"
                        />
                      }
                      label="Weather"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showPowerOutage}
                          onChange={ (e) => this.handleCheckboxChange(e, "showPowerOutage") }
                          name="showPowerOutage"
                          color="secondary"
                        />
                      }
                      label="Power Outage"
                    />
                  </FormGroup>
                </Paper>
            </Collapse>
      </div>
    );
  }
}

MapToggleLayerPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(MapToggleLayerPanel);
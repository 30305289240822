import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { DotsHorizontal } from 'mdi-material-ui';
//import _ from 'lodash';
//import fetchClient  from '../../../axios';
import PropTypes from "prop-types";
import compose from "recompose/compose";

const styles = theme => ({
    button: {
        padding: 0,
        color: '#03A9F4'
    }
});

class NetworkHierarchyRowActionsMenu extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            anchorEl: null
        };
        this.handleGetDevice = this.handleGetDevice.bind(this);
        this.handleCurrentEvents = this.handleCurrentEvents.bind(this);
        this.handleArchivedEvents = this.handleArchivedEvents.bind(this);
    }
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleGetDevice = () => {
        const rowData = this.props.rowData;
        this.props.actionsMenuHandler('getDevices', rowData, `Devices for ${rowData.name}`)
    };
    handleCurrentEvents = () => {
        const rowData = this.props.rowData;
        this.props.actionsMenuHandler('getCurrentEvents', rowData, `Events of ${rowData.name}`)
    };
    handleArchivedEvents = () => {
        const rowData = this.props.rowData;
        this.props.actionsMenuHandler('getArchivedEvents', rowData, `Devices for ${rowData.name}`)
    };
  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    return (
          <Fragment>
              <IconButton
                aria-label="More"
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClick}
                className={classes.button} >
                <DotsHorizontal />
              </IconButton>
               <Menu
                 id="simple-menu"
                 anchorEl={anchorEl}
                 open={Boolean(anchorEl)}
                 onClose={() => this.setState({ anchorEl: null })}
               >
                   <MenuItem key="getDevices" onClick={this.handleGetDevice}>Get Devices</MenuItem>
                   <MenuItem key="getCurrentEvents" onClick={this.handleCurrentEvents}>Get Current Events</MenuItem>
                   <MenuItem key="getArchivedEvents" onClick={this.handleArchivedEvents}>Get Archive Events</MenuItem>
                </Menu>
          </Fragment>
    );
  }
}

NetworkHierarchyRowActionsMenu.propTypes = {
    classes: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(NetworkHierarchyRowActionsMenu);
import axios from 'axios';

const fetchClient = () => {
  const defaultOptions = {
    // baseURL: process.env.REACT_APP_API_PATH,
    // baseURL: "http://127.0.0.1:80",
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'));
    //config.headers.Authorization =  token.accessToken ? `Bearer ${token.accessToken.accessToken}` : '';
    config.headers.Authorization =  token.idToken ? `Bearer ${token.idToken.idToken}` : '';
    return config;
  });

  return instance;
};

export default fetchClient();
import React from 'react';
import { Icon } from '@material-ui/core';
import infoBloxIcon from './infoblox-icon.svg';
 const InfoBlox = () => {
     return (
         <Icon>
             <img src={infoBloxIcon} alt="info-blox" height={25} width={25} />
         </Icon>
        )
    };
 export default InfoBlox;
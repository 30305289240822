import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
//import Avatar from '@material-ui/core/Avatar';
//import { ChartBar  } from 'mdi-material-ui';
import DataGrid from 'devextreme-react/data-grid';
import _ from 'lodash';
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


const styles = theme => ({
    root: {
      width: '100%'
    },
    card1: {
      //width: '100%',
      margin: theme.spacing(1),
    },
    cardHeader: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: '4px 16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    cardContent: {
                overflow: 'auto',
                padding: 0,
                '&:last-child': {
                        paddingBottom: theme.spacing(1)
                }
    },
    headerTitle: {
      fontSize: '1.2rem',
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    orange: {
        color: '#fff',
        backgroundColor: orange[500],
    },
    grey: {
        color: '#fff',
        backgroundColor: grey[500],
    },
    tableCell: {
        padding: '4px 16px',
        lineHeight: '0.80rem'
    },
    tableCellBold: {
        padding: '4px 16px',
        lineHeight: '0.80rem',
        fontWeight: 'bold'
    },
    tableRow: {
        height: 25,
        padding: 8,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableRow1: {
        fontWeight: 'bold',
        height: 25,
        padding: 8,
        backgroundColor: '#f5f5f5'
    }
});
class PingOrCurrentStatusVeloCloudView extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
    
        };
    }
    componentWillMount() {

    }
    componentDidMount() {

    };
    componentWillUnmount () {

    };
    render() {
        const { classes, raised, title, actionData }  = this.props;
        let primaryDeviceProps = [
            {fieldName: 'name', label: 'Edge Name'},
            {fieldName: 'hns_primeSanRole', label: 'HA Role'},
            {fieldName: 'serialNumber', label: 'Serial No'},
            {fieldName: 'modelNumber', label: 'Model'},
            {fieldName: 'hns_primeSanState', label: 'Edge Status'},
            {fieldName: 'lastContact', label: 'Last Contact'}];
        let backupDeviceProps = [
            {fieldName: 'hns_backupSan', label: 'Edge Name'},
            {fieldName: 'hns_backupSanRole', label: 'HA Role'},
            {fieldName: 'haSerialNumber', label: 'Serial No'},
            {fieldName: 'hns_backupSanState', label: 'Edge Status'},
            {fieldName: 'haLastContact', label: 'Last Contact'}];

        // Determine primary/backup device name based on custom hns properties
        let hasHA            = actionData && actionData['hns_hasHA']? true : false;
        let backupDevice     = actionData && actionData['hns_backupSan'] ? actionData['hns_backupSan'] : "";
        let standaloneDevice = actionData && !hasHA ? actionData['name'] : "";
        
        // Determine serial, last contact and up since based on active ha if known
        if (hasHA) {
            // if prime active, everything is default plus add up since
            if (actionData['hns_primeSanRole'] === 'Active') {
                // Add up since to prime
                primaryDeviceProps.push({fieldName: 'systemUpSince', label: 'Up Since'});
                
            // if prime is standby, flip serial, last contact and up since
            } else if (actionData['hns_primeSanRole'] === 'Standby') {
                // flip serial number        
                let tmpSerial = actionData['SerialNumber'];
                actionData['SerialNumber'] = actionData['haSerialNumber'];
                actionData['haSerialNumber'] = tmpSerial;
                // flip last contact        
                let tmpContact = actionData['lastContact'];
                actionData['lastContact'] = actionData['haLastContact'];
                actionData['haLastContact'] = tmpContact;
                // Add up since to backup props
                backupDeviceProps.push({fieldName: 'systemUpSince', label: 'Up Since'});
                
            // if prime is unknown, hedge serial and default last contact and up since on primary
            } else if (actionData['hns_primeSanRole'] === 'Unknown') {
                // hedge serial number        
                let tmpSerial = actionData['serialNumber'];
                actionData['serialNumber'] = tmpSerial + " or " + actionData['haSerialNumber']; 
                actionData['haSerialNumber'] = actionData['haSerialNumber'] + " or " + tmpSerial;
                // Add up since to primary props
                primaryDeviceProps.push({fieldName: 'systemUpSince', label: 'Up Since'});
            }
        } else if (actionData) {
            // Add up since to primary props for standalone
            primaryDeviceProps.push({fieldName: 'systemUpSince', label: 'Up Since'});
        }
        

        const link_status_columns = [
                {dataField:'hns_haRole', caption: 'Transport'},
                {dataField:'interface', caption: 'Interface'},
                {dataField:'displayName', caption: 'ISP'},
                {dataField:'ipAddress', caption: 'IP'},
                {dataField:'state', caption: 'Link Status'},
                {dataField:'serviceState', caption: 'Cloud Status'},
                {dataField:'vpnState', caption: 'VPN Status'},
                {dataField:'lastActive', caption: 'Last Active'}];
        let linksData = actionData['links'] ? actionData['links'] : [];

        //if (actionData['edgeState'] === 'OFFLINE') {
        //    linksData.forEach(function(item) {
        //        item['serviceState'] = 'Unknown';
        //        item['vpnState'] = 'Unknown';
        //    });
        //}

        // enhance link data and skip stale links
        let hnsLinks = []
        linksData.forEach(function(item, index) {
            // format zulu time strings 
            item['lastActive'] = item['lastActive'].replace(/T/, " ").replace(/\..*Z/, " UTC");

            // get link state and role from custom hns prop
            if (item['interface'] === actionData['hns_primeTransIf'] && 
                index === actionData['hns_primeTransIndex']) {
                item['state'] = actionData['hns_primeTransState'];
                item['hns_haRole'] = 'Prime';
                hnsLinks.push(item);
            } else if (item['interface'] === actionData['hns_backupTransIf'] &&
                       index === actionData['hns_backupTransIndex']) {
                item['state'] = actionData['hns_backupTransState'];
                item['hns_haRole'] = 'Backup';
                hnsLinks.push(item);
            }
        });

        
        let deviceDetailHeader = standaloneDevice? "Device Details" : "Primary Device Details";
        return (
                <Card variant="outlined" className={classes.root} raised={raised}>
                <CardHeader className={classes.cardHeader} classes={{title: classes.headerTitle}} title={title} />
                <CardContent className={classes.cardContent} >
                  <Table size="small">
                    <TableBody stripedRows>
                      <TableRow key='detailsHeader' className={classes.tableRow1}>
                        <TableCell colSpan={2} align="center" className={classes.tableCellBold}>{deviceDetailHeader}</TableCell>
                      </TableRow>
                      { _.map(primaryDeviceProps, function (value, key) {
                              let fieldName = value['fieldName'];
                              let val = actionData[fieldName];
                              let label = value['label'];

                              // hns_activeRole not applicable to standalone devices
                              if (standaloneDevice && fieldName === 'hns_primeSanRole') {
                                  return;
                              } 
        
                              // format zulu time strings 
                              if (fieldName === 'haLastContact' || fieldName === 'lastContact' || fieldName === 'systemUpSince') {
                                  val = val.replace(/T/, " ").replace(/\..*Z/, " UTC");
                              } 

                              return (
                                     <TableRow key={key} className={ classes.tableRow }>
                                       <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                       <TableCell className={ classes.tableCell }>{val}</TableCell>
                                     </TableRow>
                                     );
                        })
                      }
      
                      { backupDevice?  
                          (<TableRow key='backupHeader' className={ classes.tableRow1 }>
                            <TableCell colSpan={2} align="center" className={ classes.tableCellBold }>Backup Device Details</TableCell>
                          </TableRow>
                          ): ""
                      }
                      { backupDevice ? 
                        _.map(backupDeviceProps, function (value, key) {
                              let fieldName = value['fieldName'];
                              let val = actionData[fieldName];
                              let label = value['label'];

                              // format zulu time strings 
                              if (fieldName === 'haLastContact' || fieldName === 'lastContact' || fieldName === 'systemUpSince') {
                                  val = val.replace(/T/, " ").replace(/\..*Z/, " UTC");
                              } 

                              return (
                                     <TableRow key={key} className={ classes.tableRow }>
                                       <TableCell className={ classes.tableCellBold } component="head" scope="row">{label}</TableCell>
                                       <TableCell className={ classes.tableCell }>{val}</TableCell>
                                     </TableRow>
                                     );
                        })
                        : ""
                      }

                    </TableBody>
                  </Table>
                  <Table size="small">
                    <TableBody>
                      <TableRow key='linksHeader' className={classes.tableRow1}>
                        <TableCell colSpan={2} align="center" className={classes.tableCellBold}>Links Details</TableCell>
                      </TableRow>
                      <TableRow key='links' className={ classes.tableRow }>
                        <TableCell colSpan={2} className={ classes.tableCell } component="head" scope="row">
                          {actionData &&
                               <DataGrid
                                 dataSource={hnsLinks}
                                 columns={link_status_columns}
                                 showBorders={false}
                               />
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
                </Card>
        )
    }
}

PingOrCurrentStatusVeloCloudView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
        withStyles(styles, {withTheme: true})
    );
export default enhance(PingOrCurrentStatusVeloCloudView);

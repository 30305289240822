import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import compose from 'recompose/compose';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
//import * as constants from '../../constants';

const styles = theme => ({
	root: {
	  width: '100%'
	},
	cardHeader: {
        //border: '1px solid rgba(224, 224, 224, 1)',
		backgroundColor: theme.palette.primary.light,
		padding: '4px 16px',
		height: '30px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		}
	},
	cardContent: {
		padding: 0,
		border: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			paddingBottom: theme.spacing(1)
		}
	},
	headerTitle: {
	  fontSize: '1rem',
	  color: '#ffffff'
	},
	tableCell: {
		padding: '4px 16px',
		lineHeight: '0.60rem',
		color: '#616161',
		fontSize: '13px'
	},
	tableRow: {
		height: 18,
		padding: 8,
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.rowBackgroundColor
		}
	}
});
class DeviceStatus extends PureComponent {
	constructor(props) {
		super(props);
		this.state= {
			isFullScreen: false
		};
	}
	stateRender = (stat) => {
		let state, label;
		switch (stat) {
			case 1:
				state = 'critical';
				label = 'Critical';
				break;
			case 2:
				state = 'major';
				label = 'Major';
				break;
			case 3:
				state = 'minor';
				label = 'Minor';
				break;
			case 4:
				state = 'unknown';
				label = 'Unknown';
				break;
			case 5:
				state = 'normal';
				label = 'Normal';
				break;
			default:
				state = 'warning';
				label = stat;
				break;
		}
		return (<span className={"label label-status alert-"+state}>{label}</span>)
	};
	dateRender = (date) => {
		return (date === 0 || date === '0') ? 'Never' : moment.unix(date/1000000000).format('MM/DD/YYYY HH:mm:ss');
	};
	render() {
		const { classes, raised, deviceData } = this.props;
		const title = 'Device Status';
		const tableColumns = [
		    { caption: 'Name', dataField: 'name' },
		    { caption: 'Status', dataField: 'state' },
		    { caption: 'Device Type', dataField: 'siteTypeDescription' },
            { caption: 'Device Class', dataField: 'className' },
            { caption: 'Customer ID', dataField: 'nmd' },
            { caption: 'Proactive Monitoring Status', dataField: 'pamDiscoveryState' },
            { caption: 'Customer Gateway Status', dataField: 'provisionStatus' },
            { caption: 'Services', dataField: 'hnsService' },
            { caption: 'Discovered', dataField: 'discovered' },
            { caption: 'NMP Update Time', dataField: 'rsUpdatedAt' }
        ];
		return (
				<Card className={classes.root} raised={raised}>
					<CardHeader className={ classes.cardHeader } classes={{title: classes.headerTitle}} title={title} />
					<CardContent className={ classes.cardContent }>
					 <Table size="small">
						 <TableBody stripedRows>
							{deviceData ?
								tableColumns.map(row => {
									if (row.dataField === 'state') {
									  return (
										<TableRow key={row.dataField} className={ classes.tableRow }>
										  <TableCell variant="head" scope="row" className={ classes.tableCell }>{row.caption}</TableCell>
										  <TableCell className={ classes.tableCell }>{this.stateRender(deviceData[row.dataField])}</TableCell>
										</TableRow>
									  );
									} else if (row.dataField === 'rsUpdatedAt') {
									  return (
										<TableRow key={row.dataField} className={ classes.tableRow }>
										  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
										  <TableCell className={ classes.tableCell }>{this.dateRender(deviceData[row.dataField])}</TableCell>
										</TableRow>
									  );
									} else {
									  return (
										<TableRow key={row.dataField} className={ classes.tableRow }>
										  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
										  <TableCell className={ classes.tableCell }>{deviceData[row.dataField]}</TableCell>
										</TableRow>
									  );
									}
								}) :
								tableColumns.map(row => {
								  return (
									<TableRow key={row.dataField} className={ classes.tableRow }>
									  <TableCell variant="head" className={ classes.tableCell } scope="row">{row.caption}</TableCell>
									  <TableCell className={ classes.tableCell }>{'Unknown'}</TableCell>
									</TableRow>
								  );
								})
							}
						</TableBody>
					  </Table>
					</CardContent>
				</Card>
		)
	}
}

DeviceStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const enhance = compose(
		withStyles(styles, {withTheme: true})
	);
export default enhance(DeviceStatus);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from "recompose/compose";
import DeviceSearch from '../components/device-search/DeviceSearch';

const styles = theme => ({
	  root: {
	    height: '100%'
	  }
	});


class DeviceSearchContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		    hasErrors: false
		}
	};
    componentDidMount() {

    };
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    };
	render() {
		const { classes } = this.props;
		const { hasErrors } = this.state;
		return (
				<div className={classes.root}>
					{hasErrors ?
						<div>Page has erros, please fix!!!</div>
					:
						<DeviceSearch raised={true}/>
					}
				</div>
		)
	}
}
DeviceSearchContainer.propTypes = {
	  classes: PropTypes.object.isRequired,
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default withRouter(enhance(DeviceSearchContainer));
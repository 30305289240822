import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import compose from 'recompose/compose';
import { DataGrid, Column, MasterDetail } from 'devextreme-react/data-grid';
//import PieChart, { LoadingIndicator, Series, Label, Connector, Size, Font, Legend, Tooltip } from 'devextreme-react/pie-chart';
import { Chart, Series, Label, Size, Legend, Tooltip, CommonSeriesSettings, CommonAxisSettings } from 'devextreme-react/chart';
import fetchClient  from '../../axios';
import HughesServicesTemplateView from './HughesServicesTemplateView';
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import lightBlue from "@material-ui/core/colors/lightBlue";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    masterDetailCaption: {
        padding: '0 0 5px 10px',
        fontSize: '14px',
        fontWeight: 'bold'
    }
});
const formatNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
}).format;
class HughesDatacentersTemplateView extends PureComponent {
    constructor(props) {
        super(props);
        this.dataStore = this.props.data.data.data_centers;
        this.renderSparkLineChart = this.renderSparkLineChart.bind(this);
        this.dataCentersChartRef = React.createRef();
        this.getInroutes = this.getInroutes.bind(this);
    }
    componentWillMount() {
        //this.getInroutes();
    }
    customizeText = (point) => {
          return `${point.percentText}`;
    };
    chartTooltipTemplate = (info) => {
        let count = 0;
        if(info['point'] && info['point']['data'] && info['point']['data'][`${info.seriesName}_counts`]) {
            count = info['point']['data'][`${info.seriesName}_counts`]
        }
      return (
        <div className="state-tooltip">
          <div>
            <span className="">{_.capitalize(info.seriesName)}</span>: {info.percentText}
          </div>
          <div>
            <span className="">Count</span>: {formatNumber(count)}
          </div>
        </div>
      );
    };
    customizePoint = (point) => {
		switch (point.seriesName) {
			case 'Critical':
				return { color: red[500]};
			case 'Major':
				return { color: orange[500]};
			case 'Minor':
				return { color: yellow[500]};
			case 'Unknown':
				return { color: lightBlue[500]};
			case 'Normal':
				return { color: green[500]};
            default:
                //None
		}
	};
    customizeLabel = (arg) => {
        if (arg.value > 20) {
            return {
                visible: true,
                position: 'inside',
                customizeText: function(e) {
                    return `${ formatNumber(e.valueText) }%`
                }
            }
        }
    };
    renderSparkLineChart = (cellInfo) => {
        let states = cellInfo['data']['device_count_by_state'];
        let mStates = [];
        let rObj = {};
        // eslint-disable-next-line
        states.map(obj => {
            rObj[obj['label']] = obj['percent'];
            rObj[`${obj['label']}_counts`] = obj['value'];
            rObj['label'] = 'Status';
        });
        mStates.push(rObj);
        return (
                <Chart ref={ this.dataCentersChartRef }
                        id='hughes-datacenters-chart'
                        dataSource={ mStates }
                        rotated={ true }
                        customizePoint={this.customizePoint}
                        customizeLabel={this.customizeLabel}
                    >
                    <CommonAxisSettings grid={{visible: false}} tick={{ visible: false }} visible={false} label={{ visible: false}}/>
                    <CommonSeriesSettings argumentField='label' type='fullstackedbar' minBarSize={2}>
                        <Label visible={false} font={{size: 11}}/>
                    </CommonSeriesSettings>
                    <Series valueField='Critical' name='Critical' />
                    <Series valueField='Major' name='Major' />
                    <Series valueField='Minor' name='Minor' />
                    <Series valueField='Unknown' name='Unknown' />
                    <Series valueField='Normal' name='Normal' />
                    <Legend visible={false}/>
                    <Tooltip enabled={true} contentRender={this.chartTooltipTemplate} cornerRadius={4} zIndex={3000}/>
                    <Size width={200} height={40} />
                </Chart>
        )
    };
    /**
     * getInroutes:
     */
    getInroutes = () => {
    	fetchClient.get('/api/v1.0/svn/svi_hughes_inroutes')
            .then(res => {
                let hughesInroutes = [];
                if (res.data && res.data.data) {
                    hughesInroutes = res.data.data;
                    let inroutes = [];
                    let inroutes_device_counts;
                    let device_count;
                    let device_count_obj;
                    _.forEach(this.dataStore, function(value, key) {
                        inroutes_device_counts = value['inroutes_device_counts'];
                        inroutes = [];
                        //let inroutes = _.find(hughesInroutes, function(item) { return item['datacenter_id'] == value['datacenter_id']; });
                        device_count = 0;
                        device_count_obj = {};
                        // eslint-disable-next-line
                        hughesInroutes.map(obj => {
                            device_count = 0;
                            if(value['datacenter_id'] === obj['datacenter_id']) {
                                device_count_obj = _.find(inroutes_device_counts, function(item) { return (parseInt(obj['name']) === item['beam_id']); });
                                device_count = device_count_obj['device_count'] ? device_count_obj['device_count'] : 0;
                                //console.log('datacenter_id'+obj['datacanter_id']);
                                obj['device_count'] = device_count;
                                inroutes.push(obj);
                            }
                        });
                        value['inroutes'] = inroutes;
                        //console.log(inroutes);
                    });
                }
            })
            .catch ((e) => { console.log('error', e);});
    };
render() {
        const { classes, data } = this.props;
        const location = data['data']['name'];
        return (
                  <Fragment>
                    <div className={classes.masterDetailCaption}>
                        {`Datacenters at ${location}`}
                    </div>
                    <DataGrid
                        dataSource={this.dataStore}
                        showBorders={true}
                        columnAutoWidth={true}
                        showRowLines={true}
                        showColumnLines={true}
                        >
                        <MasterDetail
                            enabled={true}
                            component={HughesServicesTemplateView}
                        />
                        <Column dataField='name' caption='Name' dataType='string' sortOrder='asc' width={200} />
                        {/*<Column dataField='device_count' caption='Total Devices' dataType='number' format={{ type: "fixedPoint", precision: 0 }} />*/}
                        <Column dataField='active' caption='Active' dataType='string' width={150} />
                        <Column dataField="State" caption="Status" cellRender={this.renderSparkLineChart} alignment="center" width={215} />
                        <Column dataField='services' caption='Services' dataType='string' width={150} />
                        <Column dataField='notes' caption='Notes' dataType='string' />
                    </DataGrid>
                  </Fragment>
        )
    }
}

HughesDatacentersTemplateView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(HughesDatacentersTemplateView);
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import compose from 'recompose/compose';
import { Template } from 'devextreme-react/core/template';
import { Close } from 'mdi-material-ui';
import { DataGrid, RemoteOperations, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, HeaderFilter,
    FilterRow, Scrolling, StateStoring } from 'devextreme-react/data-grid';
import Fullscreen from "react-full-screen";
import { SelectBox } from 'devextreme-react/ui/select-box';
import { Popover, ToolbarItem } from 'devextreme-react/popover';
import Form, { Item } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import Dock from 'react-dock';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import ReactJson from 'react-json-view';
import red from '@material-ui/core/colors/red';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { Magnify } from 'mdi-material-ui';
import fetchClient  from '../../axios';
import { ManageDevicesGridColumns } from './ManageDevicesTableConfig';
import { updateBulkActionsList } from "../common/utils";
import PamSuspendView from '../common/pamSuspend';
import PamSuspendGrid from '../common/pamSuspendGrid';
import ManageDevicesRowActionsMenu from './ManageDevicesRowActionsMenu';
import DeviceSummary from './DeviceSummary';
import DeviceDetails from '../device-details/DeviceDetails';
//import DeviceDetailsForPopup from '../device-details/DeviceDetailsForPopup';
import LuceneHelp from '../common/LuceneHelp';
import notify from "devextreme/ui/notify";
import {fade} from "@material-ui/core/styles/index";
import AccelerationAppliance from '../../images/Acceleration_Appliance.png';
import DigitalMediaPlayer from '../../images/Digital-Media-Player.png';
import DisplayTV from '../../images/Display-TV.png';
import Router from '../../images/Router.png';
import SatelliteModem from '../../images/Satellite_Modem.png';
import Switch from '../../images/Switch.png';
import WiFiApp from '../../images/Wi-Fi_App.png';
import WiFiModem from '../../images/Wi-Fi_Modem.png';
import WirelineModem from '../../images/Wireline_Modem.png';
//import 'devextreme/dist/css/dx.material.blue.light.compact.css';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: 6,
        height: `calc(100% - 30px)`
    },
    cardHeader: {
        padding: theme.spacing.unit,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    bulkContainer: {
        display: 'flex',
        flexDirection: 'row',
        zIndex: 1
    },
    bulkSelect: {
        width: 250,
        marginRight: '8px'
    },
    submitBtn: {
        height: 35,
        marginTop: 2
    },
    summaryContainer: {
        padding: 4
    },
    fabButton: {
        position: 'absolute',
        right: 14,
        marginTop: -58,
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[500],
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        //marginRight: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
    },
    searchIcon: {
        paddingRight: theme.spacing(1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#03a9f4'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
        transition: theme.transitions.create('width'),
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        width: '100%',
        '&:hover': {
          borderBottom: '2px solid #03a9f4',
        },
        [theme.breakpoints.up('sm')]: {
          width: '30ch',
          '&:focus': {
            width: '55vw',
          },
        },
    },
    button: {
        padding: 0,
        color: '#03A9F4'
    },
});
let luceneQuery = '';
let searchQuery = '';
const dataToExportItems = [{value: 'exportVisible', text: 'Export Visible'}, {value: 'matchedQuery', text: 'Matched Query'}];
const exportTypes = [{value: 'xls', text: 'XLS'}, {value: 'csv', text: 'CSV'}, {value: 'json', text: 'JSON'}];
const updateQuery = (q) => {
        let qry = [];
            if ( luceneQuery && luceneQuery !== '') {
                searchQuery = luceneQuery;
                return;
            }
            if (q) {
                if(q.length === 1) {
                    qry =  q[0];
                    //console.log('Q[0]:' + q[0]);
                    searchQuery = qry;
                    //console.log('searQ'+searchQuery);
                    return;
                }
                qry.push('(');
                _.each(q, function(val, idx) {
                    if(Array.isArray(val)) {
                        if(val.length === 1 ) {
                            qry.push(val[0]);
                        } else {
                            qry.push('(');
                            _.each(val, function(v, idx) {
                                if (typeof(v) === 'string') {
                                    qry.push(_.toUpper(v));
                                } else {
                                    qry.push(v);
                                }
                            });
                            qry.push(')');
                        }
                    }
                    if (typeof(val) === 'string') {
                        qry.push(_.toUpper(val));
                    }
                });
                qry.push(')');
                qry = qry.join(' ');
            }
            /*if ( luceneQuery && luceneQuery !== '') {
                qry = (qry && qry.length > 0 ) ? qry + ' AND ' + luceneQuery : luceneQuery;
            }*/
            searchQuery = qry;
};
class ManageDevices extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            query: '*:*',
            pamStatusActions: [],
            summaryData: {},
            isCommandExecuted: true,
            popupVisible: false,
            content: '',
            title: '',
            dataToExport: dataToExportItems[0].value,
            exportType: exportTypes[0].value,
            maxRecords: 100,
            maxRecordsDisabled: true,
            isFullScreen: false,
            bulkActions: [],
            deviceData: [],
            isDockVisible: false,
            isSummaryDataLoading: false,
            value: 0,
            selectedDevices: ''
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.refreshDataGrid = this.refreshDataGrid.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
        this.handleClearSelections = this.handleClearSelections.bind(this);
        //this.updateQuery = this.updateQuery.bind(this);
        this.getDeviceSummaryData = this.getDeviceSummaryData.bind(this);
        this.handleDataToExport = this.handleDataToExport.bind(this);
        this.actionsMenuHandler = this.actionsMenuHandler.bind(this);
        this.handleLuceneSearch = this.handleLuceneSearch.bind(this);
        this.handleManageDevicesDock = this.handleManageDevicesDock.bind(this);
        //this.calculateFilterExpression = this.calculateFilterExpression.bind(this);
        //this.renderSearchPanel = this.renderSearchPanel.bind(this);
        this.manageDevicesGrid = null;
        this.bulkActionSelect = null;
        this.bulkActionSubmitBtn = null;
        this.manageDevicePopup = null;
        this.actionsScrollView = null;
        this.validationRules =  {
            dataToExport: [{type: 'required', message: 'Data to export is required'}],
            exportType: [{type: 'required', message: 'Export type is required'}],
            maxRecords: [{type: 'required', message: 'Max # of Records is required'}, {type: 'pattern', pattern: '^[0-9]*$', message: 'Use digits 0-9 only'}]
        };
        this.manageDevicesStore = {
            store: new CustomStore({
                key: 'name',
                load: function(loadOptions) {
                    let params = {};
                    if(loadOptions.filter) {
                        updateQuery(loadOptions.filter);
                    } else {
                        updateQuery();
                    }
                    params.query  = (searchQuery && searchQuery.length > 0) ? searchQuery : '*:*';
                    if (loadOptions.sort) {
                        params.sort = loadOptions.sort[0].selector;
                        params.order = loadOptions.sort[0].desc ? 'desc' : 'asc';
                    }
                    params.offset = loadOptions.skip;
                    params.limit = loadOptions.take;
                    return fetchClient.get('/api/v1.0/es/devices/all', {params: params})
                        .then(res => {
                            let devicesData = [];
                            let devicesDataTotal = 0;
                            //let scrollId = null;
                            if (res.data && res.data.data) {
                                devicesData = res.data.data;
                                devicesDataTotal = res.data.total;
                            }
                            //console.log('data:'+ devicesData+ 'Total'+devicesDataTotal);
                            return {
                                data: devicesData,
                                totalCount: devicesDataTotal
                            };
                        })
                        .catch (() => {
                            throw new Error('Data Loading Error');
                        });
                },
                onLoaded: () => { this.getDeviceSummaryData(); }
            })
        };
        this.validateForm = (e) => {
            e.component.validate();
        }
    }
    componentWillMount() {
        this.getDeviceSummaryData();
    }

    componentDidMount() {
        //this.getDeviceFilters()
    };
    componentWillUnmount () {
        this.manageDevicesStore.dispose();
    };
    onSelectionChanged = (selectedItems) => {
        if(selectedItems.selectedRowsData.length > 0) {
            const actions = updateBulkActionsList(selectedItems);
            this.setState({ bulkActions: actions });
            //this.bulkActionSubmitBtn.instance.option('disabled', false);
            //this.bulkActionSelect.instance.option('items', actions);
        } else {
            this.setState({ bulkActions: [] });
            //this.bulkActionSelect.instance.option('items', []);
            //this.bulkActionSubmitBtn.instance.option('disabled', true);
        }
    };
    refreshDataGrid = () => {
        this.manageDevicesGrid.instance.refresh();
        //this.getDeviceSummaryData();
    };
    resetDataGrid = () => {
        this.manageDevicesGrid.instance.state({});
        this.manageDevicesGrid.instance.refresh();
        //this.refreshDataGrid();
    };
    handleBulkActionSelectChange  = () => {
        //this.bulkActionSubmitBtn.instance.option('disabled', false);
    };
    handlePopupHidden = () => {
        this.setState({ popupVisible: false });
    };
    handleBulkActionSubmit = () => {
        let { value, name, deviceNames } = this.bulkActionSelect.instance.option('selectedItem');
        //let { userInfo } = this.props;
        let title = 'Results for '+name;
        let devices_len = deviceNames.split(',').length;
        if (deviceNames) {
            let url = '/api/v1.0/action/devices/'+value+'?device_names='+deviceNames;
            if(value === 'pamSuspend') {
                title = 'PAM Suspend for '+deviceNames;
                if (devices_len === 1) {
                    this.setState({selectedDevices: deviceNames, title: title}, ()=>{ this.handleManageDevicesDock(2); })
                    //this.setState({ isCommandExecuted: true, popupVisible: true, title: title, content: <PamSuspendView deviceName={deviceNames} userInfo={userInfo}/> });
                }
                if (devices_len > 1) {
                    this.setState({selectedDevices: deviceNames, title: 'PAM Suspend'}, ()=>{ this.handleManageDevicesDock(3); })
                    //this.setState({ isCommandExecuted: true, popupVisible: true, title: title, content: <PamSuspendGrid deviceNames={deviceNames} userInfo={userInfo}/> });
                }

                //this.manageDevicePopup.instance.option('width', '400px');
                //this.manageDevicePopup.instance.repaint();
                return;
            }
            this.setState({isCommandExecuted: false, popupVisible: true});
			fetchClient.post(url)
				.then(res => {
					let actionData;
					if (res.data) {
						actionData = res.data;
					}
                    this.setState({
                        isCommandExecuted: true,
                        title: title,
                        content: <ReactJson src={actionData} />
                    });
                    this.actionsScrollView.instance.update();
				})
				.catch(error => {
					console.error('Error:', error);
                    this.setState({isCommandExecuted: true, popupVisible: false});
                    notify({
                      message: 'Not able to execute '+value,
                      position: {
                        my: 'center top',
                        at: 'center top'
                      }
                    }, 'error', 3000);
				});
		}
    };
    handleClearFilters = () => {
        this.manageDevicesGrid.instance.clearFilter();
        luceneQuery=null;
        document.getElementById("manageDevicesSearchId").value = null;
        updateQuery();
        this.manageDevicesGrid.instance.refresh();
    };
    handleClearSelections = () => {
        this.manageDevicesGrid.instance.clearSelection();
    };
    handleLuceneSearch = () => {
        this.manageDevicesGrid.instance.clearFilter();
        updateQuery();
        this.manageDevicesGrid.instance.refresh();
    };
    handleManageDevicesDock = (value) => {
        this.setState({value: value, isDockVisible: !this.state.isDockVisible });
    };
    handleCloseManageDevicesDock = () => {
        this.setState({ isDockVisible: false });
    };
    /**
     * getDeviceSummaryData:
     */
    getDeviceSummaryData = () => {
      //this.setState({ isSummaryDataLoading: true });
      fetchClient.get('/api/v1.0/es/devices/summary?query='+searchQuery)
          .then(res => {
              let summaryData = [];
              if (res.data) {
                  summaryData = res.data && res.data.data ? res.data.data : {};
              }
              this.setState({
                  summaryData: summaryData
              });
          })
          .catch(error => {
              //this.setState({ isSummaryDataLoading: false });
              console.error('Error:', error);
          });
    };
    onToolbarPreparing = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    if(item.name === 'exportButton') {
                        items.splice(i, 1);
                    }
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                        filterButton.options.elementAttr = {'id': 'manageDevicesApplyFilterBtn'};
                    }
            }
            toolbarItems.push({
                    location: 'before',
                    template: 'gridBulkActionsSelect'
                },{
                    location: 'after',
                    template: 'gridSearchPanelTemplate'
                }, {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-help-circle-outline',
                        hint: 'Lucene Help',
                        text: 'Lucene Help',
                        onClick: this.handleManageDevicesDock.bind(this, 0)
                    }
                }, {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-selection-off',
                        hint: 'Clear Selections',
                        text: 'Clear Selections',
                        onClick: this.handleClearSelections.bind(this)
                    }
                } , filterButton, {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-filter-remove-outline',
                        hint: 'Clear Filters',
                        text: 'Clear Filters',
                        onClick: this.handleClearFilters.bind(this)
                    }
                } , {
                    widget: 'dxButton',
                    location: 'after',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        elementAttr: {'id': 'manageDevicesExportBtn', 'class': 'dx-button-default'},
                        hint: 'Export',
                        icon: 'export',
                        text: 'Export',
                        onContentReady: function (e) {

                        }
                    }
                },
                    columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        elementAttr: {'id': 'manageDevicesRefreshBtn'},
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                },{
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-restore',
                        hint: 'Reset Settings',
                        text: 'Reset Settings',
                        onClick: this.resetDataGrid.bind(this)
                    }
                },{
                    location: 'after',
                    locateInMenu: 'never',
                    widget: 'dxButton',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: this.state.isFullScreen ? 'mdi mdi-fullscreen-exit' : 'mdi mdi-fullscreen',
                        hint: this.state.isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
                        text: this.state.isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
                        onClick: this.handleFullScreen.bind(this)
                    }
                });
    };
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    onEditorPreparing(e) {
        if (e.parentType === 'filterRow') {
            e.editorOptions.onEnterKey = function(arg) {
                let filterButton1 = document.getElementById("manageDevicesApplyFilterBtn");
                filterButton1.click();
                luceneQuery=null;
                document.getElementById("manageDevicesSearchId").value = null;
            };
            e.editorOptions.onFocusOut = function(args) {
                let filterButton1 = document.getElementById("manageDevicesApplyFilterBtn");
                filterButton1.click();
                luceneQuery=null;
                document.getElementById("manageDevicesSearchId").value = null;
            };
        }
    };
    handleDataToExport = (e) => {
        (e.value === 'matchedQuery') ? this.setState({ maxRecordsDisabled: false }) : this.setState({ maxRecordsDisabled: true });
    };
    /**
     * calculateFilterExpression:
     * @param filterValue
     * @param selectedFilterOperation
     * @param target
     * @returns {*[]|*}
     */
    calculateFilterExpression (filterValue, selectedFilterOperation, target) {
        //console.log('dataFiled:'+this['dataField']);
        let col = this;
        let filterExpression, value;
        switch(selectedFilterOperation) {
            case '=':
                value = filterValue;
                break;
            case "<>":
                value = 'NOT '+filterValue;
                break;
            case "startswith":
                value = filterValue+'*';
                break;
            case "endswith":
                value = '*'+filterValue;
                break;
            case "contains":
                value = '*'+filterValue+'*';
                break;
            case "notcontains":
                value = 'NOT '+filterValue;
                break;
            default:
                //None
        }
        if(target === 'search') {
            value = '*'+filterValue+'*';
        }
        //filterExpression = (selectedFilterOperation === 'notcontains' || selectedFilterOperation === '<>') ? 'NOT ' + [col['dataField']+':'+filterValue] : [col['dataField']+':'+value];
        filterExpression = [col['dataField']+':'+value];
        return filterExpression
    };
    actionsMenuHandler = (widget, deviceData, title) => {
        //let component;
        if (widget === 'deviceDetails') {
            this.setState({deviceData: deviceData, title: title}, ()=>{ this.handleManageDevicesDock(1); })
            //component = <DeviceDetailsForPopup dName={deviceData.name} />
        }
        if(widget === 'pamSuspend') {
            /*component = <PamSuspendView deviceName={deviceData['name']} userInfo={this.props['userInfo']} />;
            this.manageDevicePopup.instance.option('width', '400px');
            this.manageDevicePopup.instance.repaint();
            this.setState({
                popupVisible: true,
                content: component,
                title: title
            });*/
            this.setState({selectedDevices: deviceData['name'], title: title}, ()=>{ this.handleManageDevicesDock(2); })
        }
    };
    renderDeviceName = (cellInfo) => {
        //let deviceType = cellInfo.data.siteTypeDescription;
        let productCategory = cellInfo.data.productCategory;
        let name = cellInfo.data.name;
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                    { productCategory === 'Router' &&  <img src={Router} alt="Router" title="Router" style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }}/> }
                    { productCategory === 'Wireline Modem' &&  <img src={WirelineModem} alt="Wireline Modem" title="Wireline Modem" style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }} /> }
                    { productCategory === 'Satellite Modem' &&  <img src={SatelliteModem} alt="Satellite Modem" title="Satellite Modem"  style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }} /> }
                    { productCategory === 'Acceleration Appliance' && <img src={AccelerationAppliance} alt="Acceleration Appliance" title="Acceleration Appliance" style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }} />}
                    { productCategory === 'Wireless Modem' && <img src={WiFiModem} alt="Wireless Modem" title="Wireless Modem"  style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }} />}
                    { productCategory === 'Switch' &&  <img src={Switch} alt="Switch" title="Switch" style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }} /> }
                    { productCategory === 'Digital Media Player' &&  <img src={DigitalMediaPlayer} alt="Digital Media Player" title="Digital Media Player"  style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }} /> }
                    { productCategory === 'Wi-Fi AP' &&  <img src={WiFiApp} alt="Wi-Fi AP" title="Wi-Fi AP"  style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }} /> }
                    { productCategory === 'Display TV' &&  <img src={DisplayTV} alt="Display TV" title="Display TV"  style={{ width:25, height:25, marginRight:8, color: '#03A9F4' }} /> }
                    {/* productCategory === 'notset' &&  <img src={Rake} alt="Unknown" style={{ width:25, height:25, color: '#03A9F4' }} />
                    <IconButton style={{padding: 0, color: '#03A9F4' }}>
                        { productCategory === 'notset' &&  <Rake/> }
                    </IconButton>*/}
                <div> {name} </div>
            </div>
        )
    };
    renderActionsMenu = (cellInfo) => {
        return <ManageDevicesRowActionsMenu rowData={cellInfo.data} actionsMenuHandler={this.actionsMenuHandler} />
    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen }, () => {
            this.state.isFullScreen ? this.manageDevicesGrid.instance.option('height', `calc(100vh-80px)`) : this.manageDevicesGrid.instance.option('height', `calc(100vh - 141px`);
        });
    };
    render(){
        const { classes, theme, raised, userInfo } = this.props;
        const { popupVisible, isCommandExecuted, content, title, summaryData,
        dataToExport, exportType, maxRecords, maxRecordsDisabled, isFullScreen, value, deviceData, isSummaryDataLoading, selectedDevices } = this.state;
        //const title1 = 'Manage Devices';
        const gridHeight = isFullScreen ? `calc(100vh-80px)` : `calc(100vh - ${theme.mixins.gridOffset+31}px)`;
        const renderBulkActionsSelect = () => {
                return (
                        <div className={classes.bulkContainer}>
                             <div className={classes.bulkSelect}>
                                <SelectBox
                                    ref={(ref) => this.bulkActionSelect = ref}
                                    items={this.state.bulkActions}
                                    placeholder='Select bulk action'
                                    displayExpr='name'
                                    valueExpr='value'
                                    onValueChanged={this.handleBulkActionSelectChange}
                                />
                             </div>
                            <Button
                                //icon="mdi mdi-send"
                                ref={(ref) => this.bulkActionSubmitBtn = ref}
                                elementAttr={{class: classes.submitBtn}}
                                disabled={false}
                                text="Submit"
                                type="default"
                                stylingMode="contained"
                                onClick={this.handleBulkActionSubmit}
                                />
                         </div>
                    )
            };
        const renderSearchPanel = () => {
                return (
                        <FormControl>
                          <div className={classes.search}>
                            <div className={classes.searchIcon}>
                              <Magnify />
                            </div>
                            <InputBase
                              placeholder="Search with lucene query"
                              id="manageDevicesSearchId"
                              defaultValue={luceneQuery}
                              error={false}
                              classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                              }}
                              inputProps={{ 'aria-label': 'search' }}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  luceneQuery = e.target.value;
                                  this.handleLuceneSearch();
                                }
                              }}
                            />
                          </div>
                        </FormControl>
                    )
            };
        return (
            <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
                <div id='manage-devices-container' className="full-screenable-node">
                    <Paper id='summary-container' className={classes.summaryContainer} elevation={2}>
                       { summaryData && <DeviceSummary summaryData={summaryData}/> }
                        {/*<Fab className={classes.fabButton} size="small" color="secondary" onClick={this.handleFullScreen}>{isFullScreen ? <FullscreenExit /> : <FScreen /> }</Fab>*/}
                    </Paper>
                    <Card className={classes.root} raised={raised}>
                            <DataGrid id={'manageDevicesGrid'}
                                elementAttr={{
                                    class: 'manage-devices-grid'
                                }}
                                ref={(ref) => this.manageDevicesGrid = ref}
                                height={ gridHeight }
                                dataSource={ this.manageDevicesStore }
                                showColumnLines={true}
                                showRowLines={true}
                                showBorders={true}
                                rowAlternationEnabled={true}
                                repaintChangesOnly={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnResizingMode={'widget'}//{(DevExpress.devices.current().deviceType === 'desktop' || DevExpress.devices.current().deviceType === 'tablet') ? 'widget' : 'nextColumn'}
                                columnAutoWidth={false}
                                columnHidingEnabled={true}
                                wordWrapEnabled={false}
                                columnMinWidth={70}
                                cellHintEnabled={true}
                                selection={{ mode: 'multiple', selectAllMode: 'page', showCheckBoxesMode: 'always' }}
                                onContentReady={this.onContentReady}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}
                                onEditorPreparing={this.onEditorPreparing}
                                onGridOptionsChanged={this.onGridOptionsChanged}
                                >
                                <HeaderFilter visible={true} />
                                <FilterRow visible={true} applyFilter={'onClick'} />
                                {/*<FilterPanel visible={false} />
                                <FilterBuilderPopup />*/}
                                <SearchPanel visible={false} width={200} placeholder={'Search ...'}/>
                                <ColumnChooser enabled={true} mode={'select'}/>
                                <Scrolling showScrollbar={'always'} useNative={false} />
                                <LoadPanel enabled={true}/>
                                <RemoteOperations sorting={true} paging={true} filtering={true}/>
                                <StateStoring enabled={true} type="localStorage" storageKey="mDevicesStore" />
                                <Paging defaultPageSize={15}/>
                                <Pager showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 15, 25, 50, 100]}/>
                                <Column type="button" caption="Actions" fixed={true} alignment="center" width={70} cellRender={this.renderActionsMenu} />
                                {
                                    ManageDevicesGridColumns.map(row => {
                                        if (row.dataField === 'name') {
                                          return (
                                            <Column dataField={row.dataField} caption={row.caption} dataType={row.dataType} allowHeaderFiltering={row.allowHeaderFiltering}
                                                    width={row.width} minWidth={row.minWidth} alignment={row.alignment} fixed={row.fixed} headerFilter={row.headerFilter} hidingPriority={row.hidingPriority}
                                                    // eslint-disable-next-line
                                                    customizeText={eval(row.customizeText)} cellTemplate={eval(row.cellTemplate)} cellRender={this.renderDeviceName}
                                                    calculateFilterExpression={this.calculateFilterExpression} sortOrder={row.sortOrder} />
                                          )
                                        } else {
                                          return (
                                            <Column dataField={row.dataField} caption={row.caption} dataType={row.dataType} allowHeaderFiltering={row.allowHeaderFiltering}
                                                    width={row.width} minWidth={row.minWidth} alignment={row.alignment} fixed={row.fixed} headerFilter={row.headerFilter} hidingPriority={row.hidingPriority}
                                                    // eslint-disable-next-line
                                                    customizeText={eval(row.customizeText)} cellTemplate={eval(row.cellTemplate)} cellRender={eval(row.cellRender)}
                                                    calculateFilterExpression={this.calculateFilterExpression} sortOrder={row.sortOrder} />
                                          )
                                        }
                                    })
                                }
                                <Template name="gridSearchPanelTemplate" render={renderSearchPanel}/>
                                <Template name="gridBulkActionsSelect" render={renderBulkActionsSelect}/>
                            </DataGrid>
                            <Popup ref={(ref) => this.manageDevicePopup = ref} id="manageDevicesPopupId"
                                maxwidth= { () => window.innerWidth/1.5}
                                maxHeight= '95%'
                                showTitle={true}
                                title={title}
                                dragEnabled={true}
                                closeOnOutsideClick={false}
                                visible={popupVisible}
                                onHiding={this.handlePopupHidden}
                                resizeEnabled={true}
                            >
                                <ScrollView height='100%' width='100%' direction='both' id='actions-scrollview' ref={(ref) => this.actionsScrollView = ref}>
                                        <div>
                                            {content}
                                        </div>
                                </ScrollView>
                            </Popup>
                             <LoadPanel
                                shadingColor="rgba(0,0,0,0.4)"
                                position={{ of: '#manageDevicesPopupId'}}
                                container="#actions-scrollview"
                                visible={!isCommandExecuted}
                            />
                             <LoadPanel
                                shadingColor="rgba(0,0,0,0.4)"
                                position={{ of: '#summary-container' }}
                                visible={isSummaryDataLoading}
                            />
                            <Popover
                                target='#manageDevicesExportBtn'
                                showCloseButton={true}
                                showEvent='click'
                                position='bottom'
                                width={250}
                                showTitle={true}
                                title='Export'
                            >
                                <Form
                                    id='manageDevicesExportForm'
                                    onContentReady={this.validateForm}
                                    readOnly={false}
                                    colCount={1}
                                >
                                    <Item dataField='Data to Export' editorType='dxSelectBox' editorOptions={{items: dataToExportItems,value: dataToExport, valueExpr: 'value', displayExpr: 'text',
                                        onValueChanged: this.handleDataToExport }}
                                        validationRules={this.validationRules.dataToExport}/>
                                    <Item dataField='Export Type' editorType='dxSelectBox' editorOptions={{items: exportTypes,
                                        value: exportType, valueExpr: 'value', displayExpr: 'text'}} validationRules={this.validationRules.exportType}/>
                                    <Item dataField='Max # of Records' editorType='dxNumberBox' editorOptions={{value: maxRecords, max: 10000, min: 1, showClearButton: true, height: 30,
                                        disabled: maxRecordsDisabled }}
                                    validationRules={this.validationRules.maxRecords} />
                                </Form>
                                <ToolbarItem
                                    widget='dxButton'
                                    location='after'
                                    toolbar='bottom'
                                    options={{
                                        type: 'default',
                                        icon: 'export',
                                        hint: 'Export',
                                        text: 'Export',
                                        onClick: function() {

                                        }
                                    }}
                                >
                                </ToolbarItem>
                            </Popover>
                            <Dock position="right" fluid={true} dimMode='none' zIndex='1250' duration={200} isVisible={this.state.isDockVisible} >
                                <Card  raise={true}>
                                    <CardHeader className={ classes.cardHeader } title={title}
                                            action={
                                                    <IconButton onClick={this.handleCloseManageDevicesDock}>
                                                      <Close/>
                                                    </IconButton>
                                                }
                                        />
                                        { value === 0 && <LuceneHelp /> }
                                        { value === 1 && <DeviceDetails key={deviceData.name} dName={deviceData.name} userInfo={userInfo} /> }
                                        { value === 2 && <PamSuspendView key='pam-suspend-view' deviceName={selectedDevices} userInfo={userInfo} /> }
                                        { value === 3 && <PamSuspendGrid key='pam-suspend-grid' deviceNames={selectedDevices} userInfo={userInfo} /> }
                                </Card>
                            </Dock>
                    </Card>
                </div>
            </Fullscreen>
        )
    }
}

ManageDevices.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(ManageDevices);
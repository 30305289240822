import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { Template } from 'devextreme-react/core/template';
import { DataGrid, RemoteOperations, Paging, Pager, Column, ColumnChooser, LoadPanel, SearchPanel, Scrolling, Export } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import Fullscreen from "react-full-screen";

import fetchClient  from '../../../axios';
import { textFormatter, stateRender, epochDateFormatter, secondsToStringFormatter } from "../../common/utils";
import ArchivedEventsRowActionsMenu from './ArchivedEventsRowActionsMenu';

const styles = theme => ({
    root: {
        width: '100%'
    },
    cardHeader: {
        padding: `${theme.spacing.unit} !important`,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
        height: theme.mixins.cardHeader.height,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    }
});

const ArchivedEventsGridColumns = [
    { dataField: "severity", caption: "Severity", alignment: 'center', cellRender: stateRender, fixed: true, width: 90 },
    { dataField: "elementName", caption: "Device Name", customizeText: textFormatter, fixed: true, width: 100 },
    { dataField: "instanceDisplayName", caption: "Part Name", customizeText: textFormatter, fixed: true, width: 100 },
    { dataField: "elementClassName", caption: "Device Type", customizeText: textFormatter, width: 100 },
    { dataField: "eventDisplayName", caption: "Event", customizeText: textFormatter, width: 100 },
    { dataField: "eventText", caption: "Event Description", customizeText: textFormatter, width: 100 },
    { dataField: "lastNotifiedAt", caption: "Last Notify", cellRender: epochDateFormatter, width: 100 },
    { dataField: "clearedAt", caption: "Last Clear", cellRender: epochDateFormatter, sortOrder: 'desc', width: 100 },
    { dataField: "clearDuration", caption: "Duration", cellRender: secondsToStringFormatter, width: 100 },
    { dataField: "count", caption: "Count", width: 70 },
    { dataField: "troubleTicketID", caption: "Case ID", customizeText: textFormatter, width: 100 }
];
class ArchivedEvents extends PureComponent {
    constructor(props) {
        super(props);
        this.state= {
            query: "NOT nmd:'' AND active:false",
            isFullScreen: false
        };
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.renderGridTitle = this.renderGridTitle.bind(this);
        this.renderArchivedEventsActionsMenu = this.renderArchivedEventsActionsMenu.bind(this);
        this.handleFullScreen = this.handleFullScreen.bind(this);
        this.archivedEventsDataGrid = null;
    }
    componentWillMount() {
        //this.getDevicesData();
    }

    componentDidMount() {
        //this.devicesTask = setInterval(()=> this.getDevicesData(), constants.TASK_TIME)
    };
    componentWillUnmount () {
        //clearTimeout(this.devicesTask);
    };
    refreshDataGrid () {
        this.archivedEventsDataGrid.instance.refresh();
    };
    renderGridTitle = () => {
        return (
            <div>
                <Typography variant={"h5"} component={"h5"}>Archived Events</Typography>
            </div>)
    };
    onRowPrepared = (e) => {
        if(e.rowType === 'data') {
            switch (e.data.severity) {
                case 1:
                    e.rowElement.style.backgroundColor = '#f44336';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Critical';
                    break;
                case 2:
                    e.rowElement.style.backgroundColor = '#ff9800';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Major';
                    break;
                case 3:
                    e.rowElement.style.backgroundColor = '#ffeb3b';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Minor';
                    break;
                case 4:
                    e.rowElement.style.backgroundColor = '#03a9f4';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Unknown';
                    break;
                case 5:
                    e.rowElement.style.backgroundColor = '#008000';
                    e.rowElement.style.color = '#FFFFFF';
                    e.data.severity = 'Normal';
                    break;
                default:
                    //None
            }
        }
    };
    onToolbarPreparing = (e) => {
        e.toolbarOptions.elementAttr = {id: 'dx-datagrid-header-panel'};
        let toolbarItems = e.toolbarOptions.items, searchPanel, columnChooserPanel, filterButton, item, i,
            items = e.toolbarOptions.items;
            for (i = items.length - 1; i >= 0; i--) {
                    item = items[i];
                    if(item.name === 'searchPanel') {
                        items.splice(i, 1);
                        searchPanel = item;
                        searchPanel.location = 'after';
                        searchPanel.locateInMenu = 'auto';
                    }
                    if(item.name === 'columnChooserButton') {
                        items.splice(i, 1);
                        columnChooserPanel = item;
                        columnChooserPanel.location = 'after';
                        columnChooserPanel.locateInMenu = 'auto';
                        columnChooserPanel.options.type = 'default';
                    }
                    /*if(item.name === 'exportButton') {
                        items.splice(i, 1);
                        exportButton = item;
                    }*/
                    if(item.name === 'applyFilterButton') {
                        items.splice(i, 1);
                        filterButton = item;
                        filterButton.options.icon = 'apply-filter';
                    }
            }
            toolbarItems.push({
                    location: 'before',
                    template: 'gridTitleTemplate'
                }, searchPanel , columnChooserPanel,
                {
                    location: 'after',
                    widget: 'dxButton',
                    locateInMenu: 'auto',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: 'mdi mdi-refresh',
                        hint: 'Refresh',
                        text: 'Refresh',
                        onClick: this.refreshDataGrid.bind(this)
                    }
                },{
                    location: 'after',
                    locateInMenu: 'never',
                    widget: 'dxButton',
                    showText: 'inMenu',
                    options: {
                        type: 'default',
                        icon: this.state.isFullScreen ? 'mdi mdi-fullscreen-exit' : 'mdi mdi-fullscreen',
                        hint: this.state.isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
                        text: this.state.isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
                        onClick: this.handleFullScreen.bind(this)
                    }
                });
    };
    onContentReady =  (e) => {
        /* work around for column chooser popup position */
        let columnChooserView = e.component.getView("columnChooserView");
        if (!columnChooserView._popupContainer) {
            columnChooserView._initializePopupContainer();
            columnChooserView.render();
            columnChooserView._popupContainer.option("position", { of: e.element, my: "right top", at: "right top", offset: "-40 40"});
        }
        /* work around for column chooser popup position */
    };
    renderArchivedEventsActionsMenu = (cellInfo) => {
        return <ArchivedEventsRowActionsMenu rowData={cellInfo.data} />
    };
    handleFullScreen = () => {
        this.setState({ isFullScreen: !this.state.isFullScreen });
        //this.setState({ isFullScreen: !this.state.isFullScreen }, () => { this.archivedEventsDataGrid.instance.option("height", 650); });
    };
    render(){
        const { classes, raised=false, isFullScreen } = this.props;
        const { query } = this.state;
        const archivedEventsDataStore = {
            store: new CustomStore({
                load: function(loadOptions) {
                    let params = { query };
                        if (loadOptions.sort) {
                            params.sort = loadOptions.sort[0].selector;
                            params.order = loadOptions.sort[0].desc ? 'desc' : 'asc';
                        }
                        params.offset = loadOptions.skip ? loadOptions.skip : 0;
                        params.limit = loadOptions.take ? loadOptions.take : 100;
                        return fetchClient.get('/api/v1.0/es/historical-alerts', {params: params})
                            .then(res => {
                                let devicesData = [];
                                let devicesDataTotal = 0;
                                if (res.data && res.data.data) {
                                    devicesData = res.data.data;
                                    devicesDataTotal = res.data.total;
                                }
                                return {
                                    data: devicesData,
                                    totalCount: devicesDataTotal
                                };
                            })
                            .catch (() => { throw new Error('Data Loading Error');});
                }
            })
        };
        return (
           <Fullscreen enabled={this.state.isFullScreen} onChange={isFullScreen => this.setState({isFullScreen})}>
            <div className="full-screenable-node">
                <Card className={classes.root} raised={raised}>
                    <DataGrid id={'archivedEventsGrid'}
                        ref={(ref) => this.archivedEventsDataGrid = ref}
                        height={isFullScreen? '100%' : `calc((100vh - 134px)/2)`}
                        dataSource={archivedEventsDataStore}
                        showColumnLines={true}
                        showRowLines={true}
                        showBorders={true}
                        //rowAlternationEnabled={true}
                        repaintChangesOnly={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnResizingMode={'widget'}
                        columnAutoWidth={true}
                        columnHidingEnabled={true}
                        wordWrapEnabled={false}
                        //columnWidth={100}
                        //columnMinWidth={70}
                        cellHintEnabled={true}
                        onToolbarPreparing={this.onToolbarPreparing}
                        onRowPrepared={this.onRowPrepared}
                        onContentReady={this.onContentReady}
                        >
                        <SearchPanel visible={true} width={200} placeholder={'Search ...'}/>
                        <ColumnChooser enabled={true} mode={'select'}/>
                        <Scrolling showScrollbar={'always'} useNative={false} />
                        <LoadPanel enabled={true}/>
                        <Export enabled={true} fileName={'ArchivedEvents'} />
                        <Column type="button" caption="Actions" alignment="center" fixed={true} width={70} cellRender={this.renderArchivedEventsActionsMenu}/>
                        {
                            ArchivedEventsGridColumns.map(row => {
                              return (
                                      // eslint-disable-next-line
                                <Column dataField={row.dataField} caption={row.caption} width={row.width} alignment={row.alignment} customizeText={eval(row.customizeText)} cellRender={eval(row.cellRender)} fixed={row.fixed} sortOrder={row.sortOrder}/>
                              );
                            })
                        }
                        <RemoteOperations sorting={true} paging={true} />
                        <Paging defaultPageSize={25}/>
                        <Pager infoText="Page {0} of {1} ({2})" showNavigationButton={true} showInfo={true} showPageSizeSelector={true} allowedPageSizes={[15, 25, 50, 100]}/>
                        <Template name="gridTitleTemplate" render={this.renderGridTitle}/>
                    </DataGrid>
                </Card>
            </div>
           </Fullscreen>
        )
    }
}

ArchivedEvents.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default enhance(ArchivedEvents);
import React, { Component } from 'react';
import AppLayout from './components/AppLayout';
import LightBaseTheme from './themes/lightBaseTheme';
import DarkBaseTheme from './themes/darkBaseTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles, MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
//import 'devextreme/dist/css/dx.common.css';
//import 'devextreme/dist/css/dx.material.blue.light.css';
//import 'devextreme/dist/css/dx.material.blue.dark.css';
import themes from "devextreme/ui/themes";
import * as constants from "./constants";
import compose from "recompose/compose";


const styles = theme => ({
  icon: {
    fontSize: 20,
  },
});

/*const HOST = process.env.REACT_APP_HOST;
const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = process.env.REACT_APP_CALLBACK_PATH;
const SCOPES = process.env.REACT_APP_OKTA_SCOPES;
const REDIRECT_URI = `${HOST}${CALLBACK_PATH}`;

if (!HOST || !OKTA_DOMAIN || !ISSUER || !CLIENT_ID || !CALLBACK_PATH || !SCOPES) {
    throw new Error("All environmental variables must be set")
}
const AUTH_CONFIG = {
      // Note: If your app is configured to use the Implicit Flow
      // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
      // you will need to add `pkce: false`
      issuer: ISSUER,
      clientId: CLIENT_ID,
      redirectUri: REDIRECT_URI,
      scopes: SCOPES.split(','),
      tokenManager: {
        autoRenew: true//,
        //expireEarlySeconds: 300 // 5 minutes early
      },
      pkce: false
};*/
//const history = useHistory();
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            themeType: 'Light'
        };
        this.theme = responsiveFontSizes(createMuiTheme(LightBaseTheme));
        this.changeTheme = this.changeTheme.bind(this);
        this.customAuthHandler = this.customAuthHandler.bind(this);
        this.oktaAuth = new OktaAuth(constants.AUTH_CONFIG);
        this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
          props.history.replace(toRelativeUrl(originalUri, window.location.origin));
        };
    }
    componentWillMount() {
        this.updateTheme();
    }

    componentDidMount() {

    };
    componentWillUnmount () {

    };
    updateTheme = (tType) => {
        let { themeType } = this.state;
        if(tType) {
            themeType = tType
        }
        if (themeType === 'Dark') {
            themes.current("material.blue.dark");
            this.theme = responsiveFontSizes(createMuiTheme(DarkBaseTheme));
        } else if (themeType === 'Light') {
            themes.current("material.blue.light");
            this.theme = responsiveFontSizes(createMuiTheme(LightBaseTheme));
        } else {
            themes.current("material.blue.light");
            this.theme = responsiveFontSizes(createMuiTheme(LightBaseTheme));
        }
    };
    changeTheme = (themeType) => {
        this.updateTheme(themeType);
        this.setState({themeType});
    };
    customAuthHandler = () => {
        //const tokenManager = this.oktaAuth.tokenManager;
        this.props.history.push('/login');
/*        this.oktaAuth.token.getWithoutPrompt({
          responseType: ['token','id_token']//, // or array of types
          //sessionToken: 'testSessionToken' // optional if the user has an existing Okta session
        })
        .then(function(res) {
          let tokens = res.tokens;

          // Do something with tokens, such as
          tokenManager.add('idToken', tokens.idToken);
        })
        .catch(function(err) {
          // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
        });*/
    };
  render() {
    // eslint-disable-next-line
    const { classes, theme, ...rest } = this.props;
    const { themeType } = this.state;
    return (
        <Security oktaAuth={ this.oktaAuth } restoreOriginalUri={this.restoreOriginalUri} onAuthRequired={this.customAuthHandler}>
            <CssBaseline />
            <MuiThemeProvider theme={this.theme}>
                <AppLayout changeTheme={this.changeTheme} selectedTheme={themeType} />
            </MuiThemeProvider>
        </Security>
    );
  }
}

const enhance = compose(
    withStyles(styles, {withTheme: true})
);
export default withRouter(enhance(App));

